
<template>
  <div class="automl">
    <el-container direction="vertical">
      <el-header style="height: 56px;padding:0px;">
        <automl-header />
      </el-header>
      <el-container>
        <el-aside width="auto">
          <div class="aside">
            <el-menu
              :default-active="active"
              background-color="#E5E7EB"
              text-color="#333333"
              active-text-color="#012D87"
              :collapse="nav"
            >
              <el-menu-item
                index="1"
                @click="routePush('AutoML')"
              >
                <i class="iconfont iconicon_zhuye" />
                <span
                  class="navTitle"
                  slot="title"
                >{{$t('router.Home')}}</span>
              </el-menu-item>
              <el-menu-item
                index="2"
                @click="routePush('Mdm')"
              >
                <i class="iconfont iconicon_shujujiguanli1" />
                <span
                  class="navTitle"
                  slot="title"
                >{{$t('MdmHome.title')}}</span>
              </el-menu-item>
              <el-menu-item
                index="3"
                @click="routePush('Auto');sessionClear();"
              >
                <i class="iconfont iconicon_jiqizidonghua1" />
                <span
                  class="navTitle"
                  slot="title"
                >{{$t('MdmHome.BoxMainButton2')}}</span>
              </el-menu-item>
              <el-menu-item
                index="4"
                @click="routePush('Model')"
              >
                <i class="iconfont iconicon_moxing" />
                <span
                  class="navTitle"
                  slot="title"
                >{{$t('HomePage.zyxog5')}}</span>
              </el-menu-item>
              <el-menu-item
                index="5"
                @click="routePush('TemplateSet')"
              >
                <i
                  style="margin:0 -2px 0 -3px"
                  class="el-icon-document"
                />
                <span
                  class="navTitle"
                  slot="title"
                >{{$t('router.TemplateSet')}}</span>
              </el-menu-item>
            </el-menu>
            <div class="asideBottom">
              <span
                class="title"
                v-show="!nav"
              >{{$t('biao-qian-wen-jian-jia')}}</span>
            </div>
            <el-menu
              background-color="#E5E7EB"
              text-color="#333333"
              active-text-color="#303133"
              :collapse="nav"
            >
              <el-menu-item
                @click="open()"
              >
                <i class="el-icon-plus" />
                <span
                  class="navTitle"
                  slot="title"
                >{{$t('xin-jian-mu-lu')}}</span>
              </el-menu-item>
            </el-menu>
            <el-menu
              :default-active="listActive!=undefined?listActive.toString():listActive"
              background-color="#E5E7EB"
              text-color="#333333"
              active-text-color="#012D87"
              :collapse="nav"
            >
              <el-menu-item
                @click="routePushData('TagModel',item.id);sonClick()"
                v-for="(item,index) in list"
                :key="index"
                :index="index.toString()"
              >
                <i :class="listActive==index?'el-icon-folder-opened':'el-icon-folder'" />
                <div
                  class="navTitle"
                  slot="title"
                >
                  <el-dropdown class="r">
                    <span class="el-dropdown-link">
                      <i class="el-icon-arrow-down" />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="ListPut(item.id,item.name)">
                        {{$t('MdmHome.dialogButton1')}}
                      </el-dropdown-item>
                      <el-dropdown-item @click.native="ListDel(item.id)">
                        {{$t('shan-chu')}}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <span class="titleMain l">{{ item.name }}</span>
                </div>
              </el-menu-item>
            </el-menu>
          </div>
        </el-aside>
        <el-container>
          <el-main
            id="el-main"
            ref="elmain"
          >
            <router-view
              id="router-view"
              ref="Childmain"
            />
            <footer-a id="footer" />
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import FooterA from '../../components/Footer'
import AutomlHeader from '../../components/AutomlHeader'
export default {
  data() {
    return {
      headImgUrl:'',
      show1: false,
      nav:false,
      active:'1',
      clientWidth: document.body.clientWidth,
      timer:'',
      hoverFalseData:'',
      list:[],
      listActive:'-1',
    };
  },
  watch:{
    // 监听窗口变化自适应侧边栏
    clientWidth: function(val){
    if (!this.timer) {
    this.clientWidth = val
    this.timer = true
    setTimeout(()=> {
      if(val<1365){
      this.nav=true
      }else{
      this.nav=false
      }
    this.timer = false
    }, 400)
    }
    }
  },
  mounted() {
    this.getList()
    this.indexTrue()
    this.active = this.$route.matched[1].meta.active;
    window.onresize =()=>{ this.clientWidth = document.body.clientWidth };
  },
  beforeUpdate(){
    this.indexTrue()
    this.active=this.$route.matched[1].meta.active;
    this.clientWidth = document.body.clientWidth;
    if(this.clientWidth<1365){
    this.nav=true
    }else{
    this.nav=false
    }
  },
  methods:{
    //循环匹配选中状态
    indexTrue(){
    for(let key in this.list){
        if(this.list[key].id==this.$route.query.tag_id){
          this.listActive = key
          this.$route.matched[1].meta.title =`${this.list[key].name}${this.$t('ren-wu-lie-biao')} | ${this.$GlobalVal.ORG_NAME}`
        }
      }
    },
    //获取菜单列表
    getList(){
      this.axios.get(window.apis.MODELS_TAG).then(res=>{
        this.list = res.data
        this.indexTrue()
      })
    },
    //删除操作方法
    ListDel(id){
      this.$confirm(this.$t('MdmHome.delConfirm'), this.$t('MdmHome.delTitle'), {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
      if(this.$route.query.tag_id==id){
        this.$router
        .push({
          name: 'Model'
        })
        this.listActive = '-1'
      }
      this.axios.delete(`${window.apis.MODELS_TAG}${id}/`).then(res=>{
      console.log(res)
      this.getList();
      this.sonClick()
      })}).catch(() => {});
    },
    //重命名方法
    ListPut(id,name){
      this.$prompt('', this.$t('cmmwjj'), {
      confirmButtonText: this.$t('zhong-ming-ming'),
      cancelButtonText: this.$t('qu-xiao'),
      inputPattern: /^[\u4E00-\u9FA5A-Za-z0-9_]{2,10}$/,
      inputErrorMessage: '支持中英文数字下划线2-10位',
      inputValue:name
    }).then(({ value }) => {
      this.axios.put(`${window.apis.MODELS_TAG}${id}/`,{name:value}).then(res=>{
        if (res.status === 200 || res.status === 201) {
        this.getList();
        console.log(res)
        this.$message({
          type: 'success',
          message:  '修改成功'
        });
        }
      })
    }).catch(() => {
      // this.$message({
      //   type: 'info',
      //   message: '取消输入'
      // });
    });
    },
    //创建新目录方法
    open() {
    this.$prompt('', this.$t('chuang-jian-xin-mu-lu'), {
      // confirmButtonText: '创建',
      // cancelButtonText: '取消',
      inputPattern: /^[\u4E00-\u9FA5A-Za-z0-9_]{2,10}$/,
      inputErrorMessage: this.$t('zhi-chi-zhong-ying-wen-shu-zi-xia-hua-xian-210-wei')
    }).then(({ value }) => {
      this.axios.post(window.apis.MODELS_TAG,{name:value}).then(res=>{
        if (res.status === 200 || res.status === 201) {
        this.$message({
          type: 'success',
          message:  this.$t('chuang-jian-cheng-gong')
        });
        this.sonClick()
        this.getList()
        }})
    }).catch(() => {
      // this.$message({
      //   type: 'info',
      //   message: '取消输入'
      // });
    });
    },
    routePush(dest) {
      if (this.$router.history.current.name != dest) {
        this.$router
        .push({
          name: dest
        })
        this.listActive = '-1'
      }
    },
    routePushData(dest,data) {
    this.$router
    .push({
      name: dest,
      query: {
      tag_id: data,
    },
    })
    },
    sessionClear(){
      sessionStorage.clear();
      this.$store.commit('dataset_id', '');
      this.$store.commit('dataset_name', '');
      this.$store.commit('task_type','');
      this.$store.commit('target_column', '');
      this.$store.commit('desc', '');
      this.$store.commit('read_mode_header', '');
    },
    sonClick() {
      try{
      this.$refs.Childmain.getModelList()
      this.$refs.Childmain.getList()}
      catch{
        console.log('err')
      }
    },
  },
  computed: {
  ...mapState(['user','HomeLoading','roles'])
  },
  components: {
    FooterA,
    AutomlHeader
  }
}
</script>
<style lang="scss" scoped>
.el-dropdown-link {
  display: none;
}
.el-menu-item:hover {
  .el-dropdown-link {
    display: block;
  }
}
#router-view {
  min-height: calc(100% - 119px);
  height: auto;
}
.iRotate {
  transform: translate(-50%,0) rotate(180deg);
}
.el-menu-item{
  height: 44px;
  line-height: 44px;
  i {
    font-size: 18px;
  }
}
.automl {
  overflow: hidden;
}
.navTitle {
  display: inline-block;
//   width: calc(100vw - 1805px);
//   max-width: 130px;
//   min-width: 96px;
  margin:0 10px;
  font-size: 16px;
  font-weight: 500;
  .titleMain {
    display: block;
   //  width: 65px;
    overflow:hidden; //超出的文本隐藏
    text-overflow:ellipsis; //溢出用省略号显示
    white-space:nowrap; //溢出不换行
  }
  }

.aside {
  position: relative;
  height: calc(100vh - 56px);
  background:#E5E7EB;
  text-align: left;
  overflow: auto;
  .handover {
  position: absolute;
  left: 50%;
  bottom: 10%;
  transform: translate(-50%,0);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  }
}
.asideBottom {
  margin-top: 10px;
  .title {
    width: 100%;
    display: block;
    font-size: 15px;
    padding-left: 20px;
    margin-bottom: 10px;
    text-align: left;
  }
}
#el-main {
  width: calc(100vw - 184px);
  padding: 0;
  height: calc(100vh - 56px);
  overflow: scroll;
}
</style>