<template>
  <div class="Model">
    <breadcrumb />
    <div class="ModelTitleBox">
      <h3 class="ModelTitle l">
        {{$t('router.Model')}}
        <el-tooltip class="item" effect="light" :content="$t('HomePage.9ph58g')" placement="top">
          <i class="iconfont iconbangzhu1"></i>
        </el-tooltip>
      </h3>
      <el-input
        :placeholder="$t('Analysis.input')"
        clearable
        v-model="search"
        @keyup.enter.native="getModelList"
        class="inputSelect r"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="getModelList"
        />
      </el-input>
      <el-select
        v-model="value"
        @change="selectGetModelList"
        :placeholder="$t('MdmHome.select')"
        class="r"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div class="ModelBox">
      <el-table
        :data="models"
        size="mini"
        width="100%"
        :header-cell-style="{background:'#F5F7FA',color:'#909399'}"
      >
        <el-table-column
          prop="task_name"
          :label="$t('Prediction.name')"
          width="233"
        >
          <template slot-scope="{row,$index}">
            <div>
              <div
                class="ifTrueBox"
                v-if="editable[$index]"
              >
                <el-input
                  style="width:65%"
                  size="small"
                  maxlength="20"
                  :disabled="editableDisabled[$index]"
                  :ref="'myNameId'+$index"
                  @blur="blurInput($index)"
                  v-model="task_name_input[$index]"
                >
                  <!-- <el-button slot="append" @click="task_nameClick(row,$index)">保存</el-button> -->
                </el-input>
                <el-button
                  type="primary"
                  icon="el-icon-check"
                  circle
                  style="margin-left:5px;"
                  size="small"
                  @click="task_nameClick(row,$index)"
                />
              </div>
              <span v-else>{{ row.task_name }}  <i
                @click="chengenum(row,$index)"
                class="el-icon-edit u-btn"
                circle
              /></span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="task_type_cn"
          label=""
        >
          <template slot-scope="{row}">
            <el-tag style="margin-right: 5px;" size="medium" v-for="item in row.tags_name" :key='item._index' @close="tagClose(row.id,item,row.index)" closable>{{item}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="task_type_cn"
          :label="$t('pretreatment.table2')"
        />
        <el-table-column
          prop="snapshot_dataset_name"
          :label="$t('Analysis.title')"
        />
        <!-- <el-table-column
        prop=""
        label="采样率">
      </el-table-column>
      <el-table-column
        prop=""
        label="运行">
      </el-table-column>
      <el-table-column
        prop=""
        label="持续时间">
      </el-table-column> -->
        <el-table-column
          prop="stage_cn"
          :label="$t('zhuang-tai')"
        />
        <el-table-column
          prop="created"
          :label="$t('HomePage.ynbeia')"
        >
          <template slot-scope="scope">
            {{
              scope.row.created | fmtdate
            }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('pretreatment.table6')"
          width="240"
        >
          <template slot-scope="scope">
            <!-- <el-button
              :disabled="PredictionTrue(scope.row)"
              @click="PredictionFn(scope.row)"
              type="primary"
              size="small"
            >
              预测
            </el-button> -->
            <el-dropdown @visible-change="changeValue1($event,scope.row.id,scope.row.tags)" :hide-on-click='false'>
              <el-button type="primary" plain size="small" style="margin-right:10px">
                <i class="el-icon-folder-opened"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-checkbox-group v-model="scope.row.tags">
                  <el-dropdown-item @click.native="clickParent()"><i class="el-icon-plus" />{{$t('xin-jian-mu-lu')}}</el-dropdown-item>
                  <el-dropdown-item v-for="item in TagList" :key='item._index'><el-checkbox :label="item.id">{{item.name}}</el-checkbox></el-dropdown-item>
                </el-checkbox-group>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              @click="showDetail(scope.row)"
              type="primary"
              size="small"
              style="margin-right:10px"
              plain
            >
              {{$t('HomePage.ccharh')}}
            </el-button>
            <el-dropdown>
              <el-button type="primary" size="small">
                {{$t('geng-duo')}}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu size="small" slot="dropdown">
                <el-tooltip class="item" :disabled="!PredictionTrue(scope.row)" effect="light" :content="$t('qing-zai-wan-cheng-mo-xing-xun-lian-hou-jin-hang-mo-xing-yu-ce')" placement="left">
                  <span>
                      <el-dropdown-item @click.native="PredictionFn(scope.row)" :disabled="PredictionTrue(scope.row)">{{$t('router.Prediction')}}</el-dropdown-item>
                  </span>
                </el-tooltip>
                <el-dropdown-item @click.native="handleDelete(scope.row.id, scope.row)">{{$t('shan-chu')}}</el-dropdown-item>
                <el-tooltip class="item" :disabled="!PredictionTrue(scope.row)" effect="light" :content="$t('qing-zai-wan-cheng-mo-xing-xun-lian-hou-jin-hang-mo-xing-die-dai')" placement="left">
                  <span>
                    <el-dropdown-item @click.native="toModelFn(scope.row)" :disabled="PredictionTrue(scope.row)">{{$t('mo-xing-die-dai')}}</el-dropdown-item>
                  </span>
                </el-tooltip>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- <el-button
              @click="handleDelete(scope.row.id, scope.row)"
              type="danger"
              size="small"
            >
              删除
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        background
        :total="count"
      />
    </div>
    <!-- 删除提示框 -->
    <!-- <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteRow">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb";
export default {
  name: "Model",
  components: {
    Breadcrumb,
  },
  data() {
    return {
      checkList:[],
      task_name_input: [],
      showDetailData: [],
      editable:[],
      particulars: false,
      models: [],
      delVisible: false, //删除提示弹框的状态
      msg: "", //记录每一条的信息，便于取id
      currentPage: 1, // 当前页
      pageSize: 10, // 页面大小
      count: 0,
      search: "",
      options: [
        {
          value: "",
          label: this.$t('MdmHome.optionsLabel1'),
        },
        {
          value: "0",
          label: this.$t('MdmHome.dialogRadio1'),
        },
        {
          value: "1",
          label: this.$t('MdmHome.dialogRadio2'),
        },
        {
          value: "2",
          label: this.$t('MdmHome.dialogRadio3'),
        },
        {
          value: "3",
          label: this.$t('MdmHome.optionsLabel5'),
        },
      ],
      value: "",
      task_nameClickTrue: [],
      editableDisabled: [],
      tagsListTrue:'',
    };
  },
  mounted() {
    this.getList();
    this.getModelList();
  },
  methods: {
    clickParent(){
      this.$parent.$parent.$parent.$parent.$parent.open()
    },
    tagClose(id,item,index){
      let indexDel = this.models[index].tags_name.indexOf(item)
      this.models[index].tags_name.splice(indexDel, 1);
      this.models[index].tags.splice(indexDel, 1);
      this.axios.put(`${window.apis.SYS_MODELS}${id}/`,{tags:this.models[index].tags}).then(res=>{console.log(res);this.getModelList();})
    },
    changeValue1(a,id,tagsList){
      if(!a && this.tagsListTrue != tagsList){
        console.log(this.tagsListTrue ,tagsList)
      this.axios.put(`${window.apis.SYS_MODELS}${id}/`,{tags:tagsList}).then(res=>{console.log(res);this.getModelList();})
      }else{
        this.tagsListTrue = tagsList
      }
    },
    getList(){
      this.axios.get(window.apis.MODELS_TAG).then(res=>{
        this.TagList = res.data
      })
    },
    task_nameClick(row,index){
      this.task_nameClickTrue[index] = true
      this.editableDisabled[index] = true
      let data = {
        task_name:this.task_name_input[index]
      }
      this.axios({url: `${window.apis.SYS_MODELS}${row.id}/`,method: 'put',data:data}).then(res => {
        if(res.status == 200 || res.status == 201){
        this.$message({
          message: this.$t('ren-wu-ming-cheng-xiu-gai-cheng-gong'),
          type: "success",
        });
        this.models[index].task_name = this.task_name_input[index]
        this.task_nameClickTrue[index] = false
        this.editableDisabled[index] = false
          this.editable[index] = false;
          this.$set(this.editable,index,false);
        }else{
        this.task_nameClickTrue[index] = false
        this.editableDisabled[index] = false
        }
      })
    },
    blurInput(index){
      setTimeout(()=>{
        console.log(this.task_nameClickTrue[index])
        if(!this.task_nameClickTrue[index]){
          this.editable[index] = false;
          this.$set(this.editable,index,false);
          this.task_nameClickTrue[index] = false
        }
      },500)
    },
    chengenum(row,index){
      setTimeout(()=>{this.$refs['myNameId'+ index].focus()},50)
      this.editable[index] = true;
      this.$set(this.editable,index,true);
      this.task_name_input[index] = row.task_name
    },
    selectGetModelList(){
      this.currentPage = 1
      this.getModelList()
    },
    getModelList() {
      let url = window.apis.SYS_MODELS;
      let params = {
        page: this.currentPage,
      };
      if (
        typeof this.search == "undefined" ||
        this.search == "" ||
        this.search == null
      ) {
        console.log();
      } else {
        params["name"] = this.search;
      }
      if (
        typeof this.value == "undefined" ||
        this.value == "" ||
        this.value == null
      ) {
        console.log();
      } else {
        params["task_type"] = this.value;
      }

      this.axios
        .get(url, {
          params: params,
        })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.models = res.data.results;
            this.count = res.data.count;
            for (var key in this.models) {
              this.models[key].index = key
              switch (this.models[key].task_type) {
                case 0:
                  this.models[key].task_type_cn = this.$t('MdmHome.taskType1');
                  break;
                case 1:
                  this.models[key].task_type_cn = this.$t('MdmHome.dialogRadio2');
                  break;
                case 2:
                  this.models[key].task_type_cn = this.$t('MdmHome.dialogRadio3');
                  break;
                case 3:
                  this.models[key].task_type_cn = this.$t('MdmHome.optionsLabel5');
                  break;
              }
              switch (this.models[key].stage) {
                case 1:
                  this.models[key].stage_cn = this.$t('router.Analysis');
                  break;
                case 2:
                  this.models[key].stage_cn = this.$t('router.Analysis');
                  break;
                case 3:
                  this.models[key].stage_cn = this.$t('router.PomaAuto');
                  break;
                case 4:
                  this.models[key].stage_cn = this.$t('router.Training');
                  break;
                case 5:
                  this.models[key].stage_cn = this.$t('HomePage.dx5p3l');
                  break;
                case 6:
                  this.models[key].stage_cn = this.$t('HomePage.dx5p3l');
                  break;
              }
              if(this.models[key].stage == 1 || this.models[key].stage == 2 || this.models[key].stage == 4){
              switch(this.models[key].state){
              case -2:this.models[key].stage_cn = this.models[key].stage_cn + " | "+ this.$t('HomePage.uma1ev');
              break;
              case -1:this.models[key].stage_cn = this.models[key].stage_cn + " | "+ this.$t('HomePage.j20cda');
              break;
              case 0:this.models[key].stage_cn = this.models[key].stage_cn + " | "+ this.$t('HomePage.xb0pgf');
              break;
              case 1:this.models[key].stage_cn = this.models[key].stage_cn + " | "+ this.$t('HomePage.f8byqc');
              break;
              case 2:this.models[key].stage_cn = this.models[key].stage_cn + " | "+this.$t('HomePage.kutvux');
              break;
            }}
            }
          } else {
            this.$message({
              message: this.$t('HomePage.message1'),
              type: "warning",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    PredictionFn(row) {
      this.$store.commit("task_id", row.id);
      this.$store.commit("task_name", row.task_name);
      this.$store.commit("dataset_id", row.dataset_id);
      this.$store.commit("task_type", row.task_type);
      this.$store.commit("target_column", row.target_column);
      this.$store.commit("dataset_name", row.dataset_name);
      this.$store.commit("headerData", row.header);
      this.$router.push({
        name: "Prediction",
      });
    },
    toModelFn(row) {
      this.$store.commit("task_id", row.id);
      this.$store.commit("task_name", row.task_name);
      this.$store.commit("dataset_id", row.dataset_id);
      this.$store.commit("task_type", row.task_type);
      this.$store.commit("target_column", row.target_column);
      this.$store.commit("dataset_name", row.dataset_name);
      this.$store.commit("headerData", row.header);
      this.$router.push({
        name: "Training",
      });
    },
    PredictionTrue(row) {
      if(row.stage>4){
        return false
      }else{
        return true
      }
    },
    showDetail(row) {
      this.$store.commit("task_id", row.id);
      this.$store.commit("task_name", row.task_name);
      this.$store.commit("dataset_id", row.dataset_id);
      this.$store.commit("task_type", row.task_type);
      this.$store.commit("target_column", row.target_column);
      this.$store.commit("dataset_name", row.dataset_name);
      this.$store.commit("headerData", row.header);
      this.$store.commit("stage", row.stage);
      var routeName = "";
      switch (row.stage) {
        case 1:
          routeName = "Analysis";
          break;
        case 2:
          routeName = "Analysis";
          break;
        case 3:
          routeName = "Parameter";
          break;
        case 4:
          routeName = "Training";
          break;
        case 5:
          routeName = "Report";
          break;
        case 6:
          routeName = "Output";
          break;
      }
      this.$router.push({
        name: routeName,
      });
    },
    handleDelete(id, row) {
      let url = window.apis.SYS_MODELS;
      this.msg = row;
      this.$confirm(this.$t('MdmHome.delConfirm'), this.$t('MdmHome.delTitle'), {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
      this.axios.delete(url + id + "/").then((res) => {
      if (res.status === 204) {
        this.count -= 1;
        this.$message({
          message: this.$t('MdmHome.message3'),
          type: "success",
        });
        setTimeout(()=>{this.getModelList();},50)
      }
      }).catch((error) => {
        console.log(error);
      })}
      ).catch(() => {});
    },
    deleteRow() {
      this.delVisible = false;
      let url = window.apis.SYS_MODELS;
      this.axios
        .delete(url + this.idx + "/")
        .then((res) => {
          if (res.status === 204) {
            this.count -= 1;
            this.$message({
              message: this.$t('MdmHome.message3'),
              type: "success",
            });
          setTimeout(()=>{this.getModelList();},50)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleSizeChange(val) {
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getModelList();
    },
    routePush(dest) {
      if (this.$router.history.current.name != dest) {
        this.$router.push({
          name: dest,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  // 修改按钮移入显示
  .u-btn {
    margin-left: 5px;
    opacity: 0;
    color: #396DD6;
    transition: all 500ms;
    font-size: 13px;
    cursor: pointer;
  }
  .u-btn:hover {
    font-size: 17px;
    color: #409EFF;
  }
  .el-table__body tr:hover {
    .u-btn {
      opacity: 1;
    }
  }
.ModelTitleBox {
  // width: 100%;
  min-width: 1018px;
  height: 58px;
  margin: 0 33px;
  padding: 10px 0;

  .ModelTitle {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    padding-top:10px;
  }

  .inputSelect {
    width: 300px;
    margin-left: 20px;
  }
}

.ModelBox {
  position: relative;
  width: auto;
  min-width: 1018px;
  height: auto;
  min-height: 600px;
  padding-bottom: 100px;
  margin: 0 33px;
  border: 1px solid rgba(218, 223, 231, 0.5);
  overflow: hidden;
  .el-pagination {
    position: absolute;
    bottom: 30px;
    right: 50%;
    transform: translate(50%, 0);
  }
}
</style>