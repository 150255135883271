<template>
<div class="LoginNav">
   <automl-header />
   <main>
      <div class="buttonBox" @click="routePush('AutoML')">
         <p>Auto-ML</p>
         <aside>({{ $t('tabular-data') }})</aside>
      </div>
      <div class="buttonBox" @click="routePush('DataSet')">
         <p>Auto-DL</p>
         <aside>({{ $t('img-classification') }})</aside>
      </div>
      <div class="buttonBox" @click="openWindow('https://www.sinoaus.shop/osd/#/')">
         <p>Open-Source</p>
         <aside>({{ $t('AutomlHeader.9ktlfp') }})</aside>
      </div>
      <div class="buttonBox" @click="openWindow('https://bbs.sinoaus.shop/')">
         <p>Coovally-BBS</p>
         <aside>({{ $t('HomeComponents.LoginNav.toqywh') }})</aside>
      </div>
      <i></i><i></i>
   </main>
   <el-dialog
      class="dialog"
      title="用户统计"
      width='500px'
      :visible.sync="dialogForm">
      <el-form
         :model="form"
         :rules="rules"
         ref="ruleForm">
         <el-form-item
            label="职业"
            :label-width="formLabelWidth"
            prop="career">
            <el-select v-model="form.career" placeholder="请选择职业">
               <el-option value="数据分析师"></el-option>
               <el-option value="软件工程师"></el-option>
               <el-option value="数据工程师"></el-option>
               <el-option value="企业高管"></el-option>
               <el-option value="其它"></el-option>
            </el-select>
         </el-form-item>
         <el-form-item
            label="来源"
            :label-width="formLabelWidth"
            prop="source">
            <el-select v-model="form.source" placeholder="请选择来源">
               <el-option value="抖音"></el-option>
               <el-option value="B站"></el-option>
               <el-option value="知乎"></el-option>
               <el-option value="CSDN"></el-option>
               <el-option value="小红书"></el-option>
               <el-option value="朋友推荐"></el-option>
               <el-option value="其他"></el-option>
            </el-select>
         </el-form-item>
         <el-form-item
            label="兴趣"
            :label-width="formLabelWidth"
            prop="interest">
            <el-checkbox-group v-model="form.interest">
               <el-checkbox label="金融"></el-checkbox>
               <el-checkbox label="汽车"></el-checkbox>
               <el-checkbox label="旅游"></el-checkbox>
               <el-checkbox label="房产"></el-checkbox>
               <el-checkbox label="科技"></el-checkbox>
               <el-checkbox label="娱乐"></el-checkbox>
               <el-checkbox label="家装"></el-checkbox>
               <el-checkbox label="生活"></el-checkbox>
               <el-checkbox label="游戏"></el-checkbox>
               <el-checkbox label="教育"></el-checkbox>
               <el-checkbox label="体育"></el-checkbox>
               <el-checkbox label="美食"></el-checkbox>
               <el-checkbox label="母婴"></el-checkbox>
               <el-checkbox label="法律"></el-checkbox>
               <el-checkbox label="服饰"></el-checkbox>
               <el-checkbox label="美妆"></el-checkbox>
               <el-checkbox label="商务服务"></el-checkbox>
            </el-checkbox-group>
         </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
         <el-button @click="dialogForm = false;resetForm">取 消</el-button>
         <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
   </el-dialog>
   <footer-a style="width:980px;margin:0 auto;" />
</div>
</template>

<script>
import AutomlHeader from '../../components/AutomlHeader.vue'
import footerA from "../../components/Footer.vue"

export default {
   data() {
      return {
         dialogForm: false,
         form: {
            career: '',
            source: '',
            interest: [],
         },
         user_id: "",
         formLabelWidth: '70px',
         rules: {
            career: [{
               required: true,
               message: '请选择职业',
               trigger: 'change'
            }, ],
            source: [{
               required: true,
               message: '请选择来源',
               trigger: 'change'
            }],
            interest: [{
               required: true,
               message: '请选择兴趣',
               trigger: 'change'
            }],
         }
      }
   },
   components: {
      AutomlHeader,
      footerA
   },
   mounted() {

      if (this.$route.query.dialog === true || this.$route.query.dialog === 'true') {
         this.dialogForm = true
         this.user_id = this.$route.query.user_id
      }
   },
   methods: {
      openWindow(url) {
         window.open(url, "_blank")
      },
      routePush(dest) {
         this.$router
            .push({
               name: dest
            })
      },
      submitForm() {
         this.$refs['ruleForm'].validate((valid) => {
            if (valid) {
               let config = this.form
               config.interest = config.interest.toString()
               this.axios.put(`${window.apis.SYS_USER}${this.user_id}/`, config).then(res => {
                  if (res.status === 200 || res.status === 201) {
                     this.dialogForm = false
                     this.$refs['ruleForm'].resetFields();
                  }
               })
            }
         });
      },
      resetForm() {
         this.$refs['ruleForm'].resetFields();
      }
   }
}
</script>

<style lang="scss" scoped>
.LoginNav {
   height: 100%;

   .dialog {
      text-align: left;
   }

   header {
      width: 100%;
      height: 80px;
      background: #4568ee;
   }

   main {
      display: flex;
      justify-content: space-evenly;
      min-height: calc(100vh - 175px);
      height: auto;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;

      i {
         width: 485px;
      }
   }

   .buttonBox {
      width: 485px;
      height: 270px;
      box-shadow: 0px 0px 10px 0px rgba(44, 62, 80, 0.1);
      border: 3px solid #fff;
      transition: all 300ms;
      cursor: pointer;
      margin: 40px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      p {
         font-size: 66px;
         font-weight: 400;
         color: #4568ee;
         line-height: 92px;
      }

      aside {
         font-size: 36px;
         font-weight: 400;
         color: #4568ee;
         line-height: 50px;
      }
   }

   .buttonBox:hover {
      box-shadow: 0px 0px 10px 0px rgba(44, 62, 80, 0.08);
      border: 3px solid #4568ee;
   }
}
</style>
