<template>
  <div class="Password">
    <div class="AccountTitle l">
      <ul>
        <li>{{$t('PersonalCenter.Password.Li1')}}</li>
        <li>{{$t('PersonalCenter.Password.Li2')}}</li>
        <li>{{$t('PersonalCenter.Password.Li3')}}</li>
      </ul>
    </div>
    <div class="AccountInput l">
      <el-form
        ref="form"
        :rules="rulesPassword"
        :model="resetPass"
        status-icon
      >
        <el-form-item prop="oldPassword">
          <el-input
            v-model="resetPass.oldPassword"
            :placeholder="$t('PersonalCenter.Password.input1')"
            type="password"
          />
        </el-form-item>
        <el-form-item prop="newPassword">
          <el-input
            show-password
            v-model="resetPass.newPassword"
            :placeholder="$t('PersonalCenter.Password.input2')"
            type="password"
          />
        </el-form-item>
        <el-form-item prop="checkPassword">
          <el-input
            v-model="resetPass.checkPassword"
            :placeholder="$t('PersonalCenter.Password.input3')"
            type="password"
            prop="checkPassword"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            class="l"
            type="primary"
            @click="resetPassword('form')"
          >
            {{$t('PersonalCenter.Password.button')}}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t('PersonalCenter.Password.Message4')));
      } else if (value !== this.resetPass.newPassword) {
        callback(new Error(this.$t('PersonalCenter.Password.Message5')));
      } else {
        callback();
      }
    };
    return{
      rulesPassword: {
        oldPassword: [
          { required: true, message: this.$t('PersonalCenter.Password.Message1'), trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: this.$t('PersonalCenter.Password.Message2'), trigger: "blur" },
          {
            pattern: /^[a-zA-Z]\w{5,17}$/,
            message: this.$t('PersonalCenter.Password.Message3'),
          },
        ],
        checkPassword: [{ validator: validatePass2, trigger: "blur" }],
      },
      user: {},
      resetPass:{
        oldPassword: '',
        newPassword: '',
        checkPassword: '',
      }, 
    }
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      let url = window.apis.SYS_USER_INFO;
      this.axios.get(url)
      .then((res) => {
        if (res.status === 200) {
          this.user = res.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    },
    resetPassword(formName) {
      // 校验合法性
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url = window.apis.SYS_USER;
          this.axios.put(url + this.user.id + "/", {
            password: this.resetPass.newPassword,
            old_password: this.resetPass.oldPassword,
          })
          .then((res) => {
            if (res.status === 200) {
              this.$message({
                message: this.$t('PersonalCenter.Password.Message6'),
                type: "success",
              });
              this.loadLogined();
            }
          })
          .catch((error) => {
            console.log(error);
          });
        } else {
          // 定位到错误的位置
          setTimeout(()=>{
            const isError= document.getElementsByClassName('is-error')
            isError[0].querySelector('input').focus()
          },100)
          return false;
        }
      });
    },
    loadLogined(){
      this.$router.push({name: 'Home'});
      localStorage.clear();
      this.$store.commit('loadLogined', false);
    },
  }
}
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0;
}
.el-input.is-disabled .el-input__inner {
  background:#D5DCEC;
}
  .Password  {
    text-align: left;
    overflow: hidden;
    padding: 20px 0 30px 91px;
    .AccountTitle {
      //   width: 120px;
      li {
        height: 40px;
        display: block;
        font-size: 15px;
        line-height: 40px;
        font-weight: 500;
        color: #333333;
        list-style: none;
        text-align: right;
        margin-right: 20px;
        margin-top: 17px;
      }
    }
    .AccountInput {
      width: 280px;
      height: 100%;
      .el-input {
        margin-top: 17px;
      }
      .el-button {
        margin: 0;
        margin-top: 17px;
      }
    }
  }
</style>