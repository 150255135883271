<template>
<main>
   <p class="Password">
      <span>{{$t('ForgetPassword.span')}}</span>
   </p>
   <el-form
      ref="resetForm"
      :rules="rulesResetPass"
      :model="resetPass"
      status-icon>
      <el-form-item prop="cEmail">
         <el-input v-model="resetPass.cEmail" :placeholder="$t('ForgetPassword.input1')">
         </el-input>
      </el-form-item>
      <el-form-item prop="cCode">
         <el-input
            class="codeInput l"
            v-model="resetPass.cCode"
            :placeholder="$t('ForgetPassword.input2')"
            style="width:">
         </el-input>
         <el-button
            class="prependCode r"
            @click="getEmailVerCode('resetForm')"
            :disabled="codeTimeDisabled"
            type="primary">
            <p class="prependCodeSlot">
               {{ codeTime }}
            </p>
         </el-button>
      </el-form-item>
      <el-form-item prop="pPass">
         <el-input
            v-model="resetPass.pPass"
            :placeholder="$t('ForgetPassword.input3')"
            type="password">
         </el-input>
      </el-form-item>
      <el-form-item prop="pCheckPass">
         <el-input
            v-model="resetPass.pCheckPass"
            :placeholder="$t('ForgetPassword.input4')"
            type="password"
            prop="checkPass">
         </el-input>
      </el-form-item>
      <el-form-item>
         <el-button
            @click="handleResetPass('resetForm')"
            class="PasswordButton"
            type="primary"
            style="font-size: 16px;">
            {{$t('ForgetPassword.button1')}}
         </el-button>
      </el-form-item>
   </el-form>
   <div class="dialogFooter">
      <p class="l" @click="routePush('Home')">
         {{$t('ForgetPassword.button2')}}
      </p>
   </div>
</main>
</template>

<script>
import {
   mapState
} from "vuex";

export default {
   data() {
      var validatePass = (rule, value, callback) => {
         if (value === "") {
            callback(new Error(this.$t('ForgetPassword.message1')));
         } else if (value !== this.resetPass.pPass) {
            callback(new Error(this.$t('ForgetPassword.message2')));
         } else {
            callback();
         }
      };
      var EmailCode = (rule, value, callback) => {
         if (/^\d{6}$/.test(value)) {
            if (this.trueEmailCode.email != this.resetPass.cEmail || this.trueEmailCode.code != value) {
               // 请求发送邮箱验证码
               let url = window.apis.EMAIL_CHECKCODE;
               this.axios
                  .get(url, {
                     params: {
                        email: this.resetPass.cEmail,
                        code: this.resetPass.cCode,
                     },
                  })
                  .then((res) => {
                     if (res.status === 200 || res.status === 201) {
                        //记录校验成功的验证码并保存，二次进入判断是否修改过验证码或者邮箱
                        this.trueEmailCode.email = this.resetPass.cEmail;
                        this.trueEmailCode.code = value;
                        this.$message({
                           message: this.$t('config') == 'zh' ? res.data.zh : res.data.en,
                           type: "success",
                        });
                        this.codeTimeDisabled = true;
                        callback();
                     } else {
                        callback(new Error(res.data.message || this.$t('ForgetPassword.message10')));
                        this.trueEmailCode.email = '';
                        this.trueEmailCode.code = '';
                     }
                  })
            } else {
               callback();
            }
         }
      };
      return {
         trueEmailCode: {
            email: '',
            code: ''
         },
         resetPass: {
            pPass: "",
            pCheckPass: "",
         },
         rulesResetPass: {
            cEmail: [{
                  required: true,
                  message: this.$t('ForgetPassword.message3'),
                  trigger: "blur"
               },
               {
                  pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                  message: this.$t('ForgetPassword.message4'),
                  trigger: "blur"
               },
            ],
            cCode: [{
                  required: true,
                  message: this.$t('ForgetPassword.message5'),
                  trigger: "blur"
               },
               {
                  min: 6,
                  max: 6,
                  message: this.$t('ForgetPassword.message6'),
                  trigger: "blur"
               },
               {
                  validator: EmailCode,
                  trigger: "blur"
               },
            ],
            pPass: [{
                  required: true,
                  message: this.$t('ForgetPassword.message7'),
                  trigger: "blur"
               },
               {
                  pattern: /^[a-zA-Z]\w{5,17}$/,
                  message: this.$t('ForgetPassword.message8'),
                  trigger: "blur"
               },
            ],
            pCheckPass: [{
               validator: validatePass,
               trigger: "blur"
            }],
         },
         codeTime: this.$t('ForgetPassword.codeTime'),
         codeTimeDisabled: false,
      };
   },
   computed: {
      ...mapState(["BoxLogin", "BoxRegister", "BoxSendEmailCode", "bgImg"])
   },
   components: {},
   methods: {
      routePush(name) {
         this.$router.push({
            name: name,
         });
      },
      handleResetPass(formName) {
         // 校验合法性
         this.$refs[formName].validate((valid) => {
            if (valid) {
               let url = window.apis.EMAIL_RESETPASSWORD;
               this.axios
                  .get(url, {
                     params: {
                        password: this.resetPass.pPass,
                        email: this.resetPass.cEmail,
                        code: this.resetPass.cCode,
                        language: this.$i18n.locale == 'zh' ? 0 : 1,
                     },
                  })
                  .then((res) => {
                     if (res.status === 200) {
                        this.$refs["resetForm"].resetFields();
                        this.$message({
                           message: this.$t('ForgetPassword.message9'),
                           type: "success",
                        });
                        this.routePush('Home')
                     }
                  })
                  .catch((error) => {
                     console.log(error);
                  });
            } else {
               // 定位到错误的位置
               setTimeout(() => {
                  const isError = document.getElementsByClassName("is-error");
                  isError[0].querySelector("input").focus();
               }, 100);
               return false;
            }
         });
      },
      getEmailVerCode(formName) {
         this.$refs[formName].validateField("cEmail", (error) => {
            if (!error) {
               this.codeTimeDisabled = true;
               // 请求发送邮箱验证码
               let url = window.apis.EMAIL_SENDCODE;
               this.axios
                  .get(url, {
                     params: {
                        email: this.resetPass.cEmail,
                        language: this.$i18n.locale == 'zh' ? 0 : 1,
                     },
                  })
                  .then((res) => {
                     if (res.status === 200 || res.status === 201) {
                        let timer = 120;
                        let timeres = setInterval(() => {
                           if (timer == 0) {
                              clearInterval(timeres);
                              this.codeTime = this.$t('ForgetPassword.codeTimeMessage1');
                              this.codeTimeDisabled = false;
                           } else {
                              this.codeTime = timer + ' s';
                              this.codeTimeDisabled = true;
                              timer--;
                           }
                        }, 1000);
                     } else {
                        this.codeTimeDisabled = false;
                     }
                  })
                  .catch((error) => {
                     console.log(error);
                  });
            }
         });
      },
   },
};
</script>

<style lang="scss" scoped>
main {
   width: 400px;
   height: 553px;
   background: #FFFFFF;
   //border: 2px solid rgba(255,255,255,0.5);
   border-radius: 10px;
   overflow: hidden;

   .dialogFooter {
      width: 100%;
      margin-top: -14px;
      font-size: 14px;
      font-weight: 400;
      color: #396DD6;
      line-height: 22px;
      text-align: center;

      p {
         width: 100%;
         font-size: 16px;
         cursor: pointer;
      }
   }

   .Password {
      width: 100%;
      color: #396DD6;
      margin: 50px auto 43px auto;
      overflow: hidden;

      i {
         overflow: hidden;
         display: inline-block;
      }

      span {
         font-size: 22px;
         font-weight: 500;
         line-height: 35px;
         margin-left: 9px;
         overflow: hidden;
         display: inline-block;
      }
   }

   .el-input {
      .prepend {
         width: 50px;
         text-align: center;
      }
   }

   .PasswordButton {
      margin-top: 18px;
      width: 300px;
   }

   .codeInput {
      width: 125px;
      overflow: hidden;
   }

   .code {
      width: 125px;
      height: 40px;
      overflow: hidden;
   }

   .Privacy {
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      line-height: 17px;
      margin-top: -6px;

      span {
         color: #666666;
         cursor: pointer;
      }
   }

   .prependCode {
      width: 160px;

      .p {
         text-align: center;
      }
   }
}

.el-form {
   text-align: left;
   margin: 0 auto;
   width: 300px;
}
</style>
