<template>
<div class="parameter">
   <article>
      <div v-if="task_type != 3">
         <div class="articleBox l">
            <span class="articleTitle">
               <!-- 提取特征 -->
               {{this.$t('parameter.title1')}}
            </span>
            <div class="Slider">
               <div class="tableTop">
                  <div class="tableTopBox">
                     <h4 class="tableTopTitle">
                        <!-- 原始特征值数量： -->
                        {{this.$t('parameter.title')}}
                     </h4>
                     <p>{{ analysisReport.original_feature_count }}</p>
                  </div>
                  <div class="tableTopBox">
                     <h4 class="tableTopTitle">
                        <!-- 选择的特征数量： -->
                        {{this.$t('parameter.tableTopTitle')}}
                     </h4>
                     <p>{{ listCheckbox }}</p>
                  </div>
               </div>
               <el-table
                  height="490"
                  ref="multipleTable"
                  :data="FeatureExtraction"
                  size="mini"
                  :row-class-name="tableRowClass"
                  @selection-change="handleSelectionChange"
                  @row-click="FeatureExtractionFn">
                  <el-table-column type="selection" />
                  <el-table-column prop="id" :label="$t('parameter.tableColumn1')" />
                  <el-table-column prop="name" :label="$t('parameter.tableColumn2')" />
                  <el-table-column prop="type" :label="$t('parameter.tableColumn3')" />
                  <el-table-column :label="$t('parameter.tableColumn4')">
                     <template>
                        <el-button type="primary" size="small">
                           <i class="el-icon-data-analysis" />
                           <!-- 生成图表 -->
                           {{$t('parameter.analysis')}}
                        </el-button>
                     </template>
                  </el-table-column>
               </el-table>
            </div>
         </div>
         <div class="articleBox r">
            <span class="articleTitle">
               <!-- 提取值 -->
               {{$t('parameter.articleTitle1')}}
            </span>
            <div class="Slider">
               <div id="EChart" />
            </div>
         </div>
      </div>
      <div v-else>
         <div class="articleBox">
            <span class="articleTitle">
               {{$t('parameter.articleTitle2')}}
            </span></div>
         <div v-for="item in time_series_img" :key="item._index">
            <el-image
               style="width: 50%;"
               :src="item"
               :preview-src-list="time_series_img" />

         </div>
      </div>

      <div class="articleBoxBig">
         <span class="articleTitle" v-if="task_type != 3">
            <!-- 分析报告 -->
            {{$t('parameter.articleTitle2')}}
            <el-button
               type="primary"
               class="r"
               :loading="downlodLoading"
               @click="downloadFn()">{{ $t('AutoML.Auto.Parameter.ypgv39') }}</el-button>
         </span>
         <div class="Slider" v-if="task_type != 3">
            <div class="ItemBox">
               <div class="elItem">
                  <h4>
                     <!-- 原始特征值数量： -->
                     {{$t('parameter.elItem1')}}
                  </h4>
                  <p>{{ analysisReport.original_feature_count }}</p>
               </div>
               <div class="elItem">
                  <h4>
                     <!-- 原始特征值名称： -->
                     {{$t('parameter.elItem2')}}
                  </h4>
                  <p>{{ analysisReport.original_features }}</p>
               </div>
               <div class="elItem">
                  <h4>
                     <!-- 目标列： -->
                     {{$t('parameter.elItem3')}}
                  </h4>
                  <p>{{ analysisReport.target_column }}</p>
               </div>
               <div class="elItem">
                  <h4>
                     <!-- 任务类型： -->
                     {{$t('parameter.elItem4')}}
                  </h4>
                  <p>{{ analysisReport.task }}</p>
               </div>
               <div class="elItem">
                  <h4>
                     <!-- 特征选择参数： -->
                     {{$t('parameter.elItem5')}}
                  </h4>
                  <p>{{ analysisReport.params }}</p>
               </div>
               <div class="elItem">
                  <h4>
                     <!-- 选择的特征个数： -->
                     {{$t('parameter.elItem6')}}
                  </h4>
                  <p>{{ listCheckbox }}</p>
               </div>
               <div class="elItem">
                  <h4>
                     <!-- 选择的特征名称： -->
                     {{$t('parameter.elItem7')}}
                  </h4>
                  <p>{{ analysisReport.processed_features }}</p>
               </div>
               <div class="elItem">
                  <h4>
                     <!-- 缺失值处理方式： -->
                     {{$t('parameter.elItem8')}}
                  </h4>
                  <p>{{ analysisReport.missing_handle_mode }}</p>
               </div>
            </div>
         </div>
         <div class="Slider" v-else>
            <div class="ItemBox">
               <div class="elItem">
                  <h4>
                     {{$t('parameter.elItem9')}}
                  </h4>
                  <p>{{ analysisReport.args_time_series[0] }}</p>
               </div>
               <div class="elItem">
                  <h4>
                     {{$t('parameter.elItem10')}}
                  </h4>
                  <p>{{ analysisReport.args_time_series[1] }}</p>
               </div>
               <div class="elItem">
                  <h4>
                     <!-- 任务类型： -->
                     {{$t('parameter.elItem4')}}
                  </h4>
                  <p>{{ analysisReport.task }}</p>
               </div>
               <div class="elItem">
                  <h4>
                     <!-- 特征选择参数： -->
                     {{$t('parameter.elItem11')}}
                  </h4>
                  <p>{{ analysisReport.processed_feature_count }}</p>
               </div>
               <div class="elItem">
                  <h4>
                     <!-- 选择的特征名称： -->
                     {{$t('parameter.elItem12')}}
                  </h4>
                  <p>{{ analysisReport.processed_features }}</p>
               </div>
            </div>
         </div>
      </div>
      <div class="articleBoxBig">
         <span class="articleTitle">
            <!-- 数据集概要 -->
            {{$t('parameter.articleTitle3')}}
         </span>
         <div class="Slider">
            <div class="ItemBox">
               <div class="elItem">
                  <h4>
                     <!-- 数据集名称： -->
                     {{$t('parameter.elItem2_1')}}
                  </h4>
                  <p>{{ dataset.name }}</p>
               </div>
               <div class="elItem">
                  <h4>
                     <!-- 任务名称： -->
                     {{$t('parameter.elItem2_2')}}
                  </h4>
                  <p>{{ task_name }}</p>
               </div>
               <div class="elItem">
                  <h4>
                     <!-- 任务类型： -->
                     {{$t('parameter.elItem2_3')}}
                  </h4>
                  <p>{{ dataset.task_type_cn }}</p>
               </div>
               <div class="elItem">
                  <h4>
                     <!-- 样本行数： -->
                     {{$t('parameter.elItem2_4')}}
                  </h4>
                  <p>{{ dataset.rows }}</p>
               </div>
               <div class="elItem">
                  <h4>
                     <!-- 样本列数： -->
                     {{$t('parameter.elItem2_5')}}
                  </h4>
                  <p>{{ dataset.columns }}</p>
               </div>
               <div class="elItem">
                  <h4>
                     <!-- 文件大小： -->
                     {{$t('parameter.elItem2_6')}}
                  </h4>
                  <p>{{ dataset.size | byteChange }}</p>
               </div>
               <div class="elItem css">
                  <h4>
                     <!-- 数据描述： -->
                     {{$t('parameter.elItem2_7')}}
                  </h4>
                  <p style="word-break:break-all;">
                     {{ dataset.desc }}
                  </p>
               </div>
            </div>
         </div>
      </div>
      <div
         class="articleBox l"
         v-for="v in srcList"
         :key="v.value">
         <div class="SliderImg">
            <!-- <img v-bind:src="`${downLoadUrl}${v}`" alt="" /> -->
            <el-image
               style="width: 100%; height: 100%"
               fit="contain"
               :src="v"
               :preview-src-list="srcList" />
         </div>
      </div>
   </article>
   <section>
      <el-tooltip
         class="item"
         effect="light"
         :content="$t('pretreatment.buttonTop')"
         placement="top-start">
         <el-button
            class="Step"
            type="primary"
            icon="el-icon-arrow-left"
            :disabled='stage>2'
            circle
            @click="lastStep" />
      </el-tooltip>
      <el-tooltip
         class="item"
         effect="light"
         :content="$t('pretreatment.buttonNext')"
         placement="top-start">
         <el-button
            class="Step"
            type="primary"
            icon="el-icon-arrow-right"
            circle
            @click="nextStep" />
      </el-tooltip>
   </section>
   <div class="backTop" @click="backTop">
      <el-tooltip
         class="item"
         effect="light"
         :content="$t('MdmHome.tooltip')"
         placement="top-start">
         <el-button
            type="primary"
            icon="el-icon-arrow-up"
            circle
            v-show="flag_scroll"
            class="top" />
      </el-tooltip>
   </div>
</div>
</template>

<script>
import {
   mapState
} from "vuex";

export default {
   // name: "parameter",
   data() {
      return {
         flag_scroll: false,
         scroll: "",

         FeatureExtraction: [],
         Feature: [{
               chartX: ""
            },
            {
               chartY: ""
            },
            {
               chart1X: ""
            },
            {
               chart1Y: ""
            },
            {
               title: this.$t('parameter.Feature')
            },
            {
               xName: ""
            },
            {
               yName: ""
            },
         ],
         analysisReport: {},
         imgList: [],
         downLoadUrl: "",
         srcList: [],
         dataset: [],
         echartName: "",
         tableRow: "",
         listCheckbox: "0",
         time_series_img: [], //时间序列下的图片
         downlodLoading: false,
      };
   },
   computed: {
      ...mapState([
         "task_id",
         "task_name",
         "dataset_id",
         "task_type",
         "target_column",
         "desc",
         "FeatureName",
         "stage"
      ]),
   },
   methods: {
      downloadFn() {
         //下载文档按钮事件
         this.downlodLoading = true
         this.axios(`${window.apis.SYS_MODELS}${this.task_id}/`).then(res => {
            setTimeout(() => {
               this.downlodLoading = false
               let downLoadUrl = window.apis.SERVER + window.apis.SYS_DOWNLOAD;
               let url = `${downLoadUrl}${this.toJSON(res.data.feature_output).feature_csv_path}?token=${localStorage.token.split(" ")[1]}`;
               window.open(url, "_self");
            }, 300)
         })
      },
      toJSON(str) {
         try {
            return JSON.parse(str)
         } catch (err) {
            return str
         }
      },
      //滑动超过200时显示按钮
      handleScroll(e) {
         let scrollTop = e.target.scrollTop;
         if (scrollTop > 200) {
            this.flag_scroll = true;
         } else {
            this.flag_scroll = false;
         }
      },
      //返回顶部事件
      backTop() {
         document
            .getElementById("router-view")
            .scrollIntoView({
               behavior: "smooth",
               block: "start"
            });
      },
      tableRowClass({
         rowIndex
      }) {
         if (rowIndex === this.tableRow) {
            return "warning-row";
         }
         return "";
      },
      handleSelectionChange(val) {
         this.listCheckbox = val.length;
         let a = [];
         for (let key in val) {
            a.push(val[key].name);
         }
         this.analysisReport.processed_features = a;
      },
      getFeatureReport() {
         let url = window.apis.SYS_FEATURE_REPORT;
         let SYS_DATASET_FEATURE = window.apis.SYS_DATASET_FEATURE;
         this.downLoadUrl = window.apis.SERVER + window.apis.SYS_DOWNLOAD;
         this.axios
            .get(url + this.task_id + "/")
            .then((res) => {
               console.log(res.data);
               if (res.status === 200 || res.status === 201) {
                  this.analysisReport = res.data;
                  // 循环添加token
                  for (let key in this.analysisReport.img) {
                     this.imgList.push(
                        this.analysisReport.img[key] +
                        "?token=" +
                        localStorage.token.split(" ")[1]
                     );
                  }
                  this.time_series_img[0] = this.downLoadUrl + res.data.time_series_img + "?token=" + localStorage.token.split(" ")[1]
                  this.srcList = this.imgList.map((item) => this.downLoadUrl + item);
               } else {
                  this.$message({
                     message: this.$t('parameter.message1'),
                     type: "warning",
                  });
               }
            })
            .catch((error) => {
               // this.$message({
               //     message: "请求数据错误",
               //     type: 'warning',
               //   });
               console.log(error);
            });
         this.axios
            .get(
               `${SYS_DATASET_FEATURE}?dataset_id=${this.dataset_id}&task_id=${this.task_id}`
            )
            .then((res) => {
               if (res.status === 200 || res.status === 201) {
                  this.FeatureExtraction = res.data;
                  this.CheckboxTrue();
                  // 判断是否有值
                  if (this.FeatureExtraction.length > 0) {
                     this.FeatureExtractionFn(this.FeatureExtraction[0]); // 默认取第一个
                  }
               } else {
                  this.$message({
                     message: this.$t('parameter.message1'),
                     type: "warning",
                  });
               }
            })
            .catch((error) => {
               // this.$message({
               //     message: "请求数据错误",
               //     type: 'warning',
               //   });
               console.log(error);
            });
      },
      nextStep() {
         if (this.listCheckbox > 0 || this.task_type == 3) {
            // 判断任务
            this.$store.commit(
               "FeatureName",
               this.analysisReport.processed_features
            );
            let data = {
               features: this.analysisReport.processed_features,
            };
            this.axios({
               url: `${window.apis.SYS_MODELS}${this.task_id}/`,
               method: "put",
               data: data,
            }).then((res) => {
               console.log(res);
               this.$router.push({
                  name: "Training",
               });
            });
         } else {
            this.$message({
               message: this.$t('parameter.message2'),
               type: "warning",
            });
         }
      },
      lastStep() {
         this.$router.push({
            name: "Analysis",
         });
      },
      CheckboxTrue() {
         let a = [];
         this.$nextTick(() => {
            for (let key in this.FeatureExtraction) {
               if (this.FeatureExtraction[key].selected == "1") {
                  a.push(this.FeatureExtraction[key]);
               }
            }
            if (a) {
               a.forEach((row) => {
                  this.$refs.multipleTable.toggleRowSelection(row);
               });
            }
         });
      },
      getRenderer() {
         // 基于准备好的dom，初始化echarts实例
         let EChart = this.$echarts.init(document.getElementById("EChart"));
         // 配置参数
         let config = {
            title: {
               text: `"${this.echartName}"${this.title}`,
               x: "center",
               textStyle: {
                  align: "center",
                  fontSize: "25",
               },
            },
            color: ["#396DD6", "#9CB6EA"],
            // tooltip: {
            //   trigger: "axis",
            //   axisPointer: {
            //     // 坐标轴指示器，坐标轴触发有效
            //     type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            //   },
            // },
            grid: {
               left: "3%",
               right: "10%",
               bottom: "3%",
               containLabel: true,
            },
            xAxis: [{
               name: this.xName,
               nameTextStyle: {
                  fontSize: "20",
               },
               type: "category",
               data: this.Feature.chartX,
               axisLabel: {
                  formatter: function (value) {
                     // var x = parseInt(value);
                     if (!isNaN(value)) {
                        return value.match(/^\d+(?:\.\d{0,2})?/);
                     } else {
                        return value;
                     }
                  },
               },
            }, ],
            yAxis: [{
               name: this.yName,
               nameTextStyle: {
                  fontSize: "20",
               },
               type: "value",
            }, ],
            series: [{
                  name: "Samples",
                  type: "line",
                  smooth: 0.5,
                  showSymbol: false,
                  data: this.Feature.chartY,
               },
               {
                  name: "Samples2",
                  type: "bar",
                  data: this.Feature.chart1Y,
               },
            ],
         };
         //根据窗口的大小变动图表 --- 重点
         window.onresize = function () {
            EChart.resize();
            //myChart1.resize();    //若有多个图表变动，可多写
         };
         // 设置参数
         EChart.setOption(config, true);
      },
      FeatureExtractionFn(row) {
         this.tableRow = parseInt(row.index);
         this.echartName = row.name;
         let SYS_DATASET_FEATURE = window.apis.SYS_DATASET_FEATURE;
         this.axios
            .get(
               `${SYS_DATASET_FEATURE}?dataset_id=${this.dataset_id}&column_name=${encodeURIComponent(row.name)}`
            )
            .then((res) => {
               if (res.status === 200 || res.status === 201) {
                  if (res.data.type == "number") {
                     this.Feature.chartX = res.data.data[0][0];
                     this.Feature.chartY = res.data.data[0][1];
                     this.Feature.chart1Y = res.data.data[1][0];
                     this.title = this.$t('parameter.title1');
                     this.xName = this.$t('parameter.xName1');
                     this.yName = this.$t('parameter.yName1');
                     this.getRenderer();
                  } else {
                     this.Feature.chartX = res.data.data[0];
                     this.Feature.chartY = res.data.data[1];
                     this.Feature.chart1Y = res.data.data[1];
                     this.title = this.$t('parameter.title2');
                     this.xName = this.$t('parameter.xName2');
                     this.yName = this.$t('parameter.yName2');
                     this.getRenderer();
                  }
               } else {
                  this.$message({
                     message: this.$t('parameter.message1'),
                     type: "warning",
                  });
               }
            })
            .catch((error) => {
               // this.$message({
               //     message: "请求数据错误",
               //     type: 'warning',
               //   });
               console.log(error);
            });
      },
      getDataset() {
         this.axios
            .get(window.apis.SYS_DATASET + this.dataset_id + "/")
            .then((res) => {
               if (res.status === 200 || res.status === 201) {
                  this.dataset = res.data;
                  switch (res.data.task_type) {
                     case 0:
                        this.dataset.task_type_cn = this.$t('MdmHome.taskType1');
                        break;
                     case 1:
                        this.dataset.task_type_cn = this.$t('MdmHome.taskType2');
                        break;
                     case 2:
                        this.dataset.task_type_cn = this.$t('MdmHome.taskType3');
                        break;
                     case 3:
                        this.dataset.task_type_cn = this.$t('MdmHome.taskType4');
                        break;
                  }
               } else {
                  this.$message({
                     message: this.$t('parameter.message1'),
                     type: "warning",
                  });
               }
            })
            .catch((error) => {
               // this.$message({
               //   message: "请求数据错误",
               //   type: 'warning',
               // });
               console.log(error);
            });
      },
   },
   mounted() {
      this.getFeatureReport();
      this.getDataset();
      window.addEventListener("scroll", this.handleScroll, true);
   },
   beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
   },
   created() {
      setTimeout(() => {
         this.getRenderer();
      }, 500);
   },
};
</script>

<style lang="scss" scoped>
#EChart {
   width: 100%;
   height: 100%;
}

.parameter {
   min-width: 1165px;
}

section {
   position: fixed;
   right: 100px;
   bottom: 50px;
   z-index: 100;

   .Step {
      margin: 0 15px;
      box-shadow: 0px 0px 7px #333333;
   }
}

.Slider {
   width: auto;
   overflow: hidden;

   .tableTop {
      line-height: 40px;
      border-bottom: 1px solid rgba(218, 223, 231, 0.5);

      .tableTopBox {
         text-align: center;
         margin: 0 20px;
         display: inline-block;

         p {
            width: 10px;
            display: inline-block;
         }

         .tableTopTitle {
            display: inline-block;
         }
      }
   }

   .ItemBox {
      width: 100%;
      border: 1px solid rgba(218, 223, 231, 0.5);
      overflow: hidden;

      .elItem {
         width: 100%;
         margin: 0;
         border-bottom: 1px solid rgba(218, 223, 231, 0.5);
         transition: all 200ms;
         display: inline-flex;

         h4 {
            width: 200px;
            text-align: left;
            display: inline-block;
            margin-left: 16px;
            line-height: 36px;
            font-size: 14px;
            font-weight: bold;
         }

         p {
            display: inline-block;
            margin: 0 50px;
            line-height: 36px;
            font-weight: 500;
            font-size: 12px;
            word-wrap: break-word;
            word-break: break-all;
            overflow: hidden;
         }
      }

      .elItem:hover {
         background: rgba(246, 247, 248, 0.5);
      }

      .elItem:last-of-type {
         border: 0px;
      }
   }
}

.el-slider {
   width: 250px;
}

h3 {
   display: inline-block;
   font-size: 20px;
   // font-family: "PingFang SC";
   font-weight: bold;
   color: #333333;
}

article {
   text-align: left;

   .articleBox {
      width: 50%;
      // border: 0.998px solid rgba(218, 223, 231, 0.5);
      display: inline-block;
      margin-top: 30px;

      // overflow: hidden;
      .Slider {
         width: 100%;
         height: 532px;
         border: 1px solid rgba(218, 223, 231, 0.5);
      }

      .SliderImg {
         width: 100%;
         height: 532px;
         // height: auto;
         border: 1px solid rgba(218, 223, 231, 0.5);
         position: relative;
         // overflow: hidden;
         // img {
         //   display: inline-block;
         //   width: 90%;
         //   // position:relative;
         // }
         // img:hover {
         //   width: 780px;
         //   height: 600px;
         //   position: absolute;
         //   left: -50px;
         //   top: -40px;
         //   z-index: 10;
         // }
      }
   }

   .articleBoxBig {
      width: 100%;
      display: inline-block;
      margin-top: 30px;
   }

   .articleTitle {
      display: block;
      width: 100%;
      height: auto;
      font-size: 16px;
      line-height: 30px;
      overflow: hidden;
      font-weight: bold;
      color: #4e4f54;
   }
}

#box ul {
   display: flex;
   flex-wrap: wrap;
   position: absolute;
   top: 220px;
}

#box li {
   padding: 3px;
   list-style: none;
   margin-right: 15px;
   border: 1px solid #eee;
}

#box img {
   width: 200px;
   height: 150px;
}

.scrollTop {
   width: 100%;
   height: 100%;
   overflow-y: scroll;
}

.backTop {
   position: fixed;
   bottom: 120px;
   z-index: 100;
   right: 40px;
   background: white;

   .top {
      width: 41px;
      height: 41px;
   }
}
</style>
