<template>
  <div>
    <header>
      <div class="headerBox">
        <div id="logo" :class="this.$GlobalVal.ORG_LOGO" />
        <!-- <span class="headerTitle l">{{this.$GlobalVal.ORG_NAME}}</span> -->
      </div>
    </header>
      <div class="Main">
        <div class="position">
          <img src="../assets/img/404.png" alt="">
          <el-button type="primary" @click="routePush()">回到首页</el-button>
        </div>
      </div>
    <footer-a></footer-a>
  </div>
</template>

<script>
import FooterA from '../components/Footer'
export default {
components:{
    FooterA
},
methods:{
    routePush(){
        this.$router.push({
            name:"Home"
        })
    }
}
}
</script>

<style lang="scss" scoped>
header {
  height: 90px;
  width: 100%;
  background: #4568ee;
  .headerBox {
    width: 960px;
    height: 90px;
    margin: 0 auto;
    background: #4568ee;
    .logo {
      font-size: 42px;
      line-height: 90px;
      color: #FFFFFF;
    }
    .headerTitle {
      font-size: 26px;
      // font-family: "PingFang SC";
      font-weight: bold;
      color: #ffffff;
      line-height: 90px;
      margin-left: 16px;
    }
  }
}
.Main {
    width: 100%;
    min-width: 960px;
    height: auto;
    min-height: calc(100vh - 230px);
    position: relative;
    margin-top: 20px;
    overflow: hidden;
    .position {
      overflow: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      img {
        display: block;
        width: calc(25vw + 1px);
        max-width: 640px;
        min-width: 270px;
    }
    }
    
}

</style>