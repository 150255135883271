import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
   state: {
      user: '',
      task_id: '',
      task_id_DL: '',
      task_name: '',
      dataset_id: '',
      dataset_id_DL: '',
      dataset_name: '',
      task_type: '',
      target_column: '',
      DownloadSRC: '',
      desc: '',
      TokenErr: 0,
      read_mode_header: '',
      headerData: '',
      Logined: false,
      Uploadingdata: false,
      FeatureName: '',
      vipType: '',
      stage:"",//当前阶段
      // 弹窗参数
      BoxLogin: false,
      BoxRegister: false,
      BoxSendEmailCode: false,
      vipDialog: false,
      perms: [],
      roles: [],
      breadcrumb_i18n: {},
   },
   mutations: {
      loadUser(state, payload) {
         state.user = payload;
         localStorage.setItem("user", payload);
      },
      loadLogined(state, payload) {
         state.Logined = payload;
         sessionStorage.setItem("Logined", payload);
      },
      UploadingTrue(state, payload) {
         state.Uploadingdata = payload;
         sessionStorage.setItem("Uploadingdata", payload);
      },
      task_id(state, payload) {
         state.task_id = payload;
         sessionStorage.setItem("task_id", payload);
      },
      task_id_DL(state, payload) {
         state.task_id_DL = payload;
         sessionStorage.setItem("task_id_DL", payload);
      },
      task_name(state, payload) {
         state.task_name = payload;
         sessionStorage.setItem("task_name", payload);
      },
      dataset_id(state, payload) {
         state.dataset_id = payload;
         sessionStorage.setItem("dataset_id", payload);
      },
      dataset_id_DL(state, payload) {
         state.dataset_id_DL = payload;
         sessionStorage.setItem("dataset_id_DL", payload);
      },
      dataset_name(state, payload) {
         state.dataset_name = payload;
         sessionStorage.setItem("dataset_name", payload);
      },
      task_type(state, payload) {
         state.task_type = payload;
         sessionStorage.setItem("task_type", payload);
      },
      target_column(state, payload) {
         state.target_column = payload;
         sessionStorage.setItem("target_column", payload);
      },
      desc(state, payload) {
         state.desc = payload;
         sessionStorage.setItem("desc", payload);
      },
      read_mode_header(state, payload) {
         state.read_mode_header = payload;
         sessionStorage.setItem("read_mode_header", payload);
      },
      headerData(state, payload) {
         state.headerData = payload;
         sessionStorage.setItem("headerData", payload);
      },
      DownloadSRC(state, payload) {
         state.DownloadSRC = payload;
         sessionStorage.setItem("DownloadSRC", payload);
      },
      perms(state, payload) {
         state.perms = payload;
         sessionStorage.setItem("perms", payload);
      },
      stage(state, payload) {
         state.stage = payload;
         sessionStorage.setItem("stage", payload);
      },
      roles(state, payload) {
         state.roles = payload;
         sessionStorage.setItem("roles", payload);
      },
      FeatureName(state, payload) {
         state.FeatureName = payload;
      },
      vipType(state, payload) {
         state.vipType = payload;
      },
      TokenErr(state, payload) {
         state.TokenErr = payload;
      },
      // 首页弹窗控制
      BoxLogin(state, payload) {
         state.BoxLogin = payload;
      },
      BoxRegister(state, payload) {
         state.BoxRegister = payload;
      },
      BoxSendEmailCode(state, payload) {
         state.BoxSendEmailCode = payload;
      },
      vipDialog(state, payload) {
         state.vipDialog = payload;
      },

   },
   actions: {
   },
   modules: {
   }
})
