<template>
  <div class="vipDialog">
    <el-dialog
      :visible.sync="Dialog"
      :width="payNext?'278px':'642px'"
      @close="off"
      @open="on"
      :destroy-on-close="true"
    >
      <div
        class="payMain"
        v-if="!payNext"
        v-loading="payGetTrue"
        element-loading-background="rgba(0, 0, 0, 0)"
      >
        <h3>{{title}}</h3>
        <span class="miniTitle">{{$t('VIP2.Dialog.miniTitle')}}</span>
        <div class="mainRadio">
          <div
            class="RadioButton"
            @click="order.duration='1'"
            :class="{RadioButtonTrue:order.duration=='1',RadioButton:order.duration!='1'}"
          >
            <div class="boxTop">
              <span>{{ durationY[0]>=12?`${durationY[0]/12}`:`${durationY[0]}`}}</span>{{ durationY[0]>=12?$t('VIP2.Dialog.y'):$t('VIP2.Dialog.onem')}}
            </div>
            <div class="boxBottom">
              <span>{{ price[0] }}</span> {{$t('yuan')}}
            </div>
            <div class="boxBottom" v-show="original_price[0]">
              <span>{{ original_price[0] }}</span> {{$t('yuan')}}
            </div>
            <div
              class="bg"
              v-show="discount[0]!=10"
            >
              <span>{{ $t('config')=='zh'?`${ discount[0]}折`:`${ discount[0] * 10}%off`}}</span>
            </div>
          </div>
          <div
            class="RadioButton"
            @click="order.duration='6'"
            :class="{RadioButtonTrue:order.duration=='6',RadioButton:order.duration!='6'}"
          >
            <div class="boxTop">
              <span>{{ durationY[1]>=12?`${durationY[1]/12}`:`${durationY[1]}`}}</span>{{ durationY[1]>=12?$t('VIP2.Dialog.y'):$t('VIP2.Dialog.m')}}
            </div>
            <div class="boxBottom">
              <span>{{ price[1] }}</span> {{$t('yuan')}}
            </div>
            <div class="boxBottom">
              <span>{{ original_price[1] }}</span> {{$t('yuan')}}
            </div>
            <div
              class="bg"
              v-show="discount[1]!=10"
            >
              <span>{{ $t('config')=='zh'?`${ discount[1]}折`:`${ discount[1] * 10}%off`}}</span>
            </div>
          </div>
          <div
            class="RadioButton"
            @click="order.duration='12'"
            :class="{RadioButtonTrue:order.duration=='12',RadioButton:order.duration!='12'}"
          >
            <div class="boxTop">
              <span>{{ durationY[2]>=12?`${durationY[2]/12}`:`${durationY[2]}`}}</span>{{ durationY[2]>=12?$t('VIP2.Dialog.y'):$t('VIP2.Dialog.m')}}
            </div>
            <div class="boxBottom">
              <span>{{ price[2] }}</span> {{$t('yuan')}}
            </div>
            <div class="boxBottom">
              <span>{{ original_price[2] }}</span> {{$t('yuan')}}
            </div>
            <div
              class="bg"
              v-show="discount[2]!=10"
            >
              <span>
                 {{ $t('config')=='zh'?`${ discount[2]}折`:`${ discount[2] * 10}%off`}}</span>
            </div>
          </div>
        </div>
        <span class="miniTitle">{{$t('VIP2.Dialog.payType')}}</span>
        <el-radio-group
          v-model="order.channel"
          class="radio"
        >
        <el-radio label="wx_pub_qr">
            <svg
              class="icon"
              style="font-size:20px;margin-bottom:-2px;"
              aria-hidden="true"
            >
              <use xlink:href="#iconbuy_weixin" />
            </svg>
            {{$t('VIP2.Dialog.wx')}}
          </el-radio>
          <el-radio
            label="alipay_qr"
            style="margin-right: 72px"
          >
            <svg
              class="icon"
              style="font-size:20px;margin-bottom:-2px;"
              aria-hidden="true"
            >
              <use xlink:href="#iconbuy_zhifubao" />
            </svg>
            {{$t('PersonalCenter.History.payType1')}}
          </el-radio>
          
        </el-radio-group>
        <div class="dialogFooter">
          <el-button
            type="primary"
            @click="payFn()"
            style="width:120px"
            :disabled="payGetTrue"
            class="r"
          >
            {{$t('VIP2.Dialog.Pay')}}
          </el-button>
          <!-- <div class="price r" v-show="totalTrue">
            {{$t('VIP2.Dialog.upMoney')}}<p>￥<span>{{ totalAll }}</span></p>
          </div> -->
          <div class="price r">
            <span>{{$t('VIP2.Dialog.Total')}}</span>
            <p>￥<span>{{ priceAll }}</span></p>
          </div>
          <!-- <div class="price r" :style="totalTrue?'transform: scale(0.8);':''">
            <span v-if="totalTrue">原价：</span><span v-else>{{$t('VIP2.Dialog.Total')}}</span>
            <p>￥<span :style="totalTrue?'text-decoration:line-through;':''">{{ priceAll }}</span></p>
          </div> -->
        </div>
      </div>
      <div
        class="QRcode"
        v-else
      >
        <h3>{{$t('VIP2.Dialog.text1')}}</h3>
        <el-image
          class="imgQR"
          :src="payImgQR"
          alt=""
        />
        <p>{{order.channel=='wx_pub_qr'?$t('VIP2.Dialog.text2'):$t('VIP2.Dialog.text5')}}</p>
        <aside>{{$t('VIP2.Dialog.text3')}}</aside>
        <el-link class="payNo" @click="payTrueFn()" type="primary">{{$t('VIP2.Dialog.text4')}}</el-link>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { sendWebsocket, closeWebsocket } from "../../tools/websocket";


export default {
  data(){
    return{
    pay:'1',
    order:{
      type:"basic",
      duration:"1",
      channel:'wx_pub_qr'
    },
    price:[],
    priceAll:'',
    discount:[],
    original_price:[],
    payNext: false,
    payImgQR:'',
    title:'',
    title1:'',
    durationY:[],
    payGetTrue: false,
    out_trade_no:'',
    total:[],
    totalAll:'',
    totalTrue:false,
    Dialog:false,
    vipType:""
    }
  },
  watch:{
    vipType: function (val){
      this.switchFn(val)},
      order:{ handler (val){
        switch(val.duration){
          case '1':
            this.priceAll = this.price[0];
            this.totalAll = this.total[0];
          break;
          case '6':
            this.priceAll = this.price[1];
            this.totalAll = this.total[1];
          break;
          case '12':
            this.priceAll = this.price[2];
            this.totalAll = this.total[2];
          break;
        }
      },
      deep:true
      }
    
  },
  computed: {
  },
  // mounted(){
  //   this.$root.Privilage.GIT_IOFN()
  //   this.$root.Privilage.GIT_PERMS()
  // },
  methods:{
    gradeFn(){

    },

    off(){
      closeWebsocket()
    },
    switchFn(val){
      //switch获取点击价格对应显示的续费价格
      switch(val){
        case 'basic':
        for(let item of this.$root.Privilage.price.slice(6,9).reverse()){
          this.durationY.push(item.duration)
          this.price.push(item.CNY_price)
          this.discount.push(item.discount * 10)
          this.original_price.push(item.original_price)
          this.total.push(item.CNY_total)
        }
        this.title = this.$t('VIP2.Dialog.vipType1')
          //switch循环获取状态
          switch (this.$Privilage.grade_cn){
            case this.$t('VIP2.Dialog.vipType1'):
              this.title1 = this.$t('VIP2.Dialog.Renew');
              break;
            default:
              this.title1 = this.$t('VIP2.Dialog.buy');
          }
        break;

        case 'diamond':
        for(let item of this.$root.Privilage.price.slice(3,6).reverse()){
          this.durationY.push(item.duration)
          this.price.push(item.CNY_price)
          this.discount.push(item.discount * 10)
          this.original_price.push(item.original_price)
          this.total.push(item.CNY_total)
        }
        this.title = this.$t('VIP2.Dialog.vipType2')
          //switch循环获取状态
          switch (this.$Privilage.grade_cn){
            case this.$t('VIP2.Dialog.vipType1'):
              this.title1 = this.$t('VIP2.Dialog.upVip');
              this.totalTrue = true
              break;
            case this.$t('VIP2.Dialog.vipType2'):
              this.title1 = this.$t('VIP2.Dialog.Renew');
              break;
            default:
              this.title1 = this.$t('VIP2.Dialog.buy');
          }
        break;

        case 'premium':
        for(let item of this.$root.Privilage.price.slice(0,3).reverse()){
          this.durationY.push(item.duration)
          this.price.push(item.CNY_price)
          this.discount.push(item.discount * 10)
          this.original_price.push(item.original_price)
          this.total.push(item.CNY_total)
        }
        this.title = this.$t('VIP2.Dialog.vipType3')
          //switch循环获取状态
          switch (this.$Privilage.grade_cn){
            case this.$t('VIP2.Dialog.vipType1'):
              this.title1 = this.$t('VIP2.Dialog.upVip');
              this.totalTrue = true
              break;
            case this.$t('VIP2.Dialog.vipType2'):
              this.title1 = this.$t('VIP2.Dialog.upVip');
              this.totalTrue = true
              break;
            case this.$t('VIP2.Dialog.vipType3'):
              this.title1 = this.$t('VIP2.Dialog.Renew');
              break;
            default:
              this.title1 = this.$t('VIP2.Dialog.buy');
          }
        break;
      }
    },
    on(){
      this.totalTrue = false
      this.total = []
      this.price=[]
      this.durationY=[]
      this.discount=[]
      this.original_price=[]
      this.payNext = false
      this.switchFn(this.vipType)
      //循环获取合计价格
      switch(this.order.duration){
          case '1':
            this.priceAll = this.price[0];
            this.totalAll = this.total[0];
          break;
          case '6':
            this.priceAll = this.price[1];
            this.totalAll = this.total[1];
          break;
          case '12':
            this.priceAll = this.price[2];
            this.totalAll = this.total[2];
          break;
        }
    },
    wsMessage(data){
      if(data == 'SUCCESS'||data == "TRADE_SUCCESS"){
      this.$message({
        message: this.$t('VIP2.Dialog.message1'),
        type: 'success'
      });
      this.Dialog = false;
      setTimeout(()=>{this.$router.go(0)},500)
      }
    },
    wsError(data){
      console.log(data)
    },
    payFn(){
      this.payGetTrue = true
      //循环通过选择的index获取后端传回的实际月数
      let duration = ''
      switch(this.order.duration){
          case '1': duration = this.durationY[0];
          break;
          case '6': duration = this.durationY[1];
          break;
          case '12': duration = this.durationY[2];
          break;
        }
        this.axios.post(window.apis.VIP_ORDER,{
        type: this.vipType,
        duration: duration,
        channel: this.order.channel
      }).then((res)=>{
        this.payGetTrue = false
        console.log(res)
        if (res.status === 201) {
        this.out_trade_no = res.data.out_trade_no
        this.payNext = true
        this.payImgQR = `data:image/png;base64,${res.data.img_stream}`
        closeWebsocket()
        sendWebsocket(
          window.apis.WS_SERVER + this.$Privilage.user_id,
          {},
          this.wsMessage,
          this.wsError
        )
        }
      }).catch(()=>{
         this.payGetTrue = false
      })
      
      // setTimeout(()=>{this.payNextFn()},3000)
    },
    payNextFn(){
    },
    payTrueFn(){
      this.axios(window.apis.VIP_ORDERPAY + this.out_trade_no +'/').then(
        res=>{
          if(res.data.trade_state == "SUCCESS"){
            this.$message({
              message: this.$t('VIP2.Dialog.message1'),
              type: 'success'
            });
            this.Dialog = false;
            setTimeout(()=>{this.$router.go(0)},500)
          }else{
            this.$message({
              message: this.$t('VIP2.Dialog.message2'),
              type: 'warning'
            });
          }
        }
      )
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 8px 41px 48px 41px;
}
* {
  font-family: "PingFang SC";
}
.payMain {
  h3 {
    height: 33px;
    font-size: 24px;
    font-weight: 500;
    color: #333333;
    line-height: 33px;
    margin-bottom: 40px;
  }
  .miniTitle {
    display: block;
    text-align: left;
    width: 100%;
    padding: 12px 0 12px 0;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    overflow: hidden;
  }
  .mainRadio {
    .RadioButton {
      position: relative;
      width: 166px;
      height: 114px;
      margin-left: 24px;
      border-radius: 4px;
      border: 1px solid #E1E1E1;
      transition: all 250ms;
      float: left;
      cursor:pointer;
      .boxTop {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #212121;
        line-height: 20px;
        span {
          font-size: 46px;
          font-weight: 500;
          color: #333333;
          line-height: 55px;
        }
      }
      .boxBottom {
        font-size: 14px;
        font-weight: 400;
        color: #212121;
        line-height: 20px;
        span {
          font-size: 18px;
          font-weight: 500;
          color: #ED582A;
          line-height: 22px;
        }
      }
      .boxBottom:nth-of-type(3) {
        text-decoration:line-through;
        font-size: 12px;
        font-weight: 400;
        color: #212121;
        line-height: 18px;
        span {
          font-size: 16px;
          font-weight: 500;
          color: #ED582A;
          line-height: 20px;
        }
      }
      .bg {
        display: block;
        position: absolute;
        width: 52px;
        height: 27px;
        top: -9px;
        right: -6px;
        background-image: url('../../assets/vip_img/buy_img1.png');
        background-size: 52px 27px;
        span {
          font-size: 13px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 22px;
        }
      }
    }
    .RadioButton:first-of-type {
      margin-left: 0;
    }
    .RadioButton:hover {
      background: rgba(57, 109, 214, 0.1);
      border: 1px solid #396DD6;
    }
    .RadioButtonTrue {
      background: rgba(57, 109, 214, 0.1);
      border: 1px solid #396DD6;
    }
  }
  .radio {
    width: 100%;
    text-align: left;
    font-size: 20px;
  }
  .dialogFooter {
    margin-top: 18px;
    text-align: right;
    overflow: hidden;
    .price {
      display: inline-block;
      overflow: hidden;
      margin-right: 24px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      p {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        color: #ED582A;
        span {
          font-size: 30px;
        }
      }
    }
  }
}
.QRcode {
  h3 {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 25px;
  }
  .imgQR {
    width: 160px;
    height: 160px;
    margin-top: 16px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-top: 16px;
  }
  aside {
    font-size: 10px;
    font-weight: 300;
    color: #333333;
    line-height: 15px;
    margin-top: 5px;
  }
  .payNo {
    font-size: 10px;
    margin-top: 5px;
  }
}
</style>