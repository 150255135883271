<template>
  <div class="SystemSettings">
    <breadcrumb />
    <div class="SystemTitleBox">
      <h3 class="SystemTitle l">
        {{$t('router.TemplateSet')}}
        <el-tooltip
          class="item"
          effect="light"
          :content="$t('ti-shi-wen-zi')"
          placement="top"
        >
          <div
            slot="content"
            style="width:300px;line-height: 20px;"
          >
            {{$t('mo-ban-ti-shi')}}
          </div>
          <i class="iconfont iconbangzhu1" />
        </el-tooltip>
      </h3>
      <!-- <el-button
      class="r"
      style="margin-left:20px;width:98px;"
      @click="Change"
      type="primary"
      >
        保存
      </el-button> -->
      <el-button
        type="primary"
        @click="$Privilage.perms.tpot_template_create?dialogVisible = true:routePush('VipHome')"
        class="r"
      >
        {{$t('xin-jian-mo-ban')}}
      </el-button>
    </div>
    <div class="ModelBox">
      <el-table
        :data="options"
        @row-click="rowClick"
        :row-class-name="tableRowClass"
        size="mini"
        width="100%"
        :header-cell-style="{background:'#F5F7FA',color:'#909399'}"
      >
        <el-table-column width="50">
          <template slot-scope="scope">
            <i
              v-show="successTrue(scope.row.id,scope.$index)"
              class="el-icon-check successIcon"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$t('parameter.tableColumn2')"
          width="233"
        />
        <el-table-column
          prop="type"
          :formatter="typeFormatter"
          :label="$t('Output.type')"
        />
        <el-table-column
          prop="created"
          :label="$t('HomePage.ynbeia')"
        >
          <template slot-scope="scope">
            {{
              scope.row.created | fmtdate
            }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('HomePage.fkaadm')"
          width="240"
        >
          <template slot-scope="scope">
            <el-button
              :disabled="defaultTrue(scope.row)"
              @click="$Privilage.perms.tpot_template_update?compileClick(scope.row):$store.commit('vipDialog', true)"
              type="primary"
              size="small"
            >
              {{$t('MdmHome.dialogButton1')}}
            </el-button>
            <el-button
              :disabled="defaultTrue(scope.row)"
              @click="handleDelete(scope.row.id)"
              type="danger"
              size="small"
            >
              {{$t('shan-chu')}}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="750px"
      @closed="off"
      @open="open"
      :close-on-click-modal="false"
      center
    >
      <p
        style="
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    color: #333333;
    "
      >
        {{ compileTrue?$t('bian-ji-mo-ban'):$t('Setting.button1') }}
      </p>
      <span class="titleStyle">{{$t('mo-ban-ming-cheng')}}</span>
      <el-form
        ref="checkboxName"
        :model="checkboxName"
        :rules="rules"
      >
        <el-form-item prop="checkboxName">
          <el-input
            maxlength="30"
            v-model="checkboxName.checkboxName"
            style="width:192px"
            :placeholder="$t('qing-shu-ru-mo-ban-ming-cheng')"
          />
        </el-form-item>
      </el-form>
      <p class="titleStyle">
        {{$t('zi-ding-yi-mo-ban')}}
      </p>
      <el-tabs
        v-model="activeName"
        style="width:700px"
      >
        <el-tab-pane
          name="0"
          :label="$t('fen-lei')"
        >
          <div class="dialog-class">
            <p>{{$t('yu-chu-li')}}</p>
            <el-checkbox-group v-model="checkbox.classifier">
              <el-checkbox
                v-for="item in checkbox1.classifier.processing"
                :key="item._id"
                :label="item"
              />
            </el-checkbox-group>
            <p>{{$t('parameter.xName1')}}</p>
            <el-checkbox-group v-model="checkbox.classifier">
              <el-checkbox
                v-for="item in checkbox1.classifier.feature"
                :key="item._id"
                :label="item"
              />
            </el-checkbox-group>
            <p>{{$t('fen-lei-qi')}}</p>
            <el-checkbox-group v-model="checkbox.classifier">
              <el-checkbox
                v-for="item in checkbox1.classifier.model_name"
                :key="item._id"
                :label="item"
              />
            </el-checkbox-group>
          </div>
        </el-tab-pane>
        <el-tab-pane
          name="1"
          :label="$t('MdmHome.taskType3')"
        >
          <div class="dialog-class">
            <p>{{$t('yu-chu-li')}}</p>
            <el-checkbox-group v-model="checkbox.regressor">
              <el-checkbox
                v-for="item in checkbox1.regressor.processing"
                :key="item._id"
                :label="item"
              />
            </el-checkbox-group>
            <p>{{$t('parameter.xName1')}}</p>
            <el-checkbox-group v-model="checkbox.regressor">
              <el-checkbox
                v-for="item in checkbox1.regressor.feature"
                :key="item._id"
                :label="item"
              />
            </el-checkbox-group>
            <p>{{$t('hui-gui-qi')}}</p>
            <el-checkbox-group v-model="checkbox.regressor">
              <el-checkbox
                v-for="item in checkbox1.regressor.model_name"
                :key="item._id"
                :label="item"
              />
            </el-checkbox-group>
          </div>
        </el-tab-pane>
      </el-tabs>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">{{$t('AutoML.TemplateSet.sa72ib')}}</el-button>
        <el-button
          type="primary"
          @click="newModelFn()"
        >{{$t('bao-cun')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb"
export default {
  data() {
    return {
    options:[],
    TemplateConfig:{},
    classId:'',
    dialogVisible: false,
    checkboxName: {
      checkboxName:''
    },
    checkbox:{
      classifier:[],
      regressor:[],
    },
    checkbox1:{
      classifier:{
        processing:[],
        feature:[],
        model_name:[]
      },
      regressor:{
        processing:[],
        feature:[],
        model_name:[]
      },
    },
    activeName:'1',
    rowClickFn:'',
    rules: {
    checkboxName: [
      { required: true, message: this.$t('qing-shu-ru-mo-ban-ming-cheng'), trigger: 'blur' },
      { min: 2, max: 30, message: this.$t('chang-du-zai-2-dao-30-ge-zi-fu'), trigger: 'blur' }
    ],},
    compileTrue: false,//判断是不是编辑
    };
  },
  components :{
    Breadcrumb,
  },
  created() {
    this.getTemplate()
  },
  methods: {
    routePush(name) {
      this.$router.push({
        name: name,
      });
    },
    compileClick(row){
      console.log(row)
      let url = window.apis.SYS_TEMPLATE;
      this.axios.get(url+row.id+"/").then((res)=>{
        if (res.status === 201 || res.status === 200) {
        this.activeName = String(res.data.type)
        this.checkbox = this.toJSON(res.data.methods)
        this.checkboxName.checkboxName = String(res.data.name)
        this.checkboxName.id = res.data.id
        this.compileTrue = true
        this.dialogVisible = true
      }
      })
    },
    off() {
      this.$refs["checkboxName"].resetFields();
      this.checkboxName.checkboxName = ''
      this.checkbox.classifier = []
      this.checkbox.regressor = []
      this.compileTrue = false
    },
    open() {
      this.getModel()
    },
    Change() {
    // 校验合法性
    let url = window.apis.SYS_USER;
    this.axios
    .put(url + this.TemplateConfig.id + "/", {
      config: parseInt(this.TemplateConfig.config),
    })
    .then((res) => {
      this.getTemplate();
      if (res.status === 200||res.status === 201) {
        // this.$message({
        //   message: "修改信息成功！",
        //   type: "success",
        // });
      } 
    })
    .catch((error) => {
      console.log(error);
    });
    },
    newModelFn(){
      this.$refs['checkboxName'].validate((valid) => {
      if (valid) {
      // 判断model_name是否为空
      if(this.checkbox.classifier == "" || this.checkbox.regressor == ""){
        this.$message({
        message: this.$t('hui-gui-qi-fen-lei-qi-bu-de-wei-kong'),
        type: 'warning'
      });
      }else{
        this.templateSave()
      }} else {
        return false;
      }
    });

    },
    templateSave(){
      // 保存
      let data = {
        name: this.checkboxName.checkboxName,
        type: this.activeName,
        methods: {classifier:[],regressor:[]},
      }
      let method = this.compileTrue?"put":"post"//判断是否是编辑
      data.methods.classifier = this.checkbox.classifier
      data.methods.regressor = this.checkbox.regressor
      data.methods = JSON.stringify(data.methods)
      let url = this.compileTrue?`${window.apis.SYS_TEMPLATE}${this.checkboxName.id}/`:window.apis.SYS_TEMPLATE;
      this.axios({url: url,method: method,data: data}).then((res) => {
        if (res.status === 200||res.status === 201) {
        this.$message({
          message: this.compileTrue?this.$t('mo-ban-xiu-gai-cheng-gong'):this.$t('xin-zeng-mo-ban-cheng-gong'),
          type: "success",
        });
        this.getTemplate();
        this.dialogVisible = false;
        }
      })
    },
    getModel(){
      let url = window.apis.SYS_MODEL;
      this.axios.get(url).then((res) => {
        this.checkbox1 = res.data
        console.log(this.checkbox1)
      });
    },
    // 获取自定义模板配置列表
    getTemplate() {
    let url = window.apis.SYS_TEMPLATE;
    this.axios.get(url).then((res) => {
    // this.options = res.data.map((item) => {
    // return { methods: this.toJSON(item.methods) };
    // });
    this.getTemplateConfig()
    this.options = res.data
    for(let key in res.data){
      this.options[key].methods = this.toJSON(res.data[key].methods)
    }
    });
    },
    // methode转JSON
    toJSON(str){
    try {
      return JSON.parse(str)
    } catch (err) {
      return str
    }},
    // 获取配置文件默认选中
    getTemplateConfig(){
      let url = window.apis.SYS_USER_INFO;
      this.axios.get(url).then((res) => {
        this.TemplateConfig = res.data
      })
    },
    // 选中行ICON
    successTrue(id,index){
      if(this.TemplateConfig.config == id){
        this.classId = index
        return true
      }else{
        return false
      }
    },
    // 选中行Class
    tableRowClass({rowIndex}){
      if(this.classId == rowIndex){
        return "warning-row"
      }
    },
    // 点击行事件
    rowClick(row){
      this.TemplateConfig.config = row.id
      clearTimeout(this.rowClickFn)
      this.rowClickFn = setTimeout(()=>{this.Change()},500)
    },
    // 默认选项的编辑删除置灰
    defaultTrue(row){
      if(row.type == -1){
        return true
      }else{
        return false
      }
    },
    // 格式化类型
    typeFormatter(row){
      let a = ''
      switch(row.type){
        case -1:
        a = this.$t('mo-ren');
        break;
        case 0:
        a = this.$t('fen-lei')
        break;
        case 1:
        a = this.$t('HomePage.yfhrf1')
        break;
        default: a = '';
      }
      return a
    },
    handleDelete(id) {
      this.$confirm(this.$t('ci-cao-zuo-jiang-yong-jiu-shan-chu-gai-mo-ban-shi-fou-ji-xu'), this.$t('MdmHome.delTitle'), {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
    let url = window.apis.SYS_TEMPLATE;
    this.axios
    .delete(url + id + "/")
    .then((res) => {
      this.getTemplate();
      if (res.status === 204) {
        this.$message({
          message: this.$t('MdmHome.message3'),
          type: "success",
        });
        if(this.TemplateConfig.config == id){
          this.TemplateConfig.config = 3
          this.Change()
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
      }).catch(() => {});
    },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .warning-row {
  background: #f6f7f8;
}
.titleStyle {
  height: 40px;
  display: block;
  font-size: 15px;
  line-height: 40px;
  font-weight: 500;
  color: #333333;
  list-style: none;
  margin-right: 20px;
}
.dialog-class{
  width:720px;
  height:400px;
  overflow:auto;
}
.el-checkbox-group {
  margin: 5px 0 20px 0;
}
.SystemTitleBox {
  // width: 100%;
  min-width: 822px;
  height: 58px;
  margin: 0 33px;
  padding: 10px 0;

  .SystemTitle {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    padding-top:10px;
  }

  .inputSelect {
    width: 300px;
    margin-left: 30px;
  }
}
.SystemSettings {
  height: auto;
  min-width: 888px;
}
.el-tab-pane {
  .el-checkbox {
  width: 190px;
}}
.ModelBox {
  overflow: auto;
  margin: 0 33px 10px 33px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  border: 1px solid rgba(218, 223, 231, 0.5);;
  .successIcon {
    color: #396DD6;
    font-size: 25px;
  }

}
</style>