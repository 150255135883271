<template>
<div class="automl">
   <el-container direction="vertical">
      <el-header style="height: 56px;padding:0px;">
         <automl-header />
      </el-header>
      <el-container>
         <el-aside width="auto">
            <div class="aside">
               <el-menu
                  :default-active="active"
                  background-color="#E5E7EB"
                  text-color="#333333"
                  active-text-color="#012D87"
                  :collapse="nav">

                  <el-menu-item index="1" @click="routePush('DataSet')">
                     <i class="iconfont iconicon_shujujiguanli1" />
                     <span class="navTitle" slot="title">{{$t('MdmHome.title')}}</span>
                  </el-menu-item>
                  <el-menu-item index="2" @click="routePush('AutoDL');" :disabled="!dataset_id_DL||dataset_id_DL=='null'">
                     <i class="iconfont iconicon_jiqizidonghua1" />
                     <span class="navTitle" slot="title">{{$t('MdmHome.BoxMainButton2')}}</span>
                  </el-menu-item>
                  <el-menu-item index="3" @click="routePush('TaskList')">
                     <i class="iconfont iconicon_moxing" />
                     <span class="navTitle" slot="title">{{$t('HomePage.zyxog5')}}</span>
                  </el-menu-item>
               </el-menu>
            </div>
         </el-aside>
         <el-container>
            <el-main id="el-main" ref="elmain">
               <router-view id="router-view" ref="Childmain" />
               <footer-a id="footer" />
            </el-main>
         </el-container>
      </el-container>
   </el-container>
</div>
</template>

<script>
import {
   mapState
} from 'vuex'
import FooterA from '../../components/Footer'
import AutomlHeader from '../../components/AutomlHeader'
export default {
   data() {
      return {
         headImgUrl: '',
         show1: false,
         nav: false,
         active: '1',
         clientWidth: document.body.clientWidth,
         timer: '',
         hoverFalseData: '',
         list: [],
         listActive: '-1',
      };
   },
   watch: {
      // 监听窗口变化自适应侧边栏
      clientWidth: function (val) {
         if (!this.timer) {
            this.clientWidth = val
            this.timer = true
            setTimeout(() => {
               if (val < 1365) {
                  this.nav = true
               } else {
                  this.nav = false
               }
               this.timer = false
            }, 400)
         }
      }
   },
   mounted() {
      this.getList()
      this.indexTrue()
      this.active = this.$route.matched[1].meta.active;
      window.onresize = () => {
         this.clientWidth = document.body.clientWidth
      };
   },
   beforeUpdate() {
      this.indexTrue()
      this.active = this.$route.matched[1].meta.active;
      this.clientWidth = document.body.clientWidth;
      if (this.clientWidth < 1365) {
         this.nav = true
      } else {
         this.nav = false
      }
   },
   methods: {
      //循环匹配选中状态
      indexTrue() {
         for (let key in this.list) {
            if (this.list[key].id == this.$route.query.tag_id) {
               this.listActive = key
               this.$route.matched[1].meta.title = `${this.list[key].name}${this.$t('ren-wu-lie-biao')} | ${this.$GlobalVal.ORG_NAME}`
            }
         }
      },
      //获取菜单列表
      getList() {
         this.axios.get(window.apis.MODELS_TAG).then(res => {
            this.list = res.data
            this.indexTrue()
         })
      },
      routePush(dest) {
         if (this.$router.history.current.name != dest) {
            this.$router
               .push({
                  name: dest
               })
            this.listActive = '-1'
         }
      },
      routePushData(dest, data) {
         this.$router
            .push({
               name: dest,
               query: {
                  tag_id: data,
               },
            })
      },
   },
   computed: {
      ...mapState(['user', 'HomeLoading', 'roles',"dataset_id_DL"])
   },
   components: {
      FooterA,
      AutomlHeader
   }
}
</script>

<style lang="scss" scoped>
.el-dropdown-link {
   display: none;
}

.el-menu-item:hover {
   .el-dropdown-link {
      display: block;
   }
}

#router-view {
   min-height: calc(100% - 119px);
   height: auto;
}

.iRotate {
   transform: translate(-50%, 0) rotate(180deg);
}

.el-menu-item {
   height: 44px;
   line-height: 44px;

   i {
      font-size: 18px;
   }
}

.automl {
   overflow: hidden;
}

.navTitle {
   display: inline-block;
   //   width: calc(100vw - 1805px);
   //   max-width: 130px;
   //   min-width: 96px;
   margin: 0 10px;
   font-size: 16px;
   font-weight: 500;

   .titleMain {
      display: block;
      //  width: 65px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; //溢出不换行
   }
}

.aside {
   position: relative;
   height: calc(100vh - 56px);
   background: #E5E7EB;
   text-align: left;
   overflow: auto;

   .handover {
      position: absolute;
      left: 50%;
      bottom: 10%;
      transform: translate(-50%, 0);
      width: 40px;
      height: 40px;
      border-radius: 50%;
   }
}

.asideBottom {
   margin-top: 10px;

   .title {
      width: 100%;
      display: block;
      font-size: 15px;
      padding-left: 20px;
      margin-bottom: 10px;
      text-align: left;
   }
}

#el-main {
   width: calc(100vw - 184px);
   padding: 0;
   height: calc(100vh - 56px);
   overflow: scroll;
}
</style>
