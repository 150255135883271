<template>
<div
   class="Mdm"
   v-infinite-scroll="handleCurrentChange"
   infinite-scroll-delay="300"
   :infinite-scroll-disabled="disabled"
>
   <uploading />
   <div class="MdmTitleBox">
      <h3 class="MdmTitle l">
         <!-- 数据管理 -->
         {{$t('MdmHome.title')}}
         <el-tooltip
            class="item"
            effect="light"
            placement="top"
         >
            <div slot="content" style="width:300px;line-height: 20px;">
               <!-- 从用户自有或者系统内置数据中选择需要分析的数据集。 -->
               {{$t('MdmHome.titleMessage')}}
            </div>
            <i class="iconfont iconbangzhu1" />
         </el-tooltip>
      </h3>
      <div class="MdmTitleBox__flex__content">
         <el-select
            v-model="value"
            @change="getDatasetList('1')"
            :placeholder="$t('MdmHome.select')"
            class="r"
         >
            <el-option
               v-for="item in options"
               :key="item.value"
               :label="item.label"
               :value="item.value"
            />
         </el-select>

         <el-input
            :placeholder="$t('MdmHome.input')"
            clearable
            v-model="search"
            @keyup.enter.native="getDatasetList('1')"
            class="inputSelect r"
         >
            <el-button
               slot="append"
               icon="el-icon-search"
               @click="getDatasetList('1')"
            />
         </el-input>
         <el-button
            id="MdmTitlebutton"
            class="r"
            @click="UploadingBoxTrue()"
            size="small"
            type="primary"
         >
            <i class="iconfont iconxinjian" id="jia" />
            &nbsp;
            <!-- 创建数据集 -->
            {{$t('MdmHome.button')}}
         </el-button>
      </div>
   </div>
   <div class="Tabs">
      <span :class="[
          TabsNumber == 0 ? 'TabsButtomTrue' : 'TabsButtomFalse',
          'TabsButtom',
        ]" @click="Tabs('0')">
         <!-- 自有数据 -->
         {{$t('MdmHome.tabs1')}}
      </span>
      <span :class="[
          TabsNumber == 1 ? 'TabsButtomTrue' : 'TabsButtomFalse',
          'TabsButtom',
        ]" @click="Tabs('1')">
         <!-- 系统内置 -->
         {{$t('MdmHome.tabs2')}}
      </span>
   </div>
   <div class="MdmMain">
      <div
         class="MdmMainBox"
         v-for="(item, index) in datasets"
         :key="item._id"
      >
         <div class="top">
            <el-image>
               <div slot="error" class="image-slot">
                  <img class="flowChartIcon" src="../../../assets/img/dataset.png" alt="">
               </div>
            </el-image>
            <span class="TopName">{{ item.name }}</span>

            <i v-if="item.user_id != 0" class="r iconfont iconshanchu-07 del" @click="handleDelete(item.id, item)" />
         </div>
         <div
            class="Main"
            v-loading="BoxButtomTrue(item)"
            :element-loading-text="$t('MdmHome.loading')"
            element-loading-spinner="el-icon-warning-outline"
            element-loading-background="rgba(255, 255, 255, 0.7)"
         >
            <div class="BoxMain">
               <p>
                  <!-- 任务类型： -->
                  {{$t('MdmHome.BoxMain1')}}
               </p>
               <span>{{ item.task_type_cn }}</span>
            </div>
            <div class="BoxMain">
               <p>
                  <!-- 创建时间： -->
                  {{$t('MdmHome.BoxMain2')}}
               </p>
               <span>{{ item.created | fmtdate }}</span>
            </div>
            <div class="BoxMain">
               <p>
                  <!-- 文件大小： -->
                  {{$t('MdmHome.BoxMain3')}}
               </p>
               <span>{{ item.size | byteChange }} ( {{ item.columns }} {{$t('MdmHome.BoxMainL')}} {{
              item.rows
            }} {{$t('MdmHome.BoxMainH')}} )</span>
            </div>
            <div class="BoxMain">
               <p>
                  <!-- 数据描述： -->
                  {{$t('MdmHome.BoxMain4')}}
               </p>
               <span id="About">{{ item.desc | ellipsis_s }}</span>
            </div>
         </div>
         <div class="BoxButtom" v-if="!BoxButtomTrue(item)">
            <el-button
               type="primary"
               @click="PushPivotTable(item, index)"
               plain
            >
               <!-- 数据透视 -->
               {{$t('MdmHome.BoxMainButton1')}}
            </el-button>
            <el-button
               type="primary"
               @click="gotoAnalysis(item, index)"
               plain
            >
               <!-- 数据建模 -->
               {{$t('MdmHome.BoxMainButton2')}}
            </el-button>
            <el-button
               type="primary"
               @click="showDetail(item)"
               plain
            >
               {{
              TabsNumber == 0 ? $t('MdmHome.BoxMainButton3') : $t('MdmHome.BoxMainButton4')
            }}
            </el-button>
         </div>
         <div class="BoxButtom" v-else>
            <el-button
               type="primary"
               @click="showDetail(item)"
               plain
            >
               <!-- 继续创建 -->
               {{$t('MdmHome.BoxMainButton5')}}
            </el-button>
         </div>
      </div>
      <div class="MdmMainMessage" v-show="MessageTrue">
         <i class="el-icon-warning-outline" />
         <span>
            <!-- 暂无数据 -->
            {{$t('MdmHome.BoxMain5')}}
         </span>
      </div>
   </div>
   <el-dialog
      :visible.sync="particulars"
      class="dialogParticulars"
      width="600px"
      center
      :close-on-click-modal="false"
      @close="off('editForm')"
   >
      <h3 slot="title" class="UploadingTitle">
         <!-- 数据集详情 -->
         {{$t('MdmHome.dialogTitle')}}
      </h3>
      <el-form
         ref="editForm"
         :model="showDetailData"
         label-width="130px"
         :disabled="non_editable"
         :rules="detailRules"
         status-icon
      >
         <fieldset style="width: 40%">
            <el-form-item
               :label="$t('MdmHome.dialogForm')"
               prop="name"
               size="small"
            >
               <el-input
                  v-model="showDetailData.name"
                  :placeholder="$t('MdmHome.dialogInput')"
                  class="input_width"
               />
            </el-form-item>
            <el-form-item
               :label="$t('MdmHome.dialogItem1')"
               prop="task_type"
               size="small"
            >
               <el-radio-group v-model="showDetailData.task_type">
                  <el-radio :label="0" >
                     <!-- 二分类 -->
                     {{$t('MdmHome.dialogRadio1')}}
                  </el-radio>
                  <el-radio :label="1" >
                     <!-- 多分类 -->
                     {{$t('MdmHome.dialogRadio2')}}
                  </el-radio>
                  <el-radio :label="2" >
                     <!-- 回归 -->
                     {{$t('MdmHome.dialogRadio3')}}
                  </el-radio>
                  <el-radio :label="3" >
                     {{$t('MdmHome.taskType4')}}
                  </el-radio>
               </el-radio-group>
               <el-alert
                  :title="task_type_message"
                  v-show="task_type_message"
                  :closable="false"
                  type="info"
                  effect="dark">
               </el-alert>
            </el-form-item>
            <el-form-item
               :label="$t('MdmHome.dialogItem2')"
               prop="read_mode_header"
               size="small"
            >
               <el-radio
                  v-model="showDetailData.read_mode_header"
                  label="y"
                  
               >
                  <!-- 有 -->
                  {{$t('MdmHome.dialogRadio4')}}
               </el-radio>
               <el-radio
                  v-model="showDetailData.read_mode_header"
                  label="n"
                  
               >
                  <!-- 无 -->
                  {{$t('MdmHome.dialogRadio5')}}
               </el-radio>
            </el-form-item>
            <transition name="el-fade-in-linear">
               <el-form-item
                  :label="showDetailData.task_type==3?`${$t('MdmHome.dialogItem5')}：`:$t('Uploading.formLabel4')"
                  prop="target_column"
                  size="small"
                  v-show="showDetailData.read_mode_header"
               >
                  <el-select
                     v-model="showDetailData.target_column"
                     filterable
                     :placeholder="$t('MdmHome.dialogSelect')"
                     @change="checkcodeFn()"
                  >
                     <el-option
                        v-for="item in headerData"
                        :key="item.value"
                        :label="showDetailData.read_mode_header=='y'?item.label:item.value"
                        :value="item.value"
                     />
                  </el-select>
               </el-form-item>
            </transition>
            <el-form-item
               :label="$t('MdmHome.dialogItem4')"
               prop="desc"
               size="small"
            >
               <el-input
                  type="textarea"
                  v-model="showDetailData.desc"
                  :placeholder="$t('MdmHome.dialogInput2')"
                  maxlength="500"
                  show-word-limit
                  class="input_width"
                  :rows="5"
               />
            </el-form-item>
         </fieldset>
         <br>
      </el-form>
      <div slot="footer" v-if="showDetailData.user_id != 0">
         <el-button type="primary" @click="onSubmit('editForm')">
            {{
            non_editable ? $t('MdmHome.dialogButton1') : $t('MdmHome.dialogButton2')
          }}
         </el-button>
      </div>
   </el-dialog>

   <div class="backTop" @click="backTop">
      <el-tooltip
         class="item"
         effect="light"
         :content="$t('MdmHome.tooltip')"
         placement="top-start"
      >
         <el-button
            type="primary"
            icon="el-icon-arrow-up"
            circle
            v-show="flag_scroll"
            class="top"
         />
      </el-tooltip>
   </div>
</div>
</template>

<script>
import Uploading from "../MDM/Uploading";
import {
   mapState
} from "vuex";

export default {
   name: "Mdm",
   computed: {
      ...mapState(["Uploadingdata"]),
      disabled() {
         return this.Pages < this.currentPage;
      },
   },
   components: {
      Uploading,
   },
   data() {
      return {
         flag_scroll: false,
         scroll: "",

         MessageTrue: false,
         non_editable: true,
         showDetailData: {
            name: "",
            target_column: "",
            task_type: "",
            desc: "",
         },
         particulars: false,
         datasets: [],
         msg: "", //记录每一条的信息，便于取id
         currentPage: 1, // 当前页
         search: "",
         options: [{
               value: "",
               label: this.$t('MdmHome.optionsLabel1'),
            },
            {
               value: "0",
               label: this.$t('MdmHome.optionsLabel2'),
            },
            {
               value: "1",
               label: this.$t('MdmHome.optionsLabel3'),
            },
            {
               value: "2",
               label: this.$t('MdmHome.optionsLabel4'),
            },
            {
               value: "3",
               label: this.$t('MdmHome.optionsLabel5'),
            },
         ],
         headerData: [{
            value: "",
            label: this.$t('MdmHome.optionsLabel1'),
         }, ],
         value: "",
         TabsNumber: 0,
         Pages: "1",
         task_type_message:"",
         detailRules: {
            name: [{
                  required: true,
                  message: this.$t('MdmHome.nameMessage1'),
                  trigger: "blur"
               },
               {
                  min: 5,
                  max: 30,
                  message: this.$t('MdmHome.nameMessage2'),
                  trigger: "blur"
               },
               {
                  pattern: /^[\u4E00-\u9FA5A-Za-z0-9][\S]+$/,
                  message: this.$t('MdmHome.nameMessage3'),
               },
            ],
            target_column: [{
               required: true,
               message: this.$t('MdmHome.targetMessage'),
               trigger: "blur"
            }, ],
            read_mode_header: [{
               required: true,
               message: this.$t('MdmHome.headerMessage'),
               trigger: "blur"
            }, ],
            task_type: [{
               required: true,
               message: this.$t('MdmHome.typeMessage'),
               trigger: "blur"
            }, ],
            desc: [{
               required: true,
               message: this.$t('MdmHome.descMessage'),
               trigger: "blur"
            }],
         },
      };
   },
   mounted() {
      this.clearTaskID();
      this.getDatasetList();
      window.addEventListener("scroll", this.handleScroll, true);
   },
   beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
   },
   methods: {
      checkcodeFn() {
         //检查数据集类型
         this.axios.post(`${window.apis.SYS_DATASET_EVALUATE}?dataset_id=${this.showDetailData.id}&target_column=${this.showDetailData.target_column}`).then(res => {
            if (res.data.task_type) {
               let message = ''
               switch (res.data.task_type) {
                  case 0:
                     message = '根据目标列建议类型为二分类'
                     break;
                  case 1:
                     message = '根据目标列建议类型为多分类'
                     break;
                  case 2:
                     message = '根据目标列建议类型为回归'
                     break;
                  case 3:
                     message = '根据目标列建议类型为时间序列'
                     break;
                  default:
                     message = ''
                     break;
               }
               this.task_type_message = message
            }
         })
      },
      BoxButtomTrue(item) {
         if (item.name == null) {
            return true
         } else {
            return false
         }
      },
      //滑动超过200时显示按钮
      handleScroll(e) {
         let scrollTop = e.target.scrollTop;
         if (scrollTop > 200) {
            this.flag_scroll = true;
         } else {
            this.flag_scroll = false;
         }
      },
      //返回顶部事件
      backTop() {
         document
            .getElementById("router-view")
            .scrollIntoView({
               behavior: "smooth",
               block: "start"
            });
      },

      PushPivotTable(item, index) {
         this.$router.push({
            name: "DataPivot",
            query: {
               page: item.path,
               id: item.id,
               index: index
            },
         });
      },
      clearTaskID() {
         this.$store.commit("task_id", "");
         this.$store.commit("task_name", "");
      },
      getDatasetList(pageNum) {
         let url = window.apis.SYS_DATASET;
         // 判断是否传入页码
         if (typeof pageNum == "undefined" || pageNum == "" || pageNum == null) {} else {
            this.datasets = [];
            this.currentPage = 1;
         }
         let params = {
            page: this.currentPage,
         };
         params["built_in"] = this.TabsNumber;

         if (
            typeof this.search == "undefined" ||
            this.search == "" ||
            this.search == null
         ) {} else {
            params["name"] = this.search;
         }
         if (
            typeof this.value == "undefined" ||
            this.value == "" ||
            this.value == null
         ) {} else {
            params["task_type"] = this.value;
         }

         this.axios
            .get(url, {
               params: params,
            })
            .then((res) => {
               if (res.status === 200) {
                  this.Pages = Math.ceil(res.data.count / 10);
                  // 判断有无数据
                  if (res.data.results.length < 1) {
                     this.MessageTrue = true;
                  } else {
                     this.MessageTrue = false;
                  }
                  for (var i in res.data.results) {
                     this.datasets.push(res.data.results[i]);
                  }
                  for (var key in this.datasets) {
                     switch (this.datasets[key].task_type) {
                        case 0:
                           this.datasets[key].task_type_cn = this.$t('MdmHome.taskType1');
                           break;
                        case 1:
                           this.datasets[key].task_type_cn = this.$t('MdmHome.taskType2');
                           break;
                        case 2:
                           this.datasets[key].task_type_cn = this.$t('MdmHome.taskType3');
                           break;
                        case 3:
                           this.datasets[key].task_type_cn = this.$t('MdmHome.taskType4');
                           break;
                     }
                  }
                  // this.count = res.data.count;
               }
            })
            .catch((error) => {
               // this.$message({
               //     message: "请求数据错误",
               //     type: 'warning',
               //   });
            });
      },
      // methode转JSON
      toJSON(str) {
         try {
            return JSON.parse(str)
         } catch (err) {
            return str
         }
      },
      showDetail(item) {
         this.showDetailData = JSON.parse(JSON.stringify(item)); // 拷贝，防止影响列表展示
         let header = this.toJSON(item.header)
         this.headerData = header.map((index, item) => {
            return {
               label: index,
               value: item
            }
         })
         this.particulars = true;
      },
      gotoAnalysis(row, index) {
         this.$store.commit("dataset_id", row.id);
         this.$store.commit("dataset_name", row.name);
         this.$store.commit("task_type", row.task_type);
         this.$store.commit("target_column", row.target_column);
         this.$store.commit("desc", row.desc);
         this.$store.commit("read_mode_header", row.read_mode_header);
         this.$store.commit("headerData", row.header);
         var page = Math.floor(index / 10) + 1;
         var indexZ = index - Math.floor(index / 10) * 10;
         this.$router.push({
            name: "Analysis",
            params: {
               index: indexZ,
               user_id: row.user_id,
               on: "true",
               page: page,
            },
         });
      },
      onSubmit(formName) {
         if (this.non_editable) {
            this.non_editable = false;
         } else {
            // 校验合法性
            this.$refs[formName].validate((valid) => {
               if (valid) {
                  let url = window.apis.SYS_DATASET;
                  this.axios
                     .put(url + this.showDetailData.id + "/", {
                        name: this.showDetailData.name,
                        target_column: this.showDetailData.target_column,
                        task_type: this.showDetailData.task_type,
                        read_mode_header: this.showDetailData.read_mode_header,
                        desc: this.showDetailData.desc,
                     })
                     .then((res) => {
                        if (res.status === 200 || res.status === 201) {
                           this.$message({
                              message: this.$t('MdmHome.message1'),
                              type: "success",
                           });
                           this.getDatasetList("1"); // 刷新列表
                           this.particulars = false; // 关闭对话框
                        }
                     })
                     .catch((error) => {
                        this.$message({
                           message: this.$t('MdmHome.message2'),
                           type: "warning",
                        });
                     });
               } else {
                  // 定位到错误的位置
                  setTimeout(() => {
                     const isError = document.getElementsByClassName("is-error");
                     isError[0].querySelector("input").focus();
                  }, 100);
                  return false;
               }
            });
         }
      },
      off(formName) {
         this.$refs[formName].resetFields();
         this.task_type_message = ''
         this.particulars = false; // 关闭对话框
         this.non_editable = true;
      },
      handleDelete(id, row) {
         this.msg = row;
         // let cleatedTrue = row.name==null?{flag:1}:''
         let url = window.apis.SYS_DATASET;
         this.$confirm(this.$t('MdmHome.delConfirm'), this.$t('MdmHome.delTitle'), {
            //   confirmButtonText: '确定',
            //   cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            this.axios({
                  method: 'delete',
                  url: url + id + "/",
                  data: row.name == null ? {
                     flag: 1
                  } : ''
               })
               .then((res) => {
                  if (res.status === 204) {
                     this.getDatasetList("1");
                     this.$message({
                        message: this.$t('shan-chu-cheng-gong'),
                        type: "success",
                     });
                  }
               })
         }).catch(() => {});
      },
      handleSizeChange(val) {},
      handleCurrentChange() {
         this.currentPage += 1;
         if (this.Pages < this.currentPage) {} else {
            this.getDatasetList();
         }
      },
      routePush(dest) {
         if (this.$router.history.current.name != dest) {
            this.$router.push({
               name: dest,
            });
         }
      },
      UploadingBoxTrue() {
         this.$store.commit("UploadingTrue", true);
      },
      refreshList() {
         if (!this.Uploadingdata) {
            this.getDatasetList("1");
         }
      },
      Tabs(index) {
         this.TabsNumber = index;
         this.datasets = [];
         this.currentPage = 1;
         this.getDatasetList();
      },
   },
   watch: {
      Uploadingdata: "refreshList",
   },
};
</script>

<style lang="scss" scoped>
.flowChartIcon {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.del {
   color: #396DD6;
   font-size: 20px;
   cursor: pointer;
   transition: all 300ms;
}

.del:hover {
   font-size: 22px;
   margin-top: -2px;
   color: #409EFF;
}

.Mdm {
   min-width: 970px;
   padding: 0px 32px 0 32px;
   text-align: left;

   .el-pagination {
      margin-top: 30px;
   }

   .dialogParticulars {
      .nameTitle {
         font-size: 22px;
         // font-family: "PingFang SC";
         font-weight: 500;
         color: #333333;
      }

      .el-input {
         width: 371px;
      }

      aside {
         width: 600px;
         margin: 56px 0 0 40px;

         .el-button:last-of-type {
            margin-left: 76px;
         }

         .el-button:hover {
            color: #4568ee;
            border-color: #4568ee;
         }

         .el-button:focus {
            color: #4568ee;
            border-color: #4568ee;
         }
      }

      // div {
      //   height: auto;
      //   border-top: 0px;
      //   p {
      //     width: 185px;
      //     font-size: 14px;
      //     line-height: 18px;
      //     display: inline-block;
      //     text-align: right;
      //   }
      //   span {
      //     overflow: hidden;
      //     width: 166px;
      //     height: auto;
      //     text-align: center;
      //     margin-left: 9px;
      //     display: inline-block;
      //     font-size: 14px;
      //     font-weight: 400;
      //     color: #333333;
      //     padding: 10px 0;
      //     border-bottom: 1px solid rgba(218, 223, 231, 0.5);
      //   }
      // }
   }
}

.MdmTitleBox {
   display: flex;
   justify-content: space-between;
   width: 100%;
   height: 58px;
   margin: 0 auto;
   padding: 10px 0;

   .MdmTitleBox__flex__content {
      display: flex;
   }

   .MdmTitle {
      white-space: nowrap;
      font-size: 18px;
      line-height: 46px;
      font-weight: bold;
      color: #333333;
      display: block;
      margin-right: 20px;

   }

   #MdmTitlebutton {
      //  width: 120px;
      height: 39px;
      font-size: 14px;

      #jia {
         font-size: 13px;
      }
   }

   .tableCheckbox {
      margin-right: 20px;
   }

   .el-select {
      margin-right: 20px;
   }

   .inputSelect {
      width: 300px;
      margin-right: 20px;
   }
}

.Tabs {
   width: 100%;
   height: 36px;
   text-align: left;

   .TabsButtom {
      display: inline-block;
      width: 110px;
      height: 36px;
      border-radius: 2px;
      font-size: 14px;
      font-weight: bold;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
   }

   .TabsButtomFalse {
      border: 0;
      color: rgba(92, 105, 123, 1);
      transition: all 300ms;
   }

   .TabsButtomFalse:hover {
      color: rgba(92, 105, 123, 0.8);
   }

   .TabsButtomTrue {
      border: 1px solid #396dd6;
      color: #4568ee;
   }
}

.MdmMain {
   position: relative;
   margin: 5.5px 0;
   display: flex;
   flex-wrap: wrap;
   flex-direction: row;
   // justify-content: space-around;
   justify-content:flex-start;

   .MdmMainBox {
      width:400px;
      height: auto;
      padding: 16px 16px 20px 16px;
      background: #ffffff;
      box-shadow: 0px 0px 17px 1px rgba(222, 226, 230, 0.6);
      border-radius: 10px;
      text-align: left;
      margin: 12.5px 10.5px;
      //  margin: 12.5px 13px;
      position: relative;

      .top {
         .el-image {
            width: 76px;
            height: 76px;
            border-radius: 4px;
         }

         span {
            position: absolute;
            width: 239px;
            top: 38px;
            left: 107px;
            font-size: 16px;
            font-weight: 500;
            color: #3e3e3e;
            line-height: 24px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
         }
      }

      .Main {
         height: 120px;
         overflow: hidden;

         .BoxMain {
            width: 100%;
            margin: 7.5px 0;
            overflow: hidden;

            p {
               font-size: 14px;
               line-height: 15px;
               font-weight: 500;
               color: #555d64;
               display: block;
               float: left;
            }

            span {
               display: block;
               float: left;
               line-height: 15px;
               color: #6c757d;
               font-weight: 400;
               font-size: 15px;
               text-overflow: ellipsis;
            }

            #About {
               width: 280px;
               line-height: 23px;
               margin-top: -3px;
            }
         }
      }

      .BoxButtom {
         display: flex;
         justify-content: space-around;
         width: 100%;
         height: 66px;
         text-align: center;
         border-top: 1px solid rgba(108, 117, 125, 0.08);

         .el-button {
            margin: 14px 4px;
         }
      }
   }

   .MdmMainMessage {
      position: absolute;
      left: 50%;
      top: 20vh;
      transform: translate(-50%, -50%);
      font-size: 25px;
      font-weight: 500;
      color: rgba(92, 105, 123, 0.8);
      display: block;
   }
}

.backTop {
   position: fixed;
   bottom: 120px;
   z-index: 100;
   right: 40px;
   background: white;

   .top {
      width: 41px;
      height: 41px;
   }
}
</style>
