<template>
<div class="Uploading">
   <el-dialog
      :visible.sync="Uploadingdata"
      width="577px"
      class="UploadingBox"
      center
      :close-on-click-modal="false"
      @close="clearForm('form')">
      <h3 slot="title" class="UploadingTitle">
         <!-- 创建数据集 -->
         {{$t('Uploading.h3')}}
      </h3>
      <el-form
         ref="form"
         :model="form"
         label-width="130px"
         :rules="rules"
         v-show="steps">
         <fieldset style="width: 40%">
            <el-form-item
               :label="$t('Uploading.formLabel1')"
               prop="name"
               size="small">
               <el-input
                  v-model="form.name"
                  :placeholder="$t('Uploading.placeholder')"
                  class="input_width" />
            </el-form-item>
            <el-form-item
               :label="$t('Uploading.formLabel2')"
               prop="task_type"
               size="small">
               <el-radio-group v-model="form.task_type">
                  <el-radio :label="0">
                     <!-- 二分类 -->
                     {{$t('MdmHome.taskType1')}}
                  </el-radio>
                  <el-radio :label="1">
                     <!-- 多分类 -->
                     {{$t('MdmHome.taskType2')}}
                  </el-radio>
                  <el-radio :label="2">
                     <!-- 回归 -->
                     {{$t('MdmHome.taskType3')}}
                  </el-radio>
                  <el-radio :label="3">
                     {{$t('MdmHome.taskType4')}}
                  </el-radio>
               </el-radio-group>
               <el-alert
                  :title="task_type_message"
                  v-show="task_type_message"
                  :closable="false"
                  type="info"
                  effect="dark">
               </el-alert>
            </el-form-item>
            <el-form-item
               :label="$t('Uploading.formLabel3')"
               prop="read_mode_header"
               size="small">
               <el-radio v-model="form.read_mode_header" label="y">
                  <!-- 有 -->
                  {{$t('MdmHome.dialogRadio4')}}
               </el-radio>
               <el-radio v-model="form.read_mode_header" label="n">
                  <!-- 无 -->
                  {{$t('MdmHome.dialogRadio5')}}
               </el-radio>
            </el-form-item>
            <transition name="el-fade-in-linear">
               <el-form-item
                  :label="form.task_type==3?`${$t('MdmHome.dialogItem5')}：`:$t('Uploading.formLabel4')"
                  prop="target_column"
                  size="small"
                  v-show="form.read_mode_header">
                  <el-select
                     v-model="form.target_column"
                     filterable
                     :placeholder="$t('MdmHome.dialogSelect')"
                     @change="checkcodeFn()">
                     <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="form.read_mode_header=='y'?item.label:item.value"
                        :value="item.value" />
                  </el-select>
                  <!-- <el-radio-group v-model="form.target_column">
                <el-radio
                  :label="-1"
                  border
                >
                  最后一列
                </el-radio>
                <el-radio
                  :label="0"
                  border
                >
                  首列
                </el-radio>
              </el-radio-group> -->
               </el-form-item>
            </transition>
            <el-form-item
               :label="$t('MdmHome.dialogItem4')"
               prop="desc"
               size="small">
               <el-input
                  type="textarea"
                  v-model="form.desc"
                  :placeholder="$t('Uploading.input1')"
                  maxlength="500"
                  show-word-limit
                  class="input_width"
                  :rows="5" />
            </el-form-item>
         </fieldset>
         <br>
      </el-form>
      <el-upload
         v-show="!steps"
         class="uploadClass"
         drag
         ref="upload_attach"
         action=""
         multiple
         accept=".csv"
         :limit="1"
         :on-change="changFile"
         :on-exceed="handleExceed"
         :auto-upload="false"
         :before-upload="beforeAvatarUpload"
         :http-request="uploadSectionFile">
         <i class="el-icon-upload" />
         <div class="el-upload__text">
            {{$t('Uploading.upload1')}}<em>{{$t('Uploading.upload2')}}</em>
         </div>
         <div class="el-upload__tip" slot="tip">
            {{$t('Uploading.messageError1')}}
         </div>

      </el-upload>
      <!--              <div-->
      <!--          class="separator"-->
      <!--        >-->
      <!--          <el-link-->
      <!--            target="_blank"-->
      <!--            @click="Advanced = !Advanced"-->
      <!--          >-->
      <!--            高级设置-->
      <!--          </el-link>-->
      <!--          <div-->
      <!--            class="box"-->
      <!--            v-show="Advanced"-->
      <!--          >-->
      <!--            <p class="title">-->
      <!--              自定义分隔符：-->
      <!--            </p>-->
      <!--            <el-input-->
      <!--              style="width:100px;"-->
      <!--              size="mini"-->
      <!--              maxlength="1"-->
      <!--              v-model="value"-->
      <!--              placeholder="请输入内容"-->
      <!--            />-->
      <!--          </div>-->
      <!--        </div>-->
      <el-progress v-if="progressFlag" :percentage="loadProgress" />
      <div
         class="dialogFooter"
         slot="footer"
         v-if="steps">
         <el-button type="primary" @click="onReset('form')">
            <!-- 重置 -->
            {{$t('Uploading.button1')}}
         </el-button>
         <el-button type="primary" @click="onSubmit('form')">
            <!-- 提交 -->
            {{$t('Uploading.button2')}}
         </el-button>
      </div>
      <div
         class="dialogFooter"
         slot="footer"
         v-else>
         <el-button type="primary" @click="next">
            <!-- 下一步 -->
            {{$t('Uploading.button3')}}
         </el-button>
      </div>
   </el-dialog>
</div>
</template>

<script>
import SparkMD5 from "spark-md5";
export default {
   name: "Uploading",
   data() {

      return {
         Advanced: false,
         value: '',
         loadProgress: 0, // 动态显示进度条
         progressFlag: false, // 关闭进度条
         loading: false,
         body_show: true,
         form: {
            name: "",
            target_column: "",
            read_mode_header: "",
            task_type: "",
            desc: "",
            file: null,
            MD5: "",
         },
         options: [{
            value: '0',
            label: 'Auto'
         }],
         upData: {},
         steps: false,
         progressPercent: 0,
         show_progress: false,
         task_type_message: "",
         rules: {
            name: [{
                  required: true,
                  message: this.$t('MdmHome.nameMessage1'),
                  trigger: "blur"
               },
               {
                  min: 5,
                  max: 30,
                  message: this.$t('MdmHome.nameMessage2'),
                  trigger: "blur"
               },
               {
                  pattern: /^[\u4E00-\u9FA5A-Za-z0-9][\S]+$/,
                  message: this.$t('MdmHome.nameMessage3'),
               },
            ],
            target_column: [{
               required: true,
               message: this.$t('MdmHome.targetMessage'),
               trigger: "blur"
            }, ],
            read_mode_header: [{
               required: true,
               message: this.$t('MdmHome.headerMessage'),
               trigger: "blur"
            }, ],
            task_type: [{
               required: true,
               message: this.$t('MdmHome.typeMessage'),
               trigger: "blur"
            }, ],
            desc: [{
               required: true,
               message: this.$t('MdmHome.descMessage'),
               trigger: "blur"
            }],
         },
      };
   },
   methods: {
      checkcodeFn() {
         //检查数据集类型
         this.axios.post(`${window.apis.SYS_DATASET_EVALUATE}?dataset_id=${this.form.dataset_id}&target_column=${this.form.target_column}`).then(res => {
            if (res.data.task_type) {
               let message = ''
               switch (res.data.task_type) {
                  case 0:
                     message = '根据目标列建议类型为二分类'
                     break;
                  case 1:
                     message = '根据目标列建议类型为多分类'
                     break;
                  case 2:
                     message = '根据目标列建议类型为回归'
                     break;
                  case 3:
                     message = '根据目标列建议类型为时间序列'
                     break;
                  default:
                     message = ''
                     break;
               }
               this.task_type_message = message
            }
         })
      },
      querySearch(queryString, cb) {
         var restaurants = this.restaurants;
         var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
         // 调用 callback 返回建议列表的数据
         cb(results);
      },
      next() {
         this.$refs.upload_attach.submit();
      },
      beforeAvatarUpload(file) {
         const isLt = file.size / 1024 / 1024 < 1023;
         if (!isLt) {
            this.$message.error(this.$t('Uploading.messageError1'));
         }
         return isLt;
      },
      changFile(file) {
         // this.$refs.upload_attach_item.validate();
         //console.log(file)
         var fileReader = new FileReader();
         //此处打印file可看到file下的raw为文件属性
         var dataFile = file.raw;
         // eslint-disable-next-line no-unused-vars
         let blobSlice =
            File.prototype.slice ||
            File.prototype.mozSlice ||
            File.prototype.webkitSlice;
         var spark = new SparkMD5.ArrayBuffer();
         //获取文件二进制数据
         fileReader.readAsArrayBuffer(dataFile);

         //异步执行函数
         fileReader.onload = (e) => {
            spark.append(e.target.result);
            var md5 = spark.end();
            this.upData.MD5 = md5;
            //此处this指向发生改变，需提前声明const _this = this
            //上传图片，如手动上传可不在此处执行
         };
      },
      handleExceed() {
         this.$message.warning(this.$t('Uploading.messageError2'));
      },
      onReset(formName) {
         this.$refs[formName].resetFields();
      },
      clearForm(formName) {
         this.$nextTick(() => {
            this.$refs[formName].resetFields();
            this.$refs.upload_attach.clearFiles()
         })
         this.task_type_message = ''
         this.steps = false
      },
      onSubmit(formName) {
         // 校验合法性
         this.$refs[formName].validate((valid) => {
            if (valid) {
               let url = window.apis.SYS_DATASET;
               this.axios
                  .put(url + this.form.dataset_id + "/", {
                     name: this.form.name,
                     target_column: this.form.target_column,
                     task_type: this.form.task_type,
                     read_mode_header: this.form.read_mode_header,
                     desc: this.form.desc,
                  })
                  .then((res) => {
                     if (res.status === 201 || res.status === 200) {
                        console.log(res)
                        // 关闭对话框
                        this.$store.commit("UploadingTrue", false);
                     }
                  })
            } else {
               console.log("error submit!!");
               return false;
            }
         });

         // this.apply();
         // this.$refs.upload_attach.submit() // 触发调用uploadSectionFile，拿到param参数里面的File
      },
      uploadSectionFile(param) {
         let url = window.apis.SYS_DATASET;

         let data = new FormData();

         // todo 非常重要，一定要加file.raw，从浏览器中查看需要使用binary类型，后台才能正确接收
         // this.form.files = this.fileList[0].raw
         // console.log(this.fileList[0].raw)

         this.upData.file = param.file; // 将form中的files字段赋值File对象

         // 将form表单中的值都赋值给FormData传递给后台
         for (let key in this.upData) {
            data.append(key, this.upData[key]);
         }
         // this.show_progress = true
         const config = {
            headers: {
               "Content-Type": "multipart/form-data",
            },
         };
         const loading = this.$loading({
            lock: true,
            text: this.$t('Uploading.message'),
            background: "rgba(255, 255, 255, 0.4)",
         });
         this.progressFlag = true; // 显示进度条
         this.axios({
               url: url,
               method: "post",
               data: data,
               config: config,
               onUploadProgress: (progressEvent) => {
                  this.loadProgress = progressEvent.loaded / progressEvent.total * 100 | 0; //百分比
               },
            })
            .then((res) => {
               // this.show_progress = false
               this.progressPercent = 0;
               setTimeout(() => {
                  this.progressFlag = false;
                  this.loadProgress = 0
               }, 1000)
               if (res.status === 201) {
                  loading.close();
                  this.form.name = res.data.filename;
                  this.form.dataset_id = res.data.dataset_id;
                  console.log(res.data.header)
                  let header = res.data.header
                  this.options = header.map((index, item) => {
                     return {
                        label: index,
                        value: item
                     }
                  })
                  this.steps = true
                  // 关闭对话框
                  // this.$store.commit("UploadingTrue", false);
               }
            })
            .catch(function (error) {
               // 请求失败处理
            });
      },
   },
   computed: {
      Uploadingdata: {
         get() {
            return this.$store.state.Uploadingdata;
         },
         set(val) {
            this.$store.commit("UploadingTrue", val);
         },
      },
   },
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload-dragger {
   width: 527px;
}

.dialogFooter {
   text-align: right;
}

.UploadingTitle {
   font-size: 26px;
   // font-family: "PingFang SC";
   font-weight: bold;
   color: #333333;
   margin: 14px 0 0 32px;
}

.nameTitle {
   font-size: 22px;
   // font-family: "PingFang SC";
   font-weight: 500;
   color: #333333;
}

.el-input {
   width: 371px;
}

.separator {
   margin-top: 15px;
   margin-bottom: -30px;
   text-align: left;
   overflow: hidden;

   .box {
      .title {
         display: inline-block;
      }
   }
}

aside {
   width: 600px;
   margin: 56px 0 0 80px;

   .el-button:last-of-type {
      margin-left: 76px;
   }

   .el-button:hover {
      color: #4568ee;
      border-color: #4568ee;
   }

   .el-button:focus {
      color: #4568ee;
      border-color: #4568ee;
   }
}
</style>
