<template>
<div class="header">
   <header style="height: 56px;">
      <p
         class="l"
         @click="routePush('Home')" />
                 <div
          class="l logo"
          :class="this.$GlobalVal.ORG_LOGO"
          @click="routePush('Home')"
        />
      <!-- <span class="headerTitle l" @click="routePush('Home')">{{$t('global.ORG_NAME')}}</span> -->
      <div
         class="LoginBox r"
         @mouseover="hoverTrue()"
         @mouseleave="hoverFalse()">
         <div class="LoginBoxTop">
            <el-avatar
               class="headImg l"
               shape="circle"
               :size="19"
               :src="`${headImgUrl}`"
               @error="errorHandler">
               <img src="../assets/img/Avatar.png">
            </el-avatar>
            <p class="l">
               {{ user }}
            </p>
            <i class="iconfont iconiconshangla1" :style="show1?'transform: rotate(180deg)scale(0.64);':''" />
         </div>
         <transition name="el-zoom-in-top">
            <div class="LoginBoxHover" v-show="show1">
               <div class="HoverBoxTop" :id="this.$Privilage.grade_cn!=$t('VIP.type1')?'VipBg':''">
                  <el-avatar
                     class="headImg"
                     shape="circle"
                     :src="`${headImgUrl}`"
                     @error="errorHandler">
                     
                     <img src="../assets/img/Avatar.png">
                  </el-avatar>
                  <!-- <div class="user">
              {{ user }}
            </div>
            <p class="iconBox">{{this.$Privilage.grade_cn}}</p>
            <p class="time">
              2099-99-99 到期
            </p> -->
                  <div class="rightBox r" :style="this.$Privilage.grade_cn!=$t('VIP.type1')?'color: #836029;':'color: #3B4454;'">
                     <div class="user">
                        {{ user }}
                     </div>
                     <!-- <div class="vipBox">
                        <span class="grade">{{this.$Privilage.grade_cn}}</span>
                        <div class="buttonBox" @click="routePush('VipHome')"><span>{{this.$Privilage.grade_cn!=$t('VIP.type1')?$t('VIP.Renew'):$t('VIP.up')}}</span></div>
                     </div> -->
                     <!-- <p class="time" v-show="this.$Privilage.grade_cn!=$t('VIP.type1')">{{this.$Privilage.expire_time}} {{$t('VIP.timeTo')}}</p> -->
                  </div>
               </div>
               <div class="HoverB" @click="routePush('PersonalCenter')">
                  <i class="iconfont icontanchuang_my1" />
                  {{$t('components.headerHover1')}}
               </div>
               <!-- <div class="HoverB" @click="routePush('VipHome')">
                  <i class="iconfont icontanchuang_dingyue" />
                  {{ $t('AutomlHeader.40bu2w') }}
               </div> -->
               <div class="HoverB" @click="routePush('SystemSettings')">
                  <i class="iconfont icontanchuang_shezhi1" />
                  {{$t('components.headerHover2')}}
               </div>
               <!-- <div class="HoverB" @click="routePush('Document')">
                  <i class="el-icon-document" style="font-size: 17px;" />
                  {{$t('doc')}}
               </div> -->
               <div class="HoverB" @click="loadLogined">
                  <i class="iconfont icontanchuang_tuichu1" />
                  {{$t('components.headerHover3')}}
               </div>
            </div>
         </transition>
      </div>
      <el-dropdown class="r">
  <!-- <span class="dropdownTitle">
    {{ $t('AutomlHeader.p42aqs') }}<i class="el-icon-arrow-down el-icon--right"></i>
  </span> -->
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item @click.native="routePush('DataSet')">{{ $t('AutomlHeader.t6rmak') }}</el-dropdown-item>
    <el-dropdown-item @click.native="routePush('AutoML')">{{ $t('AutomlHeader.a5two7') }}</el-dropdown-item>
    <el-dropdown-item @click.native="openWindow('https://www.sinoaus.shop/osd/#/')">{{ $t('AutomlHeader.9ktlfp') }}</el-dropdown-item>
  </el-dropdown-menu>
</el-dropdown>
      <!-- <el-button
         type="warning"
         style="margin:13px 5px 0 0"
         @click="routePush('VipHome')"
         size="mini"
         class="r"
         round
      >{{$t('VIP.home')}}</el-button> -->
      <!-- <div class="enCnBox r" @click="routePush('VipHome')">
         <span>工单</span>
         <transition name="el-zoom-in-top">
            <div class="bottomBox" v-show="show2">
               <div class="bottomButton"><i class="iconfont icontanchuang_wodegongdan" /> <span>我的工单</span></div>
               <div class="bottomButton"><i class="iconfont icontanchuang_chuangjiangongdan" /> <span>创建工单</span></div>
            </div>
         </transition>
      </div> -->
      <!-- <div class="enCnBox r" @click="routePush('VipHome')">
         <span>{{$t('ding-jia')}}</span>
      </div> -->
      <div class="enCnBox l">
         <!-- <i class="iconfont enCn" :class="$i18n.locale=='zh'?'iconnavbar_zhongwen':'iconnavbar_english'" @click="i18n()"></i> -->
         <span :style="$i18n.locale=='zh'?'color: rgba(255, 255, 255, 1);':''" @click="i18nZH()">中文</span>
         <span> / </span>
         <span :style="$i18n.locale=='en'?'color: rgba(255, 255, 255, 1);':''" @click="i18nEN()">En</span>
      </div>
   </header>
</div>
</template>

<script>
import {
   mapState
} from 'vuex'

export default {
   inject: ['reload'],
   data() {
      return {
         headImgUrl: '',
         show1: false,
         show2: true,
         nav: false,
         active: '1',
         clientWidth: document.body.clientWidth,
         timer: '',
         hoverFalseData: '',
         list: [],
         listActive: '-1',
      };
   },
   mounted() {
      this.getHeadImg();

   },
   methods: {
      openWindow(url){
         window.open(url,"_blank")
      },
      i18n() {
         switch (this.$i18n.locale) {
            case 'zh':
               this.$i18n.locale = 'en';
               break;
            case 'en':
               this.$i18n.locale = 'zh';
               break;
            default:
               return;
               break;
         }
         document.title = this.$route.meta.title
         this.$Privilage.ROLES_FN() //对应的用户等级
         localStorage.setItem("i18n", this.$i18n.locale);
         this.reload()
         this.LANGUAGE_FN()
      },
      i18nEN() {
         if (this.$i18n.locale != 'en') {
            this.$i18n.locale = 'en';
            document.title = this.$route.meta.title
            this.$Privilage.ROLES_FN() //对应的用户等级
            window.localStorage.setItem("i18n", this.$i18n.locale);
            this.reload()
            this.LANGUAGE_FN()
         }
      },
      i18nZH() {
         if (this.$i18n.locale != 'zh') {
            this.$i18n.locale = 'zh';
            document.title = this.$route.meta.title
            this.$Privilage.ROLES_FN() //对应的用户等级
            window.localStorage.setItem("i18n", this.$i18n.locale);
            this.reload()
            this.LANGUAGE_FN()
         }
      },
      LANGUAGE_FN() {
         let url = window.apis.USERS_LANGUAGE
         this.axios.post(url, {
            language: this.$i18n.locale == 'zh' ? 0 : 1
         }).then((res) => {
            console.log(res.data)
         })
      },
      hoverTrue() {
         clearTimeout(this.hoverFalseData)
         this.show1 = true
      },
      hoverFalse() {
         this.hoverFalseData = setTimeout(() => {
            this.show1 = false
         }, 500)
      },
      vipFn() {
         this.$store.commit('vipDialog', true);
      },
      errorHandler() {
         return true
      },
      getHeadImg() {
         let url = window.apis.USER_HEAD_IMG;
         this.axios.get(url)
            .then((res) => {
               if (res.status === 200) {
                  this.headImgUrl = res.data.email_avatar;
               }
            })
            .catch((error) => {
               console.log(error);
            });
      },
      routePush(dest) {
         if (this.$router.history.current.name != dest) {
            this.$router
               .push({
                  name: dest
               })
            this.listActive = '-1'
         }
      },
      routePushData(dest, data) {
         this.$router
            .push({
               name: dest,
               query: {
                  tag_id: data,
               },
            })
      },
      loadLogined() {
         this.$router.push({
            name: 'Home'
         });
         localStorage.clear();
         this.$store.commit('loadLogined', false);
      },
   },
   computed: {
      ...mapState(['user', 'HomeLoading', 'roles'])
   },
}
</script>

<style lang="scss" scoped>
.header {
   width: 100%;
   background: #4568ee;
}

header {
   width: 100%;
   min-width: 750px;
   background: #4568ee;
   padding: 0 0 0 40px;
   z-index: 999;

   .logo {
      font-size: 25px;
      color: #FFFFFF;
      line-height: 56px;
      cursor: pointer;
   }

   .headerTitle {
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      line-height: 56px;
      margin-left: 16px;
      cursor: pointer;
   }
   .dropdownTitle{
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      line-height: 56px;
      margin-left: 16px;
      cursor: pointer;
   }
   .LoginBox {
      position: relative;
      width: 190px;
      height: 56px;
      z-index: 5;

      .LoginBoxTop {
         width: 190px;
         height: 56px;
         color: #333333;
         transition: all 300ms;
         cursor: pointer;

         i {
            transition: all 300ms;
         }

         .headImg {
            width: 19px;
            height: 19px;
            border-radius: 50%;
            margin: 18px 0 0 32px;
            ;
         }

         p {
            width: 70px;
            text-align: left;
            margin: 18px 0 0 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            color: #FFFFFF;
            white-space: nowrap;
         }

         i {
            position: absolute;
            top: 22px;
            left: 136px;
            font-size: 12px;
            transform: scale(0.64);
            color: #ffffff;
         }
      }

      .LoginBoxHover {
         position: absolute;
         width: 230px;
         left: -58px;
         height: auto;
         margin-top: 13px;
         background: #ffffff;
         border-radius: 5px;
         transition: all 400ms;
         // box-shadow: 0px 1px 11px rgba(222, 226, 230, 0.6);
         box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);

         #VipBg {
            background-image: url('../assets/vip_img/tanchuang_img1.png');
            background-size: 230px;
         }

         .HoverBoxTop {
            height: auto;
            overflow: hidden;
            color: #FFF;
            background-image: url('../assets/vip_img/yonghu_pic.png');
            position: relative;
            text-align: left;
            border-radius: 5px 5px 0 0;

            .el-avatar {
               width: 45px;
               height: 45px;
               position: absolute;
               top: 50%;
               left: 20px;
               transform: translate(0, -50%);
               // border: 2px solid rgba(57, 109, 214, 0.8);
               transition: all 300ms;
            }

            .el-avatar:hover {
               transform: translate(0, -50%) rotate(360deg);
               // border: 2px solid rgba(57, 109, 214, 0.4);
            }

            .rightBox {
               margin: 19px 20px 19px 0;

               .user {
                  width: 118px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20px;
               }

               .vipBox {
                  height: 24px;

                  .grade {
                     font-size: 12px;
                     font-weight: 500;
                     // color: #836029;
                     line-height: 24px;
                  }

                  .buttonBox {
                     width: 64px;
                     height: 24px;
                     display: inline-block;
                     font-size: 10px;
                     margin-left: 6px;
                     background: linear-gradient(136deg, #FFEDB6 0%, #FFDA7F 100%);
                     border-radius: 17px;
                     cursor: pointer;

                     span {
                        width: 100%;
                        display: inline-block;
                        text-align: center;
                        font-size: 10px;
                        font-weight: 500;
                        color: #49330F;
                        line-height: 24px;
                     }
                  }
               }

               .time {
                  margin-top: 7px;
                  font-size: 10px;
                  font-weight: 500;
                  // color: #836029;
                  line-height: 14px;
               }
            }
         }

         .HoverB {
            height: 48px;
            padding-left: 20px;
            text-align: left;
            font-size: 14px;
            font-weight: 500;
            color: #4568ee;
            line-height: 48px;
            cursor: pointer;

            i {
               margin-right: 5px;
            }
         }

         .HoverB:last-of-type {
            border-radius: 0 0 5px 5px;
         }

         .HoverB:hover {
            background: #F6F7F8;
         }
      }
   }

   .enCnBox {
      margin: 0 0 0 16px;
      line-height: 56px;

      .bottomBox {
         width: 130px;
         height: 95px;
         background: #FFFFFF;
         box-shadow: 0px 2px 10px 2px #DEDFE6;
         border-radius: 4px;
         margin-top: 5px;

         .bottomButton {
            width: 100%;
            height: 48px;
            font-size: 14px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 48px;

            span {
               font-size: 14px;
               font-weight: 500;
               color: #FFFFFF;
            }
         }
      }

      span {
         font-size: 16px;
         font-weight: 500;
         color: rgba(255, 255, 255, 0.8);
         line-height: 22px;
         font-family: "PingFang SC";
         cursor: pointer;
      }

      .enCn {
         font-size: 20px;
         color: #FFFFFF;
         cursor: pointer;
         // margin: 12px 30px 0 0;
      }
   }
}
</style>
