<template>
  <div class="Setting">
    <div class="AccountTitle l">
      <ul>
        <li>{{$t('Setting.Li1')}}</li>
        <li>{{$t('Setting.Li2')}}</li>
      </ul>
    </div>
    <div class="AccountInput l">
      <el-form
        ref="form"
        :model="user"
        status-icon
      >
        <el-form-item prop="sendEmail">
          <!-- 邮件通知开关 -->
          <el-checkbox-group v-model="checkList">
            <el-checkbox
              border
              :label="$t('Setting.checkbox1')"
            />
            <el-checkbox
              border
              :label="$t('Setting.checkbox2')"
            />
          </el-checkbox-group>
          <div>
            <el-select
              v-model="value"
              class="l"
              :placeholder="$t('Setting.select1')"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <el-button
              style="margin-left:20px"
              @click="routePush('TemplateSet')"
              class="l"
            >
              {{$t('Setting.button1')}}
            </el-button>
          </div>
        </el-form-item>
      </el-form>
      <div>
        <el-button
          type="primary"
          @click="saveUserInfoChange()"
        >
          {{$t('Setting.button2')}}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      checkList: [],
      user: {},
      flag: "",
      sendEmail: true,
      options: [
        {
          value: "",
          label: "",
        },
      ],
      value: "",
      activeName:'1',
    };
    
  },
  mounted() {
    this.getUserInfo();
    this.getTemplate();
  },
  methods: {
    routePush(dest) {
        this.$router
        .push({
          name: dest
        })
    },
    getTemplate() {
      let url = window.apis.SYS_TEMPLATE;
      this.axios.get(url).then((res) => {
        console.log(res);
        // let id = JSON.parse(JSON.stringify(res.data))
        console.log(JSON.parse(JSON.stringify(res.data)));
        this.options = res.data.map((item) => {
          return { value: item.id, label: item.name };
        });
        // console.log(res.data.length)
      });
    },
    getUserInfo() {
      let url = window.apis.SYS_USER_INFO;
      this.axios
        .get(url)
        .then((res) => {
          console.log(res.data);
          // console.log(res.data.flags);
          this.checkList = [];
          if (res.data.flags) {
            for (let item of res.data.flags) {
              if (item === "1") {
                this.checkList.push(this.$t('Setting.checkbox1'));
              } else {
                this.checkList.push(this.$t('Setting.checkbox2'));
              }
            }
          }
          console.log(this.checkList);
          if (res.status === 200) {
            this.user = res.data;
            this.value = res.data.config;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveUserInfoChange() {
      this.flags = "";
      if (this.checkList.length > 0 && this.checkList.length < 2) {
        this.flags = this.checkList.includes(this.$t('Setting.checkbox1')) ? "1" : "2";
      } else {
        if (this.checkList.length != 0) {
          this.flags = "1,2";
        }
      }
      console.log(this.flags, this.checkList);
      // 校验合法性
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let url = window.apis.SYS_USER;
          this.axios
            .put(url + this.user.id + "/", {
              username: this.user.username,
              flags: this.flags,
              config: parseInt(this.value),
            })
            .then((res) => {
              console.log(res);
              if (res.status === 200||res.status === 201) {
                this.$message({
                  message: this.$t('Setting.message1'),
                  type: "success",
                });
                this.emailAA = res.data.flags;
              } 
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          // 定位到错误的位置
          setTimeout(() => {
            const isError = document.getElementsByClassName("is-error");
            isError[0].querySelector("input").focus();
          }, 100);
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.titleStyle {
    height: 40px;
    display: block;
    font-size: 15px;
    line-height: 40px;
    font-weight: 500;
    color: #333333;
    list-style: none;
    margin-right: 20px;
}
.el-tab-pane {
  .el-checkbox {
  width: 190px;
}
p {
     font-size: 14px;
    line-height: 15px;
    font-weight: 500;
    margin-top: 10px;
}
}

.el-input.is-disabled .el-input__inner {
  background: #d5dcec;
}
.Setting {
  text-align: left;
  overflow: hidden;
  padding: 20px 0 30px 91px;
  .AccountTitle {
   //  width: 120px;
   //  margin-right: 20px;
    li {
       width: auto;
      height: 40px;
      display: block;
      font-size: 15px;
      line-height: 40px;
      font-weight: 500;
      color: #333333;
      list-style: none;
      text-align: right;
      margin-right: 20px;
      margin-top: 19px;
      white-space: nowrap;
    }
  }
  .AccountInput {
    width: 500px;
    height: 100%;
    .el-input {
      margin-bottom: 17px;
    }
    p {
      margin-top: 17px;
      font-size: 15px;
      font-weight: 300;
      span {
        color: #0976b9;
        margin: 0 5px;
      }
    }
    .el-image {
      width: 80px;
      height: 80px;
      margin-top: 17px;
      margin-right: 100%;
    }
    .el-button {
      margin-top: 17px;
    }
  }
}
.el-checkbox-group {
  margin-top: 17px;
}
.el-select {
  margin-top: 17px;
}
.dialog-class{
  width:720px;
  height:400px;
  overflow:auto;
}
</style>