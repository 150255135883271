<template>
  <div class="Phone">
    <div class="AccountTitle l">
      <ul>
        <li>{{$t('PersonalCenter.Phone.Li1')}}</li>
        <li>{{$t('PersonalCenter.Phone.Li2')}}</li>
      </ul>
    </div>
    <div class="AccountInput l">
      <el-form
        ref="form"
        :rules="rulesPhone"
        :model="user"
        status-icon
      >
        <el-form-item prop="telephone">
          <el-input v-model="user.telephone">
            <template slot="prepend">
              <i class="el-icon-mobile-phone" />
              +86
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="verCodeA">
          <el-input
            v-model="user.verCodeA"
            :placeholder="$t('PersonalCenter.Phone.input1')"
          >
            <el-button
              class="prependCode r"
              slot="append"
              @click="getPhoneVerCode('form')"
              :disabled="codeTimeDisabled"
            >
              <p class="prependCodeSlot">
                {{ $t('PersonalCenter.Phone.CodeTime') }}
              </p>
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="bindPhoneNumber('form')"
            class="l elbutton"
          >
            {{$t('PersonalCenter.Phone.button')}}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      rulesPhone: {
        telephone: [
          { required: true, message: this.$t('PersonalCenter.Phone.Message1'), trigger: "blur" },
          {
            pattern: /^([1][3,4,5,6,7,8,9])\d{9}$/,
            message: this.$t('PersonalCenter.Phone.Message2'),
          },
        ],
        verCodeA: [
          { required: true, message: this.$t('PersonalCenter.Phone.Message3'), trigger: "blur" },
          { min: 6, max: 6, message: this.$t('PersonalCenter.Phone.Message3'), trigger: "blur" },
        ],
      },
      user:{
        telephone:'',
        verCodeA:''
      },
      codeTime:this.$t('PersonalCenter.Phone.CodeTime'),
      codeTimeDisabled:false,
    }
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      let url = window.apis.SYS_USER_INFO;
      this.axios.get(url)
      .then((res) => {
        if (res.status === 200) {
          this.user = res.data;
          this.user.verCode = "";
          console.log(this.user)
        }
      })
      .catch((error) => {
        console.log(error);
      });
    },
    getPhoneVerCode(formName) {
      this.$refs[formName].validateField('telephone', (error) => {
      if(!error){
      let timer = 60;
      let timeres = setInterval(() => {
        if(timer == 0){
          clearInterval(timeres);
          this.codeTime = this.$t('PersonalCenter.Phone.CodeTime');
          this.codeTimeDisabled = false;
        }else{
          this.codeTime = timer + this.$t('PersonalCenter.Mail.codeTime2');
          this.codeTimeDisabled = true;
          timer--;
        }
      },1000)}
      })},
    bindPhoneNumber(formName) {
      // 校验合法性
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url = window.apis.SYS_USER;
          this.axios.put(url + this.user.id + "/", {
            telephone: this.user.telephone
          })
          .then((res) => {
            if (res.status === 200) {
              this.$message({
                message: this.$t('PersonalCenter.Phone.Message4'),
                type: "success",
              });
              this.user = res.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
        } else {
          // 定位到错误的位置
          setTimeout(()=>{
            const isError= document.getElementsByClassName('is-error')
            isError[0].querySelector('input').focus()
          },100)
          return false;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item {
    margin: 0;
}
.el-input.is-disabled .el-input__inner {
  background:#D5DCEC;
}
  .Phone {
    text-align: left;
    overflow: hidden;
    padding: 20px 0 30px 91px;
    .AccountTitle {
      //   width: 120px;
      li {
        height: 40px;
        display: block;
        font-size: 15px;
        line-height: 40px;
        font-weight: 500;
        color: #333333;
        list-style: none;
        text-align: right;
        margin-right: 20px;
        margin-top: 17px;
      }
    }
    .AccountInput {
      width: 280px;
      height: 100%;
      .el-input {
        margin-top: 17px;
      }
      .elbutton {
        margin: 0;
        margin-top: 17px;
      }
    }
 
  }
</style>