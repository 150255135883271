import { render, staticRenderFns } from "./PersonalCenter.vue?vue&type=template&id=1ad6e775&scoped=true&"
import script from "./PersonalCenter.vue?vue&type=script&lang=js&"
export * from "./PersonalCenter.vue?vue&type=script&lang=js&"
import style0 from "./PersonalCenter.vue?vue&type=style&index=0&id=1ad6e775&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ad6e775",
  null
  
)

export default component.exports