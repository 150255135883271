<template>
<main>
   <p class="login">
      <span>
         {{$t('Login.span')}}
      </span>
   </p>
   <el-form
      ref="loginForm"
      :rules="rulesLogin"
      :model="Login"
      status-icon>
      <el-form-item prop="lUser">
         <el-input
            :validate-event="false"
            v-model="Login.lUser"
            :placeholder="$t('Login.input1')" />
      </el-form-item>
      <el-form-item prop="lPass">
         <el-input
            :validate-event="false"
            v-model="Login.lPass"
            :placeholder="$t('Login.input2')"
            type="password"
            autocomplete="off"
            @keyup.enter.native="handleLogin('loginForm')"
            show-password />
      </el-form-item>
      <el-form-item prop="iCode">
         <el-input
            :validate-event="false"
            v-model="identifyCodeInput"
            prop="iCode"
            :placeholder="$t('Login.input3')"
            class="codeInput l"
            @keyup.enter.native="handleLogin('loginForm')" />
         <div class="code r" @click="refreshCode">
            <IdentifyCode
               :identify-code="identifyCode"
               :content-width="100"
               :content-height="38" />
         </div>
      </el-form-item>
      <el-form-item>
         <el-button
            @click="handleLogin('loginForm')"
            class="LoginButton"
            type="primary"
            style="font-size: 16px;">
            {{$t('Login.button1')}}
         </el-button>
      </el-form-item>
   </el-form>
   <div class="dialogFooter">
      <p class="r" @click="routePush('Register')">
         {{$t('Login.button2')}}
      </p>
      <p class="l" @click="routePush('ForgetPassword')">
         {{$t('Login.button3')}}
      </p>
   </div>
</main>
</template>

<script>
import IdentifyCode from "vue-identifycode"; // 引入包
import {
   mapState
} from "vuex";

export default {
   data() {
      var identifyCodeInputFn = (rule, value, callback) => {
         if (
            this.identifyCodeInput.toUpperCase() ==
            this.identifyCode.toUpperCase() &&
            this.identifyCodeInput.toUpperCase() != ""
         ) {
            callback();
            // 万能验证码C518
         }
         // if (this.identifyCodeInput.toUpperCase() == "C518") {
         //    callback();
         // } else {
         if (
            this.identifyCodeInput.toUpperCase() ==
            this.identifyCode.toUpperCase()
         ) {
            callback();
         } else {
            callback(new Error(this.$t('Login.message4')));
         }
         // }
      };
      return {
         Login: {
            lUser: "",
            lPass: "",
         },
         cssData: {

         },
         rulesLogin: {
            lUser: [{
               required: true,
               message: this.$t('Login.message1')
            }],
            lPass: [{
                  required: true,
                  message: this.$t('Login.message2')
               },
               {
                  pattern: /^[a-zA-Z]\w{5,17}$/,
                  message: this.$t('Login.message3'),
               },
            ],
            iCode: [{
               validator: identifyCodeInputFn
            }],
         },
         identifyCode: "",
         identifyCodeInput: "",
      };
   },
   computed: {
      ...mapState(["BoxLogin", "BoxRegister", "BoxSendEmailCode", "bgImg"]),
   },
   methods: {
      getCss() {
         this.axios(window.apis.SYS_FRONT).then(
            (res) => {
               this.cssData = res.data
               this.cssData.background = `data:image/png;base64,${res.data.background}`
               this.$store.commit("bgImg", this.cssData.background);
            }
         )
      },
      routePush(name) {
         this.$router.push({
            name: name,
         });
      },
      handleLogin(formName) {
         // 校验合法性
         this.$refs[formName].validate((valid) => {
            if (valid) {
               let url = window.apis.SYS_USER_DOLOGIN;
               this.axios
                  .post(url, {
                     username: this.Login.lUser,
                     password: this.Login.lPass,
                     language: this.$i18n.locale == 'zh' ? 0 : 1,
                  })
                  .then((res) => {
                     console.log(res);
                     if (res.status === 200 || res.status === 201) {
                        //全局存储token
                        window.localStorage["token"] = "Token " + res.data.token;
                        this.$store.commit("loadUser", res.data.username);
                        this.$store.commit("loadLogined", true);
                        this.$store.commit("BoxLogin", false);
                        this.$Privilage.GIT_IOFN()
                        this.$Privilage.GIT_PERMS()
                        let DataSetId = localStorage.getItem('DataSetId')
                        if (DataSetId) {
                           this.$router.push({
                              name: 'Analysis',
                              query: {
                                 DataSetId: DataSetId
                              }
                           })
                        } else {
                           // this.$router.push({
                           //    name: 'LoginNav',
                           //    query: {
                           //       dialog: res.data.is_firstlogin,
                           //       user_id: res.data.user_id,
                           //    }
                           // })
                             this.$router.push({
                              name: 'AutoML',
                           })
                        }
                     }
                  })
                  .catch((error) => {
                     this.$message({
                        message: this.$t('Login.message5'),
                        type: "warning",
                     });
                     console.log(error);
                  });
            } else {
               // 定位到错误的位置
               setTimeout(() => {
                  const isError = document.getElementsByClassName("is-error");
                  isError[0].querySelector("input").focus();
               }, 100);
               return false;
            }
         });
      },
      createCode() {
         let code = "";
         const codeLength = 4; // 验证码的长度
         // eslint-disable-next-line no-array-constructor
         const random = new Array(
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "J",
            "K",
            "L",
            "M",
            "N",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z"
         ); // 随机数
         for (let i = 0; i < codeLength; i++) {
            // 循环操作
            const index = Math.floor(Math.random() * 32); // 取得随机数的索引（0~35）
            code += random[index]; // 根据索引取得随机数加到code上
         }
         return code;
      },
      refreshCode() {
         this.identifyCode = this.createCode();
      },
      token() {
         if (localStorage.token) {
            //判断token是否存在
            this.routePush('AutoML')
         }
      },
   },
   mounted() {
      // this.getCss();
      this.token();
      this.identifyCode = this.createCode();
   },
   components: {
      IdentifyCode
   },
};
</script>

<style lang="scss" scoped>
main {
   width: 400px;
   height: 488px;
   background: rgba(255, 255, 255, 1);
   //border: 2px solid rgba(255,255,255,0.5);
   border-radius: 10px;
   overflow: hidden;

   .dialogFooter {
      margin: 0 auto;
      width: 300px;
      margin-top: -14px;
      overflow: hidden;
      font-size: 12px;
      font-weight: 400;
      color: #396DD6;
      line-height: 17px;

      p {
         cursor: pointer;
      }
   }

   .login {
      // height: 30px;
      width: 100%;
      color: #396DD6;
      margin: 50px auto 43px auto;
      overflow: hidden;

      i {
         overflow: hidden;
         display: inline-block;
      }

      span {
         font-size: 22px;
         font-weight: 500;
         line-height: 35px;
         margin-left: 9px;
         overflow: hidden;
         display: inline-block;
      }
   }

   .el-input {
      .prepend {
         width: 50px;
         text-align: center;
      }
   }

   .LoginButton {
      margin-top: 18px;
      width: 300px;
   }

   .codeInput {
      width: 180px;
      overflow: hidden;
   }

   .code {
      width: 100px;
      height: 40px;
      overflow: hidden;
   }
}

.el-form {
   text-align: left;
   margin: 0 auto;
   width: 300px;
}
</style>
