<template>
  <div class="Mdm">
    <breadcrumb />
    <router-view />
  </div>
</template>

<script>
import Breadcrumb from "../../../components/Breadcrumb"

export default {
  components :{
    Breadcrumb
  },
}
</script>

<style lang="scss" scoped>
.Mdm {
  position: relative;
}
</style>