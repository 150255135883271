<template>
<div class="Analysis">
   <article v-if="task_type==3" class="l">
      <el-form
         ref="temp_task_name"
         :model="temp_task_name"
         :rules="rules">
         <span class="articleTitle">
            <!-- 任务命名与参数设置 -->
            {{$t('Analysis.articleTitle')}}
         </span>
         <div class="detailsBox">
            <div class="divBox">
               <h3 class="l">
                  <!-- 数据集名称 -->
                  {{$t('Analysis.title')}}
               </h3>
               <div class="r">
                  <el-input
                     size="small"
                     type="text"
                     disabled="disabled"
                     class="r"
                     v-model="dataset_name" />
               </div>
            </div>
            <div class="divBox">
               <h3 class="l">
                  <!-- 任务名称 -->
                  {{$t('Analysis.name')}}
               </h3>
               <div class="r">

                  <el-form-item prop="temp_task_name">
                     <el-input
                        size="small"
                        :disabled="startVisible"
                        class="inputName"
                        v-model="temp_task_name.temp_task_name"
                        maxlength="30"
                        :placeholder="$t('Analysis.input')"
                        @blur="saveTaskName" />
                  </el-form-item>

               </div>
            </div>
            <div class="divBox">
               <h3 class="l">
                  {{$t('MdmHome.dialogItem5')}}
               </h3>
               <div class="r">
                  <el-form-item prop="time_column_name">
                     <el-select
                        :disabled="startVisible"
                        size="small"
                        v-model="temp_task_name.time_column_name"
                        filterable
                        @change="getSequence"
                        :placeholder="$t('MdmHome.dialogSelect')">
                        <el-option
                           v-for="item in headerDataA"
                           :key="item._value"
                           :label="item.label"
                           :value="item.label" />
                     </el-select>
                  </el-form-item>
               </div>
            </div>
            <div class="divBox">
               <h3 class="l">
                  {{$t('MdmHome.dialogItem6')}}
               </h3>
               <div class="r">
                  <el-form-item prop="data_name">
                     <el-select
                        :disabled="startVisible"
                        size="small"
                        v-model="temp_task_name.data_name"
                        filterable
                        :placeholder="$t('MdmHome.dialogSelect')">
                        <el-option
                           v-for="item in headerDataA"
                           :key="item._value"
                           :label="item.label"
                           :value="item.label" />
                     </el-select>
                  </el-form-item>
               </div>
            </div>
            <!-- <div class="divBox">
               <h3 class="l">
                  {{$t('MdmHome.dialogItem8')}}
               </h3>
               <div class="r">
                  <el-form-item>
                     <el-select
                        :disabled="startVisible"
                        size="small"
                        v-model="SequenceKey"
                        filterable
                        multiple
                        :placeholder="$t('MdmHome.dialogSelect')"
                     >
                        <el-option
                           v-for="(val, key, index) in Sequence"
                           :key="key.index"
                           :label="key"
                           :value="key"
                        />
                     </el-select>
                  </el-form-item>
               </div>
            </div> -->
         </div>
      </el-form>
      <div class="Slider">
         <h3>
            {{$t('MdmHome.dialogItem7')}}
         </h3>
         <el-slider
            :min="1"
            :max="20"
            :disabled="startVisible"
            v-model="featureParam.frequency" />
         <el-input-number
            :disabled="startVisible"
            controls-position="right"
            size="small"
            v-model="featureParam.frequency"
            :min="1"
            :max="20"
            :step="1" />
      </div>
      <el-divider content-position="left" v-if="Sequence">{{$t('MdmHome.dialogItem8')}}</el-divider>
      <el-select
         :disabled="startVisible"
         size="small"
         v-model="SequenceKey"
         filterable
         multiple
         style="width:450px;margin: 0 20px 15px 20px;"
         :placeholder="$t('MdmHome.dialogSelect')"
         v-if="Sequence">
         <el-option
            v-for="(val, key, index) in Sequence"
            :key="key.index"
            :label="key"
            :value="key" />
      </el-select>
      <div class="minDetailsBox" v-if="SequenceTrue">
         <div
            class="divBox"
            v-for="(val, key, index) in SequenceObj"
            :key='key.index'>
            <h3 class="l">
               {{key}}
            </h3>
            <div class="r">
               <el-select
                  :disabled="startVisible"
                  size="small"
                  v-model="temp_task_name.query[key]"
                  :placeholder="$t('MdmHome.dialogSelect')">
                  <el-option
                     v-for="item in val"
                     :key="item._value"
                     :label="item"
                     :value="item" />
               </el-select>
            </div>
         </div>
      </div>

   </article>
   <article v-else class="l">
      <span class="articleTitle">
         <!-- 任务命名与参数设置 -->
         {{$t('Analysis.articleTitle')}}
      </span>
      <div class="detailsBox">
         <div class="divBox">
            <h3 class="l">
               <!-- 数据集名称 -->
               {{$t('Analysis.title')}}
            </h3>
            <div class="r">
               <el-input
                  size="small"
                  type="text"
                  disabled="disabled"
                  class="r"
                  v-model="dataset_name" />
            </div>
         </div>
         <div class="divBox">
            <h3 class="l">
               <!-- 任务名称 -->
               {{$t('Analysis.name')}}
            </h3>
            <div class="r">
               <el-form
                  ref="temp_task_name"
                  :model="temp_task_name"
                  :rules="rules">
                  <el-form-item prop="temp_task_name">
                     <el-input
                        size="small"
                        :disabled="startVisible"
                        class="inputName"
                        v-model="temp_task_name.temp_task_name"
                        maxlength="30"
                        :placeholder="$t('Analysis.input')"
                        @blur="saveTaskName" />
                  </el-form-item>
               </el-form>
            </div>
         </div>
         <div class="divBox">
            <h3 class="l">
               <!-- 目标列 -->
               {{$t('MdmHome.dialogItem3')}}
            </h3>
            <div class="r">
               <el-select
                  :disabled="startVisible"
                  size="small"
                  v-model="temp_task_name.target_column"
                  filterable
                  :placeholder="$t('MdmHome.dialogSelect')">
                  <el-option
                     v-for="item in headerDataA"
                     :key="item._value"
                     :label="mode_header=='y'?item.label:item.value"
                     :value="item.value" />
               </el-select>
            </div>
         </div>
      </div>
      <div class="Slider">
         <h3>
            <!-- 缺失值阈值 -->
            {{$t('Analysis.Slider1')}}
         </h3>
         <el-slider
            :disabled="startVisible"
            v-model="featureParam.missing_threshold"
            :format-tooltip="formatTooltip" />
         <el-input-number
            :disabled="startVisible"
            controls-position="right"
            size="small"
            v-model="featureParam.missing_thresholdA"
            :min="0"
            :max="1"
            :step="0.01" />
      </div>
      <div class="Slider">
         <h3>
            <!-- 共线相关性阈值 -->
            {{$t('Analysis.Slider2')}}
         </h3>
         <el-slider
            :disabled="startVisible"
            v-model="featureParam.correlation_threshold"
            :format-tooltip="formatTooltip" />
         <el-input-number
            :disabled="startVisible"
            controls-position="right"
            size="small"
            v-model="featureParam.correlation_thresholdA"
            :min="0"
            :max="1"
            :step="0.01" />
      </div>
      <div class="Slider">
         <h3>
            <!-- 累积重要性阈值 -->
            {{$t('Analysis.Slider3')}}
         </h3>
         <el-slider
            :disabled="startVisible"
            v-model="featureParam.cumulative_importance"
            :format-tooltip="formatTooltip" />
         <el-input-number
            :disabled="startVisible"
            controls-position="right"
            size="small"
            v-model="featureParam.cumulative_importanceA"
            :min="0"
            :max="1"
            :step="0.01" />
      </div>
   </article>
   <aside class="r">
      <p class="asideMainTitle">
         <!-- 日志详情 -->
         {{$t('Analysis.asideMainTitle')}}
      </p>
      <div class="asideMain">
         <el-tooltip
            :content="$t('Training.tooltip')"
            placement="top-start"
            v-if="!startVisible">
            <i class="el-icon-document-copy" v-if="!startVisible" @click="Copy()" />
         </el-tooltip>
         <ul>
            <li v-for="item in logMsg" :key="item.message">
               {{ item }}
            </li>
         </ul>
      </div>
      <el-button
         plain
         class="analyse"
         :disabled="startVisible"
         @click="beforeStartAnalysis">
         <i class="el-icon-video-play" />
         <!-- 开始分析 -->
         {{$t('Analysis.play')}}
      </el-button>
      <el-button
         plain
         class="analyse"
         :disabled="stopVisible"
         @click="stopAnalysis">
         <i class="el-icon-video-pause" />
         <!-- 停止分析 -->
         {{$t('Analysis.pause')}}
      </el-button>
      <el-button
         plain
         class="analyse"
         :disabled="startVisible"
         @click="skipFn()">
         <i class="el-icon-d-arrow-right" />
         {{ $t('AutoML.Auto.Analysis.7b8tln') }}
      </el-button>
   </aside>
   <section>
      <el-tooltip
         class="item"
         effect="light"
         :content="$t('pretreatment.buttonTop')"
         placement="top-start"
         :hide-after="200">
         <el-button
            class="Step"
            type="primary"
            icon="el-icon-arrow-left"
            :disabled="lastVisible"
            circle
            @click="lastStep" />
      </el-tooltip>
      <el-tooltip
         class="item"
         effect="light"
         :content="$t('pretreatment.buttonNext')"
         placement="top-start">
         <el-button
            class="Step"
            type="primary"
            icon="el-icon-arrow-right"
            :disabled="nextVisible"
            circle
            @click="nextStep" />
      </el-tooltip>
   </section>
</div>
</template>

<script>
import {
   sendWebsocket,
   closeWebsocket
} from "@/tools/websocket.js";
import {
   mapState
} from "vuex";
import moment from "moment";

export default {
   beforeDestroy() {
      // 页面销毁时关闭ws。因为有可能ws连接接收数据尚未完成，用户就跳转了页面
      // 在需要主动关闭ws的地方都可以调用该方法
      closeWebsocket();
   },
   watch: {
      "featureParam.missing_threshold": function (val) {
         this.featureParam.missing_thresholdA = val / 100;
      },
      "featureParam.correlation_threshold": function (val) {
         this.featureParam.correlation_thresholdA = val / 100;
      },
      "featureParam.cumulative_importance": function (val) {
         this.featureParam.cumulative_importanceA = val / 100;
      },
      "featureParam.missing_thresholdA": function (val) {
         this.featureParam.missing_threshold = val * 100;
      },
      "featureParam.correlation_thresholdA": function (val) {
         this.featureParam.correlation_threshold = val * 100;
      },
      "featureParam.cumulative_importanceA": function (val) {
         this.featureParam.cumulative_importance = val * 100;
      },
      task_code: "handleTaskStatus", // 监听任务状态值
      'SequenceKey': function (val) {
         this.selectFn()
      }
   },
   computed: {
      ...mapState([
         "task_id",
         "task_name",
         "dataset_id",
         "task_type",
         "target_column",
         "desc",
         "read_mode_header",
         "dataset_name",
         "headerData"
      ]),
   },
   data() {
      return {
         rules: {
            temp_task_name: [{
                  required: true,
                  message: this.$t('Analysis.input'),
                  trigger: "blur"
               },
               {
                  min: 5,
                  max: 18,
                  message: this.$t('Analysis.message5'),
                  trigger: "blur"
               },
               {
                  pattern: /^[\u4E00-\u9FA5A-Za-z0-9][\S]+$/,
                  message: this.$t('Analysis.message6'),
               },
            ],
            time_column_name: [{
               required: true,
               message: this.$t('Analysis.message8'),
               trigger: "blur"
            }, ],
            data_name: [{
               required: true,
               message: this.$t('Analysis.message9'),
               trigger: "blur"
            }, ]
         },
         featureParam: {
            missing_threshold: 60,
            correlation_threshold: 90,
            cumulative_importance: 99,
            missing_thresholdA: 0.6,
            correlation_thresholdA: 0.9,
            cumulative_importanceA: 0.99,
         },
         temp_task_name: {
            temp_task_name: "",
            target_column: "",
            query: {},
         }, // 任务名称，可编辑
         task_code: 0, // 任务执行状态：1执行中，0执行结束
         logMsg: [],
         CopyData: [],
         // 控制按钮显示
         startVisible: false, //开始分析的状态
         stopVisible: true, //停止分析的状态
         nextVisible: true, //下一步的状态
         lastVisible: false, //上一步的状态
         time: "",
         feature_input: {},
         feature_output: {},
         task_status: null,
         msg: "",
         paramsData: '',
         headerDataA: [],
         mode_header: '',
         Sequence: '', //获取的时间序列列表
         SequenceKey: '', //获取的时间序列列表,的 key
         SequenceObj: {},
         SequenceTrue: false,
      };
   },
   mounted() {
      this.initAnalysis();
      this.logAnalysis();
      this.paramsData = this.$route.params
   },
   methods: {
      skipFn() {
         //跳过分析
         let config1 = {
            dataset_id: this.dataset_id,
            target_column: this.temp_task_name.target_column,
            task_name: this.task_name,
            read_mode_header: this.readModeHeader,
            missing_threshold: this.featureParam.missing_threshold / 100,
            correlation_threshold: this.featureParam.correlation_threshold / 100,
            cumulative_importance: this.featureParam.cumulative_importance / 100,
         }
         let config2 = {
            dataset_id: this.dataset_id,
            target_column: this.temp_task_name.target_column,
            task_name: this.task_name,
            time_column_name: this.temp_task_name.time_column_name,
            data_name: this.temp_task_name.data_name,
            frequency: this.featureParam.frequency,
            query: {}
         }
         let url2 = window.apis.SYS_MODELS;
         const nextFn = () => {
            let url = window.apis.SYS_FEATURE;
            this.axios
               .post(
                  url,
                  this.task_type == 3 ? config2 : config1, {
                     params: {
                        task_id: this.task_id,
                        action: "skip",
                     },
                  }
               ).then((res) => {
                  console.log(res.data)
                  this.nextStep()
               })
         }
         if (
            typeof this.task_id == "undefined" ||
            this.task_id == "" ||
            this.task_id == null
         ) {
            this.axios
               .post(url2, {
                  task_name: this.task_name,
                  task_type: this.task_type,
                  dataset_id: this.dataset_id,
               })
               .then((res) => {
                  if (res.status === 201) {
                     this.$store.commit("task_id", res.data.id);
                     nextFn()

                  }
               })
         } else {
            nextFn()
         }

      },
      selectFn() {
         this.SequenceObj = {}
         for (let index in this.SequenceKey) {
            Object.keys(this.Sequence).forEach(key => {
               if (key == this.SequenceKey[index]) {
                  this.SequenceObj[key] = this.Sequence[key]
               }
            })
         }
         if (this.SequenceKey.length == 0) {
            this.SequenceTrue = false
         } else {
            this.SequenceTrue = true
         }
      },
      // ws连接成功，后台返回的ws数据，组件要拿数据渲染页面等操作
      wsMessage(data) {
         // 这里写拿到数据后的业务代码
         if (data.t === 1) {
            // 日志
            this.logMsg.push(data.p);
            this.scrollToBottom();
         }
         if (data.t === 2) {
            // -2:未知状态, -1: 执行失败, 0: 执行成功, 1: 执行中, 2: 停止
            // 根据返回值，修改对应的 task_code: -2,-1,2 只有开始按钮可以点击，0只有停止按钮不可以点击，1只有停止按钮可以点击
            if (data.p == -2 || data.p == -1 || data.p == 2) {
               this.task_code = 2;
            }
            if (data.p == 0) {
               this.task_code = 3;
            }
            if (data.p == 1) {
               this.task_code = 1;
               // 插入“正在执行中...”
               this.logMsg.push(this.$t('Analysis.message'));
            }
         }
      },
      // ws连接失败，组件要执行的代码
      wsError() {
         // 比如取消页面的loading
         // 失败处理
      },
      requstWs() {
         // 防止用户多次连续点击发起请求，所以要先关闭上次的ws请求。
         closeWebsocket();
         // 跟后端协商，需要什么参数数据给后台
         const obj = {};
         // 发起ws请求

         sendWebsocket(
            window.apis.WS_SERVER + `feature_${this.task_id}`,
            obj,
            this.wsMessage,
            this.wsError
         );
      },

      // 查询任务信息，获取任务的特征分析结果，初始化页面信息
      initAnalysis() {
         if (this.task_id) {
            // 任务名称
            this.temp_task_name.temp_task_name = this.task_name;
            // 获取任务全部信息
            let url = window.apis.SYS_MODELS;
            this.axios
               .get(url + this.task_id + "/")
               .then((res) => {
                  if (res.status === 200 || res.status === 201) {
                     if (res.data.feature_input) {
                        this.feature_input = JSON.parse(res.data.feature_input);
                        this.featureParam.missing_thresholdA = this.feature_input.missing_threshold;
                        this.featureParam.correlation_thresholdA = this.feature_input.correlation_threshold;
                        this.featureParam.cumulative_importanceA = this.feature_input.cumulative_importance;

                        this.feature_output = JSON.parse(res.data.feature_output);
                        this.task_status = this.feature_output.task_status;
                        this.msg = this.feature_output.msg;
                        this.$store.commit("stage", res.data.stage);

                        if (this.task_status === 1) {
                           // 执行中
                           // 获取任务特征分析状态，如果是执行中，打印日志，上下步骤
                           let url = window.apis.SYS_FEATURE;
                           this.axios
                              .get(url, {
                                 params: {
                                    task_id: this.task_id,
                                 },
                              })
                              .then((res) => {
                                 if (res.status === 200 || res.status === 201) {
                                    if (res.data.task_code === 1) {
                                       this.task_code = res.data.task_code;
                                       // 插入“正在执行中...”
                                       this.logMsg.push(this.$t('Analysis.message'));
                                       this.requstWs();
                                    } else {
                                       // 异常终止
                                       // 日志打印终止错误信息
                                       this.logMsg.push(this.msg);
                                       this.task_code = 2;
                                    }
                                 }
                              })
                              .catch((error) => {
                                 console.log(error);
                              });
                        } else if (this.task_status === 2) {
                           // 执行失败
                           this.logMsg.push(this.msg);
                           this.task_code = 2;
                        } else if (this.task_status === 3) {
                           // 执行成功
                           this.logMsg.push(this.msg);
                           this.task_code = 3;
                        } else {
                           console.log("" + this.task_status);
                        }
                     }
                  } else {
                     this.$message({
                        message: "未找到数据",
                        type: "warning",
                     });
                  }
               })
               .catch((error) => {
                  console.log(error);
               });
         } else {
            // 无task_id，新建，生成任务名称
            this.temp_task_name.temp_task_name =
               "T_" + moment(new Date()).format("YYYYMMDDHHmmss");
            this.$store.commit("task_name", this.temp_task_name.temp_task_name);
         }
      },
      // methode转JSON
      toJSON(str) {
         try {
            return JSON.parse(str)
         } catch (err) {
            return str
         }
      },
      // 获取表头列表
      logAnalysis() {
         let dataset_id = this.$route.query.DataSetId ? this.$route.query.DataSetId : this.dataset_id
         this.axios
            .get(window.apis.SYS_DATASET + dataset_id + "/")
            .then((res) => {
               if (this.$route.query.DataSetId) {
                  //判断路由是否有 ID
                  this.$store.commit("dataset_id", res.data.id);
                  this.$store.commit("dataset_name", res.data.name);
                  this.$store.commit("task_type", res.data.task_type);
                  this.$store.commit("target_column", res.data.target_column);
                  this.$store.commit("desc", res.data.desc);
                  this.$store.commit("read_mode_header", res.data.read_mode_header);
                  this.$store.commit("headerData", res.data.header);
                  localStorage.removeItem('DataSetId')
               }
               this.mode_header = res.data.read_mode_header
               this.temp_task_name.target_column = res.data.target_column
               this.temp_task_name.time_column_name = res.data.time_column
               this.task_type == 3 ? this.getSequence() : '' //如果是时间序列请求获取过滤条件
               let header = this.toJSON(res.data.header)
               this.headerDataA = header.map((index, item) => {
                  return {
                     label: index,
                     value: item
                  }
               })
            })

      },
      saveTaskName() {
         this.$store.commit("task_name", this.temp_task_name.temp_task_name);
      },
      beforeStartAnalysis() {
         this.$refs["temp_task_name"].validate((valid) => {
            if (valid) {
               if (
                  typeof this.task_id == "undefined" ||
                  this.task_id == "" ||
                  this.task_id == null
               ) {
                  let url = window.apis.SYS_MODELS;

                  this.axios
                     .post(url, {
                        task_name: this.task_name,
                        task_type: this.task_type,
                        dataset_id: this.dataset_id,
                     })
                     .then((res) => {
                        if (res.status === 201) {
                           this.$store.commit("task_id", res.data.id);
                           this.startAnalysis();
                        }
                     })
                     .catch((error) => {
                        this.$message({
                           message: this.$t('Analysis.message7'),
                           type: "warning",
                        });
                        console.log(error);
                     });
               } else {
                  this.startAnalysis();
               }
            } else {
               return false;
            }
         });
      },
      startAnalysis() {
         // 插入“正在启动中...”
         this.logMsg.push(this.$t('Analysis.message2'));

         let url = window.apis.SYS_FEATURE;
         let config1 = {
            dataset_id: this.dataset_id,
            target_column: this.temp_task_name.target_column,
            task_name: this.task_name,
            read_mode_header: this.readModeHeader,
            missing_threshold: this.featureParam.missing_threshold / 100,
            correlation_threshold: this.featureParam.correlation_threshold / 100,
            cumulative_importance: this.featureParam.cumulative_importance / 100,
         }
         let config2 = {
            dataset_id: this.dataset_id,
            target_column: this.temp_task_name.target_column,
            task_name: this.task_name,
            time_column_name: this.temp_task_name.time_column_name,
            data_name: this.temp_task_name.data_name,
            frequency: this.featureParam.frequency,
            query: {}
         }
         for (let index in this.SequenceKey) {
            //根据已选中的时间序列，判断传的参数
            Object.keys(this.temp_task_name.query).forEach(key => {
               if (this.SequenceKey[index] == key) {
                  config2.query[key] = this.temp_task_name.query[key]
               }
            })
         }
         // Object.keys(this.temp_task_name.query).forEach((key)=>{
         //    config2.query.push(this.temp_task_name.query[key])
         // })
         this.axios
            .post(
               url,
               this.task_type == 3 ? config2 : config1, {
                  params: {
                     task_id: this.task_id,
                     action: "start",
                  },
               }
            )
            .then((res) => {
               if (res.status === 200 || res.status === 201) {
                  if (res.data.task_code === 1) {
                     this.task_code = res.data.task_code;
                     this.requstWs();
                  } else {
                     // 启动任务失败
                     this.logMsg.push(res.data.message || this.$t('Analysis.message3'));
                     this.task_code = 2;
                  }
               } else {
                  // 启动任务失败
                  this.logMsg.push(res.data.message || this.$t('Analysis.message3'));
                  this.task_code = 2;
               }
            })
            .catch((error) => {
               this.$message({
                  message: this.$t('Analysis.message3'),
                  type: "warning",
               });
               console.log(error);
            });
      },
      stopAnalysis() {
         // 正在停止中...”
         this.logMsg.push(this.$t('Analysis.message4'));
         let url = window.apis.SYS_FEATURE;
         this.axios
            .post(url, null, {
               params: {
                  task_id: this.task_id,
                  action: "stop",
               },
            })
            .then((res) => {
               console.log(res);
               if (res.status === 200 || res.status === 201) {
                  if (res.data.task_code == 1) {
                     // 停止成功
                     this.task_code = 2;
                  }
               } else {
                  this.$message({
                     message: "停止分析失败",
                     type: "warning",
                  });
               }
            })
            .catch((error) => {
               this.$message({
                  message: "停止分析失败",
                  type: "warning",
               });
               console.log(error);
            });
      },
      handleTaskStatus(curVal, oldVal) {
         if (curVal === 1) {
            // 执行中
            // 控制按钮显示
            (this.startVisible = true), //开始分析的状态
            (this.stopVisible = false), //停止分析的状态
            (this.nextVisible = true), //下一步的状态
            (this.lastVisible = true); //上一步的状态
         } else if (curVal === 0) {
            // 未执行
            // 控制按钮显示
            (this.startVisible = false), //开始分析的状态
            (this.stopVisible = true), //停止分析的状态
            (this.nextVisible = false), //下一步的状态
            (this.lastVisible = true); //上一步的状态
         } else if (curVal === 2) {
            // 失败
            // 控制按钮显示
            (this.startVisible = false), //开始分析的状态
            (this.stopVisible = true), //停止分析的状态
            (this.nextVisible = true), //下一步的状态
            (this.lastVisible = true); //上一步的状态
         } else if (curVal === 3) {
            // 成功
            // 控制按钮显示
            (this.startVisible = false), //开始分析的状态
            (this.stopVisible = true), //停止分析的状态
            (this.nextVisible = false), //下一步的状态
            (this.lastVisible = true); //上一步的状态
         } else if (curVal === -1) {
            // 异常 失败
            // 控制按钮显示
            (this.startVisible = false), //开始分析的状态
            (this.stopVisible = true), //停止分析的状态
            (this.nextVisible = true), //下一步的状态
            (this.lastVisible = true); //上一步的状态
         } else {
            // 其他默认失败
            (this.startVisible = false), //开始分析的状态
            (this.stopVisible = true), //停止分析的状态
            (this.nextVisible = true), //下一步的状态
            (this.lastVisible = true); //上一步的状态
            console.log(oldVal);
         }
      },
      nextStep() {
         // 判断任务
         this.$router.push({
            name: "Parameter",
         });
      },
      lastStep() {
         this.$router.push({
            name: "Auto",
            params: {
               index: this.paramsData.index,
               user_id: this.paramsData.user_id,
               on: "true",
               page: this.paramsData.page,
            },

         });
      },
      formatTooltip(val) {
         return val / 100;
      },
      scrollToBottom() {
         this.$nextTick(() => {
            let asideMain = this.$el.querySelector(".asideMain");
            asideMain.scrollTop = asideMain.scrollHeight;
         });
      },
      Copy() {
         // 循环遍历this.logMsg
         for (var key in this.logMsg) {
            this.CopyData.push(this.logMsg[key]);
            this.CopyData.push(" \n");
         }
         var a = this.CopyData.join("");
         this.$copyText(a);
         this.$message({
            message: "复制成功",
            type: "success",
         });
      },
      getSequence() {
         let url = `${window.apis.SYS_DATASET_SEQUENCE}${this.dataset_id}/?col=${this.temp_task_name.time_column_name}`
         this.axios(url).then((res) => {

            //    //遍历对象设置默认选中
            //    Object.keys(res.data).forEach((key)=>{
            //    // this.temp_task_name.query[key] = res.data[key][0]
            //    this.$set(this.temp_task_name.query,key,res.data[key][0])
            // })
            this.Sequence = res.data
         })
      }
   },
};
</script>

<style lang="scss" scoped>
.Analysis {
   min-width: 1165px;
   margin-top: 30px;
   overflow: hidden;
}

.el-form-item {
   margin: 0;

   .el-form-item__content {
      line-height: 0;
   }
}

::v-deep .el-form-item__content {
   line-height: 0;
}

.l {
   height: auto;
}

.detailsBox {
   width: 92.5%;
   margin: 20px 0 1px 20px;
   font-size: 14px;

   .divBox {
      width: 100%;
      height: auto;
      padding-bottom: 17px;
      overflow: hidden;

      .el-input {
         width: 335px;
      }

      .el-select {
         width: 335px;
      }

      h3 {
         display: block;
         font-weight: 500;
         font-size: 14px;
         line-height: 32px;
         width: 105px;
      }
   }
}

.minDetailsBox {
   width: 92.5%;
   margin: 0 0 1px 20px;
   font-size: 14px;
   display: flex;
   justify-content: space-between;
   flex-direction: row;
   flex-wrap: wrap;

   .divBox {
      // width: 47%;
      height: auto;
      padding-bottom: 15px;
      overflow: hidden;

      .el-input {
         width: 100px;
      }

      .el-select {
         width: 100px;
      }

      h3 {
         display: block;
         font-weight: 500;
         font-size: 14px;
         line-height: 32px;
         width: 105px;
         text-align: center;
         text-overflow: ellipsis;
         white-space: nowrap;
         overflow: hidden;
         margin-right: 10px;
      }
   }
}

section {
   position: fixed;
   right: 100px;
   bottom: 50px;
   z-index: 9999;

   .Step {
      margin: 0 15px;
      box-shadow: 0px 0px 7px #333333;
   }
}

.Slider {
   width: auto;
   height: auto;
   display: inline-block;
   margin: 0px 0 0 20px;
   position: relative;

   .inputName {
      margin: 10px 0;
   }

   h3 {
      display: block;
      font-weight: 500;
      font-size: 14px;
   }

   .el-slider {
      display: inline-block;
   }

   .el-input-number {
      display: inline-block;
      overflow: hidden;
      margin-left: 31px;
   }
}

.el-slider {
   width: 290px;
}

h3 {
   display: inline-block;
   font-size: 20px;
   font-weight: bold;
   color: #333333;
}

article {
   width: 490px;
   height: 420px;
   border: 1px solid rgba(218, 223, 231, 0.5);
   padding-bottom: 22px;
   text-align: left;
   overflow: hidden;

   .articleTitle {
      display: block;
      width: 100%;
      height: 47px;
      border-bottom: 1px solid rgba(218, 223, 231, 0.5);
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 47px;
      padding-left: 20px;
   }
}

aside {
   position: relative;
   // width: auto;
   max-width: 1250px;
   height: 420px;
   margin-bottom: 98px;

   .asideMainTitle {
      display: block;
      width: 100%;
      height: 47px;
      background: #ffffff;
      border: 1px solid rgba(218, 223, 231, 0.5);
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 47px;
      text-align: left;
      padding-left: 20px;
   }

   .asideMain {
      width: calc(100vw - 820px);
      min-width: 635px;
      height: 372px;
      border: 1px solid rgba(218, 223, 231, 0.5);
      background: rgba(0, 0, 0, 0.8);
      overflow: auto;

      i {
         position: absolute;
         top: 70px;
         right: 30px;
         color: rgba($color: #ffffff, $alpha: 1);
         font-size: 20px;
         cursor: pointer;
         transition: all 200ms;
      }

      i:hover {
         color: rgba($color: #ffffff, $alpha: 0.6);
      }

      ul li {
         width: auto;
         list-style: none;
         text-align: left;
         color: #ffffff;
         font-size: 12px;
         font-family: monospace;
         white-space: pre;
         word-wrap: normal;
         -webkit-font-variant-ligatures: contextual;
         font-variant-ligatures: contextual;
      }
   }

   .analyse {
      margin: 20px;
   }
}
</style>
