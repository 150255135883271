// import axios from 'axios'
import service from "./http";
import { i18n } from "../lang/index";

const Privilage = {};


// 新增模板
Privilage.TEMPLATE_CREATE = "tpot_template_create";
// 删除模板
Privilage.TEMPLATE_DELETE = "tpot_template_delete";
// 编辑模板
Privilage.TEMPLATE_UPDATE = "tpot_template_update";

// 中文用户登记
Privilage.grade_cn = "";

// 用户ID
Privilage.user_id = "";

//弹窗打开，获取购买类型
Privilage.type = "";

//会员到期时间
Privilage.expire_time = "";

//价格
Privilage.price = [
  { CNY_price: "" },
  { CNY_price: "" },
  { CNY_price: "" },
  { CNY_price: "" },
  { CNY_price: "" },
  { CNY_price: "" },
  { CNY_price: "" },
  { CNY_price: "" },
  { CNY_price: "" },
];

Privilage.perms = {};

//获取价格
Privilage.GIT_PERMS = () => {
  service
    .get(`${window.apis.VIP_PRICE}?id=${Privilage.user_id}`)
    .then((res) => {
      Privilage.price = res.data.results;
      for (let key in Privilage.price) {
        Privilage.price[key].CNY_price =
          Math.floor(
            Privilage.price[key].unit_price *
              Privilage.price[key].discount *
              Privilage.price[key].duration *
              10
          ) / 1000;
        Privilage.price[key].CNY_total =
          Math.floor(Privilage.price[key].total * 10) / 1000;
        if (Privilage.price[key].discount != "1") {
          Privilage.price[key].original_price =
            (Privilage.price[key].unit_price * Privilage.price[key].duration) /
            100;
        }
      }
    });
};

// 获取权限表
(Privilage.GIT_IOFN = () => {
  service
    .get(window.apis.SYS_USER_INFO)
    .then((res) => {
      if (res.status === 200) {
        localStorage.setItem("perms", JSON.stringify(res.data.perms));
        localStorage.setItem("roles", JSON.stringify(res.data.roles));
        Privilage.user_id = res.data.id;
        Privilage.expire_time = res.data.expire_time;
        res.data.perms.map((item) => {
          Privilage.perms[item] = true;
        });
        console.log(Privilage.perms);
        Privilage.type = res.data.roles[0];

        Privilage.GIT_PERMS();
        Privilage.ROLES_FN();
      }
    })
    .catch((error) => {
      console.log(error);
    });
}),
  // 循环得到中文对应的用户等级
  (Privilage.ROLES_FN = () => {
    switch (Privilage.type) {
      case "basic":
        Privilage.grade_cn = i18n.t("VIP2.Dialog.vipType1");
        break;
      case "diamond":
        Privilage.grade_cn = i18n.t("VIP2.Dialog.vipType2");
        break;
      case "premium":
        Privilage.grade_cn = i18n.t("VIP2.Dialog.vipType3");
        break;
      default:
        Privilage.grade_cn = i18n.t("VIP.type1");
        break;
    }
  });

// 验证是否符合权限列表的权限控制
Privilage.template_fn = (data) => {
  let perms = JSON.parse(localStorage.getItem("perms"));
  return perms.indexOf(data) == "-1" ? false : true;
};

export default Privilage;
