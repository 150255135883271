<template>
<footer>
   <div class="footerMain" id="footerMain">
      <p><span>{{$t('components.footerMain4')}}</span> © 2020 - {{date}} {{$t('global.ORG_NAME')}} | 
      <span @click="newOpen('https://beian.miit.gov.cn/')">苏ICP备2022012159号-1 | </span><span @click="routerGo('PrivacyPolicy')">{{$t('components.footerMain2')}}</span>
         | <span @click="routerGo('About')">{{$t('components.footerMain3')}}</span>
      </p>
   </div>
</footer>
</template>

<script>
export default {
   data() {
      return {
         date: ''
      }
   },
   mounted() {
      this.date = new Date().getFullYear() + ''
   },
   methods: {
      routePush(name) {
         this.$router.push({
            name: name,
         });
      },
      routerGo(routeName) {
         const {
            href
         } = this.$router.resolve({
            name: routeName
         })
         window.open(href)
      },
      newOpen(url) {
         window.open(url)
      }
   }
}
</script>

<style lang="scss" scoped>
footer {
   width: 100%;
   height: auto;
   min-width: 960px;
   padding: 0 33px;
   overflow: hidden;

   .footerMain {
      width: 100%;
      height: 69px;
      margin: 0 auto;
      margin-top: 50px;
      border-top: 1px solid rgba(218, 223, 231, 0.5);

      p {
         font-size: 14px;
         // font-family: "PingFang SC";
         font-weight: 400;
         line-height: 69px;

         span {
            font-weight: 400;
            cursor: pointer;
         }

         span:hover {
            color: #2c3e50;
         }
      }
   }
}
</style>
