<template>
<div class="Model">
   <!-- <breadcrumb /> -->
   <div style="height:43px;width:100%"></div>
   <div class="ModelTitleBox">
      <h3 class="ModelTitle l">
         {{ $t('HomePage.zyxog5') }}
         <!-- <el-tooltip
            class="item"
            effect="light"
            :content="$t('HomePage.9ph58g')"
            placement="top">
            <i class="iconfont iconbangzhu1"></i>
         </el-tooltip> -->
      </h3>
      <el-input
         :placeholder="$t('Analysis.input')"
         clearable
         v-model="name"
         @keyup.enter.native="getModelList"
         class="inputSelect r">
         <el-button
            slot="append"
            icon="el-icon-search"
            @click="getModelList()" />
      </el-input>

   </div>
   <div class="ModelBox">
      <el-table
         :data="models"
         size="mini"
         width="100%"
         :header-cell-style="{background:'#F5F7FA',color:'#909399'}">

         <el-table-column prop="task_name" :label="$t('AutoDL.TaskList.n1y56j')" />
         <el-table-column prop="dataset_name" :label="$t('AutoDL.TaskList.w52bqp')" />
         <el-table-column prop="stage_cn" :label="$t('AutoDL.TaskList.uy7qn9')" />
         <el-table-column prop="created" :label="$t('HomePage.ynbeia')">
            <template slot-scope="scope">
               {{
              scope.row.created | fmtdate
            }}
            </template>
         </el-table-column>
         <el-table-column :label="$t('pretreatment.table6')" width="240">
            <template slot-scope="scope">
               <el-button
                  @click="showDetail(scope.row)"
                  type="primary"
                  size="small"
                  style="margin-right:10px"
                  plain>
                  {{$t('HomePage.ccharh')}}
               </el-button>
               <el-button
                  @click="handleDelete(scope.row.id)"
                  type="primary"
                  size="small"
                  style="margin-right:10px"
                  plain>
                  {{ $t('shan-chu') }}
               </el-button>
               <el-button
                  @click="showDetail(scope.row)"
                  type="primary"
                  :disabled="scope.row.PreviewDisabled||scope.row.PreviewDisabled_a"
                  size="small"
                  style="margin-right:10px"
                  plain>
                  {{ $t('AutoDL.TaskList.47ds7h') }}
               </el-button>
            </template>
         </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
         @current-change="handleCurrentChange"
         :current-page.sync="currentPage"
         :page-size="pageSize"
         layout="total, prev, pager, next"
         background
         :total="count" />
   </div>

</div>
</template>

<script>
import {
   mapState
} from "vuex";
import Breadcrumb from "../../components/Breadcrumb";
export default {
   name: "Model",
   components: {
      Breadcrumb,
   },
   computed: {
      ...mapState([
         "task_id_DL",
         "dataset_id_DL",
      ]),
   },
   data() {
      return {
         editable: [],
         models: [],
         delVisible: false, //删除提示弹框的状态
         currentPage: 1, // 当前页
         pageSize: 10, // 页面大小
         count: 0,
         name: "", //搜索名称
         value: "",
         upData: {},
         Uploadingdata: false,

      };
   },
   mounted() {
      this.getModelList();
   },
   methods: {

      selectGetModelList() {
         this.currentPage = 1
         this.getModelList()
      },
      getModelList() {
         let url = window.apis.AUTODL_TASK;
         let params = {
            page: this.currentPage,
            name: this.name
         };

         this.axios
            .get(url, {
               params: params,
            })
            .then((res) => {
               if (res.status === 200 || res.status === 201) {
                  this.models = res.data.results;
                  this.count = res.data.count;
                  for (var key in this.models) {
                     this.models[key].PreviewDisabled =true
                     this.models[key].index = key
                    switch (this.models[key].stage) {
                case 1:
                  this.models[key].stage_cn = this.$t('AutoDL.TaskList.c2vg93');
                  this.models[key].PreviewDisabled_a =true
                  break;
                case 2:
                  this.models[key].stage_cn = this.$t('router.Training');
                  break;
                case 3:
                  this.models[key].stage_cn = this.$t('router.Report');
                  break;
                case 4:
                  this.models[key].stage_cn = this.$t('router.Prediction');
                  break;
              }
              if(this.models[key].stage == 1 || this.models[key].stage == 2 || this.models[key].stage == 4){
              switch(this.models[key].state){
              case -2:this.models[key].stage_cn = this.models[key].stage_cn + " | "+ this.$t('HomePage.uma1ev');
              break;
              case -1:this.models[key].stage_cn = this.models[key].stage_cn + " | "+ this.$t('HomePage.j20cda');
              break;
              case 0:this.models[key].stage_cn = this.models[key].stage_cn + " | "+ this.$t('HomePage.xb0pgf');
              this.models[key].PreviewDisabled =false
              break;
              case 1:this.models[key].stage_cn = this.models[key].stage_cn + " | "+ this.$t('HomePage.f8byqc');
              break;
              case 2:this.models[key].stage_cn = this.models[key].stage_cn + " | "+this.$t('HomePage.kutvux');
              break;
            }}
                  }
               } else {
                  this.$message({
                     message: this.$t('HomePage.message1'),
                     type: "warning",
                  });
               }
            })
            .catch((error) => {
               console.log(error);
            });
      },

      PredictionTrue(row) {
         if (row.stage > 4) {
            return false
         } else {
            return true
         }
      },
      showDetail(row) {
         this.$store.commit("task_id_DL", row.id);
         // this.$store.commit("task_name", row.task_name);
         this.$store.commit("dataset_id_DL", row.dataset_id);
         // this.$store.commit("task_type", row.task_type);
         // this.$store.commit("target_column", row.target_column);
         // this.$store.commit("dataset_name", row.dataset_name);
         // this.$store.commit("headerData", row.header);
         // this.$store.commit("stage", row.stage);
         var routeName = "";
         switch (row.stage) {
            case 1:
               routeName = "AutoDL";
               break;
            case 2:
               routeName = "TrainingDL";
               break;
            case 3:
               routeName = "Preview";
               break;
            case 3:
               routeName = "PredictionDL";
               break;
         }
         this.$router.push({
            name: routeName,
         });
      },
      handleDelete(id) {
         let url = `${window.apis.AUTODL_TASK}${id}/`;

         this.$confirm(this.$t('MdmHome.delConfirm'), this.$t('MdmHome.delTitle'), {
            type: 'warning'
         }).then(() => {
            this.axios({
               method: 'delete',
               url: url,
            }).then((res) => {
               if (res.status === 204 || res.status === 200) {
                  this.count -= 1;
                  this.$message({
                     message: this.$t('MdmHome.message3'),
                     type: "success",
                  });
                  if (this.task_id_DL == id) {
                     this.$store.commit("dataset_id_DL", "");
                     this.$store.commit("task_id_DL", "");
                  }
                  setTimeout(() => {
                     this.getModelList();
                  }, 50)
               }
            }).catch((error) => {
               console.log(error);
            })
         }).catch(() => {});
      },

      handleCurrentChange(currentPage) {
         this.currentPage = currentPage;
         this.getModelList();
      },
      routePush(dest) {
         if (this.$router.history.current.name != dest) {
            this.$router.push({
               name: dest,
            });
         }
      },
   },
};
</script>

<style lang="scss" scoped>
// 修改按钮移入显示
.u-btn {
   margin-left: 5px;
   opacity: 0;
   color: #396DD6;
   transition: all 500ms;
   font-size: 13px;
   cursor: pointer;
}

.u-btn:hover {
   font-size: 17px;
   color: #409EFF;
}

.el-table__body tr:hover {
   .u-btn {
      opacity: 1;
   }
}

.ModelTitleBox {
   // width: 100%;
   min-width: 1018px;
   height: 58px;
   margin: 0 33px;
   padding: 10px 0;

   .ModelTitle {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      padding-top: 10px;
   }

   .inputSelect {
      width: 300px;
      margin-left: 20px;
   }
}

.ModelBox {
   position: relative;
   width: auto;
   min-width: 1018px;
   height: auto;
   min-height: 600px;
   padding-bottom: 100px;
   margin: 0 33px;
   border: 1px solid rgba(218, 223, 231, 0.5);
   overflow: hidden;

   .el-pagination {
      position: absolute;
      bottom: 30px;
      right: 50%;
      transform: translate(50%, 0);
   }
}
</style>
