<template>
<div class="Output">
   <article>
      <h2>{{$t('Output.title')}}</h2>
   </article>
   <el-table
      :data="tableData"
      size="small"
      border
      :header-cell-style="{background:'#F5F7FA',color:'#909399'}"
      style="width: 100%"
   >
      <el-table-column
         prop="dataset_name"
         :label="$t('Output.dataset_name')"
         width=""
      />
      <el-table-column
         prop="task_name"
         :label="$t('Output.task_name')"
         width=""
      />
      <el-table-column
         prop="version"
         :label="$t('Output.version')"
         width=""
      />
      <el-table-column
         prop="type"
         :label="$t('Output.type')"
         width=""
      />
      <el-table-column :label="$t('Output.img')">
         <template slot-scope="scope">
            <el-link
               :underline="false"
               v-if="scope.row.imgList[0]"
               @click="scope.row.show=true,isShowIndex=scope.row.index,isShowTrue=true"
            ><i class="el-icon-picture-outline"></i> {{scope.row.score}}</el-link>
            <span v-else>{{$t('Output.null')}}</span>
            <el-link
               :underline="false"
               v-if="scope.row.imgList2[0]"
               @click="scope.row.show=true,isShowIndex=scope.row.index,isShowTrue=false"
               style="margin-left:15px"
            ><i class="el-icon-picture-outline"></i> R-square</el-link>
            <el-image-viewer
               v-if="scope.row.show"
               :on-close="isShowFn"
               :url-list="isShowTrue?scope.row.imgList:scope.row.imgList2"
            />
         </template>
      </el-table-column>
      <el-table-column :label="$t('Output.download')">
         <template slot-scope="scope">
            <el-button
               class="Download"
               type="primary"
               size="small"
               @click="DownloadCilck(scope.row.url)"
            >
               <i class="el-icon-download" />
               {{$t('Output.download')}}
            </el-button>
         </template>
      </el-table-column>
   </el-table>
   <section>
      <el-tooltip
         class="item"
         effect="light"
         :content="$t('pretreatment.buttonTop')"
         placement="top-start"
      >
         <el-button
            class="Step"
            type="primary"
            icon="el-icon-arrow-left"
            circle
            @click="lastStep"
         />
      </el-tooltip>
      <el-tooltip
         class="item"
         effect="light"
         :content="$t('pretreatment.buttonNext')"
         placement="top-start"
      >
         <el-button
            class="Step"
            type="primary"
            icon="el-icon-arrow-right"
            circle
            @click="nextStep"
         />
      </el-tooltip>
   </section>
</div>
</template>

<script>
import {
   mapState
} from "vuex";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
   components: {
      ElImageViewer
   },
   data() {
      return {
         Download: [],
         options: [{
            value: "1",
            label: "Py",
         }, ],
         value: "1",
         tableData: [{
            url: "",
            version: ""
         }],
         imgList: [],
         isShow: false,
         isShowIndex: 0,
         isShowTrue: true,//用于判断当前点击是否为R-square
      };
   },
   mounted() {
      this.getDownloadList()
      this.Download =
         window.apis.SERVER +
         window.apis.SYS_DOWNLOAD +
         this.DownloadSRC +
         "?token=" +
         localStorage.token.split(" ")[1];
   },
   methods: {
      isShowFn() {
         this.tableData[this.isShowIndex].show = false
      },
      getDownloadList() {
         let url = `${window.apis.SYS_DOWNLOAD_HISTORY}?task_id=${this.task_id}`
         this.axios.get(url).then((res) => {
            let data = res.data.results
            this.tableData = data.map((item, index) => {
               return {
                  version: `V${index+1}_${item.version}`,
                  url: window.apis.SERVER +
                     window.apis.SYS_DOWNLOAD +
                     this.toJSON(item.train_output).model_result +
                     "?token=" +
                     localStorage.token.split(" ")[1],
                  type: 'py',
                  dataset_name: this.dataset_name,
                  task_name: this.task_name,
                  score: this.toJSON(item.train_output).best_pipline.score.f1 ? this.$t('Output.imgType1') : this.$t('Output.imgType2'),
                  index: index,
                  imgList: [
                     this.toJSON(item.train_output).best_pipline.score_img ?
                     window.apis.SERVER +
                     window.apis.SYS_DOWNLOAD +
                     this.toJSON(item.train_output).best_pipline.score_img +
                     "?token=" +
                     localStorage.token.split(" ")[1] : null,
                  ],
               //imgList2为R-square图
                  imgList2: [
                     this.toJSON(item.train_output).best_pipline.score_img_r2 ?
                     window.apis.SERVER +
                     window.apis.SYS_DOWNLOAD +
                     this.toJSON(item.train_output).best_pipline.score_img_r2 +
                     "?token=" +
                     localStorage.token.split(" ")[1] : null,
                  ],
                  show: false,
               }
            })
         })
      },
      toJSON(str) {
         try {
            return JSON.parse(str)
         } catch (err) {
            return str
         }
      },
      nextStep() {
         this.$router.push({
            name: "Prediction",
         });
      },
      lastStep() {
         this.$router.push({
            name: "Report",
         });
      },
      DownloadCilck(url) {
         window.open(url, "_self");
      },
   },
   computed: {
      ...mapState([
         "task_id",
         "task_name",
         "dataset_id",
         "task_type",
         "target_column",
         "desc",
         "DownloadSRC",
         "dataset_name",
      ]),
   },
};
</script>

<style lang="scss" scoped>
* {
   text-align: left;
}

.detailsBox {
   width: 392px;
   margin-top: 30px;
   font-size: 14px;
   border: 1px solid rgba(218, 223, 231, 0.5);

   div {
      width: 100%;
      height: auto;
      padding: 5px 10px;
      overflow: hidden;
      border-bottom: 1px solid rgba(218, 223, 231, 0.5);

      p {
         width: 25%;
         font-weight: 500;
      }

      span {
         width: 75%;
         text-align: left;
      }
   }

   div:hover {
      background: rgba(246, 247, 248, 0.5);
   }

   div:last-of-type {
      border: 0px;
   }
}

section {
   position: fixed;
   right: 100px;
   bottom: 50px;
   z-index: 100;

   .Step {
      margin: 0 15px;
      box-shadow: 0px 0px 7px #333333;
   }
}

article {
   width: auto;
   margin: 30px 0 0 0;

   h2 {
      margin: 30px 0 20px 0;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      font-weight: 500;
      text-align: left;
   }

   h3 {
      display: inline-block;
      font-size: 15px;
      font-weight: 400;
      color: #333333;
      margin: 0 8px 0 0;
   }

   .el-select {
      margin-top: 24px;
   }

   .Download {
      margin-left: 20px;
   }
}
</style>
