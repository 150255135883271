<template>
  <div class="VipHome">
    <automl-header />
    <main>
      <div class="mainBox">
        <div class="topBox">
          <!-- <div
            class="topLift"
            v-show="this.$Privilage.price[6].discount!=1"
          >
            <p>{{ $t('config')=='zh'?`年付${this.$Privilage.price[6].discount * 10}折`:`${this.$Privilage.price[0].discount * 100}% off`}}</p>
          </div> -->
          <div class="bottomText">
            <span style="color:rgba(31, 46, 58, 0.67);">{{$t('VIP2.Dialog.vipType4')}}</span>
            <!-- <span style="margin-left:8px;font-size: 20px;">Basic</span> -->
          </div>
        </div>
        <div class="MainButtomBox">
          <ul>
            <li>
              <p class="l">
                {{$t('VIP2.Home.p1')}}1
              </p>
              <i class="el-icon-check r" />
            </li>
            <li>
              <p class="l">
                {{$t('VIP2.Home.p2')}}1G
              </p>
              <i class="el-icon-check r" />
            </li>
            <li>
              <p class="l">
                {{$t('VIP2.Home.p3')}}1
              </p>
              <i class="el-icon-check r" />
            </li>
            <li>
              <p class="l">
                {{$t('VIP2.Home.p4')}}
              </p>
              <i class="el-icon-close r" />
            </li>
            <li>
              <p class="l">
                GPU
              </p>
              <i class="el-icon-close r" />
            </li>
          </ul>
          <div class="money">
            <span class="moneySymbol">￥</span>
            <span class="moneyNumber" >0</span>
            <span class="time">/{{$t('yue')}}</span>
          </div>
          <!-- <el-button
            type="primary"
            style="width:155px"
          >
            使用中
          </el-button> -->
        </div>
      </div>
      <div class="mainBox">
        <div class="topBox">
          <div
            class="topLift"
            v-show="this.$Privilage.price[6].discount!=1"
          >
            <p>{{ $t('config')=='zh'?`年付${this.$Privilage.price[6].discount * 10}折`:`Yearly ${this.$Privilage.price[0].discount * 100}% off`}}</p>
          </div>
          <div class="bottomText">
            <span>{{$t('VIP2.Dialog.vipType1')}}</span>
            <!-- <span style="margin-left:8px;font-size: 20px;">Basic</span> -->
          </div>
        </div>
        <div class="MainButtomBox">
          <ul>
            <li>
              <p class="l">
                {{$t('VIP2.Home.p1')}}2
              </p>
              <i class="el-icon-check r" />
            </li>
            <li>
              <p class="l">
                {{$t('VIP2.Home.p2')}}3G
              </p>
              <i class="el-icon-check r" />
            </li>
            <li>
              <p class="l">
                {{$t('VIP2.Home.p3')}}2
              </p>
              <i class="el-icon-check r" />
            </li>
            <li>
              <p class="l">
                {{$t('VIP2.Home.p4')}}
              </p>
              <i class="el-icon-check r" />
            </li>
            <li>
              <p class="l">
                GPU
              </p>
              <i class="el-icon-close r" />
            </li>
          </ul>
          <div class="money">
            <span class="moneySymbol">￥</span>
            <span class="moneyNumber">{{ this.$Privilage.price[8].CNY_price }}</span>
            <span class="time">/{{$t('yue')}}</span>

          </div>
          <el-button
            type="primary"
            @click="vipFn('basic')"
            style="width:155px"
          >
            {{$t('VIP2.Home.button2')}}
          </el-button>
        </div>
      </div>
      <div class="mainBox">
        <div class="topBox">
          <div
            class="topLift"
            v-show="this.$Privilage.price[3].discount!=1"
          >
            <p>{{ $t('config')=='zh'?`年付${this.$Privilage.price[3].discount * 10}折`:`Yearly ${this.$Privilage.price[0].discount * 100}% off`}}</p>
          </div>
          <div class="bottomText">
            <span>{{this.$t('VIP2.Dialog.vipType2')}}</span>
            <!-- <span style="margin-left:8px;font-size: 20px;">Diamond</span> -->
          </div>
        </div>
        <div class="MainButtomBox">
          <ul>
            <li>
              <p class="l">
                {{$t('VIP2.Home.p1')}}5
              </p>
              <i class="el-icon-check r" />
            </li>
            <li>
              <p class="l">
                {{$t('VIP2.Home.p2')}}7G
              </p>
              <i class="el-icon-check r" />
            </li>
            <li>
              <p class="l">
                {{$t('VIP2.Home.p3')}}4
              </p>
              <i class="el-icon-check r" />
            </li>
            <li>
              <p class="l">
                {{$t('VIP2.Home.p4')}}
              </p>
              <i class="el-icon-check r" />
            </li>
            <li>
              <p class="l">
                {{$t('VIP2.Home.p5')}}
              </p>
              <i class="el-icon-close r" />
            </li>
          </ul>
          <div class="money">
           <span class="moneySymbol">￥</span>
            <span class="moneyNumber">{{ this.$Privilage.price[5].CNY_price }}</span>
            <span class="time">/{{$t('yue')}}</span>

          </div>
          <el-button
            type="primary"
            @click="vipFn('diamond')"
            style="width:155px"
          >
            {{$t('VIP2.Home.button2')}}
          </el-button>
        </div>
      </div>
      <div class="mainBox">
        <div class="topBox">
          <div
            class="topLift"
            v-show="this.$Privilage.price[0].discount!=1"
          >
            <p>{{ $t('config')=='zh'?`年付${this.$Privilage.price[0].discount * 10}折`:`Yearly ${this.$Privilage.price[0].discount * 100}% off`}}</p>
          </div>
          <div class="bottomText">
            <span>{{$t('VIP2.Dialog.vipType3')}}</span>
            <!-- <span style="margin-left:8px;font-size: 20px;">Premium</span> -->
          </div>
        </div>
        <div class="MainButtomBox">
          <ul>
            <li>
              <p class="l">
                {{$t('VIP2.Home.p1')}}7
              </p>
              <i class="el-icon-check r" />
            </li>
            <li>
              <p class="l">
                {{$t('VIP2.Home.p2')}}15G
              </p>
              <i class="el-icon-check r" />
            </li>
            <li>
              <p class="l">
                {{$t('VIP2.Home.p3')}}6
              </p>
              <i class="el-icon-check r" />
            </li>
            <li>
              <p class="l">
                {{$t('VIP2.Home.p4')}}
              </p>
              <i class="el-icon-check r" />
            </li>
            <li>
              <p class="l">
                {{$t('VIP2.Home.p6')}}
              </p>
              <i class="el-icon-check r" />
            </li>
          </ul>
          <div class="money">
            <span class="moneySymbol">￥</span>
            <span class="moneyNumber">{{ this.$Privilage.price[2].CNY_price }}</span>
            <span class="time">/{{$t('yue')}}</span>

          </div>
          <el-button
            type="primary"
            @click="vipFn('premium')"
            style="width:155px"
          >
            {{$t('VIP2.Home.button2')}}
          </el-button>
        </div>
      </div>
    </main>
    <footer-a style="width:980px;margin:0 auto;" />
    <VipDialog ref="VipDialog"></VipDialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import footerA from "../../components/Footer"
import AutomlHeader from "../../components/AutomlHeader"
import VipDialog from  "./VipDialog.vue"

export default {
  data() {
    return {
      buttonTrue:{a:false,b:false,c:false},
      buttonText:{a:this.$t('VIP2.Home.button2'),b:this.$t('VIP2.Home.button2'),c:this.$t('VIP2.Home.button2')}
    };
  },
    mounted() {
    let a = 0
    const setFn = setInterval(()=>{
      this.gradeFn();
      a++;
      if(a>5){clearInterval(setFn)}
    },500);

    // this.$Privilage.GIT_PERMS();
  },
    methods:{
      COUPON(){
        this.axios.post(window.apis.COUPON).then(
            (res)=>{
              if(res.status==200||res.status==201){
                this.$message({
                  message: this.$t('VIP2.Home.message1'),
                  type: 'success'
                });
              }
        })
      },
    vipFn(type){
        this.$refs.VipDialog.Dialog=true;
        this.$refs.VipDialog.vipType = type;
    },
    routePush(dest) {
      if (this.$router.history.current.name != dest) {
        this.$router
        .push({
          name: dest
        })
      }
    },
    gradeFn(){
      //switch循环获取状态
      switch (this.$Privilage.grade_cn){
        case this.$t('VIP2.Dialog.vipType1'):
          this.buttonTrue.a = false;
          this.buttonTrue.b = false;
          this.buttonTrue.c = false;
          this.buttonText.a = this.$t('VIP2.Home.button3');
          this.buttonText.b = this.$t('VIP2.Home.button4');
          this.buttonText.c = this.$t('VIP2.Home.button4');
        break;
        case this.$t('VIP2.Dialog.vipType2'):
          this.buttonTrue.a = true;
          this.buttonTrue.b = false;
          this.buttonTrue.c = false;
          this.buttonText.a = this.$t('VIP2.Home.button2');
          this.buttonText.b = this.$t('VIP2.Home.button3');
          this.buttonText.c = this.$t('VIP2.Home.button4');
        break;
        case this.$t('VIP2.Dialog.vipType3'):
          this.buttonTrue.a = true;
          this.buttonTrue.b = true;
          this.buttonTrue.c = false;
          this.buttonText.a = this.$t('VIP2.Home.button2');
          this.buttonText.b = this.$t('VIP2.Home.button2');
          this.buttonText.c = this.$t('VIP2.Home.button3');
        break;
        default:
          this.buttonTrue.a = false;
          this.buttonTrue.b = false;
          this.buttonTrue.c = false;
          this.buttonText.a = this.$t('VIP2.Home.button2');
          this.buttonText.b = this.$t('VIP2.Home.button2');
          this.buttonText.c = this.$t('VIP2.Home.button2');
      }
    }
  },
  components: {footerA,AutomlHeader,VipDialog},
    computed: {
  ...mapState(['user'])
  },
}
</script>

<style lang="scss" scoped>
* {
font-family: "PingFang SC";
}
main {
    width: 1200px;
    min-height: calc(100vh - 275px);
    height: auto;
    margin: 0 auto;
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    margin-top: calc(100vh * 0.1);
        .mainBox {
        width: 270px;
        height: 503px;
        background: #FFFFFF;
        border-radius: 8px;
        float: left;
      //   margin-left: 64px;
        transition: all 300ms;
        overflow: hidden;
        .topBox {
            position: relative;
            height: 83px;
            overflow: hidden;
            .topLift {
                position: absolute;
                left: -27px;
                top:16.5px;
                transform: rotate(-30deg);
                width: 147px;
                height: 23px;
                background: #FC7750;
                box-shadow: 0px 2px 9px 0px #476BB3;
                p {
                    font-size: 12px;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 23px;
                }
            }
            .bottomText {
                margin-top: 25px;
            }
            span {
                font-size: 24px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 33px;
            }
        }
        .MainButtomBox {
            height: 420px;
            border: 1px solid #E1E1E1;
            border-top: 0;
            overflow: hidden;
            border-radius: 0 0 8px 8px;
        }
        ul {
            width: 80%;
            margin: 0 auto;
            margin-top: 22px;
            padding-bottom: 32px;
            overflow: hidden;
            border-bottom: 1px solid #E1E1E1;
            li {
                overflow: hidden;
                list-style-type:none;
                margin-top: 16px;
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 20px;
            }
        }
        .money {
            margin-top: 10px;
            margin-bottom: 10px;
            .moneySymbol {
                font-size: 16px;
                font-weight: 500;
                color: #333333;
                //line-height: 22px;
            }
            .moneyNumber {
                font-size: 46px;
                font-weight: 500;
                //line-height: 65px;
            }
            .time {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                margin-left: 8px;
            }
        }
        }
        .mainBox:nth-of-type(1) {
            margin-left: 0;
            background: url('../../assets/vip_img/mianfeiban_pic.png')no-repeat;
        }
        .mainBox:nth-of-type(2) {
            margin-left: 0;
            background: url('../../assets/vip_img/pt.png')no-repeat;
        }
        .mainBox:nth-of-type(3) {
            background: url('../../assets/vip_img/dingjia_img2.png')no-repeat;
            .bottomText {
                span {
                    color: #875B06;
                }
            }
            .topLift  {
                box-shadow: 0px 2px 9px 0px #D9A73A;
            }
        }
        .mainBox:nth-of-type(4) {
            background: url('../../assets/vip_img/dingjia_img3.png')no-repeat;
            .bottomText {
                span {
                    color: #836029;
                }
            }
            .topLift  {
                box-shadow: 0px 2px 9px 0px #C7AB8A;
            }
        }
        .mainBox:hover {
        box-shadow: 0px 2px 28px 1px #DEDFE6;
        margin-top: -12px;
        .MainButtomBox {
            border: 1px rgba($color: #fff, $alpha: 0);
        }
        }
}
</style>