<template>
  <div>
    <el-collapse-transition>
    <main v-show="show">
        <div class="text"><slot></slot></div>
        <i @click="show = false" class="el-icon-close close"></i>
        <i class="el-icon-warning-outline warning"></i>
    </main>
    </el-collapse-transition>
  </div>
</template>

<script>
export default {
data(){
    return{
        show: true,
    }
}
}
</script>

<style lang="scss" scoped>
main {
    width: auto;
    height: auto;
    position: relative;
    margin: 20px 33px;
    padding: 30px;
    border-radius: 5px;
    color: #3170ED;
    background: #EDF2FC;
    .text {
        text-align: left;
        margin-left: 40px;
    }
    .close {
        position: absolute;
        right: 15px;
        bottom: 15px;
        font-size: 18px;
        cursor:pointer;
    }
    .close:hover {
        color: #1764FC;
    }
    .warning {
        position: absolute;
        font-size: 30px;
        left: 30px;
        top: 50%;
        transform: translate(0,-50%) rotate(180deg);
    }
    
}
</style>