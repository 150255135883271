<template>
  <div class="pretreatment">
    <article class="l">
      <span class="tableTitle">
        <el-input
          :placeholder="$t('Uploading.placeholder')"
          v-model="search"
          clearable
          @keyup.enter.native="getDatasetList"
          class="inputSelect r"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getDatasetList"
          />
        </el-input>
        <el-select
          v-model="value"
          @change="selectGetDatasetList"
          :placeholder="$t('pretreatment.select')"
          class="r"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </span>
      <el-tabs
        v-model="dataset_class"
        
        @tab-click="getDatasetListTbs"
      >
        <el-tab-pane
          :label="$t('MdmHome.tabs1')"
          name="0"
        >
          <div class="PreBox">
            <el-table
              :header-cell-style="{background:'#F5F7FA',color:'#909399'}"
              :data="datasets"
              size="mini"
              @row-click="showDetailA"
              :row-class-name="tableRowClassA"
            >
              <el-table-column
                prop="name"
                width="325px"
                :label="$t('pretreatment.table1')"
              />
              <el-table-column
                prop="task_type_cn"
                :label="$t('pretreatment.table2')"
              />
              <el-table-column
                prop="columns"
                :label="$t('pretreatment.table3')"
              />
              <el-table-column
                prop="rows"
                :label="$t('pretreatment.table4')"
              />
              <el-table-column
                prop="size"
                :label="$t('pretreatment.table5')"
              >
                <template slot-scope="scope">
                  {{ scope.row.size | byteChange }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('pretreatment.table6')"
              >
                <template slot-scope="scope">
                  <el-button
                    style="width:70px"
                    type="primary"
                    size="small"
                  >
                    <span v-show="tableRowA(scope.$index)">{{$t('pretreatment.tableButton')}}</span><span v-show="!tableRowA(scope.$index)">{{$t('pretreatment.tableButton1')}}</span>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('MdmHome.tabs2')"
          name="1"
        >
          <div class="PreBox">
            <el-table
              :header-cell-style="{background:'#F5F7FA',color:'#909399'}"
              :data="datasets"
              size="mini"
              @row-click="showDetailB"
              :row-class-name="tableRowClassB"
            >
              <el-table-column
                prop="name"
                width="325px"
                :label="$t('pretreatment.table1')"
              />
              <el-table-column
                prop="task_type_cn"
                :label="$t('pretreatment.table2')"
              />
              <el-table-column
                prop="columns"
                :label="$t('pretreatment.table3')"
              />
              <el-table-column
                prop="rows"
                :label="$t('pretreatment.table4')"
              />
              <el-table-column
                prop="size"
                :label="$t('pretreatment.table5')"
              >
                <template slot-scope="scope">
                  {{ scope.row.size | byteChange }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('pretreatment.table6')"
              >
                <template slot-scope="scope">
                  <el-button
                    style="width:70px"
                    type="primary"
                    size="small"
                  >
                    <span v-show="tableRowB(scope.$index)">
                       <!-- 已选择 -->
                       {{$t('pretreatment.tableButton')}}
                       </span><span v-show="!tableRowB(scope.$index)">
                          <!-- 选择 -->
                          {{$t('pretreatment.tableButton1')}}
                          </span>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- 分页 -->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        background
        :total="count"
      />
    </article>
    <aside class="r">
      <div class="type">
        <h3>
           <!-- 数据集名称 -->
{{$t('pretreatment.table1')}}
        </h3>
        <el-input
          v-model="dataset_name"
          class="asideText"
          size="small"
          :disabled="true"
        />
      </div>
      
      <div class="type">
        <h3>
           <!-- 任务类型 -->
           {{$t('pretreatment.table2')}}
           </h3>
        <el-radio-group
          v-model="task_type"
          :disabled="true"
        >
          <el-radio
            :label="0"
            
            size="small"
          >
            <!-- 二分类 -->
            {{$t('MdmHome.dialogRadio1')}}
          </el-radio>
          <el-radio
            :label="1"
            
            size="small"
          >
            <!-- 多分类 -->
            {{$t('MdmHome.dialogRadio2')}}
          </el-radio>
          <el-radio
            :label="2"
            
            size="small"
          >
            <!-- 回归 -->
            {{$t('MdmHome.dialogRadio3')}}
          </el-radio>
          <el-radio
            :label="3"
            
            size="small"
          >
            {{$t('MdmHome.taskType4')}}
          </el-radio>
        </el-radio-group>
      </div>
      <div class="type">
        <h3>
           <!-- 目标列 -->
           {{$t('pretreatment.type1')}}
         </h3>
<!--        <el-radio-group-->
<!--          v-model="target_column"-->
<!--          :disabled="true"-->
<!--        >-->
<!--          <el-radio-->
<!--            :label="-1"-->
<!--            border-->
<!--            size="small"-->
<!--          >-->
<!--            最后一列-->
<!--          </el-radio>-->
<!--          <el-radio-->
<!--            :label="0"-->
<!--            border-->
<!--            size="small"-->
<!--          >-->
<!--            首列-->
<!--          </el-radio>-->
<!--        </el-radio-group>-->
        <el-input
            v-model="target_column"
            class="asideText"
            size="small"
            :disabled="true"
        />


      </div>
      <div class="type">
        <h3>
           <!-- 是否有表头 -->
         {{$t('pretreatment.type2')}}
        </h3>
        <el-radio-group
          v-model="read_mode_header"
          :disabled="true"
        >
          <el-radio
            label="y"
            
            size="small"
          >
            <!-- 有 -->
            {{$t('MdmHome.dialogRadio4')}} 
          </el-radio>
          <el-radio
            label="n"
            
            size="small"
          >
            <!-- 无 --> 
            {{$t('MdmHome.dialogRadio5')}}
          </el-radio>
        </el-radio-group>
      </div>
      <div class="type">
        <h3 class="l">
          <!-- 数据描述 -->
          {{$t('pretreatment.type3')}}
        </h3>
        <el-input
          type="textarea"
          v-model="desc"
          :placeholder="$t('pretreatment.type3')"
          disabled
          class="asideText2 l"
          :rows="5"
        />
      </div>
    </aside>
    <section>
      <el-tooltip
        class="item"
        effect="light"
        :content="$t('pretreatment.buttonNext')"
        placement="top-start"
      >
        <el-button
          class="Step"
          type="primary"
          icon="el-icon-arrow-right"
          circle
          @click="nextStep"
        />
      </el-tooltip>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data(){
    return {
      datasets: [],
      currentPage: 1, // 当前页
      pageSize: 10, // 页面大小
      count:0,
      search: '',
      options: [{
          value: '',
          label: this.$t('MdmHome.optionsLabel1'),
        }, {
          value: '0',
          label: this.$t('MdmHome.optionsLabel2'),
        }, {
          value: '1',
          label: this.$t('MdmHome.optionsLabel3'),
        }, 
        {
          value: '2',
          label: this.$t('MdmHome.optionsLabel4'),
        },
        {
          value: '3',
          label: this.$t('MdmHome.optionsLabel5'),
        },
        ],
      tableRow:{
        a:'',
        b:''
      },
    value: '',
    dataset_class: '0',
      headerData:[]
    }

  },
  computed :{
    ...mapState(["task_id","task_name","dataset_id","dataset_name","task_type","target_column","read_mode_header","desc"])
  },
  created() {
    this.tableClass();
    this.getDatasetList();
    this.clearTaskID();
  },
  methods: {
    toJSON(str){
      try {
        return JSON.parse(str)
      } catch (err) {
        return str
      }},
    tableClass(){
      if(this.$route.params.on){
        this.currentPage = this.$route.params.page
      if(this.$route.params.user_id=="0"){
        this.dataset_class = "1"
        this.tableRow.b = this.$route.params.index
      }else{
      this.dataset_class = "0"
      this.tableRow.a = this.$route.params.index
      }
    }
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassA({row,rowIndex}){
    if(row.name == null){
        return 'none';
      }else{
      if(rowIndex===this.tableRow.a){
        return 'warning-row';
      }else{
        return '';
      }
    }},
    tableRowClassB({rowIndex}){
      if(this.tableRow.b===rowIndex){
        return 'warning-row';
      }else{
        return '';
      }
    },
    clearTaskID() {
      this.$store.commit('task_id', ''); 
      this.$store.commit('task_name', '');
    },
    getDatasetListTbs() {
      this.tableRow={a:'',b:''}
      this.currentPage = 1
      this.getDatasetList()
    },
    selectGetDatasetList(){
      this.currentPage = 1
      this.getDatasetList()
    },
    getDatasetList() {
      let url = window.apis.SYS_DATASET;
      let params = {
        page: this.currentPage,
      };
      if (this.dataset_class === '1') {
        params['built_in'] = 1;
      } else {
        params['built_in'] = 0;
      }
      if (typeof(this.search) == "undefined" || this.search == '' || this.search == null){
      } else {
        params['name'] = this.search;
      }
      if (typeof(this.value) == "undefined" || this.value =='' || this.value ==null){
      } else {
        params['task_type']  = this.value;
      }
      this.axios.get(url,{
        params: params
      })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          this.datasets = res.data.results;
          for(let key in res.data.results){
            this.datasets[key].index=key
          }
          this.count = res.data.count;
          for(let key in this.datasets){
            switch(this.datasets[key].task_type){
              //二分类
              case 0:this.datasets[key].task_type_cn=this.$t('MdmHome.optionsLabel2');
              break;
              //多分类
              case 1:this.datasets[key].task_type_cn=this.$t('MdmHome.optionsLabel3');
              break;
              //回归
              case 2:this.datasets[key].task_type_cn=this.$t('MdmHome.optionsLabel4');
              break;
              case 3:this.datasets[key].task_type_cn=this.$t('MdmHome.optionsLabel5');
              break;
            }
          }
        } else {
          this.$message({
            message: this.$t('pretreatment.message1'),
            type: 'warning',
          });
        }
      })
      .catch((error) => {
      });
    },
    tableRowA(row){
      if(this.tableRow.a===row){
        return true
      }else{
        return false
      }
    },
    tableRowB(row){
      if(this.tableRow.b===row){
        return true
      }else{
        return false
      }
    },
    showDetailA(row) {
      this.tableRow.a=parseInt(row.index)
      this.$store.commit('dataset_id', row.id); 
      this.$store.commit('dataset_name', row.name); 
      this.$store.commit('task_type', row.task_type); 
      this.$store.commit('target_column', row.target_column); 
      this.$store.commit('desc', row.desc);
      this.$store.commit('read_mode_header', row.read_mode_header);
      this.$store.commit("headerData", row.header);
      let header = this.toJSON(row.header)
      let target_column = row.read_mode_header=='y'?header[row.target_column]:row.target_column
      this.$store.commit('target_column', target_column);
    },
    showDetailB(row) {
      this.tableRow.b=parseInt(row.index)
      this.$store.commit('dataset_id', row.id); 
      this.$store.commit('dataset_name', row.name); 
      this.$store.commit('task_type', row.task_type);
      this.$store.commit('desc', row.desc);
      this.$store.commit('read_mode_header', row.read_mode_header);
      this.$store.commit("headerData", row.header);
      let header = this.toJSON(row.header)
      let target_column = row.read_mode_header=='y'?header[row.target_column]:row.target_column
      this.$store.commit('target_column', target_column);

    },

    handleCurrentChange(currentPage) {
      this.tableRow={a:'',b:''}
      this.currentPage = currentPage;
      this.getDatasetList();
    },
   nextStep() {
      if (this.dataset_id) {
        let user_id = ''
        let index = '123'
        if(this.dataset_class == '0'){
          user_id = '1'
          index = this.tableRow.a
        }else {
          user_id = '0'
          index = this.tableRow.b
        }
        this.$router
          .push({
            name: 'Analysis',
            params: {
              index: index,
              user_id: user_id,
              on: "true",
              page: this.currentPage,
            },
          })
      } else {
        this.$message({
          message: this.$t('qing-xuan-ze'),
          type: 'warning',
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  text-align: left;
}
::v-deep .warning-row {
  background: #f6f7f8;
}
::v-deep .none {
  display: none;
}
.pretreatment {
  min-width: 1165px;
  // overflow: hidden;
  margin: 30.01px 0 0 0;
}
section {
  position: fixed;
  right: 99.994px;
  bottom: 49.997px;
  z-index:100;
  .Step {
    margin:0 14.995px;
    box-shadow:0px 0px 7.008px #333333;
  }
}

.el-slider {
  width: 250.003px;
}
h3 {
  width: 80.003px;
  display: inline-block;
  font-size: 20.006px;
  // font-family: 'PingFang SC';
  font-weight: bold;
  color: #333333;
  text-align: right;
}
aside {
//   width:  390px;
//   height: 370px;
  border: 0.998px solid rgba(218, 223, 231, 0.5);
  position: relative;
  margin-top: 77px;
  padding: 10px 10px;
  overflow: hidden;
  .type {
    display: flex;
    align-items: top;
    height: auto;
    margin: 15px 0 0 0;
    h3 {
      display: inline-block;
      text-align: right;
      font-size: 13.997px;
      line-height: 32px;
    }

    .asideText {
        width: 263px;
        height: 39.994px;
        display: inline-block;
        margin:0 0 0 12.998px;
      }
    .asideText2 {
        width: 263px;
        margin:0 0 0 12.998px;
      }
    .el-radio-group {
       display: flex;
       margin:0px 0 0 12.998px;
       align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    width: 260px;
    }
    .el-radio {
      margin-right: 5px;
    }
  }
  .Slider {
    display: inline-block;
    margin: 49.997px 39.994px 0 39.994px;
  }
}
.el-table{
  border: 0.998px solid rgba(218, 223, 231, 0.5);
}
article {
  width: calc(100% - 440px);
  min-width: 750px;
  height: 640px;
  // border: 0.998px solid rgba(218, 223, 231, 0.5);
  position: relative;
  .el-tabs {
  position: absolute;
  width: 100%;
  top: 22px;
  }
  .el-pagination {
  position: absolute;
  bottom: 24px;
  right: 50%;
  transform: translate(50%,0);
  }
  .tableTitle {
    position: absolute;
    right: 0;
    display: block;
    padding-top: 11.002px;
    z-index: 999;
    .MdmTitle {
    font-size: 25.997px;
    // font-family: "PingFang SC";
    font-weight: bold;
    color: #333333;
    display: block;
  }
  .tableCheckbox {
    margin-right: 20.006px;
  }
  .el-select {
    margin-right: 20.006px;
  }
  .inputSelect {
    width: 260px;
    margin-right: 0px;
  }
  }
}
.analyse {
  position: absolute;
  bottom: -70.003px;
  left: 50%;
  transform: translate(-50%, 0);
}
.submit {
  position: absolute;
  bottom: -70.003px;
  left: 50%;
  transform: translate(-50%, 0);
}

.PreBox {
  width: 100%;
  margin: 0 auto;
}

</style>