<template>
<div class="PersonalCenter">
   <div class="PersonalCenterBox">
      <div class="BoxTitle">
         <div
            class="tabsButton"
            :style="TabStyle('1')"
            @click="routePush('PersonalCenter')"
         >
            {{$t('PersonalCenter.tabs1')}}
         </div>
         <!-- <div
            class="tabsButton"
            :style="TabStyle('2')"
            @click="routePush('Phone')"
         >
            {{$t('PersonalCenter.tabs2')}}
         </div> -->
         <div
            class="tabsButton"
            :style="TabStyle('3')"
            @click="routePush('Password')"
         >
            {{$t('PersonalCenter.tabs3')}}
         </div>
         <div
            class="tabsButton"
            :style="TabStyle('4')"
            @click="routePush('Mail')"
         >
            {{$t('PersonalCenter.tabs4')}}
         </div>
         <div
            class="tabsButton"
            :style="TabStyle('5')"
            @click="routePush('Record')"
         >
            {{$t('PersonalCenter.tabs5')}}
         </div>
         <!-- <div
            class="tabsButton"
            :style="TabStyle('6')"
            @click="routePush('PurchaseHistory')"
         >
            {{$t('PersonalCenter.tabs6')}}
         </div> -->
      </div>
      <div class="BoxMain">
         <router-view />
      </div>
   </div>
</div>
</template>

<script>
export default {
   data() {
      return {};
   },
   methods: {
      routePush(res) {
         this.$router.push({
            name: res
         })
      },
      TabStyle(index) {
         if (this.$route.matched[2].meta.tabs == index) {
            return "background:#F6F7FB;"
         } else {
            return "background:#D5DCEC;"
         }
      }
   },
};
</script>

<style lang="scss" scoped>
.PersonalCenter {
   width: auto;
   min-width: 1002px;
   margin: 0 32px;
   padding-top: 20px;

   .PersonalCenterBox {
      height: 100%;

      .BoxTitle {
         height: 80px;
         background: #E1E4EB;
         text-align: left;
         padding: 44px 0 0 20px;
         display: flex;

         .tabsButton {
            // width: 84px;
            height: 36px;
            padding: 0 10px;
            box-sizing: border-box;
            text-align: center;
            background: #D5DCEC;
            border-radius: 2px 2px 0px 0px;
            display: inline-block;
            margin: 0 2px;
            font-size: 12px;
            font-weight: 400;
            color: #333333;
            line-height: 36px;
            cursor: pointer;
         }
      }

      .BoxMain {
         background: #F6F7FB;
         width: 100%;
         // height: calc(100% - 80px);

      }
   }
}
</style>
