<template>
<div class="PivotTable" ref="elmain">
   <div id="sheetTitleBox">
      <span class="sheetTitleBoxT l">{{ sheetTitleBoxT }}</span>
      <div class="sheetTitleBoxTag l">
         <el-tag
            v-for="item in rowNumber"
            :key="item._id"
            :closable="false"
            @close="handleClose(item)"
            style="margin: 0 3px; position: relative; top: 50%; transform: translateY(-50%)"
            type="">
            {{ item }}
         </el-tag>
         <el-tag
            v-for="item in Axis"
            :key="item._id"
            :closable="false"
            :disable-transitions="true"
            @close="handleCloseA(item)"
            style="margin: 0 3px; position: relative; top: 50%; transform: translateY(-50%)"
            type="">
            {{ item }}
         </el-tag>
         <span class="title" v-show="rowNumber == ''" v-if="Axis == ''">
            <!-- 请选择数据 -->
            {{ $t("DataPivot.Text") }}
         </span>
      </div>
      <el-select
         class="l"
         :disabled="ImgDisabled"
         @change="selectGet"
         style="width: 160px"
         v-model="imgList"
         :placeholder="$t('DataPivot.Select')">
         <el-option-group
            v-for="group in Select"
            :key="group.value"
            :label="group.label">
            <el-option
               v-for="item in group.Select"
               :key="item.value"
               :label="item.label"
               :disabled="item.disabled"
               :value="item.value" />
         </el-option-group>
      </el-select>
      <el-button
         class="r"
         :disabled="ImgDisabled"
         style="margin-right: 30px"
         type="primary"
         @click="goAutoML">
         <i class="iconfont iconicon_jiqizidonghua1" />
         <!-- 数据建模 -->
         {{ $t("DataPivot.button") }}
      </el-button>
   </div>
   <transition name="el-fade-in-linear">
      <div
         id="imgBox"
         v-show="imgBox"
         :style="`left:${x0};top:${y0};`">
         <span class="imgBoxMain">
            <div id="imgBoxTitle" @mousedown="move">
               {{ SelectTitle2 }}
               <i class="el-icon-error" @click="imgBox = false" />
            </div>
            <el-image
               style="width: 100%; height: auto"
               :src="imgData"
               :preview-src-list="imgData2" />
         </span>
      </div>
   </transition>
   <div
      @mouseup="rowData"
      v-loading="loading"
      :element-loading-text="$t('DataPivot.loading')"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 0.8)"
      id="luckysheet" />
</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import LuckyExcel from "luckyexcel";
export default {
   data() {
      return {
         canDrag: false,
         ImgDisabled: false,
         x0: "800px",
         y0: "100px",
         style: null,
         Select: [{
               label: this.$t("DataPivot.Select1"),
               Select: [{
                     value: "histplot",
                     label: this.$t("DataPivot.Select2"),
                  },
                  {
                     value: "kdeplot",
                     label: this.$t("DataPivot.Select4"),
                  },
                  {
                     value: "ecdfplot",
                     label: this.$t("DataPivot.Select5"),
                  },
               ],
            },
            {
               label: this.$t("DataPivot.Select7"),
               Select: [{
                     value: "boxplot",
                     label: this.$t("DataPivot.Select8"),
                  },
                  {
                     value: "violinplot",
                     label: this.$t("DataPivot.Select9"),
                  },
                  {
                     value: "barplot",
                     label: this.$t("DataPivot.Select10"),
                  },
               ],
            },
            {
               label: this.$t("DataPivot.Select12"),
               Select: [{
                  value: "regplot",
                  label: this.$t('xian-xing-hui-gui-tu'),
               }, ],
            },
            {
               label: this.$t('DataPivot.Select13'),
               Select: [{
                  value: "heatmap",
                  label: this.$t('DataPivot.Select14'),
               }, ],
            },
            {
               label: this.$t('lian-he-wang-ge-tu'),
               Select: [{
                  value: "jointplot",
                  label: this.$t("DataPivot.Select6"),
               }, ],
            },
            {
               label: this.$t('pei-dui-wang-ge-tu'),
               Select: [{
                  value: "pairplot",
                  label: this.$t("DataPivot.Select3"),
               }, ],
            },
            {
               label: this.$t('AutoML.MDM.DataPivot.ioza5t'),
               Select: [
               {
               value: "pcaplot",
               label: this.$t('AutoML.MDM.DataPivot.flkcmu'),
               },
               {
               value: "factorplot",
               label: this.$t('AutoML.MDM.DataPivot.qjm5z7'),
               },
               {
               value: "kmeansplot",
               label: this.$t('AutoML.MDM.DataPivot.nhiwtx'),
               },
               {
               value: "hierarchicalplot",
               label: this.$t('AutoML.MDM.DataPivot.zyrsup'),
               },
               ],
            },
         ],
         imgList: "",
         file: "xlsx",
         page: "",
         rowNumber: [],
         imgData: "",
         imgData2: [],
         imgBox: false,
         rowNumberData: [],
         SelectTitle: "",
         SelectTitle2: "",
         SelectTitleRow: "",
         inputTrue: true,
         sheetTitleBoxT: this.$t("DataPivot.sheetTitleBoxT"),
         Axis: [],
         AxisNumber: [],
         loading: true,
      };
   },
   destroyed() {
      window.luckysheet.destroy();
   },
   mounted() {
      this.page = this.$route.query.page;
      this.id = this.$route.query.id;
      console.log(this.page);
      let xlsx = `${window.apis.SERVER}${window.apis.SYS_DATASET_CELLDATA}?dataset_id=${this.id}`;
      this.LuckyExcelTo(xlsx);
   },
   watch: {
      // 控制加载隐藏
      ImgDisabled: function (val) {
         if (val) {
            this.loading = true;
            this.imgBox = false;
         } else {
            this.loading = false;
            this.imgBox = true;
         }
      },
      rowNumber: function (val) {
         let data = [];
         switch (val.length) {
            case 1:
               data = ["violinplot", "pairplot", "jointplot", "boxplot", "boxplot", "barplot", "regplot","pcaplot","factorplot","kmeansplot","hierarchicalplot"];
               this.SelectForFn(data);
               break;
            case 2:
               data = ["kdeplot", "ecdfplot"];
               this.SelectForFn(data);
               break;
            case 0:
               break;
            default:
               data = [ "kdeplot", "boxplot", "boxplot", "barplot", "regplot", "jointplot", "ecdfplot", "violinplot"];
               this.SelectForFn(data);
         }
      },
      rowNumberData: function (val) {
         let a = [];
         for (let key in val) {
            a.push(this.numberToA(val[key]));
         }
         this.rowNumber = a;
      },
      sheetTitleBoxT: function (val) {
         let data = [];
         if (val != this.$t("DataPivot.sheetTitleBoxT")) {
            data = ["regplot","pcaplot","factorplot","kmeansplot","hierarchicalplot"];
            this.SelectForFn(data);
         }
      },
   },
   methods: {
      goAutoML() {
         let url = window.apis.SYS_DATASET;
         let index = this.$route.query.index;
         this.axios.get(`${url}${this.$route.query.id}/`).then((res) => {
            console.log(res);
            this.$store.commit("dataset_id", res.data.id);
            this.$store.commit("dataset_name", res.data.name);
            this.$store.commit("task_type", res.data.task_type);
            this.$store.commit("target_column", res.data.target_column);
            this.$store.commit("desc", res.data.desc);
            this.$store.commit("read_mode_header", res.data.read_mode_header);
            let page = Math.floor(index / 10) + 1;
            let indexZ = index - Math.floor(index / 10) * 10;
            this.$router.push({
               name: "Analysis",
               params: {
                  index: indexZ,
                  user_id: res.data.user_id,
                  on: "true",
                  page: page,
               },
            });
         });
      },
      SelectForFn(val) {
         for (let key in this.Select) {
            for (let key2 in this.Select[key].Select) {
               this.Select[key].Select[key2].disabled = false;
               for (let key3 in val) {
                  if (val[key3] == this.Select[key].Select[key2].value) {
                     this.Select[key].Select[key2].disabled = true;
                  }
               }
            }
         }
      },
      selectGet(vId) {
         for (let key in this.Select) {
            this.Select[key].Select.forEach((item) => {
               if (item.value === vId) {
                  this.SelectTitle = item.label;
               }
            });
         }
         this.numToString();
      },
      move(e) {
         // let odiv = e.target;        //获取目标元素
         //阻止默认事件的方法,如果不阻止默认事件onmouseup会无法触发
         e.preventDefault();
         //算出鼠标相对元素的位置
         let imgBox = window.document.getElementById("imgBox");
         let div = document.getElementById("el-main");
         let disX = e.clientX - imgBox.offsetLeft;
         let disY = e.clientY - imgBox.offsetTop;
         let maxHeight = div.clientHeight - imgBox.offsetHeight;
         let maxWidth = div.clientWidth - imgBox.offsetWidth;
         document.onmousemove = (e) => {
            // let height= this.$refs.elmain.offsetHeight;
            //鼠标按下并移动的事件
            //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            //因为img居中对齐，所以还要减去目标元素距离body的偏移量
            let left = e.clientX - disX;
            let top = e.clientY - disY;
            //移动当前元素
            if (left > maxWidth) {
               left = maxWidth;
            }
            if (top > maxHeight) {
               top = maxHeight;
            }
            if (left < 0) {
               left = 0;
            }
            if (top < 0) {
               top = 0;
            }
            this.x0 = left + "px";
            this.y0 = top + "px";
         };
         document.onmouseup = () => {
            document.onmousemove = null;
            document.onmouseup = null;
         };
      },
      goBack() {
         this.$router.back(-1);
      },
      rowData() {
         let row = window.luckysheet.getRange();
         let Axis = window.luckysheet.getRangeAxis();
         let a = [];
         let data = [];
         let AxisData = [];
         // a = [...this.rowNumberData] 累加选中列
         for (let key in row) {
            if (row[key].column[0] == row[key].column[1]) {
               this.sheetTitleBoxT = this.$t("DataPivot.sheetTitleBoxT");
               this.Axis = [];
               a.push(row[key].column[0]);
               data = [...new Set(a)];
            } else {
               this.sheetTitleBoxT = this.$t("DataPivot.sheetTitleBoxT2");
               data = [];
               this.AxisNumber = row[0];
               AxisData.push(Axis[0]);
               this.Axis = AxisData;
            }
         }

         this.rowNumberData = data;
      },
      LuckyExcelTo(xlsx) {
         let _this = this;
         let exportJson = "";
         // eslint-disable-next-line no-unused-vars
         this.axios.get(xlsx).then((res) => {
            exportJson = res.data;
            window.luckysheet.destroy();
            window.luckysheet.create({
               container: "luckysheet", //luckysheet is the container id
               showinfobar: false,
               data: [exportJson],
               cellRightClickConfig: {
                  insertRow: false, // 插入行
                  insertColumn: false, // 插入列
                  deleteRow: false, // 删除选中行
                  deleteColumn: false, // 删除选中列
                  deleteCell: false, // 删除单元格
                  hideRow: false, // 隐藏选中行和显示选中行
                  hideColumn: false, // 隐藏选中列和显示选中列
                  rowHeight: false, // 行高
                  columnWidth: false, // 列宽
                  clear: false, // 清除内容
                  matrix: false, // 矩阵操作选区
                  sort: false, // 排序选区
                  filter: false, // 筛选选区
                  chart: false, // 图表生成
                  image: false, // 插入图片
                  link: false, // 插入链接
                  data: false, // 数据验证
                  cellFormat: false, // 设置单元格格式
               },
               lang: this.$t('config') == 'zh' ? 'zh' : 'en',
               hook: {
                  // eslint-disable-next-line no-unused-vars
                  cellRenderAfter: function (cell, postion, sheetFile, ctx) {
                     _this.loading = false;
                  },
               },
            });
         });
      },
      handleClose(tag) {
         this.rowNumber.splice(this.rowNumber.indexOf(tag), 1);
         let str = tag.toLowerCase().split("");
         let al = str.length;
         let getCharNumber = function (charx) {
            return charx.charCodeAt() - 96;
         };
         let numout = 0;
         let charnum = 0;
         for (let i = 0; i < al; i++) {
            charnum = getCharNumber(str[i]);
            numout += charnum * Math.pow(26, al - i - 1) - 1;
            this.rowNumberData.splice(this.rowNumberData.indexOf(numout), 1);
         }
      },
      handleCloseA(tag) {
         this.Axis.splice(this.Axis.indexOf(tag), 1);
      },
      numberToA(val) {
         let ordA = "A".charCodeAt(0);
         let ordZ = "Z".charCodeAt(0);
         let len = ordZ - ordA + 1;
         let s = "";
         while (val >= 0) {
            s = String.fromCharCode((val % len) + ordA) + s;
            val = Math.floor(val / len) - 1;
         }
         return s;
      },
      setArray(array) {
         // eslint-disable-next-line no-unused-vars
         var n = {},
            r = [],
            len = array.length,
            val,
            type;
         for (var i = 0; i < array.length; i++) {
            val = array[i];
            type = typeof val;
            if (!n[val]) {
               n[val] = [type];
               r.push(val);
            } else if (n[val].indexOf(type) < 0) {
               n[val].push(type);
               r.push(val);
            }
         }
         return r;
      },
      numToString() {
         if (this.sheetTitleBoxT == this.$t("DataPivot.sheetTitleBoxT")) {
            let data = {
               column1: ""
            }
            let array = []
            // 格式化数据XY

            data.flag = 1
            for (let key in this.rowNumberData) {
               array.push(this.rowNumberData[key])
            }
            data.column1 = array.toString()

            if (this.rowNumberData.length < 2) {
               if (this.imgList == 'pairplot' || this.imgList == 'heatmap' || this.imgList == 'histplot' ||
                  this.imgList == 'kdeplot' || this.imgList == 'ecdfplot') {
                  this.inputTrue = true;
                  this.postImg(data)
               }
            } else {
               this.postImg(data)
            }
         } else {
            let data = {}
            data.flag = 2;
            this.inputTrue = false;
            data.column1 = this.AxisNumber.column[0];
            data.column2 = this.AxisNumber.column[1];
            data.row1 = this.AxisNumber.row[0];
            data.row2 = this.AxisNumber.row[1];
            console.log(data);
            this.postImg(data);
         }
      },
      postImg(data) {
         if (this.imgList == "") {
            this.$message({
               message: this.$t("DataPivot.message"),
               // '请选择图表类型',
               type: "warning",
            });
         } else {
            let url = `${window.apis.SYS_DATASET_IMG}?dataset_id=${this.id}&chart=${this.imgList}`;
            // 点击获取图表修改标题名称
            console.log(this.rowNumber, data);
            // 加载数据禁止操作
            this.ImgDisabled = true;
            this.axios({
                  method: "post",
                  url: url,
                  responseType: "arraybuffer",
                  data: data
               })
               .then((res) => {
                  this.imgList = "";
                  if (res.status === 200 || res.status === 201) {
                     this.ImgDisabled = false;
                     this.SelectTitleRow = this.rowNumber;
                     this.SelectTitle2 = this.SelectTitle;
                     //将从后台获取的图片流进行转换
                     this.imgData = "data:image/png;base64," + btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
                     this.imgData2 = [];
                     this.imgData2.push(this.imgData);
                     this.imgBox = true;
                  } else {
                     this.imgBox = false;
                     let enc = new TextDecoder("utf-8");
                     let item = JSON.parse(enc.decode(new Uint8Array(res.data))); //转化成json对象
                     console.log(item);
                     this.$message({
                        message: this.$i18n.locale == 'zh' ? item.zh : item.en,
                        type: "warning",
                     });
                     this.imgData = null;
                     this.imgData2 = null;
                     this.ImgDisabled = false;
                     // this.postImgError(data,url)
                  }
               })
               .catch((res) => {
                  this.ImgDisabled = false;
               });
         }
      },

   },
};
</script>

<style lang="scss" scoped>
#imgBox {
   width: 361px;
   height: auto;
   position: absolute;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
   background: #ffffff;
   border-radius: 5px;
   z-index: 99999;

   .imgBoxMain {
      position: relative;
      width: 100%;
      height: 100%;

      #imgBoxTitle {
         position: relative;
         width: 100%;
         height: 30px;
         border-bottom: 1px solid rgba(218, 223, 231, 0.5);
         transition: all 300ms;
         cursor: move;

         p {
            display: inline-block;
         }

         i {
            position: absolute;
            top: 50%;
            right: 15px;
            color: #396dd6;
            transform: translate(0, -50%);
            font-size: 22px;
            cursor: pointer;
         }

         i:hover {
            color: #c4d3f3;
         }
      }

      #imgBoxTitle:hover {
         background: #f5f7fa;
      }

      #image {
         position: relative;
         right: 10px;
         bottom: 10px;
      }
   }
}

#sheetTitleBox {
   width: 100%;
   height: 500px;
   padding: 0 0 0 33px;
   margin: 10px 0;

   .sheetTitleBoxTag {
      float: left;
      width: auto;
      min-width: 160px;
      height: 40px;
      overflow: hidden;
      margin-right: 10px;
      padding: 0 10px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      text-align: left;

      .title {
         color: #d9dbe0;
         font-size: 14px;
         height: 40px;
         line-height: 40px;
      }
   }

   .sheetTitleBoxT {
      width: 73px;
      float: left;
      margin-right: 10px;
      font-size: 17px;
      line-height: 40px;
      font-weight: 400;
   }
}

#luckysheet {
   margin: 0px;
   padding: 0px;
   position: absolute;
   width: calc(100% - 62px);
   min-width: 960px;
   height: calc(100% - 5px);
   left: 31px;
   top: 105px;
   background: #ffffff;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.PivotTable {
   min-width: 960px;

   .PivotTablelTitle {
      text-align: left;
      height: 62px;
      line-height: 62px;
      color: #333333;
      font-size: 18px;
      font-weight: bold;
   }
}

h3 {
   margin: 40px 0 0;
}
</style>
