<template>
<div class="MyAccount">
   <div class="AccountTitle l">
      <ul>
         <!-- <li>{{$t('PersonalCenter.Account.Li1')}}</li> -->
         <li>{{$t('PersonalCenter.Account.Li2')}}</li>
         <li>{{$t('PersonalCenter.Account.Li3')}}</li>
      </ul>
   </div>
   <div class="AccountInput l">
      <!-- <el-input
         v-model="user.id"
         :placeholder="$t('PersonalCenter.Account.Input1')"
         :disabled="true"
      /> -->
      <el-input
         v-model="user.email"
         :placeholder="$t('PersonalCenter.Account.Input2')"
         :disabled="true"
      />
      <el-form
         ref="form"
         :rules="rulesUser"
         :model="user"
         status-icon
      >
         <el-form-item prop="username">
            <el-input v-model="user.username" :placeholder="$t('PersonalCenter.Account.Input3')" />
         </el-form-item>
      </el-form>

      <p>
         {{$t('PersonalCenter.Account.Span1')}}
         <span><a href="http://cn.gravatar.com/">Gravatar</a></span>
         {{$t('PersonalCenter.Account.Span3')}}
      </p>
      <el-image :src="`${headImgUrl}`" fit="fit" />
      <el-button type="primary" @click="saveUserInfoChange('form')">
         {{$t('PersonalCenter.Account.Button')}}
      </el-button>
   </div>
</div>
</template>

<script>
export default {
   data() {
      return {
         rulesUser: {
            username: [{
                  required: true,
                  message: this.$t('PersonalCenter.Mail.Message1'),
                  trigger: "blur"
               },
               {
                  min: 5,
                  max: 14,
                  message: this.$t('PersonalCenter.Mail.Message2'),
                  trigger: "blur"
               },
               {
                  pattern: /^[\u4E00-\u9FA5A-Za-z0-9]+$/,
                  message: this.$t('PersonalCenter.Mail.Message3'),
               },
            ],
         },
         user: {},
         headImgUrl: "",
         flag: "",
         sendEmail: true,
      };
   },
   mounted() {
      this.getHeadImg();
      this.getUserInfo();
   },
   methods: {
      getUserInfo() {
         let url = window.apis.SYS_USER_INFO;
         this.axios
            .get(url)
            .then((res) => {
               console.log(res);
               if (res.status === 200) {
                  this.user = res.data;
               }
            })
            .catch((error) => {
               console.log(error);
            });
      },
      getHeadImg() {
         let url = window.apis.USER_HEAD_IMG;
         this.axios
            .get(url)
            .then((res) => {
               if (res.status === 200) {
                  this.headImgUrl = res.data.email_avatar;
               }
            })
            .catch((error) => {
               console.log(error);
            });
      },
      saveUserInfoChange(formName) {
         // 校验合法性
         this.$refs[formName].validate((valid) => {
            if (valid) {
               let url = window.apis.SYS_USER;
               this.axios
                  .put(url + this.user.id + "/", {
                     username: this.user.username,
                  })
                  .then((res) => {
                     console.log(res);
                     if (res.status === 200) {
                        this.$message({
                           message: this.$t('PersonalCenter.Setting.message'),
                           type: "success",
                        });
                        this.user = res.data;
                        this.$store.commit("loadUser", res.data.username);
                     }
                  })
                  .catch((error) => {
                     console.log(error);
                  });
            } else {
               // 定位到错误的位置
               setTimeout(() => {
                  const isError = document.getElementsByClassName("is-error");
                  isError[0].querySelector("input").focus();
               }, 100);
               return false;
            }
         });
      },
   },
};
</script>

<style lang="scss" scoped>
.el-input.is-disabled .el-input__inner {
   background: #d5dcec;
}

.MyAccount {
   text-align: left;
   overflow: hidden;
   padding: 20px 0 30px 91px;

   .AccountTitle {
      // width: 120px;

      li {
         height: 40px;
         display: block;
         font-size: 15px;
         line-height: 40px;
         font-weight: 500;
         color: #333333;
         list-style: none;
         text-align: right;
         margin-right: 20px;
         margin-top: 19px;
         white-space: nowrap;
      }
   }

   .AccountInput {
      width: 280px;
      height: 100%;

      .el-input {
         margin-top: 17px;
      }

      p {
         margin-top: 17px;
         font-size: 15px;
         font-weight: 300;

         span {
            color: #0976b9;
            margin: 0 5px;
         }
      }

      .el-image {
         width: 80px;
         height: 80px;
         margin-top: 17px;
         margin-right: 100%;
      }

      .el-button {
         margin-top: 17px;
      }
   }
}

.el-switch {
   margin-top: 26px;
}

// .el-form {
//   .el-input {
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }
// }
</style>
