<template>
<div class="HomePage">
   <breadcrumb />
   <div class="homeBox">
      <!-- <h2 class="HomePageTitle">
      {{ $t('HomePage.y2vsee') }}
   </h2> -->
      <article>
         <div class="tableBox">
            <h5>
               {{ $t('HomePage.sejo5a') }}
               <el-tooltip
                  class="item"
                  effect="light"
                  placement="top">
                  <div slot="content" style="line-height: 20px;text-align: left;">
                     {{ $t('HomePage.ce9037') }}
                  </div>
                  <i class="iconfont iconbangzhu1" />
               </el-tooltip>
            </h5>
            <div class="ProgressBox">
               <div class="l ProgressTitleBox">
                  <span class="ProgressTitle">{{ $t('HomePage.02s92q') }}</span>
                  <span class="ProgressTitle">{{ $t('HomePage.gqp026') }}</span>
                  <span class="ProgressTitle">{{ $t('HomePage.5uzkho') }}</span>
               </div>
               <div class="r ProgressMainBox">
                  <!--            <span class="text">{{`总核数 ${metrics.cpu_cores_threshold} 核`}}</span>-->
                  <el-tooltip
                     class="item"
                     effect="light"
                     :content="`${metrics.used_cpu_cores}/${metrics.cpu_cores_threshold}${$t('HomePage.gfi2em') }`"
                     placement="top">
                     <el-progress
                        :text-inside="true"
                        :percentage="usedCountPercentage"
                        :stroke-width="20" />
                  </el-tooltip>
                  <el-tooltip
                     class="item"
                     effect="light"
                     :content="`${format.used_size} / ${format.size_threshold}`"
                     placement="top">
                     <el-progress
                        :text-inside="true"
                        :percentage="usedSizePercentage"
                        :stroke-width="20" />
                  </el-tooltip>
                  <el-tooltip
                     class="item"
                     effect="light"
                     :content="`${metrics.used_task} / ${metrics.task_threshold}${ $t('HomePage.w3rons') }`"
                     placement="top">
                     <el-progress
                        :text-inside="true"
                        :percentage="usedTaskPercentage"
                        :stroke-width="20" />
                  </el-tooltip>
               </div>
            </div>
         </div>
         <div class="tableBox">
            <div style="width:100%;height:30px">
               <h5 class="l">
                  {{ $t('HomePage.zyxog5') }}
                  <el-tooltip
                     class="item"
                     effect="light"
                     placement="top">
                     <div slot="content" style="line-height: 20px;text-align: left;">
                        {{ $t('HomePage.9ph58g') }}
                     </div>
                     <i class="iconfont iconbangzhu1" />
                  </el-tooltip>
               </h5>
               <el-button
                  size="mini"
                  type="primary"
                  @click="gotoCreate"
                  class="r"
                  round>
                  {{ $t('HomePage.vapuco') }}
               </el-button>
            </div>

            <div class="tableBoxMain">
               <el-table :data="models" size="mini">
                  <el-table-column
                     prop="task_name"
                     min-width="130"
                     :label="$t('HomePage.i4ea6v')" />
                  <el-table-column prop="task_type_cn" :label="$t('HomePage.2tf39b')" />
                  <el-table-column
                     prop="stage_cn"
                     min-width="129"
                     :label="$t('HomePage.0z9puc')" />
                  <el-table-column
                     prop="created"
                     min-width="125"
                     :label="$t('HomePage.ynbeia')">
                     <template slot-scope="scope">
                        {{ scope.row.created | fmtdate }}
                     </template>
                  </el-table-column>
                  <el-table-column :label="$t('HomePage.fkaadm')">
                     <template slot-scope="scope">
                        <el-button
                           @click="showDetail(scope.row)"
                           type="primary"
                           size="small">
                           {{ $t('HomePage.ccharh') }}
                        </el-button>
                     </template>
                  </el-table-column>
               </el-table>
               <!-- 分页 -->
               <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage"
                  :page-size="pageSize"
                  layout="total, prev, pager, next"
                  background
                  :total="count" />
            </div>
         </div>

      </article>
      <!-- <h2 class="HomePageTitle">
      留言空间
   </h2> -->
      <article>
         <div class="tableBox">
            <div style="width:100%;height:30px">
               <h5 class="l">
                  {{ $t('liu-yan-lie-biao') }}
                  <el-tooltip
                     class="item"
                     effect="light"
                     placement="top">
                     <div slot="content" style="line-height: 20px;text-align: left;">
                        {{ $t('cha-kan-liu-yan-zhuang-tai') }}
                     </div>
                     <i class="iconfont iconbangzhu1" />
                  </el-tooltip>
               </h5>
            </div>

            <div class="MessageBox">
               <el-table
                  :data="MessageList"
                  height="400"
                  size="mini">
                  <el-table-column
                     prop="question"
                     min-width="130"
                     :label="$t('liu-yan-nei-rong')" />
                  <el-table-column
                     prop="user_name"
                     min-width="130"
                     :label="$t('liu-yan-zhe')" />
                  <el-table-column
                     prop="status"
                     min-width="125"
                     :label="$t('hui-fu-zhuang-tai')">
                     <template slot-scope="scope">
                        {{ scope.row.status?$t('yi-hui-fu'):$t('wei-hui-fu') }}
                     </template>
                  </el-table-column>
                  <el-table-column
                     prop="created"
                     min-width="125"
                     :label="$t('HomePage.ynbeia')">
                     <template slot-scope="scope">
                        {{ scope.row.created | fmtdate }}
                     </template>
                  </el-table-column>
                  <el-table-column :label="$t('HomePage.fkaadm')">
                     <template slot-scope="scope">
                        <el-button
                           @click="DialogFn(scope.row.id)"
                           type="primary"
                           size="small">
                           {{ $t('HomePage.ccharh') }}
                        </el-button>
                     </template>
                  </el-table-column>
               </el-table>
            </div>
         </div>
         <div class="tableBox">
            <h5>
               {{ $t('liu-yan-ban') }}
               <el-tooltip
                  class="item"
                  effect="light"
                  placement="top">
                  <div slot="content" style="line-height: 20px;text-align: left;">
                     {{ $t('shu-ru-liu-yan-xin-xi') }}
                  </div>
                  <i class="iconfont iconbangzhu1" />
               </el-tooltip>
            </h5>
            <div class="ProgressBox">
               <el-form :model="question" ref="question">
                  <el-form-item prop="question" :rules="[
      { required: true, message: $t('nei-rong-bu-neng-wei-kong')},
    ]">
                     <el-input
                        type="textarea"
                        :rows="5"
                        v-model="question.question"
                        :placeholder="$t('shu-ru-liu-yan-xin-xi')"
                        autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item>
                     <el-button
                        class="r"
                        @click="sendMessage()"
                        type="primary">{{ $t('fa-song-liu-yan') }}</el-button>
                  </el-form-item>
               </el-form>
            </div>
         </div>

      </article>
   </div>
   <el-dialog
      :title="$t('liu-yan-xiang-qing')"
      :visible.sync="MessageDialog"
      width="400px">
      <el-card class="box-card">
         <div slot="header" class="clearfix">
            <span class="title">{{MessageDetails.user_name}}</span>
         </div>
         <div>
            <span class="time">{{MessageDetails.created | fmtdate }}</span><br />
            {{MessageDetails.question}}
         </div>
      </el-card>
      <el-card
         class="box-card"
         v-if="MessageDetails.response"
         style="margin-top:20px">
         <div slot="header" class="clearfix">
            <span class="title">{{ $t('ke-fu') }}</span>
         </div>
         <div>
            <span class="time">{{MessageDetails.response_time | fmtdate }}</span><br />
            {{MessageDetails.response}}
         </div>
      </el-card>
   </el-dialog>
</div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb"
export default {
   components: {
      Breadcrumb,
   },
   data() {
      return {
         models: [],
         metrics: {},
         arrMetrics: [],
         format: [],
         currentPage: 1, // 当前页
         pageSize: 10, // 页面大小
         count: 0,
         usedCountPercentage: 0,
         usedSizePercentage: 0,
         usedTaskPercentage: 0,
         question: {
            question: ""
         }, //留言板输入框
         MessageList: [], //留言板输入框
         MessageDialog: false, //留言板
         MessageDetails: "", //留言板
      }
   },
   watch: {
      metrics: function (val) {
         let data = []
         for (let key in val) {
            data[key] = this.formatData(val[key])
         }
         this.format = data
      }
   },
   mounted() {
      this.getModelList();
      this.getMetrics();
      this.getMessage();
      const timer = setInterval(() => {
         this.getModelList();
      }, 5000)
      this.$once('hook:beforeDestroy', () => {
         clearInterval(timer)
      })
   },
   methods: {
      textFn() {
         return `总核数${this.metrics.cpu_cores_threshold}核`;
      },
      formatData(v) {
         if (!v) return ''
         var num = 1024.00; //byte
         if (v < num)
            return v + " B";
         if (v < Math.pow(num, 2))
            return (v / num).toFixed(2) + " KiB"; //kb
         if (v < Math.pow(num, 3))
            return (v / Math.pow(num, 2)).toFixed(2) + " MiB"; //M
         if (v < Math.pow(num, 4))
            return (v / Math.pow(num, 3)).toFixed(2) + " GiB"; //G
         return (v / Math.pow(num, 4)).toFixed(2) + " TiB"; //T
      },
      getModelList(pageNum) {
         let url = window.apis.SYS_MODELS;
         // 判断是否传入页码
         if (typeof (pageNum) == "undefined" || pageNum == '' || pageNum == null) {
            console.log()
         } else {
            this.datasets = []
            this.currentPage = 1
         }
         let params = {
            page: this.currentPage,
            state: "-2,-1,1,2",
         };
         params['stage'] = '2,4';
         this.axios.get(url, {
               params: params
            })
            .then((res) => {
               if (res.status === 200 || res.status === 201) {
                  this.models = res.data.results;
                  this.count = res.data.count;
                  for (var key in this.models) {
                     switch (this.models[key].task_type) {
                        case 0:
                           this.models[key].task_type_cn = this.$t('HomePage.lrotdg');
                           break;
                        case 1:
                           this.models[key].task_type_cn = this.$t('HomePage.dswqlm');
                           break;
                        case 2:
                           this.models[key].task_type_cn = this.$t('HomePage.yfhrf1');
                           break;
                        case 3:
                           this.models[key].task_type_cn = this.$t('MdmHome.optionsLabel5');
                           break;
                     }
                     switch (this.models[key].stage) {
                        case 1:
                           this.models[key].stage_cn = this.$t('HomePage.ravgmp');
                           break;
                        case 2:
                           this.models[key].stage_cn = this.$t('HomePage.1hk6z8');
                           break;
                        case 3:
                           this.models[key].stage_cn = this.$t('HomePage.74gk3j');
                           break;
                        case 4:
                           this.models[key].stage_cn = this.$t('HomePage.cppa3a');
                           break;
                        case 5:
                           this.models[key].stage_cn = this.$t('HomePage.dx5p3l');
                           break;
                        case 6:
                           this.models[key].stage_cn = this.$t('HomePage.jsubug');
                           break;
                     }
                     switch (this.models[key].state) {
                        case -2:
                           this.models[key].stage_cn = this.models[key].stage_cn + " | " + this.$t('HomePage.uma1ev');
                           break;
                        case -1:
                           this.models[key].stage_cn = this.models[key].stage_cn + " | " + this.$t('HomePage.j20cda');
                           break;
                        case 0:
                           this.models[key].stage_cn = this.models[key].stage_cn + " | " + this.$t('HomePage.xb0pgf');
                           break;
                        case 1:
                           this.models[key].stage_cn = this.models[key].stage_cn + " | " + this.$t('HomePage.f8byqc');
                           break;
                        case 2:
                           this.models[key].stage_cn = this.models[key].stage_cn + " | " + this.$t('HomePage.kutvux');
                           break;
                     }
                  }
               } else {
                  console.log("未找到数据");
               }
            })
      },
      showDetail(row) {
         this.$store.commit('task_id', row.id);
         this.$store.commit('task_name', row.task_name);
         this.$store.commit('dataset_id', row.dataset_id);
         this.$store.commit('task_type', row.task_type);
         this.$store.commit('target_column', row.target_column);
         this.$store.commit("dataset_name", row.dataset_name);
         var routeName = '';
         switch (row.stage) {
            case 1:
               routeName = "Analysis";
               break;
            case 2:
               routeName = "Analysis";
               break;
            case 3:
               routeName = "Parameter";
               break;
            case 4:
               routeName = "Training";
               break;
            case 5:
               routeName = "Report";
               break;
            case 6:
               routeName = "Output";
               break;
         }
         this.$router
            .push({
               name: routeName,
            })
      },
      getMetrics() {
         let url = window.apis.SYS_METRICS;
         this.axios.get(url + "1/")
            .then((res) => {
               if (res.status === 200 || res.status === 201) {
                  this.metrics = res.data;
                  // 计算百分比
                  this.usedCountPercentage = (Math.round(this.metrics.used_cpu_cores / this.metrics.cpu_cores_threshold * 100));
                  this.usedSizePercentage = (Math.round(this.metrics.used_size / this.metrics.size_threshold * 100));
                  this.usedTaskPercentage = (Math.round(this.metrics.used_task / this.metrics.task_threshold * 100));
               } else {
                  this.$message({
                     message: this.$t('HomePage.message1'),
                     type: 'warning',
                  });
               }
            })
            .catch((error) => {
               console.log(error);
            });
      },
      getMessage() {
         let url = window.apis.MESSAGE_BOARD
         this.axios.get(url).then(res => {
            this.MessageList = res.data
         })
      },
      DialogFn(id) {
         let url = window.apis.MESSAGE_BOARD
         this.axios.get(url + id + '/').then(res => {
            this.MessageDialog = true
            this.MessageDetails = res.data
         })
      },
      sendMessage() {
         let url = window.apis.MESSAGE_BOARD
         this.$refs["question"].validate((valid) => {
            if (valid) {
               this.axios.post(url, {
                  question: this.question.question
               }).then(res => {
                  this.$message({
                     message: this.$t('fa-song'),
                     type: 'success'
                  });
                  this.$refs["question"].resetFields();

                  this.getMessage()
               })
            }
         })
      },
      gotoCreate() {
         // 清空vuex存储数据
         sessionStorage.clear();
         this.$store.commit('dataset_id', '');
         this.$store.commit('dataset_name', '');
         this.$store.commit('task_type', '');
         this.$store.commit('target_column', '');
         this.$store.commit('desc', '');
         this.$store.commit('read_mode_header', '');
         // 跳转
         this.$router
            .push({
               name: 'Auto',
            })
      },
      handleSizeChange(val) {
         console.log(`${this.$t('HomePage.6t8zob')} ${val} ${this.$t('HomePage.gxep98')}`);
      },
      handleCurrentChange(currentPage) {
         this.currentPage = currentPage;
         this.getModelList();
      },
      routePush(dest) {
         if (this.$router.history.current.name != dest) {
            this.$router
               .push({
                  name: dest
               })
         }
      },
   }
}
</script>

<style lang="scss" scoped>
::v-deep .el-progress-bar__innerText {
   color: #000000;
}

.HomePage {
   min-width: 1185px;
}

.box-card {
   text-align: left;

   .time {
      font-size: 13px;
      color: #999999;
   }
}

section {
   min-width: 860px;
   overflow: hidden;
   margin: -20px 11px 0 0.6%;
}

.HomePageTitle {
   text-align: left;
   height: 62px;
   line-height: 62px;
   color: #333333;
   padding-left: 31px;
   font-size: 18px;
   font-weight: bold;
}

.homeBox {
   padding: 0 33px;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: space-between;
}

article {
   width: 48%;

   overflow: hidden;
   display: flex;
   flex-direction: column;
}

.tableBox {
   position: relative;
   margin-top: 30px;

   .tableBoxMain {
      height: 580px;
      overflow: hidden;
      border: 1px solid rgba(218, 223, 231, 0.5);
   }

   h5 {
      height: 16px;
      margin-bottom: 14px;
      line-height: 16px;
      font-size: 16px;
      font-weight: bold;
      color: #4E4F54;
      text-align: left;
   }

   .el-pagination {
      position: absolute;
      overflow: hidden;
      bottom: 30px;
      right: 50%;
      transform: translate(50%, 0);
   }
}

.tableBox {
   .ProgressBox {
      height: 230px;
      padding: 31px;
      overflow: hidden;
      border: 1px solid rgba(218, 223, 231, 0.5);

      .ProgressTitleBox {
         width: 20%;

         .ProgressTitle {
            display: block;
            height: 20px;
            font-size: 14px;
            text-align: right;
            margin-top: 20px;
         }
      }

      .ProgressMainBox {
         width: 77%;

         .text {
            display: block;
            height: 20px;
            text-align: left;
            font-size: 14px;
            margin-top: 20px;
         }

         .el-progress {
            margin-top: 20px;
         }
      }
   }

   .MessageBox {
      height: 400px;
      overflow: hidden;
      border: 1px solid rgba(218, 223, 231, 0.5);

      .ProgressTitleBox {
         width: 20%;

         .ProgressTitle {
            display: block;
            height: 20px;
            font-size: 14px;
            text-align: right;
            margin-top: 20px;
         }
      }

      .ProgressMainBox {
         width: 77%;

         .text {
            display: block;
            height: 20px;
            text-align: left;
            font-size: 14px;
            margin-top: 20px;
         }

         .el-progress {
            margin-top: 20px;
         }
      }
   }

}

.Htitle {
   text-align: left;
   font-size: 18px;
   font-weight: 500;
   color: #333333;
   position: absolute;
   top: -45px;
}

.buttonBox {
   width: 22.8%;
   min-width: 265px;
   height: 200px;
   border: 1px solid rgba(218, 223, 231, 0.5);
   float: left;
   margin: 1.1% 1.1%;
   overflow: hidden;
   text-align: left;
   padding: 39px 0;

   .BoxMain {
      max-width: 300px;
      min-width: 260px;
      overflow: hidden;
      padding: 0 20px;
      margin: 0 auto;

      .buttonBoxIcon {
         width: 49px;
         height: 49px;
         border: 1px solid rgba(239, 239, 239, 1);
         border-radius: 50%;
         display: block;
         position: relative;
         float: left;

         i {
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 29px;
            transform: translate(-50%, -50%);
            color: rgba(40, 96, 211, 1);
         }
      }

      .buttonBoxMain {
         display: block;
         width: 160px;
         text-align: left;
         float: right;

         h4 {
            font-size: 18px;
            font-weight: bold;
            color: #333333;
         }

         span {
            display: block;
            font-size: 14px;
            font-weight: 500;
            color: #666666;
            margin-top: 18px;
         }

         .el-button {
            margin-top: 28px;
         }
      }
   }
}

.buttonBoxNew {
   width: 22.8%;
   min-width: 265px;
   height: 200px;
   border: 1px solid rgba(218, 223, 231, 0.5);
   float: left;
   overflow: hidden;
   text-align: left;
   padding: 75px 0 0 0;
   margin: 1.1% 1.1%;

   .BoxNewMain {
      width: 97px;
      margin: 0 auto;
      overflow: hidden;

      .buttonBoxIcon {
         width: 49px;
         height: 49px;
         border: 1px solid rgba(239, 239, 239, 1);
         border-radius: 50%;
         float: left;
         position: relative;

         i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
         }
      }

      span {
         float: left;
         font-size: 18px;
         font-weight: bold;
         color: #333333;
         line-height: 48px;
         margin-left: 12px;
         overflow: hidden;
         cursor: pointer;
      }
   }
}
</style>
