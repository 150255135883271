<template>
  <div
    class="Auto"
    size="default"
  >
    <breadcrumb />
    <div class="main">
      <header>
        <div class="flowChart">
          <div class="flowChartBox">
            <span><img
              class="flowChartIcon"
              src="../../../assets/img/abt.png"
              alt=""
            ></span>
            <h6>
              <!-- 选择数据集  -->
              {{ $t("Auto.List1") }}
              <el-tooltip
                class="item"
                effect="light"
                placement="top"
              >
                <div
                  slot="content"
                  style="line-height: 20px;"
                >
                  <!-- 从用户自有或者系统内置数据中选择需要分析的数据集。 -->
                  {{ $t("Auto.ListContent1") }}
                </div>
                <i class="iconfont iconbangzhu1" />
              </el-tooltip>
            </h6>
          </div>
          <div
            class="flowChartT"
            :style="computedStyle('2')"
          />
          <div class="flowChartBox">
            <span
              :style="computedStyle('2')"
            ><img
              class="flowChartIcon"
              src="../../../assets/img/Tsnr.png"
              alt=""
            ></span>
            <h6>
              <!-- 特征分析  -->
              {{ $t("Auto.List2") }}
              <el-tooltip
                class="item"
                effect="light"
                placement="top"
              >
                <div
                  slot="content"
                  style="width:300px;line-height: 20px;text-align: justify;"
                >
                  <!-- 根据用户设置的缺失值阈值、共线性阈值以及累积重要性阈值，使用机器学习算法自动选择重要特征。 -->
                {{ $t("Auto.ListContent2") }}
                </div>
                <i class="iconfont iconbangzhu1" />
              </el-tooltip>
            </h6>
          </div>
          <div
            class="flowChartT"
            :style="computedStyle('3')"
          />
          <div class="flowChartBox">
            <span
              :style="computedStyle('3')"
            ><img
              class="flowChartIcon"
              src="../../../assets/img/snrye.png"
              alt=""
            ></span>
            <h6>
              <!-- 数据分析  -->
              {{ $t("Auto.List3") }}
              <el-tooltip
                class="item"
                effect="light"
                content=""
                placement="top"
              >
                <div
                  slot="content"
                  style="width:311px;line-height: 20px;text-align: justify;"
                >
                  <!-- 展示选择的重要特征。可通过点击“生成图表”按钮，查看每一列数据的分布。图表下方展示了详细的特征分析报告。 -->
                {{ $t("Auto.ListContent3") }}
                </div>
                <i class="iconfont iconbangzhu1" />
              </el-tooltip>
            </h6>
          </div>
          <div
            class="flowChartT"
            :style="computedStyle('4')"
          />
          <div class="flowChartBox">
            <span
              :style="computedStyle('4')"
            ><img
              class="flowChartIcon"
              src="../../../assets/img/dsfwe.png"
              alt=""
            ></span>
            <h6>
              <!-- 模型训练  -->
              {{ $t("Auto.List4") }}
              <el-tooltip
                class="item"
                effect="light"
                content=""
                placement="top"
              >
                <div
                  slot="content"
                  style="width:300px;line-height: 20px;text-align: justify;"
                >
                  <!-- 设定模型的参数并训练模型。模型参数包含默认和自定义两种。针对机器学习背景较少的用户，可以直接选择默认参数，也可以根据实际需求自定义参数。 -->
                {{ $t("Auto.ListContent4") }}
                </div>
                <i class="iconfont iconbangzhu1" />
              </el-tooltip>
            </h6>
          </div>
          <div
            class="flowChartT"
            :style="computedStyle('5')"
          />
          <div class="flowChartBox">
            <span
              :style="computedStyle('5')"
            ><img
              class="flowChartIcon"
              src="../../../assets/img/eqwe.png"
              alt=""
            ></span>
            <h6>
              <!-- 报告  -->
              {{ $t("Auto.List5") }}
              <el-tooltip
                class="item"
                effect="light"
                placement="top"
              >
                <div
                  slot="content"
                  style="width:300px;line-height: 20px;text-align: justify;"
                >
                  <!-- 报告包含特征分析和模型训练结果两部分。特征分析报告对应“特征分析”输出的结果，模型训练报告对应“模型训练”的输出结果，默认输出选择的最优的TOP10模型。可通过“下载PDF”生成详细的PDF报告文档。 -->
                {{ $t("Auto.ListContent5") }}
                </div>
                <i class="iconfont iconbangzhu1" />
              </el-tooltip>
            </h6>
          </div>
          <div
            class="flowChartT"
            :style="computedStyle('6')"
          />
          <div class="flowChartBox">
            <span
              :style="computedStyle('6')"
            ><img
              class="flowChartIcon"
              src="../../../assets/img/asdas.png"
              alt=""
            ></span>
            <h6>
              <!-- 输出 -->
              {{ $t("Auto.List6") }}
              <el-tooltip
                class="item"
                effect="light"
                content=""
                placement="top"
              >
                <div
                  slot="content"
                  style="width:300px;line-height: 20px;text-align: justify;"
                >
                  <!-- 本次任务输出的所有模型列表，用户可以选择对应的记录，下载对应的模型源代码、PDF分析报告等。 -->
                {{ $t("Auto.ListContent6") }}
                </div>
                <i class="iconfont iconbangzhu1" />
              </el-tooltip>
            </h6>
          </div>
          <div
            class="flowChartT"
            :style="computedStyle('7')"
          />
          <div class="flowChartBox">
            <span
              :style="computedStyle('7')"
            ><img
              class="flowChartIcon"
              src="../../../assets/img/yuce.png"
              alt=""
            ></span>
            <h6>
              <!-- 模型预测  -->
              {{ $t("Auto.List7") }}
              <el-tooltip
                class="item"
                effect="light"
                content=""
                placement="top"
              >
                <div
                  slot="content"
                  style="width:280px;line-height: 20px;text-align: justify;"
                >
                  <!-- 上传包含训练时输入的特征，调用生成的模型进行预测。若上传的文件中包含目标列，对于分类问题会展示预测结果生成的混淆矩阵，对于回归问题会展示预测的曲线图。 -->
                {{ $t("Auto.ListContent7") }}
                </div>
                <i class="iconfont iconbangzhu1" />
              </el-tooltip>
            </h6>
          </div>
        </div>
      </header>
      <div class="routerTransition">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../../components/Breadcrumb";
export default {
  data() {
    return {
      active: 1,
      transitionName: "",
    };
  },
  components: {
    Breadcrumb,
  },
  watch: {
    $route(to, from) {
      if (to.meta.active > from.meta.active) {
        this.transitionName = "slide-left";
      } else {
        this.transitionName = "slide-right";
      }
    },
  },
  created() {
    this.active = JSON.parse(this.$route.matched[2].meta.active);
  },
  updated() {
    this.active = JSON.parse(this.$route.matched[2].meta.active);
  },
  computed: {
    computedStyle() {
      return function (type) {
        return {
          background:
            type <= this.active
              ? "rgba(57, 109, 214, 0.5)"
              : "rgba(204, 204, 204, 1)",
        };
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: auto;
  min-width: 1165px;
  margin: 0 33px;
}
header {
  width: auto;
  min-width: 900px;
  height: auto;
  // border: 1px solid rgba(218, 223, 231, 0.5);
  margin: 5px auto;
  margin-bottom: 0px;
  position: relative;
  // box-sizing: border-box;
  .flowChart {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    // margin-top: 15px;
    .flowChartBox {
      display: inline-block;
      // overflow: hidden;
      // margin-bottom: 10px;
      .flowChartIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      span {
        display: inline-block;
        width: 90px;
        height: 90px;
        background: rgba(57, 109, 214, 0.5);
        border-radius: 50%;
        // overflow: hidden;
        position: relative;
        img {
          width: 53px;
        }
      }
      h6 {
      //   width: 100px;
      white-space: nowrap;
        margin-top: 5px;
        font-size: 16px;
        font-weight: 500;
        color: #4568ee;
      }
    }
    .flowChartT {
      display: inline-block;
      width: 130px;
      height: 3px;
      background: rgba(57, 109, 214, 0.5);
      margin: 0 30px 40px 30px;
      overflow: hidden;
    }
  }
}
</style>