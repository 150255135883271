<template>
<div id="app">
   <router-view v-if="isRouterAlive" v-wechat-title="$t($route.meta.title)+ ' | ' +$t('global.ORG_NAME')" />
</div>
</template>

<script>
export default {
   provide() {
      return {
         reload: this.reload
      }
   },
   data() {
      return {
         isRouterAlive: true
      }
   },
   mounted() {
      this.token();
      // document.getElementsByTagName('html')[0].setAttribute('version', process.env.MY_VERSION)
   },
   created() {
      this.i18n();
      this.vuex();
   },
   methods: {
      reload() {
         this.isRouterAlive = false;
         this.$nextTick(() => {
            this.isRouterAlive = true;
         });
      },
      token() {
         if (localStorage.token) {
            //判断token是否存在
            this.$store.commit("loadLogined", true);
            this.$Privilage.GIT_IOFN()
         }
      },
      i18n() {
         if (localStorage.i18n) {
            //判断i18n是否存在
            switch (localStorage.i18n) {
               case 'zh':
                  this.$i18n.locale = 'zh';
                  break;
               case 'en':
                  this.$i18n.locale = 'en';
                  break;
               default:
                  return;
                  break;
            }
         }
      },
      vuex() {
         this.$store.commit("task_id", sessionStorage.getItem("task_id"));
         this.$store.commit("task_id_DL", sessionStorage.getItem("task_id_DL"));
         this.$store.commit("task_name", sessionStorage.getItem("task_name"));
         this.$store.commit("dataset_id", sessionStorage.getItem("dataset_id"));
         this.$store.commit("dataset_id_DL", sessionStorage.getItem("dataset_id_DL"));
         this.$store.commit("stage", sessionStorage.getItem("stage"));
         this.$store.commit(
            "dataset_name",
            sessionStorage.getItem("dataset_name")
         );
         this.$store.commit("task_type", sessionStorage.getItem("task_type"));
         this.$store.commit(
            "target_column",
            sessionStorage.getItem("target_column")
         );
         this.$store.commit("DownloadSRC", sessionStorage.getItem("DownloadSRC"));
         this.$store.commit("desc", sessionStorage.getItem("desc"));
         this.$store.commit("loadUser", localStorage.getItem("user"));
      },
   },
   components: {}
};
</script>

<style lang="scss">
.icon {
   width: 1em;
   height: 1em;
   vertical-align: -0.15em;
   fill: currentColor;
   overflow: hidden;
}

.mzindex {
   z-index: 3000 !important;
}

* {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
   border: 0;
   font-family: "Times New Roman", "PingFang SC";
}

span,
i,
p {
   padding: 0;
   margin: 0;
}

.l {
   float: left;
}

.r {
   float: right;
}

// img,
// span,
// p,
// li,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -khtml-user-select: none;
//   -ms-user-select: none;
// }
#app {
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-align: center;
   color: #2c3e50;
}

.el-image-viewer__btn {
   .el-icon-circle-close {
      background: #ffffff;
      border-radius: 50%;
   }
}

#nav {
   padding: 30px;

   a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
         color: #42b983;
      }
   }
}
</style>
