<template>
<div class="Record">
   <el-table
      :data="loginRecord"
      style="width: 100%"
      height="400"
   >
      <el-table-column prop="created" :label="$t('PersonalCenter.History.table1')">
         <template slot-scope="scope">
            {{ scope.row.created | fmtdate }}
         </template>
      </el-table-column>
      <el-table-column prop="description_cn" :label="$t('PersonalCenter.History.table2')" />
      <el-table-column prop="duration" :label="$t('PersonalCenter.History.table3')">
         <template slot-scope="scope">
            {{ `${scope.row.duration}${$t('yue')}` }}
         </template>
      </el-table-column>
      <el-table-column prop="unit_price" :label="$t('PersonalCenter.History.table4')">
         <template slot-scope="scope">
            {{ `￥${scope.row.unit_price / 100}` }}
         </template>
      </el-table-column>
      <el-table-column prop="pay_channel_cn" :label="$t('PersonalCenter.History.table5')" />
      <el-table-column prop="is_pay_cn" :label="$t('PersonalCenter.History.table6')" />
   </el-table>
</div>
</template>

<script>
export default {
   data() {
      return {
         loginRecord: []
      }
   },
   mounted() {
      this.getLoginRecord();
   },
   methods: {
      getLoginRecord() {
         this.axios.get(window.apis.VIP_ORDER).then((res) => {
            console.log(res)
            this.loginRecord = res.data.results
            for (let key in this.loginRecord) {
               switch (this.loginRecord[key].pay_channel) {
                  case 'alipay_qr':
                     this.loginRecord[key].pay_channel_cn = this.$t('PersonalCenter.History.payType1');
                     break;
                  case 'wx_pub_qr':
                     this.loginRecord[key].pay_channel_cn = this.$t('PersonalCenter.History.payType2');
                     break;
               }
               switch (this.loginRecord[key].is_pay) {
                  case 0:
                     this.loginRecord[key].is_pay_cn = this.$t('PersonalCenter.History.pay1');
                     break;
                  case 1:
                     this.loginRecord[key].is_pay_cn = this.$t('PersonalCenter.History.pay2');
                     break;
               }
               switch (this.loginRecord[key].description) {
                  case '钻石版会员':
                     this.loginRecord[key].description_cn = this.$t('VIP2.Dialog.vipType2')
                     break;
                  case '至尊版会员':
                     this.loginRecord[key].description_cn = this.$t('VIP2.Dialog.vipType3')
                     break;
                  case '标准版会员':
                     this.loginRecord[key].description_cn = this.$t('VIP2.Dialog.vipType1')
                     break;
               }
            }
         })
      },
   }
}
</script>

<style lang="scss" scoped>
.Record {
   overflow: hidden;
   text-align: left;
   padding: 20px 30px 30px 30px;
   // height: 100%;  
}
</style>
