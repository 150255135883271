<template>
<div class="breadcrumb">
   <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
         v-for="(item,index) in breadList"
         :key="index"
         :to="{ path: item.path }"
         @click="routePush(item.path)"
      >{{$t(item.bar)}}</el-breadcrumb-item>
   </el-breadcrumb>
</div>
</template>

<script>
export default {
   name: "Breadcrumb",
   data() {
      return {
         breadList: [] // 路由集合
      };
   },
   watch: {
      $route() {
         this.getBreadcrumb();
      }
   },
   methods: {
      isHome(route) {
         return route.name === "";
      },
      getBreadcrumb() {
         let matched = this.$route.matched;
         let arr = []
         //如果不是首页
         if (!this.isHome(matched[0])) {
            matched = [{
               path: "/",
               meta: {
                  message: this.$t('router.Parameter')
               }
            }].concat(matched);
         }
         for (let item of matched) {
            arr.push({
               bar: item.meta.bar,
               path: item.path
            })
         }
         let result = [];
         let obj = {};
         for (var i = 0; i < arr.length; i++) {
            if (!obj[arr[i].bar]) {
               result.push(arr[i]);
               obj[arr[i].bar] = true;
            }
         }
         this.breadList = result;
      },
      routePush(dest) {
         if (this.$router.history.current.name != dest) {
            this.$router
               .push({
                  name: dest
               })
         }
      }
   },
   created() {
      this.getBreadcrumb();
   }
};
</script>

<style lang="scss" scoped>
.breadcrumb {
   width: auto;
   min-width: 960px;
   padding: 13px 33px;
   // border-bottom: 1px solid rgba(218, 223, 231, 0.5);;
}

.el-breadcrumb {
   font-size: 16px;

   .el-breadcrumb-item {
      font-size: 16px;
   }
}

.el-bread-one>>>.el-breadcrumb__inne {
   color: #4568ee;
}
</style>
