<template>
<div class="workOrder">
   <AutomlHeader />
   <breadcrumb />
   <div class="top">
      <span class="l">我的工单</span>
      <p class="r">
         <el-button
            id="MdmTitlebutton"
            class="r"
            @click="UploadingBoxTrue()"
            size="small"
            type="primary"
         >
            <i class="iconfont iconxinjian" id="jia" />
            &nbsp;
            创建工单
         </el-button>
         <el-input
            placeholder="请输入工单摘要"
            clearable
            class="inputSelect l"
         >
            <el-button
               slot="append"
               icon="el-icon-search"
               @click="getDatasetList('1')"
            />
         </el-input>

      </p>
   </div>
   <div class="MainBox">
      <el-table style="width: 100%">
         <el-table-column
            prop="date"
            label="日期"
            width="180"
         >
         </el-table-column>
         <el-table-column
            prop="name"
            label="姓名"
            width="180"
         >
         </el-table-column>
         <el-table-column prop="address" label="地址">
         </el-table-column>
      </el-table>
   </div>
   <el-pagination
      background
      layout="prev, pager, next"
      :total="1000"
   >
   </el-pagination>
   <div class="question">
      <p class="title">
         <span>热门问题</span>
      </p>
      <div class="document">
         <ul class="ulBox">
            <li>1222</li>
            <li>1222</li>
            <li>1222</li>
            <li>1222</li>
         </ul>
      </div>
   </div>
   <Footer />
</div>
</template>

<script>
import AutomlHeader from '../../components/AutomlHeader'
import Breadcrumb from "../../components/Breadcrumb"
import Footer from "../../components/Footer"
export default {
   components: {
      AutomlHeader,
      Breadcrumb,
      Footer
   }
}
</script>

<style lang="scss" scoped>
.workOrder {
   .top {
      max-width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      p {
         display: block;
         width: 380px;
         overflow: hidden;

         .inputSelect {
            width: 256px;
         }

         #MdmTitlebutton {
            width: 108px;
         }
      }
   }

   .MainBox {
      max-width: 1200px;
      margin: 0 auto;
      border: 1px solid #DADFE7;
      overflow: hidden;
   }

   .question {
      .title {
         width: 1200px;
         margin: 0 auto;
         text-align: left;
      }

      .document {
         width: 1200px;
         margin: 0 auto;
         padding-left: 15px;

         .ulBox {
            list-style-position: disc;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;

            li {
               width: 50%;
               list-style-position: disc;
               text-align: left;
               font-size: 15px;
               font-weight: 500;
               line-height: 17px;
               cursor: pointer;
            }

            li:hover {
               color: #396DD6;
            }
         }
      }
   }
}
</style>
