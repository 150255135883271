<template>
  <div class="home">
    <header>
      <div class="headerBox">
        <div
          class="l logo"
          :class="this.$GlobalVal.ORG_LOGO"
          @click="routePush('Home')"
        />

        <!-- <span class="headerTitle l" @click="routePush('Home')">{{ $t('global.ORG_NAME') }}</span> -->
                <!-- <span class="headerTitle l">{{this.$GlobalVal.ORG_NAME}}</span> -->

      </div>
    </header>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "Home",
  data(){
    return{
      headImgUrl:"",
    }
  },
  methods: {
    errorHandler() {
        return true
      },
    routePush(name) {
      this.$router.push({
        name: name,
      });
    },
    getHeadImg() {
      if(this.Logined){
      let url = window.apis.USER_HEAD_IMG;
      this.axios.get(url)
      .then((res) => {
        if (res.status === 200) {
          this.headImgUrl = res.data.email_avatar;
        } 
      })
      .catch((error) => {
        console.log(error);
      });}
    },
    elDropdownClick(command){
      switch(command){
        case 'logOut':
        localStorage.clear();
        this.$store.commit('loadLogined', false);
        break;
        default:
          this.$router.push({
            name: command,
          });
      }
    },
    clearForm(formName) {
      this.$refs[formName].resetFields();
    },
    headerClick(formName){
      this.$store.commit(formName, true)
    },
  },
  mounted(){
    this.getHeadImg();
  },
  computed :{
    ...mapState(['Logined'])
  },
};
</script>

<style lang="scss" scoped>

header {
  height: 90px;
  width: 100%;
  background: #4568ee;
  .headerBox {
    width: 960px;
    height: 90px;
    margin: 0 auto;
    background: #4568ee;
    .logo {
      font-size: 42px;
      line-height: 90px;
      color: #FFFFFF;
      cursor: pointer;
    }
    .headerMeun {
      font-size: 18px;
      // font-family: "PingFang SC";
      font-weight: 300;
      line-height: 90px;
      color: #ffffff;
      cursor: pointer;
    }
    .headerMeun:hover {
      color: rgba(254, 191, 0, 1);
      transition: all 200ms linear;
    }
    .headerTitle {
      font-size: 26px;
      // font-family: "PingFang SC";
      font-weight: bold;
      color: #ffffff;
      line-height: 90px;
      margin-left: 16px;
      cursor: pointer;
    }
    .el-avatar {
      margin-top: 25px;
    }

  }
}




</style>