<template>
<div class="Model">
   <!-- <breadcrumb /> -->
   <div style="height:43px;width:100%"></div>
   <div class="ModelTitleBox">
      <h3 class="ModelTitle l">
         {{$t('MdmHome.title')}}
         <!-- <el-tooltip
            class="item"
            effect="light"
            :content="$t('HomePage.9ph58g')"
            placement="top">
            <i class="iconfont iconbangzhu1"></i>
         </el-tooltip> -->
      </h3>
      <el-button
         class="r"
         @click="Uploadingdata=true"
         type="primary">
         <!-- 创建数据集 -->
         {{$t('MdmHome.button')}}
      </el-button>
      <el-input
         :placeholder="$t('AutoDL.DataSet.jbxyqw') "
         clearable
         v-model="name"
         @keyup.enter.native="getModelList"
         class="inputSelect r">
         <el-button
            slot="append"
            icon="el-icon-search"
            @click="getModelList()" />
      </el-input>
      <el-radio-group
         v-model="built_in"
         class="r"
         @change="getModelList()">
         <el-radio-button label="1">{{ $t('MdmHome.tabs2') }}</el-radio-button>
         <el-radio-button label="">{{ $t('MdmHome.tabs1') }}</el-radio-button>
      </el-radio-group>

   </div>
   <div class="ModelBox">
      <el-table
         :data="models"
         size="mini"
         width="100%"
         :header-cell-style="{background:'#F5F7FA',color:'#909399'}">
         <el-table-column prop="name" :label="$t('Analysis.title')">
            <template slot-scope="scope">
               {{scope.row.name }}
               <el-progress
                  :text-inside="true"
                  :stroke-width="15"
                  style="width:150px;display: inline-block;"
                  v-if="scope.row.upload_amount<scope.row.amount"
                  :percentage="Math.floor(scope.row.upload_amount/scope.row.amount*100)"></el-progress>
               <span v-if="scope.row.upload_amount<scope.row.amount">{{ $t('AutoDL.DataSet.5w0c7g') }}</span>
               <span v-if="scope.row.status_code<0" style="color: #f00;">{{ $t('AutoDL.DataSet.qtq7yb') }}</span>
            </template>
         </el-table-column>
         <el-table-column prop="size" :label="$t('HomePage.fsize')">
            <template slot-scope="scope">
               {{scope.row.size|byteChange}}
            </template>
         </el-table-column>
         <el-table-column prop="created" :label="$t('HomePage.ynbeia')">
            <template slot-scope="scope">
               {{scope.row.created | fmtdate}}
            </template>
         </el-table-column>
         <el-table-column :label="$t('pretreatment.table6')" :width="built_in==''?300:210">
            <template slot-scope="scope">
               <el-button
                  @click="showDetail(scope.row)"
                  type="primary"
                  size="small"
                  :disabled='scope.row.upload_amount<scope.row.amount || scope.row.status_code<0'
                  style="margin-right:10px"
                  plain>
                  {{$t('HomePage.ccharh')}}
               </el-button>
               <el-button
                  @click="modellingDetail(scope.row)"
                  type="primary"
                  size="small"
                  :disabled='scope.row.upload_amount<scope.row.amount || scope.row.status_code<0'
                  style="margin-right:10px"
                  plain>
                  {{ $t('HomePage.jian-mo') }}
               </el-button>
               <el-button
                  v-if="built_in==''"
                  @click="handleDelete(scope.row.id)"
                  type="primary"
                  size="small"
                  style="margin-right:10px"
                  :disabled='scope.row.upload_amount<scope.row.amount'
                  plain>
                  {{ $t('shan-chu') }}
               </el-button>
            </template>
         </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
         @current-change="handleCurrentChange"
         :current-page.sync="currentPage"
         :page-size="pageSize"
         layout="total, prev, pager, next"
         background
         :total="count" />
   </div>
   <div class="Uploading">
      <el-dialog
         :visible.sync="Uploadingdata"
         width="410px"
         class="UploadingBox"
         center
         :close-on-click-modal="false">
         <h3 slot="title" class="UploadingTitle">
            <!-- 创建数据集 -->
            {{$t('Uploading.h3')}}
         </h3>
         <el-upload
            class="upload"
            ref="upload"
            drag
            :headers="headers"
            :data="upData"
            accept="application/zip,application/x-zip,application/x-zip-compressed"
            :before-upload="handelUpload"
            :action="upUrl"
            :on-success="upSuccess"
            :on-error="upError"
            multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">{{$t('Uploading.upload1')}}<em>{{$t('Uploading.upload2')}}</em></div>
            <!-- <div class="el-upload__tip" slot="tip">只能上传zip文件</div> -->
         </el-upload>
      </el-dialog>
   </div>
</div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb";
import {
   mapState
} from "vuex";
export default {
   name: "Model",
   components: {
      Breadcrumb,
   },
   computed: {
      ...mapState([
         "task_id_DL",
         "dataset_id_DL",
      ]),
   },
   data() {
      return {
         myInterval: '',
         editable: [],
         models: [],
         delVisible: false, //删除提示弹框的状态
         msg: "", //记录每一条的信息，便于取id
         currentPage: 1, // 当前页
         pageSize: 10, // 页面大小
         count: 0,
         name: "", //搜索名称
         built_in: "", //分类
         value: "",
         upData: {},
         Uploadingdata: false,
         headers: {
            Authorization: `Token ${localStorage.token.split(" ")[1]}`
         },
         upUrl: `${window.apis.SERVER}${window.apis.IMG_DATASET}`,

      };
   },
   mounted() {
      this.getModelList();
      this.myInterval = window.setInterval(() => {
         setTimeout(() => {
            this.getModelList() //调用接口的方法
         }, 1)
      }, 5000);

   },
   destroyed() {
      clearInterval(this.myInterval)
   },

   methods: {
      modellingDetail(row) {
         this.$store.commit("dataset_id_DL", row.id);
         this.$store.commit("task_id_DL", "");
         this.$router.push({
            name: 'TrainingDL',
         });
      },
      upSuccess() {
         this.Uploadingdata = false
         this.$message({
            message: this.$t('AutoDL.DataSet.tanq0v'),
            type: "success",
         });
         this.getModelList();
         this.$refs.upload.clearFiles()
      },
      upError(err) {
         this.Uploadingdata = false
         let myError = err.toString(); //转字符串
         myError = myError.replace("Error: ", "") // 去掉前面的" Error: "
         myError = JSON.parse(myError); //转对象
         this.$message({
            message: this.$t('config') == 'zh' ? myError.zh : myError.en || this.$t('AutoDL.DataSet.sf1v2j'),
            type: "warning",
         });
         this.getModelList();
         this.$refs.upload.clearFiles()
      },
      handelUpload(file) {
         this.upData.size = file.size;
         this.upData.name = file.name.substring(0, file.name.indexOf("."));
      },
      selectGetModelList() {
         this.currentPage = 1
         this.getModelList()
      },
      getModelList() {
         let url = window.apis.IMG_DATASET;
         let params = {
            page: this.currentPage,
            built_in: this.built_in,
            name: this.name
         };

         this.axios
            .get(url, {
               params: params,
            })
            .then((res) => {
               if (res.status === 200 || res.status === 201) {
                  this.models = res.data.results;
                  this.count = res.data.count;
                  for (var key in this.models) {
                     this.models[key].index = key

                  }
               } else {
                  this.$message({
                     message: this.$t('HomePage.message1'),
                     type: "warning",
                  });
               }
            })
            .catch((error) => {
               console.log(error);
            });
      },

      PredictionTrue(row) {
         if (row.stage > 4) {
            return false
         } else {
            return true
         }
      },
      showDetail(row) {
         this.$store.commit("dataset_id_DL", row.id);
         this.$store.commit("task_id_DL", "");
         this.$router.push({
            name: 'AutoDL',
         });
      },
      handleDelete(id) {
         let url = window.apis.IMG_DATASET;
         this.$confirm(this.$t('MdmHome.delConfirm'), this.$t('MdmHome.delTitle'), {
            //   confirmButtonText: '确定',
            //   cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            this.axios.delete(url + id + "/").then((res) => {
               if (res.status === 204 || res.status === 200) {
                  this.count -= 1;
                  this.$message({
                     message: this.$t('MdmHome.message3'),
                     type: "success",
                  });
                  if (this.dataset_id_DL == id) {
                     this.$store.commit("dataset_id_DL", "");
                     this.$store.commit("task_id_DL", "");
                  }
                  setTimeout(() => {
                     this.getModelList();
                  }, 50)
               }
            }).catch((error) => {
               console.log(error);
            })
         }).catch(() => {});
      },

      handleCurrentChange(currentPage) {
         this.currentPage = currentPage;
         this.getModelList();
      },
      routePush(dest) {
         if (this.$router.history.current.name != dest) {
            this.$router.push({
               name: dest,
            });
         }
      },
   },
};
</script>

<style lang="scss" scoped>
// 修改按钮移入显示
.u-btn {
   margin-left: 5px;
   opacity: 0;
   color: #396DD6;
   transition: all 500ms;
   font-size: 13px;
   cursor: pointer;
}

.u-btn:hover {
   font-size: 17px;
   color: #409EFF;
}

.el-table__body tr:hover {
   .u-btn {
      opacity: 1;
   }
}

.ModelTitleBox {
   // width: 100%;
   min-width: 1018px;
   height: 58px;
   margin: 0 33px;
   padding: 10px 0;

   .ModelTitle {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      padding-top: 10px;
   }

   .inputSelect {
      width: 300px;
      margin: 0 20px;
   }
}

.ModelBox {
   position: relative;
   width: auto;
   min-width: 1018px;
   height: auto;
   min-height: 600px;
   padding-bottom: 100px;
   margin: 0 33px;
   border: 1px solid rgba(218, 223, 231, 0.5);
   overflow: hidden;

   .el-pagination {
      position: absolute;
      bottom: 30px;
      right: 50%;
      transform: translate(50%, 0);
   }
}
</style>
