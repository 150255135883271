<template>
  <div>
    <home-header />
    <main>
      <h3>{{$t('About.title')}}</h3>
      <span>{{$t('About.span')}}</span>
      <h5>{{$t('About.title2')}}</h5>
      <div class="box">
        <img
          src="../assets/about_img/aboutus_team.png"
          alt=""
        >
        <span>{{$t('About.text1')}}</span>
        <p>{{$t('About.p1')}}</p>
      </div>
      <div class="MainBox">
        <div class="ABox l">
          <img
            src="../assets/about_img/aboutus_new.png"
            alt=""
          >
          <span>{{$t('About.text2')}}</span>
          <p>{{$t('About.p2')}}</p>
        </div>
        <div class="ABox r">
          <img
            src="../assets/about_img/aboutus_hezuo.png"
            alt=""
          >
          <span>{{$t('About.text3')}}</span>
          <p>{{$t('About.p3')}}</p>
        </div>
      </div>
      <h5>{{$t('About.title3')}}</h5>
      <h6>{{$t('About.text4')}}</h6>
      <ul>
        <li>{{$t('About.li1')}}</li>
        <li>{{$t('About.li2')}}</li>
        <li>{{$t('About.li3')}}</li>
        <li>{{$t('About.li4')}}</li>
      </ul>
      <img
        src="../assets/about_img/aboutus_img1.png"
        alt=""
      >
    </main>
    <footer-a style="width:980px;margin:0 auto;" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import footerA from "../components/Footer"
import HomeHeader from "../components/HomeHeader"
export default {

  data(){
    return{
      headImgUrl: ""
    }
  },
  mounted(){
    this.getHeadImg();
  },
  methods:{
  routePush(routeName) {
    this.$router.push({
      name: routeName
    })
  },
  headerClick(formName){
    this.$store.commit(formName, true)
  },
  elDropdownClick(command){
  switch(command){
  case 'logOut':
  localStorage.clear();
  this.$store.commit('loadLogined', false);
  break;
  default:
      this.$router.push({
      name: command,
      });
  }
  },
  errorHandler() {
  return true
  },
  getHeadImg() {
  if(this.Logined){
  let url = window.apis.USER_HEAD_IMG;
  this.axios.get(url)
  .then((res) => {
  if (res.status === 200) {
    this.headImgUrl = res.data.email_avatar;
  }
  })
  .catch((error) => {
  console.log(error);
  });}
  },
  },
  components: {footerA,HomeHeader},
  computed :{
  ...mapState(['Logined'])
  },
}
</script>

<style lang="scss" scoped>
main {
  width: 960px;
  margin: 0 auto;
  text-align: left;
  padding: 24px 0 0 0;
  h3 {
    height: 58px;
    font-size: 30px;
    font-weight: 500;
    line-height: 58px;
  }
  span {
    // word-break:break-all;
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 20px;
  }
  h5 {
    font-size: 26px;
    font-weight: 500;
    line-height: 33px;
  }
  .box {
    width: 960px;
    height: 310px;
    background: url('../assets/about_img/aboutus_beijing1.png')no-repeat;
    margin-top: 24px;
    img {
      display: block;
      margin: 0 auto;
      padding-top: 18px;
    }
    span {
      height: 30px;
      display: block;
      text-align: center;
      font-size: 22px;
      font-weight: 500;
      color: #262626;
      line-height: 30px;
    }
    p {
      margin: 0 auto;
      width: 896px;
      padding-top: 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
    }
  }
  .MainBox {
    width: 960px;
    margin: 24px 0 35px 0;
    overflow: hidden;
  }
  .ABox {
    width: 464px;
    height: 340px;
    overflow: hidden;
    background: url('../assets/about_img/aboutus_beijing2.png')no-repeat;
    img {
      display: block;
      margin: 0 auto;
      padding-top: 18px;
    }
    span {
      height: 30px;
      display: block;
      text-align: center;
      font-size: 22px;
      font-weight: 500;
      color: #262626;
      line-height: 30px;
    }
    p {
      margin: 0 auto;
      width: 400px;
      padding-top: 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
    }
  }
  h6 {
    height: 26px;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    margin-top: 15px;
  }
  ul {
    li {
    list-style-type: none;
    height: 22px;
    margin: 16px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    }
  }
}

</style>