import axios from "axios";
import router from "../router";
import { Message } from "element-ui";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
//引入action模块，并添加至axios的类属性urls上
// import store from '../store/index'
import store from "../store/index";
import { i18n } from "../lang/index";

// 创建axios实例
const service = axios.create({
  validateStatus(status) {
    return status >= 200 && status <= 500; // 设置默认的合法的状态
  },
  timeout: 9000000, // 请求超时时间
});

// axios.defaults.timeout = 60000;
service.defaults.baseURL = window.apis.SERVER;
// http request 拦截器
service.interceptors.request.use(
  (config) => {
    if (localStorage.token) {
      //判断token是否存在
      config.headers.Authorization = localStorage.token; //将token设置成请求头
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
// http response 拦截器
// 定义一个值统计错误次数同步VUEX退出登录只提示一次
let errTure = store.state.TokenErr;
service.interceptors.response.use(
  (response) => {
    switch (response.status) {
      case 200:
      case 201:
      case 202:
      case 203:
      case 204:
      case 205:
      case 206:
      case 207:
      case 208:
      case 226:
        return response;
      default:
        if (response.data.code == "token_refresh_required") {
          store.commit("TokenErr", errTure++);
          localStorage.clear();
          router.push({ name: "Home" });
          console.log(store.state.TokenErr);
          if (store.state.TokenErr == 0) {
            Message({
              message: i18n.t("token-expired"),
              type: "warning",
              customClass: "mzindex",
            });
          }
        } else {
          if (!response.data.zh) {
            return response;
          } else {
            Message({
              message:
                i18n.t("config") == "zh" ? response.data.zh : response.data.en,
              type: "warning",
            });
            return response
          }
        }
    }
  },
  (error) => {
    console.log(error);
  }
);

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    // 判断该路由是否需要登录权限
    if (localStorage.token) {
      // 获取当前的token是否存在
      next();
    } else {
      localStorage.clear();
      if (router.history.pending.query.DataSetId) {
        localStorage.setItem(
          "DataSetId",
          router.history.pending.query.DataSetId
        );
      }
      next({ name: "Home" });
      //   Message ({
      //     message: "请登录",
      //     type: 'warning',
      //     customClass:'mzindex'
      // });
    }
  } else {
    // 如果不需要权限校验，直接进入路由界面
    next();
  }
});

// axios请求拦截器
service.interceptors.request.use(
  (config) => {
    NProgress.start(); // 设置加载进度条(开始..)
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// NProgress加载进度条 axios响应拦截器
service.interceptors.response.use(
  function(response) {
    NProgress.done(); // 设置加载进度条(结束..)
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);
// 当路由跳转结束后
router.afterEach((to, form) => {
  // 设置title
  const { label } = to.meta;
  if (label) {
    window.document.title = i18n.t(label);
  }
  // 关闭进度条
  NProgress.done();
});

export default service;
