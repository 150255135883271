<template>
  <div class="PersonalCenter">
    <div class="PersonalCenterBox">
      <div class="BoxTitle">
        <div
          class="tabsButton"
          :style="TabStyle('1')"
          @click="routePush('SystemSettings')"
        >
          {{$t('components.headerHover2')}}
        </div>
        <div
          class="tabsButton"
          :style="TabStyle('2')"
          @click="routePush('TemplateSet')"
        >
          {{$t('router.TemplateSet')}}
        </div>
      </div>
      <div class="BoxMain">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
      };
    },
    methods: {
      routePush(res){
        this.$router.push({
          name:res
        })
      },
      // 依然是二级路由，设置非该页面Tabs隐藏css
      TabStyle(index){
        if(this.$route.matched[2].meta.tabs==index){
          return "background:#F6F7FB;"
        }else{
          return "display: none;"
        }
      }
    },
  };
</script>


<style lang="scss" scoped>
.PersonalCenter {
  width: auto;
  min-width: 1002px;
  margin: 0 32px;
  padding-top: 20px;
  .BoxTitle {
    height: 80px;
    background: #E1E4EB;
    text-align: left;
    padding: 44px 0 0 20px;
    .tabsButton {
    width: 84px;
    height: 36px;
    text-align: center;
    background: #D5DCEC;
    border-radius: 2px 2px 0px 0px;
    display: inline-block;
    margin: 0 2px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 36px;
    cursor: pointer;
    }
  }
  .BoxMain {
  background: #F6F7FB;
  width: 100%;
  height: 100%;

  }
}
</style>