import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import About from '../views/About.vue'
import Document from '../views/Document.vue'
import AutoML from '../views/AutoML/AutoML.vue'
import AutoDLIndex from '../views/AutoDL/index.vue'
import DataSet from '../views/AutoDL/DataSet.vue'
import AutoDL from '../views/AutoDL/DL/AutoDL.vue'
import TaskList from '../views/AutoDL/TaskList.vue'
import Model from '../views/AutoML/Model.vue'
import TagModel from '../views/AutoML/TagModel.vue'
import PersonalCenter from '../views/AutoML/PersonalCenter.vue'
import HomePage from '../views/AutoML/HomePage.vue'
import Mdm from '../views/AutoML/MDM/Mdm.vue'
import MdmHome from '../views/AutoML/MDM/MdmHome'
import DataPivot from '../views/AutoML/MDM/DataPivot.vue'
import Auto from '../views/AutoML/Auto/Auto.vue'
import Pretreatment from '../views/AutoML/Auto/Pretreatment.vue'
import Output from '../views/AutoML/Auto/Output.vue'
import Prediction from '../views/AutoML/Auto/Prediction.vue'
import Report from '../views/AutoML/Auto/Report.vue'
import Analysis from '../views/AutoML/Auto/Analysis.vue'
import Parameter from '../views/AutoML/Auto/Parameter.vue'
import Training from '../views/AutoML/Auto/Training.vue'
import MyAccount from '../views/AutoML/PersonalCenter/MyAccount.vue'
import Phone from '../views/AutoML/PersonalCenter/Phone.vue'
import Password from '../views/AutoML/PersonalCenter/Password.vue'
import Mail from '../views/AutoML/PersonalCenter/Mail.vue'
import Record from '../views/AutoML/PersonalCenter/Record.vue'
import PurchaseHistory from '../views/AutoML/PersonalCenter/PurchaseHistory.vue'
import SystemSettings from '../views/AutoML/SystemSettings.vue'
import Setting from '../views/AutoML/SystemSettings/Setting.vue'
import TemplateSet from '../views/AutoML/TemplateSet.vue'
import Login from '../views/HomeComponents/Login.vue'
import LoginHome from '../views/HomeComponents/LoginHome.vue'
import Register from '../views/HomeComponents/Register.vue'
import ForgetPassword from '../views/HomeComponents/ForgetPassword.vue'
import LoginNav from '../views/HomeComponents/LoginNav.vue'
import HTTP404 from '../components/404.vue'
import GlobalVal from '../api/global_val'
import VipHome from '../views/VIP/VipHome.vue'
import WorkOrder from '../views/WorkOrder/index.vue'
import {i18n} from "../lang/index"

Vue.use(VueRouter)


const routes = [
  {
    path: '/WorkOrder',
    name: 'WorkOrder',
    component: WorkOrder,
    meta:{title: '123' ,bar:'222'},
  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta:{title: 'router.PrivacyPolicy' },
  },
  {
    path: '/About',
    name: 'About',
    component: About,
    meta:{title:  'router.About'  },
  },
  {
    path: '/Document',
    name: 'Document',
    component: Document,
    meta:{title:  'router.About'  },
  },
  {
    path: '/VipHome',
    name: 'VipHome',
    component: VipHome,
    meta:{title: 'router.VipHome' ,requireAuth: true},
  },
  {
    path: "/",
    name: "",
    component: LoginHome,
    meta:{title: "router.Login" },
    children: [
    {
      path: '/',
      name: 'Home',
      component: Login,
      meta:{title: "router.Login" },
    },
    {
      path: '/Register',
      name: 'Register',
      component: Register,
      meta:{title: "router.Register" },
    },
    {
      path: '/ForgetPassword',
      name: 'ForgetPassword',
      component: ForgetPassword,
      meta:{title: "router.Password" },
    },
    ]
  },
  {
   path: '/LoginNav',
   name: 'LoginNav',
   component: LoginNav,
   meta:{title: "LoginNav" },
 },
  {
    path: "/Coovally",
    name: "",
    component: AutoML,
    meta:{title: 'router.Home' ,bar:'router.Home',requireAuth: true},
    children: [
      {
        path : "/",
        name: "AutoML",
        component: HomePage,
        meta:{title:'router.Home' ,bar:'router.Home',requireAuth: true,active:"1"}
      },
      {
        path : "/Coovally/Model",
        name: "Model",
        component: Model,
        meta:{title:'router.Model' ,bar:'router.Model',requireAuth: true,active:"4"}
      },
      {
        path : "/Coovally/TagModel",
        name: "TagModel",
        component: TagModel,
        meta:{title:'router.Model' ,bar:'router.Model',requireAuth: true,active:"7"}
      },
      {
        path:"/Coovally/TemplateSet",
        name:"TemplateSet",
        component: TemplateSet,
        meta:{title:"router.TemplateSet" ,bar:"router.TemplateSet",requireAuth: true,active:"5"}
      },
      {
        path : "/Coovally/PersonalCenter",
        name: "",
        component: PersonalCenter,
        meta:{title:"router.PersonalCenter" ,bar:"router.PersonalCenter",requireAuth: true,active:"6"},
        children:[
          {
            path:"/",
            name:"PersonalCenter",
            component: MyAccount,
            meta:{title:"router.MyAccount" ,bar:"router.MyAccount",requireAuth: true,tabs:"1"}
          },
          {
            path:"/Coovally/PersonalCenter/Phone",
            name:"Phone",
            component: Phone,
            meta:{title:"router.Phone" ,bar:"router.Phone",requireAuth: true,tabs:"2"}
          },
          {
            path:"/Coovally/PersonalCenter/Password",
            name:"Password",
            component: Password,
            meta:{title:"router.Password" ,bar:"router.Password",requireAuth: true,tabs:"3"}
          },
          {
            path:"/Coovally/PersonalCenter/Mail",
            name:"Mail",
            component: Mail,
            meta:{title:"router.Mail" ,bar:"router.Mail",requireAuth: true,tabs:"4"}
          },
          {
            path:"/Coovally/PersonalCenter/Record",
            name:"Record",
            component: Record,
            meta:{title:"router.Record" ,bar:"router.Record",requireAuth: true,tabs:"5"}
          },
          {
            path:"/Coovally/PersonalCenter/PurchaseHistory",
            name:"PurchaseHistory",
            component: PurchaseHistory,
            meta:{title:"router.PurchaseHistory" ,bar:"router.PurchaseHistory",requireAuth: true,tabs:"6"}
          },
        ]
      },
      {
        path : "/Coovally/SystemSettings",
        name: "",
        component: SystemSettings,
        meta:{title: 'router.SystemSettings' ,bar:'router.SystemSettings',requireAuth: true,active:"6"},
        children:[
          {
            path:"/",
            name:"SystemSettings",
            component: Setting,
            meta:{title:'router.SystemSettings' ,bar:'router.SystemSettings',requireAuth: true,tabs:"1"}
          },
        ]
      },
      {
        path : "/Coovally/Mdm",
        name: "",
        component: Mdm,
        meta:{title: 'router.Mdm'  ,bar:'router.Mdm',requireAuth: true,active:"2"},
        children:[
          {
            path:'/',
            name:'Mdm',
            component: MdmHome,
            meta:{title:'router.Mdm' ,bar:"router.Mdm",requireAuth: true}
          },
          {
            path:'/Coovally/Mdm/DataPivot',
            name:'DataPivot',
            component: DataPivot,
            meta:{title:"router.DataPivot" ,bar:"router.DataPivot",requireAuth: true}
          }
        ]
      },
      {
        path : "/Coovally/Auto",
        name: "",
        component: Auto,
        meta:{title:"router.PomaAuto" ,bar:"router.PomaAuto",active:"3"},
        children:[
          {
            path:"/",
            name:"Auto",
            component :Pretreatment,
            meta:{title:"router.Pretreatment" ,bar:"router.Pretreatment",active:"1",requireAuth: true}
          },
          {
            path:"/Coovally/Auto/Analysis",
            name:"Analysis",
            component :Analysis,
            meta:{title:"router.Analysis" ,bar:"router.Analysis",active:"2",requireAuth: true}
          },
          {
            path:"/Coovally/Auto/Parameter",
            name:"Parameter",
            component :Parameter,
            meta:{title:"router.PomaAuto" ,bar:"router.PomaAuto",active:"3",requireAuth: true}
          },
          {
            path:"/Coovally/Auto/Training",
            name:"Training",
            component :Training,
            meta:{title:"router.Training" ,bar:"router.Training",active:"4",requireAuth: true}
          },
          {
            path:"/Coovally/Auto/Report",
            name:"Report",
            component :Report,
            meta:{title:"router.Report" ,bar:"router.Report",active:"5",requireAuth: true}
          },
          {
            path:"/Coovally/Auto/Output",
            name:"Output",
            component :Output,
            meta:{title:"router.Output" ,bar:"router.Output",active:"6",requireAuth: true}
          },
          {
            path:"/Coovally/Auto/Prediction",
            name:"Prediction",
            component :Prediction,
            meta:{title:"router.Prediction" ,bar:"router.Prediction",active:"7",requireAuth: true}
          }
        ]
      },
    ]
  },
  {
    path:'/AutoDLHome',
    name:'',
    component:AutoDLIndex,
    meta:{requireAuth: true},
    children:[
      {
         path : "/",
         name: "DataSet",
         component: DataSet,
         meta:{title:'img-classification' ,bar:'DataSet',requireAuth: true,active:"1"}
      },
      {
         path : "/AutoDL",
         name: "",
         component: AutoDL,
         meta:{bar:'AutoDL',requireAuth: true,active:"2"},
         children:[
            {
               path : "/",
               name: "AutoDL",
               component: ()=>import('../views/AutoDL/DL/Result.vue'),
               meta:{title:'AutoDL.DL.AutoDL.vh436l' ,bar:'Result',requireAuth: true,active:"1"}
            },
            {
               path : "/AutoDL/TrainingDL",
               name: "TrainingDL",
               component: ()=>import('../views/AutoDL/DL/TrainingDL.vue'),
               meta:{title:'router.Training' ,bar:'TrainingDL',requireAuth: true,active:"2"}
            },
            {
               path : "/AutoDL/Preview",
               name: "Preview",
               component: ()=>import('../views/AutoDL/DL/Preview.vue'),
               meta:{title:'router.Prediction' ,bar:'Preview',requireAuth: true,active:"4"}
            },
            {
               path : "/AutoDL/PredictionDL",
               name: "PredictionDL",
               component: ()=>import('../views/AutoDL/DL/PredictionDL.vue'),
               meta:{title:'router.Report' ,bar:'PredictionDL',requireAuth: true,active:"3"}
            },
         ]
      },
      {
         path : "/TaskList",
         name: "TaskList",
         component: TaskList,
         meta:{title:'router.Model' ,bar:'TaskList',requireAuth: true,active:"3"}
      },
    ]
  },
  {
    path:'*',
    name:'404',
    component:HTTP404,
    meta:{title:"404"},
  }
]



const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  linkActiveClass: "active",//路由跳转时动态添加样式
  linkExactActiveClass: "active",
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
