<template>
<div class="Prediction">
   <div class="uploadBox l">
      <div class="detailsBox">
         <div class="box">
            <p class="l">
               {{ $t("Prediction.MdmName") }}
            </p>
            <span class="r">{{ dataset_name }}</span>
         </div>
         <div class="box">
            <p class="l">
               {{ $t("Prediction.name") }}
            </p>
            <span class="r">{{ task_name }}</span>
         </div>
         <div class="box">
            <p class="l" style="line-height: 32px">
               {{ $t("Prediction.versions") }}
            </p>
            <span class="r">
               <el-select
                  size="small"
                  v-model="optionsData"
                  :placeholder="this.$t('Setting.select1')"
               >
                  <el-option
                     v-for="item in options"
                     :key="item._id"
                     :label="item.version"
                     :value="item.id"
                  />
               </el-select>
            </span>
         </div>
      </div>
      <el-upload
         ref="uploadRef"
         class="upload"
         :on-remove="upClearFiles"
         drag
         :multiple="false"
         :on-change="changFile"
         :auto-upload="false"
         :http-request="uploadFile"
         action=""
         accept=".csv"
      >
         <div class="uploadText">
            {{ $t("Prediction.uploadText") }}
         </div>
         <el-button
            type="primary"
            style="width: 116px"
            round
         >
            {{ $t("Prediction.upload") }}
         </el-button>
      </el-upload>
      <div :class="{ BottomButtom: classTrue, BottomButtomA: csvDownloadTrue }">
         <el-button
            type="primary"
            class="r"
            style="width: 98px"
            @click="submit"
         >
            {{ $t("Prediction.button") }}
         </el-button>
         <el-button
            type="primary"
            class="r"
            v-show="csvDownloadTrue"
            style="width: 98px; margin-right: 30px"
            @click="csvDownloadFn"
         >
            {{ $t("Prediction.download") }}
         </el-button>
      </div>
   </div>
   <el-image
      class="r"
      :src="imgUrl"
      v-show="imgUrl"
      style="width: auto; max-width: 740px; height: 100%; border: 1px solid rgba(218, 223, 231, 0.5)"
      :preview-src-list="imgUrlList"
   />
   <div v-show="!imgUrl" class="hint r">
      <div class="hintBox">
         <i v-show="hintText" class="el-icon-warning-outline" />
         <span> {{ hintText }}</span>
      </div>
   </div>
   <section>
      <el-tooltip
         class="item"
         effect="light"
         :content="$t('pretreatment.buttonTop')"
         placement="top-start"
      >
         <el-button
            class="Step"
            type="primary"
            icon="el-icon-arrow-left"
            circle
            @click="lastStep"
         />
      </el-tooltip>
   </section>
</div>
</template>

<script>
// import SparkMD5 from 'spark-md5'
import {
   mapState
} from "vuex";
export default {
   data() {
      return {
         form: {
            file: "",
            task_id: "",
            dataset_id: "",
         },
         imgUrl: "",
         imgUrlList: [],
         upTrue: false,
         csvDownload: "",
         classTrue: true,
         csvDownloadTrue: false,
         options: "",
         optionsData: "",
         hintText: "",
      };
   },
   watch: {
      // 监听列表切换并且已生成，清空上传列表
      optionsData: function () {
         if (this.csvDownloadTrue) {
            this.csvDownloadTrue = false;
            this.$refs.uploadRef.clearFiles();
            this.imgUrl = "";
            this.hintText = "";
         }
      },
   },
   computed: {
      ...mapState(["task_id", "task_name", "dataset_id", "dataset_name", "task_type", "target_column", "read_mode_header", "desc"]),
   },
   methods: {
      getDownloadList() {
         let url = `${window.apis.SYS_DOWNLOAD_HISTORY}?task_id=${this.task_id}`;
         this.axios.get(url).then((res) => {
            let data = res.data.results;
            this.options = data.map((item) => {
               return {
                  version: item.version,
                  id: item.id
               };
            });
            this.optionsData = res.data.results[0].id;
         });
      },
      // 移除文件，文件列表为0移除下载
      upClearFiles(file, fileList) {
         if (fileList.length == 0) {
            this.csvDownloadTrue = false;
         }
         this.imgUrl = "";
         this.hintText = "";
      },
      lastStep() {
         this.$router.push({
            name: "Output",
         });
      },
      changFile(file, fileList) {
         if (fileList.length > 1) {
            fileList.splice(0, 1);
         }
         this.upTrue = true;
      },
      submit() {
         this.form.trainingHistory_id = this.optionsData;
         if (this.upTrue) {
            this.$refs.uploadRef.submit();
         } else {
            this.uploadFile();
         }
      },
      csvDownloadFn() {
         window.open(this.csvDownload, "_self");
      },
      uploadFile(param) {
         let url = window.apis.SYS_PREDICT;
         let downLoadUrl = window.apis.SERVER + window.apis.SYS_DOWNLOAD;
         let data = new FormData();
         const loading = this.$loading({
            lock: true,
            text: this.$t("Prediction.model_prediction"),
            background: "rgba(255, 255, 255, 0.4)",
         });
         if (param) {
            this.form.file = param.file;
            for (let key in this.form) {
               data.append(key, this.form[key]);
            }
            this.axios({
                  url: url,
                  method: "post",
                  data: data,
                  onUploadProgress: (progressEvent) => {
                     let num = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
                     param.onProgress({
                        percent: num
                     });
                  },
               })
               .then((res) => {
                  loading.close();
                  if (res.status == 201 || res.status == 200) {
                     this.imgUrl = `${downLoadUrl}${res.data.image}?token=${localStorage.token.split(" ")[1]}&1=${Math.random()}`;
                     if (res.data.image == "") {
                        this.imgUrl = "";
                     }
                     this.csvDownload = `${downLoadUrl}${res.data.predict_csv}?token=${localStorage.token.split(" ")[1]}&1=${Math.random()}`;
                     this.csvDownloadTrue = true;
                     this.imgUrlList = [this.imgUrl];
                     if (res.data.status == 201 || res.data.status == 202||res.data.status == 200) {
                        this.hintText = this.$t('config')=='zh'?res.data.result_zh:res.data.result;
                     }
                     this.$message({
                        message: this.$t('config')=='zh'?res.data.zh:res.data.en,
                        type: "success",
                     });
                  } 
               })
               .catch(function (error) {
                  loading.close();
               });
         } else {
            for (let key in this.form) {
               data.append(key, this.form[key]);
            }
            this.axios({
                  url: url,
                  method: "post",
                  data: data
               })
               .then((res) => {
                  loading.close();
                  if (res.status == 201 || res.status == 200) {
                     this.imgUrl = `${downLoadUrl}${res.data.image}?token=${localStorage.token.split(" ")[1]}&1=${Math.random()}`;
                     this.csvDownload = `${downLoadUrl}${res.data.predict_csv}?token=${localStorage.token.split(" ")[1]}&1=${Math.random()}`;
                     this.csvDownloadTrue = true;
                     this.imgUrlList = [this.imgUrl];
                     this.$message({
                        message: this.$t('config')=='zh'?res.data.zh:res.data.en,
                        type: "success",
                     });
                  }
               })
               .catch(function (error) {
                  loading.close();
               });
         }
      },
   },
   mounted() {
      this.form.task_id = this.task_id;
      this.form.dataset_id = this.dataset_id;
      this.getDownloadList();
   },
};
</script>

<style lang="scss" scoped>
.detailsBox {
   width: 100%;
   margin: 0px 0 20px 0;
   font-size: 14px;
   border: 1px solid rgba(218, 223, 231, 0.5);
   text-align: left;
   overflow: hidden;

   .box {
      width: 100%;
      height: auto;
      padding: 5px 10px;
      overflow: hidden;
      border-bottom: 1px solid rgba(218, 223, 231, 0.5);

      p {
         width: 25%;
         font-weight: 500;
      }

      span {
         width: 75%;
         text-align: left;
      }
   }

   div:hover {
      background: rgba(246, 247, 248, 0.5);
   }

   div:last-of-type {
      border: 0px;
   }
}

.Prediction {
   overflow: hidden;
   margin-top: 30px;

   .hint {
      position: relative;
      width: 60%;
      height: 445px;
      border: 1px solid rgba(218, 223, 231, 0.5);

      .hintBox {
         position: absolute;
         left: 50%;
         top: 20vh;
         transform: translate(-50%, -50%);
         font-size: 25px;
         font-weight: 500;
         color: rgba(92, 105, 123, 0.8);
         display: block;
      }
   }
}

::v-deep .el-upload {
   width: 100%;
}

::v-deep .el-upload-dragger {
   width: 100%;
   height: 319px;
   border: 1px solid rgba(218, 223, 231, 0.5);
   border-radius: 0px;
}

.uploadBox {
   width: calc(100% - 1053px);
   min-width: 403px;
   height: auto;
   position: relative;
   margin-bottom: 70px;

   .uploadText {
      margin: 116px 0 33px 0;
      font-size: 18px;
      font-weight: 500;
      color: #999999;
   }

   .BottomButtom {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: -70px;
      width: 100%;
   }

   .BottomButtomA {
      width: 100%;
   }
}

section {
   position: fixed;
   right: 100px;
   bottom: 50px;
   z-index: 100;

   .Step {
      margin: 0 15px;
      box-shadow: 0px 0px 7px #333333;
   }
}
</style>
