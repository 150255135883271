<template>
  <transition name="el-fade-in-linear">
    <div
      class="LoginMain"
      id="LoginMain"
      v-show="imgTrue"
      :style="min_height + cssData.background + cssData.blur"
    >
      <div class="logoBox">
        <div><img src="../../assets/img/denglu_logo@2x.png" alt="" /></div>
      </div>
      <div class="enCnBox r">
        <!-- <i class="iconfont enCn" :class="$i18n.locale=='zh'?'iconnavbar_zhongwen':'iconnavbar_english'" @click="i18n()"></i> -->
        <span
          :style="$i18n.locale == 'zh' ? 'color: rgba(45, 84, 239, 1);' : ''"
          @click="i18nZH()"
        >
          中
        </span>
        <span> / </span>
        <span
          :style="$i18n.locale == 'en' ? 'color: rgba(45, 84, 239, 1);' : ''"
          @click="i18nEN()"
        >
          EN
        </span>
      </div>
      <div class="mainBox">
        <div ref="main" class="mainBoxTop">
          <div class="background">
            <div class="title">
              <span>{{ $t("LoginHome.p") }}</span>
            </div>

            <div class="text">
              <span>
                {{ $t("LoginHome.span") }}
              </span>
            </div>
            <div class="img loginhome">
              <img src="../../assets/img/shouye@2x.png" alt="" />
            </div>
          </div>
          <router-view class="r" />
        </div>
        <footer ref="footer">
          <footer-a class="footer" :style="cssData.color"></footer-a>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import footerA from "../../components/Footer";

export default {
  inject: ["reload"],
  data() {
    return {
      cssData: {
        blur: "",
        background: "",
      },
      min_height: "",
      imgTrue: false,
    };
  },
  updated() {
    //获取DOM高度计算背景图最小高度
    this.$nextTick(() => {
      this.min_height = ` height:${
        this.$refs.main.offsetHeight + this.$refs.footer.offsetHeight
      }px;`;
    });
  },
  methods: {
    i18n() {
      switch (this.$i18n.locale) {
        case "zh":
          this.$i18n.locale = "en";
          break;
        case "en":
          this.$i18n.locale = "zh";
          break;
        default:
          return;
          break;
      }
      document.title = this.$route.meta.title;
      this.$Privilage.ROLES_FN(); //对应的用户等级
      localStorage.setItem("i18n", this.$i18n.locale);
      this.reload();
    },
    i18nEN() {
      if (this.$i18n.locale != "en") {
        this.$i18n.locale = "en";
        document.title = this.$route.meta.title;
        this.$Privilage.ROLES_FN(); //对应的用户等级
        localStorage.setItem("i18n", this.$i18n.locale);
        this.reload();
      }
    },
    i18nZH() {
      if (this.$i18n.locale != "zh") {
        this.$i18n.locale = "zh";
        document.title = this.$route.meta.title;
        this.$Privilage.ROLES_FN(); //对应的用户等级
        localStorage.setItem("i18n", this.$i18n.locale);
        this.reload();
      }
    },
    getCss() {
      //设置定时器加载完图片后展示页面
      let timeout = setTimeout(() => {
        this.imgTrue = true;
      }, 4000);
      this.axios(window.apis.SYS_FRONT).then((res) => {
        this.imgTrue = true;
        clearTimeout(timeout);
        if (res.status === 200 || res.status === 201) {
          this.cssData = res.data;
          this.cssData.background = `background-repeat: no-repeat;background-size:cover;background-image: url('${res.data.background}');`;
          this.cssData.blur = `--blur: ${res.data.blur}px;`;
          this.cssData.color = `color: ${res.data.font_color};`;
        }
      });
    },
    routePush(name) {
      this.$router.push({
        name: name,
      });
    },
  },
  mounted() {
    this.getCss();
  },
  components: {
    footerA,
  },
};
</script>

<style lang="scss" scoped>
//element输入框样式
//::v-deep .el-form-item.is-error .el-input__validateIcon{
//  color: #ffffff;
//}
//::v-deep .el-input__inner{
//  background: transparent;
//  color: #fff;
//  border: 0;
//  padding: 0;
//  border-radius: 0;
//  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
//}
//::v-deep .el-form-item.is-error .el-input__inner {
//  border-color: rgba(255, 255, 255, 1);
//}
//::v-deep .el-form-item.is-error .el-input__inner:focus {
//  border-color: rgba(255, 255, 255, 1);
//}
//::v-deep .el-input__inner:focus{
//  border-bottom: 1px solid rgba(255, 255, 255, 1);
//}
//::v-deep .el-form-item {
//  margin-bottom: 30px;
//}
::v-deep #footerMain {
  border-top: 0;
  //color: #fff;
}

.LoginMain {
  position: relative;
  min-height: 100vh;

  background: #e0e7f0;
  // background: url('../../assets/img/bg_006.jpg') no-repeat center;
  // background-size: cover;
  z-index: 1;
}

.LoginMain:after {
  content: "";
  width: 100%;
  // height: 100%;
  min-width: 1120px;
  position: absolute;
  left: 0;
  top: 0;
  filter: blur(0px);
  transform: translate3d(0, 0, 0);
  transition: color, filter 0.3s linear;
  z-index: 2;
}

#LoginMain:after {
  filter: blur(var(--blur));
  backdrop-filter: blur(var(--blur));
}

.logoBox {
  position: absolute;
  left: 40px;
  top: 32px;
  z-index: 999;
  // margin: 13px 40px 0 0;
  img {
    width: 296px;
    height: 40px;
  }

  span {
    font-size: 16px;
    font-weight: 500;
    color: rgba(85, 85, 85, 1);
    line-height: 22px;
    font-family: "PingFang SC";
    cursor: pointer;
  }

  .enCn {
    font-size: 20px;
    color: rgba(85, 85, 85, 1);
    cursor: pointer;
    // margin: 12px 30px 0 0;
  }
}

.enCnBox {
  position: absolute;
  right: 10px;
  z-index: 999;
  margin: 36px 40px 0 0;

  span {
    font-size: 16px;
    font-weight: 500;
    color: rgba(85, 85, 85, 1);
    line-height: 22px;
    font-family: "PingFang SC";
    cursor: pointer;
  }

  .enCn {
    font-size: 20px;
    color: rgba(85, 85, 85, 1);
    cursor: pointer;
    // margin: 12px 30px 0 0;
  }
}

.mainBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  //   position: absolute;
  //   width: 100%;
  //   min-width: 1110px;
  //   max-width: 1430px;
  //   height: 100%;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  z-index: 11;

  .mainBoxTop {
    height: calc(90vh - 119px);
    margin-top: calc(100vh * 0.07);
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .background {
      // background: yellow;
      width: 62%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      z-index: 1;

      .title {
        span {
          height: 4.2vh;
          font-size: 3.22vh;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 4.2vh;
        }
      }
      .text {
        margin-top: 2.15vh;
        text-align: left !important ;

        span {
          width: 300px;
          height: 7.2vh;
          font-size: 2.32vh;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #555555;
          line-height: 3.6vh;
        }
      }

      .img {
        margin-top: 4.06vh;

        img {
          width: 350px;
          height: 200px;
        }
      }
    }
  }
}

.el-form {
  text-align: left;
  margin: 0 auto;
  width: 300px;
}
</style>
