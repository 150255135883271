<template>
<div>
   <help-document></help-document>
   <iframe allowfullscreen="true" :src="src" id="iframe" ref="main_page_iframe"></iframe>
</div>
</template>

<script>
import HelpDocument from "../components/AutomlHeader"
export default {
   data() {
      return {
         src: ""
      }
   },
   components: {
      HelpDocument
   },
   mounted() {
      switch (this.$t('config')) {
         case 'zh':
            this.src = 'https://sinoaus.shop:60043/';
            break;
         case 'en':
            this.src = 'https://sinoaus.shop:60043/en';
            break;
         default:
            return;
            break;
      }
   }
}
</script>

<style lang="scss" scoped>
#iframe {
   width: 100%;
   height: calc(100vh - 60px);
}
</style>
