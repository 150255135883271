<template>
  <div class="Training">
    <article class="l">
      <!-- <span class="articleTitle">参数设置</span> -->
      <el-tabs
        v-model="activeName"
        type="card"
        @tab-click="handleClick"
      >
        <el-tab-pane
          class="defaultBox"
          :label="$t('Training.tab')"
          :disabled="startVisible"
          name="first"
        >
          <el-radio
            v-model="getParamLevel"
            :disabled="startVisible"
            :label="1"
            size="small"
            border
          >
            <!-- 快速验证 -->
{{$t('Training.radio1')}}
          </el-radio>
          <el-radio
            v-model="getParamLevel"
            :disabled="startVisible"
            :label="2"
            size="small"
            border
          >
            <!-- 标准验证 -->
            {{$t('Training.radio2')}}
          </el-radio>
          <el-radio
            v-model="getParamLevel"
            :disabled="startVisible"
            :label="3"
            size="small"
            border
          >
            <!-- 深度挖掘 -->
            {{$t('Training.radio3')}}
          </el-radio>
          <div class="detailsBox">
            <div>
              <p class="l">
                <!-- 评估指标 -->
                {{$t('Training.radioTitle1')}}
              </p>
              <span class="r">{{ selectParams.scoringCn }}</span>
            </div>
            <div>
              <p class="l">
                <!-- 配置文件 -->
                {{$t('Training.radioTitle2')}}
              </p>
              <span class="r">{{ configCN }}</span>
            </div>
            <div>
              <p class="l">
                <!-- 遗传代数 -->
                {{$t('Training.radioTitle3')}}
              </p>
              <span class="r">{{ selectParams.g }}</span>
            </div>
            <div>
              <p class="l">
                <!-- 种群个数 -->
                {{$t('Training.radioTitle4')}}
              </p>
              <span class="r">{{ selectParams.p }}</span>
            </div>
            <div>
              <p class="l">
                <!-- CPU核数 -->
                {{$t('Training.radioTitle5')}}
              </p>
              <span class="r">{{ selectParams.njobs }}</span>
            </div>
            <div>
              <p class="l">
                <!-- 最长时间 -->
                {{$t('Training.radioTitle6')}}
              </p>
              <span class="r">{{ selectParams.maxtime }}{{$t('Training.radioTitle7')}}</span>
            </div>
            <div>
              <p class="l">
                Early Stop
              </p>
              <span class="r">{{ selectParams.es }}</span>
            </div>
            <div>
              <p class="l">
                <!-- 目标列 -->
                {{$t('Training.radioTitle8')}}
              </p>
              <span class="r">{{ headerData }}</span>
            </div>
            <!-- <div><p class="l">选择的特征名称</p><span class="r">{{FeatureName}}</span></div> -->
            <div>
              <p class="l">
                <!-- 数据集名称 -->
                {{$t('Training.radioTitle9')}}
              </p>
              <span class="r">{{ dataset_name }}</span>
            </div>
            <div>
              <p class="l">
                <!-- 任务名称 -->
                {{$t('Training.radioTitle10')}}
              </p>
              <span class="r">{{ task_name }}</span>
            </div>
            <el-collapse accordion>
              <el-collapse-item
                :title="$t('Training.collapse')"
                name="1"
              >
                <div>{{ FeatureName }}</div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-tab-pane>
        <el-tab-pane
          class="CustomBox"
          :label="$t('Training.tab2')"
          :disabled="startVisible"
          name="second"
        >
          <div class="block">
            <span class="demonstration">
               <!-- 数据集名称 -->
               {{$t('Training.demonstration1')}}
               </span>
            <el-input
              size="small"
              v-model="dataset_name"
              :disabled="true"
              :placeholder="$t('Training.input')"
            />
          </div>
          <div class="block">
            <span class="demonstration">
               <!-- 任务名称 -->
                {{$t('Training.radioTitle10')}}
            </span>
            <el-input
              size="small"
              v-model="task_name"
              :disabled="true"
              :placeholder="$t('Training.input')"
            />
          </div>
          <div class="block">
            <span class="demonstration">
                <!-- 目标列 -->
                {{$t('Training.radioTitle8')}}
            </span>
            <el-select
              :disabled="true"
              size="small"
              v-model="target_columnIndex"
              filterable
              :placeholder="$t('Training.input')"
            >
              <el-option
                v-for="item in headerOption"
                :key="item.value"
                :label="mode.read_mode_header=='y'?item.label:item.value"
                :value="item.value"
              />
            </el-select>
          </div>
          <div class="block">
            <span class="demonstration">
               <!-- 评估指标 -->
                {{$t('Training.radioTitle1')}}
            </span>
            <el-select
              :disabled="startVisible"
              v-model="customParams.scoring"
              placeholder="请选择评估指标"
              size="small"
            >
              <el-option
                v-for="item in dic"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
          <div class="block">
            <span class="demonstration">
               <!-- 配置文件 -->
                {{$t('Training.radioTitle2')}}
            </span>
            <el-select
              :disabled="startVisible"
              v-model="customParams.config"
              :placeholder="$t('Training.radioTitle2')"
              size="small"
            >
              <el-option
                v-for="item in config"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
          <div class="block">
            <span class="demonstration">
               <!-- 遗传代数 -->
               {{$t('Training.demonstration6')}}
               </span>
            <el-slider
              :disabled="startVisible"
              v-model="customParams.g"
              :min="1"
              :max="100"
              :step="1"
            />
            <el-input-number
              controls-position="right"
              :disabled="startVisible"
              size="small"
              v-model="customParams.g"
              :min="1"
              :max="100"
              :step="1"
            />
          </div>
          <div class="block">
            <span class="demonstration">
               <!-- 种群个数 -->
               {{$t('Training.demonstration7')}}
               </span>
            <el-slider
              :disabled="startVisible"
              v-model="customParams.p"
              :min="1"
              :max="100"
              :step="1"
            />
            <el-input-number
              controls-position="right"
              :disabled="startVisible"
              size="small"
              v-model="customParams.p"
              :min="1"
              :max="100"
              :step="1"
            />
          </div>
          <div class="block">
            <span class="demonstration">Early Stop</span>
            <el-slider
              :disabled="startVisible"
              v-model="customParams.es"
              :min="1"
              :max="100"
              :step="1"
            />
            <el-input-number
              controls-position="right"
              :disabled="startVisible"
              size="small"
              v-model="customParams.es"
              :min="1"
              :max="100"
              :step="1"
            />
          </div>
          <div class="block">
            <span class="demonstration">
               <!-- CPU核数 -->
               {{$t('Training.demonstration9')}}
               </span>
            <el-slider
              :disabled="startVisible"
              v-model="customParams.njobs"
              :min="1"
              :max="maxCPU"
              :step="1"
            />
            <el-input-number
              controls-position="right"
              :disabled="startVisible"
              size="small"
              v-model="customParams.njobs"
              :min="1"
              :max="maxCPU"
              :step="1"
            />
          </div>
          <div class="block">
            <span class="demonstration">
               <!-- 最长时间 -->
               {{$t('Training.demonstration10')}}
               </span>
            <el-input-number
              style="margin-bottom: 4px"
              controls-position="right"
              :disabled="startVisible"
              size="small"
              v-model="customParams.maxtime"
              :min="0"
              :step="1"
            />
            <span
              class="demonstration"
            >{{$t('Training.radioTitle7')}}</span>
          </div>
          <!-- <div class="block">
            <span class="demonstration">CPU核数</span>
            <el-radio-group
              v-model="customParams.njobs"
              :disabled="startVisible"
            >
              <el-radio :label="2">
                2
              </el-radio>
              <el-radio :label="4">
                4
              </el-radio>
              <el-radio :label="8">
                8
              </el-radio>
            </el-radio-group>
          </div> -->

          <!--
            <div class="detailsBox">
              <div><p class="l">选择的特征名称</p><span class="r">{{FeatureName}}</span></div>
            </div> -->
          <el-collapse accordion>
            <el-collapse-item
              :title="$t('Training.collapse')"
              name="1"
            >
              <div>{{ FeatureName }}</div>
            </el-collapse-item>
          </el-collapse>
        </el-tab-pane>
      </el-tabs>
    </article>
    <aside class="r">
      <span class="asideMainTitle">
         <!-- 日志详情 -->
         {{$t('Training.asideMainTitle')}}
         </span>
      <div class="asideMain">
        <el-tooltip
          :content="$t('Training.tooltip')"
          placement="top-start"
          v-if="!startVisible"
        >
          <i
            class="el-icon-document-copy"
            v-if="!startVisible"
            @click="Copy()"
          />
        </el-tooltip>
        <ul>
          <li
            v-for="item in logMsg"
            :key="item.message"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <el-button
        plain
        class="analyse"
        :disabled="startVisible"
        @click="startTraining"
      >
        <i class="el-icon-video-play" /> 
        <!-- 开始训练 -->
        {{$t('Training.button1')}}
      </el-button>
      <el-button
        plain
        class="analyse"
        :disabled="stopVisible"
        @click="stopTraining"
      >
        <i class="el-icon-video-pause" /> 
        <!-- 停止训练 -->
        {{$t('Training.button2')}}
      </el-button>
    </aside>
    <section>
      <el-tooltip
        class="item"
        effect="light"
        :content="$t('pretreatment.buttonTop')"
        placement="top-start"
      >
        <el-button
          class="Step"
          type="primary"
          icon="el-icon-arrow-left"
          :disabled="lastVisible"
          circle
          @click="lastStep"
        />
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="light"
        :content="$t('pretreatment.buttonNext')"
        placement="top-start"
      >
        <el-button
          class="Step"
          type="primary"
          icon="el-icon-arrow-right"
          :disabled="nextVisible"
          circle
          @click="nextStep"
        />
      </el-tooltip>
    </section>
  </div>
</template>

<script>
import { sendWebsocket, closeWebsocket } from "@/tools/websocket.js";
import { mapState } from "vuex";

export default {
  beforeDestroy() {
    // 页面销毁时关闭ws。因为有可能ws连接接收数据尚未完成，用户就跳转了页面
    // 在需要主动关闭ws的地方都可以调用该方法
    closeWebsocket();
  },
  data() {
    return {
      configCN: "",
      dic: [],
      b_cls_dic: [
        {
          value: "f1",
          label: "F-Score",
        },
        {
          value: "accuracy",
          label: this.$t('Training.accuracy'),
        },
        {
          value: "precision",
          label: this.$t('Training.precision'),
        },
        {
          value: "recall",
          label: this.$t('Training.recall'),
        },
        {
          value: "neg_log_loss",
          label: this.$t('Training.neg_log_loss'),
        },
      ],
      m_cls_dic: [
        {
          value: "f1_weighted",
          label: this.$t('Training.f1_weighted'),
        },
        {
          value: "accuracy",
          label: this.$t('Training.accuracy'),
        },
        {
          value: "precision_weighted",
          label: this.$t('Training.precision_weighted'),
        },
        {
          value: "recall_weighted",
          label: this.$t('Training.recall_weighted'),
        },
        {
          value: "neg_log_loss",
          label: this.$t('Training.neg_log_loss'),
        },
      ],
      reg_dic: [
        {
          value: "neg_root_mean_squared_error",
          label: this.$t('Training.neg_root_mean_squared_error'),
        },
        {
          value: "neg_mean_squared_error",
          label: this.$t('Training.neg_mean_squared_error'),
        },
        {
          value: "neg_mean_absolute_error",
          label: this.$t('Training.neg_mean_absolute_error'),
        },
        {
          value: "r2",
          label: this.$t('Training.r2'),
        },
        {
          value: "neg_mean_squared_log_error",
          label: this.$t('Training.neg_mean_squared_log_error'),
        },
        {
          value: "max_error",
          label: this.$t('Training.max_error'),
        },
      ],
      config: "",
      // 选择参数：快速、标准、深度
      selectParams: {},
      // 自定义参数
      customParams: {
        scoring: "",
        config: "",
        maxtime: "60",
        g: 5,
        p: 10,
        njobs: 2,
        es: 3,
        param_level: null, // 默认模式：1快速验证,2标准,3深度,0自定义
      },
      // 模型训练参数
      trainingParams: {},
      task_code: 0, // 任务执行状态：1执行中，0执行结束
      logMsg: [],
      CopyData: [],
      // 控制按钮显示
      startVisible: false, //开始分析的状态
      stopVisible: true, //停止分析的状态
      nextVisible: true, //下一步的状态
      lastVisible: false, //上一步的状态
      activeName: "first",
      getParamLevel: '',
      train_input: {},
      train_output: {},
      task_status: null,
      msg: "",
      FeatureName: "",
      mode: {},
      headerArray: [],
      headerData: '',
      headerOption: '',
      target_columnIndex: '',
      maxCPU:99,
      dataTrue:''
      };
  },
  computed: {
    ...mapState([
      "task_id",
      "task_name",
      "dataset_id",
      "task_type",
      "target_column",
      "desc",
      "dataset_name"
    ]),
  },
  watch: {
    task_code: "handleTaskStatus", // 监听任务状态值
    getParamLevel: function (){
      this.getTpotParams()
    }, // 监听模式
  },
  created() {
    this.getData();
    this.initTraining();
  },
  methods: {
    // methode转JSON
    toJSON(str){
    try {
      return JSON.parse(str)
    } catch (err) {
      return str
    }},
    // 调数据集详情接口，获取目标列
    getHeader(){
    this.axios
    .get(window.apis.SYS_DATASET + this.dataset_id + "/")
    .then((res) => {
      this.mode = res.data
      this.headerArray = this.toJSON(res.data.header)
      this.headerData = this.mode.read_mode_header=='y'?this.headerArray[this.target_columnIndex]:this.target_columnIndex
      this.headerOption = this.headerArray.map((index,item)=>{
      return {label:index,value:item}
    })
    })
    },
    // 获取自定义参数
    getData() {
      let url = window.apis.SYS_TEMPLATE;
      let url2 = window.apis.SYS_USER_INFO;
      let data = {};
      let dataArray = [];
      this.axios.get(url).then((res) => {
        for (let key in res.data) {
          data.value = res.data[key].id;
          data.label = res.data[key].name;
          dataArray.push(data);
          data = {};
        }
        this.config = dataArray;
        // 在获取config选项之后再获取已选中
        this.axios.get(url2).then((res) => {
          this.maxCPU = res.data.cpu_threshold
          if (this.customParams.config == "") {
            this.customParams.config = res.data.config;
            this.selectParams.config = res.data.config
          }
          // 循环获取默认参数的config
          for (let key in this.config) {
            if (this.config[key].value == res.data.config) {
              this.configCN = this.config[key].label;
              this.selectParams.config = res.data.config
            }
          }
        });
      });
    },
    // ws连接成功，后台返回的ws数据，组件要拿数据渲染页面等操作
    wsMessage(data) {
      // 这里写拿到数据后的业务代码
      if (data.t === 1) {
        // 日志
        this.logMsg.push(data.p);
        this.scrollToBottom();
      }
      if (data.t === 2) {
        // -2:未知状态, -1: 执行失败, 0: 执行成功, 1: 执行中, 2: 停止
        // 根据返回值，修改对应的 task_code: -2,-1,2 只有开始按钮可以点击，0只有停止按钮不可以点击，1只有停止按钮可以点击
        if (data.p == -2 || data.p == -1 || data.p == 2) {
          this.task_code = 2;
        }
        if (data.p == 0) {
          this.task_code = 3;
        }
        if (data.p == 1) {
          this.task_code = 1;
          // 插入“正在执行中...”
          this.logMsg.push(this.$t('Analysis.message'));
        }
      }
    },
    // ws连接失败，组件要执行的代码
    wsError() {
      // 比如取消页面的loading
      // 失败处理
    },
    requstWs() {
      // 防止用户多次连续点击发起请求，所以要先关闭上次的ws请求。
      closeWebsocket();
      // 跟后端协商，需要什么参数数据给后台
      const obj = {};
      // 发起ws请求
      sendWebsocket(
        window.apis.WS_SERVER + `tpot_${this.task_id}`,
        obj,
        this.wsMessage,
        this.wsError
      );
    },
    initTraining() {
      // 判断task_type是 二分类，多分类 还是 回归
      if (this.task_type == 0) {
        // 二分类问题
        this.dic = this.b_cls_dic;
        // 设置默认值
        this.customParams.scoring = "f1";
      } else if (this.task_type == 1) {
        // 多分类问题
        this.dic = this.m_cls_dic;
        // 设置默认值
        this.customParams.scoring = "f1_weighted";
      } else if (this.task_type == 2) {
        // 回归问题
        this.dic = this.reg_dic;
        // 设置默认值
        this.customParams.scoring = "neg_root_mean_squared_error";
      } else if (this.task_type == 3) {
        // 回归问题
        this.dic = this.reg_dic;
        // 设置默认值
        this.customParams.scoring = "neg_root_mean_squared_error";
      }

      //获取任务信息
      let modelUrl = window.apis.SYS_MODELS;
      this.axios
        .get(modelUrl + this.task_id + "/")
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.$store.commit("stage", res.data.stage);
            // 判断是否是首次执行
            this.dataTrue = JSON.parse(res.data.train_input);
            this.target_columnIndex = res.data.target_column;
            // 先从任务列表取到target_column，再获取表头列
            this.getHeader();
            this.FeatureName = this.dataTrue.features;
            if (this.dataTrue.scoring) {
              this.train_input = JSON.parse(res.data.train_input);
              this.getParamLevel = this.train_input.param_level;
              if (this.getParamLevel === 0) {
                this.activeName = "second";
                this.customParams = this.train_input;
                if (this.train_input.maxtime) {
                  this.customParams.maxtime = this.train_input.maxtime;
                } if (this.train_input.maxtime == 0){
                  this.customParams.maxtime = 0;
                }else {
                  this.customParams.maxtime = 60;
                }
              } else {
                this.activeName = "first";
                this.selectParams = this.train_input;
              }

              this.train_output = JSON.parse(res.data.train_output);
              this.task_status = this.train_output.task_status;
              this.msg = this.train_output.msg;

              if (this.task_status === 1) {
                // 执行中
                // 获取任务训练状态，如果是执行中，打印日志，上下步骤
                let url = window.apis.SYS_TPOT;
                this.axios
                  .get(url, {
                    params: {
                      task_id: this.task_id,
                    },
                  })
                  .then((res) => {
                    if (res.status === 200 || res.status === 201) {
                      if (res.data.task_code === 1) {
                        this.task_code = res.data.task_code;
                        // 插入“正在执行中...”
                        this.logMsg.push(this.$t('Analysis.message'));
                        this.requstWs();
                      } else {
                        // 异常终止
                        // 日志打印终止错误信息
                        this.logMsg.push(this.msg);
                        this.task_code = 2;
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else if (this.task_status === 2) {
                // 执行失败
                this.logMsg.push(this.msg);
                this.task_code = 2;
              } else if (this.task_status === 3) {
                // 执行成功
                this.logMsg.push(this.msg);
                this.task_code = 3;
              } else {
                console.log("" + this.task_status);
              }
            } else {
              // 模型训练从未执行
              this.activeName = "first";
              this.getParamLevel = 1;
            }
          } else {
            this.$message({
              message: this.$t('Report.message1'),
              type: "warning",
            });
            // 启动任务失败
            this.logMsg.push(res.data.message || this.$t('Analysis.message3'));
            this.task_code = 2;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTpotParams() {
      if (this.getParamLevel === 0) {
        console.log("do nothing");
      } else {
        let url = window.apis.SYS_TPOT_PARAMS;
        let params = {
          task_type: this.task_type,
          level: this.getParamLevel,
        };
        this.axios
          .get(url, {
            params: params,
          })
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              this.selectParams = JSON.parse(res.data[0].params);
              switch (this.selectParams.scoring) {
                case "neg_root_mean_squared_error":
                  this.selectParams.scoringCn = this.$t('Training.neg_root_mean_squared_error');
                  break;
                case "f1_weighted":
                  this.selectParams.scoringCn = this.$t('Training.f1_weighted');
                  break;
                case "f1":
                  this.selectParams.scoringCn = this.$t('Training.FScore');
                  break;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    startTraining() {
      if (this.dataTrue.scoring) {
        this.$confirm(this.$t('Training.confirm'), this.$t('Training.confirm1'), {
         //  confirmButtonText: '确定',
         //  cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.startTrainingTrue()
        }).catch(() => {

        });
      }else {
        this.startTrainingTrue()
      }
    },
    startTrainingTrue() {
        // 插入“正在启动中...”
        this.logMsg.push(this.$t('Training.message2'));
        // 如果是自定义 param_level=0
        if (this.activeName === "second") {
          this.trainingParams = this.customParams;
          this.trainingParams.param_level = 0;
        } else {
          this.trainingParams = this.selectParams;
          this.trainingParams.param_level = this.getParamLevel;
        }

        let url = window.apis.SYS_TPOT;
        let param = this.trainingParams;
        param.features = this.FeatureName;
        this.axios
            .post(url, param, {
              params: {
                task_id: this.task_id,
                action: "start",
              },
            })
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                this.task_code = res.data.task_code;
                if (this.task_code === 1) {
                  this.requstWs();
                } else {
                  // 启动任务失败
                  this.logMsg.push(res.data.message || this.$t('Analysis.message3'));
                  this.task_code = 2;
                }
              } else {
                // 启动任务失败
                this.logMsg.push(res.data.message || this.$t('Analysis.message3'));
                this.task_code = 2;
              }
            })
            .catch((error) => {
              this.$message({
                message: this.$t('Analysis.message3'),
                type: "warning",
              });
            });
    },
    stopTraining() {
      // 正在停止中...”
      this.logMsg.push(this.$t('Analysis.message4'));
      let url = window.apis.SYS_TPOT;
      this.axios
        .post(url, null, {
          params: {
            task_id: this.task_id,
            action: "stop",
          },
        })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            if(res.data.task_code == 1) {
              this.task_code = 2;
            }
          } 
        })
        .catch((error) => {
          this.$message({
            message: this.$t('Analysis.message10'),
            type: "warning",
          });
        });
    },
    handleTaskStatus(curVal, oldVal) {
      if (curVal === 1) {
        // 控制按钮显示
        (this.startVisible = true), //开始训练
          (this.stopVisible = false), //停止训练
          (this.nextVisible = true), //下一步的状态
          (this.lastVisible = true); //上一步的状态
      } else if (curVal === 0) {
        // 控制按钮显示
        (this.startVisible = false), //开始训练
          (this.stopVisible = true), //停止训练
          (this.nextVisible = false), //下一步的状态
          (this.lastVisible = false); //上一步的状态
      } else if (curVal === 2) {
        // 控制按钮显示
        (this.startVisible = false), //开始分析的状态
          (this.stopVisible = true), //停止分析的状态
          (this.nextVisible = true), //下一步的状态
          (this.lastVisible = false); //上一步的状态
      } else if (curVal === 3) {
        // 控制按钮显示
        (this.startVisible = false), //开始分析的状态
          (this.stopVisible = true), //停止分析的状态
          (this.nextVisible = false), //下一步的状态
          (this.lastVisible = true); //上一步的状态
      } else if (curVal === -1) {
        // 控制按钮显示
        (this.startVisible = false), //开始分析的状态
          (this.stopVisible = true), //停止分析的状态
          (this.nextVisible = true), //下一步的状态
          (this.lastVisible = false); //上一步的状态
      } else {
        // 其他默认失败
        (this.startVisible = false), //开始分析的状态
          (this.stopVisible = true), //停止分析的状态
          (this.nextVisible = true), //下一步的状态
          (this.lastVisible = false); //上一步的状态
        console.log(oldVal);
      }
    },
    nextStep() {
      // 判断任务
      this.$router.push({
        name: "Report",
      });
    },
    lastStep() {
      this.$router.push({
        name: "Parameter",
      });
    },
    handleClick(tab, event) {
      this.getParamLevel = 1;
      this.getTpotParams();
    },
    scrollToBottom() {
      this.$nextTick(() => {
        let asideMain = this.$el.querySelector(".asideMain");
        asideMain.scrollTop = asideMain.scrollHeight;
      });
    },
    Copy() {
      // 循环遍历this.logMsg
      for (var key in this.logMsg) {
        this.CopyData.push(this.logMsg[key]);
        this.CopyData.push(" \n");
      }
      var a = this.CopyData.join("");
      this.$copyText(a);
      this.$message({
        message: this.$t('Training.message3'),
        type: "success",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.defaultBox {
  margin: 30px;
  margin-top: 10px;
  .detailsBox {
    width: 100%;
    margin-top: 30px;
    font-size: 14px;
    border: 1px solid rgba(218, 223, 231, 0.5);
    div {
      width: 100%;
      height: auto;
      padding: 5px 10px;
      overflow: hidden;
      border-bottom: 1px solid rgba(218, 223, 231, 0.5);
      p {
        width: 25%;
        font-weight: 500;
      }
      span {
        width: 75%;
        text-align: left;
      }
    }
    div:hover {
      background: rgba(246, 247, 248, 0.5);
    }
    div:last-of-type {
      border: 0px;
    }
  }
}
.CustomBox {
  margin: 10px;
  margin-top: 10px;
  .detailsBox {
    width: calc(100% - 28px);
    margin: 15px 8px 0 20px;
    overflow: hidden;
    font-size: 14px;
    border: 1px solid rgba(218, 223, 231, 0.5);
    div {
      width: 100%;
      height: auto;
      padding: 5px 10px;
      overflow: hidden;
      border-bottom: 1px solid rgba(218, 223, 231, 0.5);
      p {
        width: 25%;
        font-weight: 500;
      }
      span {
        width: 75%;
        text-align: left;
      }
    }
    div:hover {
      background: rgba(246, 247, 248, 0.5);
    }
    div:last-of-type {
      border: 0px;
    }
  }
  .block {
   //  height: 40px;
    margin-top: 12px;
    span {
      width: 95px;
      display: inline-block;
      overflow: hidden;
      text-align: right;
      line-height: 32px;
    }
    .demonstration {
      display: inline-block;
      color: #333333;
      font-weight: 500;
      font-size: 14px;
    }
    .el-slider {
      width: 180px;
      display: inline-block;
      margin-left: 30px;
    }
    .el-input-number {
      display: inline-block;
      margin-left: 30px;
      overflow: hidden;
    }
    .el-select {
      width: 180px;
      overflow: hidden;
      margin-left: 30px;
    }
    .el-input {
      width: 180px;
      overflow: hidden;
      margin-left: 30px;
    }
    .el-radio-group {
      margin-left: 30px;
      overflow: hidden;
      margin-bottom: 30px;
    }
  }
  .block:first-of-type {
    margin-top: 0px;
  }
}
.Training {
  min-width: 1165px;
  margin-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
}
section {
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 500;
  .Step {
    margin: 0 15px;
    box-shadow: 0px 0px 7px #333333;
  }
}
.Slider {
  display: inline-block;
  margin: 41px 0 0 32px;
  position: relative;
  h5 {
    position: absolute;
    font-size: 14px;
    // font-family: 'Microsoft YaHei';
    font-weight: 500;
    color: #ff5e5e;
  }
}
.el-slider {
  width: 250px;
}
h3 {
  display: inline-block;
  font-size: 20px;
  // font-family: 'PingFang SC';
  font-weight: bold;
  color: #333333;
}
article {
  width: 490px;
  height: auto;
  min-height: 420px;
  border: 1px solid rgba(218, 223, 231, 0.5);
  text-align: left;
  .articleTitle {
    display: block;
    width: 100%;
    height: 47px;
    border-bottom: 1px solid rgba(218, 223, 231, 0.5);
    font-size: 16px;
    // font-family: "PingFang SC";
    font-weight: bold;
    color: #333333;
    line-height: 47px;
    padding-left: 20px;
  }
}
aside {
  position: relative;
  // width: auto;
  max-width:1250px;
  height: 420px;
  margin-bottom: 100px;
  .asideMainTitle {
    display: block;
    width: 100%;
    height: 47px;
    background: #ffffff;
    border: 1px solid rgba(218, 223, 231, 0.5);
    font-size: 16px;
    // font-family: "PingFang SC";
    font-weight: bold;
    color: #333333;
    line-height: 47px;
    text-align: left;
    padding-left: 20px;
  }
  .asideMain {
    width: calc(100vw - 820px);
    min-width: 640px;
    height: 372px;
    border: 1px solid rgba(218, 223, 231, 0.5);
    background: rgba(0, 0, 0, 0.8);
    overflow: auto;
    i {
      position: absolute;
      top: 70px;
      right: 30px;
      color: rgba($color: #ffffff, $alpha: 1);
      font-size: 20px;
      cursor: pointer;
      transition: all 200ms;
    }
    i:hover {
      color: rgba($color: #ffffff, $alpha: 0.6);
    }
    ul li {
      width: auto;
      list-style: none;
      text-align: left;
      color: #ffffff;
      font-size: 12px;
      font-family: monospace;
      white-space: pre;
      word-wrap: normal;
      -webkit-font-variant-ligatures: contextual;
      font-variant-ligatures: contextual;
    }
  }
  .analyse {
    margin: 20px;
  }
}
</style>