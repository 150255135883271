<template>
  <div class="Record">
    <el-table
      :data="loginRecord"
      style="width: 100%"
      height="400"
    >
      <el-table-column
        prop="created"
        :label="$t('PersonalCenter.Record.table1')"
      >
        <template slot-scope="scope">
          {{ scope.row.created | fmtdate }}
        </template>
      </el-table-column>
      <el-table-column
        prop="ip"
        :label="$t('PersonalCenter.Record.table2')"
      />
      <el-table-column
        prop="platform"
        :label="$t('PersonalCenter.Record.table3')"
      />
      <el-table-column
        prop="device"
        :label="$t('PersonalCenter.Record.table4')"
      />
    </el-table>
  </div>
</template>

<script>
export default {
  data(){
    return{
      loginRecord: []
    }
  },
  mounted() {
    this.getLoginRecord();
  },
  methods:{
    getLoginRecord() {
      let url = window.apis.USER_LOGIN_RECORD;
      this.axios.get(url)
      .then((res) => {
        if (res.status === 200) {
          this.loginRecord = res.data.results;
        } 
      })
      .catch((error) => {
        console.log(error);
      });
    },
  }
}
</script>

<style lang="scss" scoped>
    .Record{
        overflow: hidden;
        text-align: left;
        padding: 20px 30px 30px 30px; 
      //   height: 100%;
    }
</style>