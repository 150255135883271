<template>
<main>
   <p class="Register">
      <span>
         {{$t('Register.span')}}
      </span>
   </p>
   <el-form
      ref="registerForm"
      :rules="rulesRegister"
      :model="Register"
      status-icon>
      <el-form-item prop="rUser">
         <el-input
            id="username"
            v-model="Register.rUser"
            :placeholder="$t('Register.input1')" />
      </el-form-item>
      <el-form-item prop="rEmail">
         <el-input
            v-model="Register.rEmail"
            :placeholder="$t('Register.input2')"
            auto-complete="new-password" />
      </el-form-item>

      <el-form-item prop="rPass">
         <el-input
            v-model="Register.rPass"
            auto-complete="new-password"
            :placeholder="$t('Register.input3')"
            type="password"
            show-password />
      </el-form-item>
      <el-form-item prop="rCode">
         <el-input
            class="codeInputs l"
            v-model="Register.rCode"
            :placeholder="$t('Register.input4')" />
         <el-button
            class="prependCode r"
            type="primary"
            @click="getEmailVerCode(Register.rEmail, 'registerForm')"
            :disabled="codeTimeDisabled">
            <p class="prependCodeSlot">
               {{ codeTime }}
            </p>
         </el-button>
      </el-form-item>
      <el-form-item prop="iCode">
         <el-input
            v-model="identifyCodeInput"
            :placeholder="$t('Register.input5')"
            class="codeInput l" />
         <div class="code r" @click="refreshCode">
            <IdentifyCode
               :identify-code="identifyCode"
               :content-width="125"
               :content-height="40" />
         </div>
      </el-form-item>
      <p class="Privacy">
         {{$t('Register.Privacy1')}}<span @click="routePushOpen('PrivacyPolicy')" style="margin-top: -4px;" target="_blank"> {{$t('Register.Privacy2')}}</span>
      </p>
      <el-form-item>
         <el-button
            class="dialogButton"
            style="font-size: 16px;"
            @click="handleRegister('registerForm')"
            type="primary">
            {{$t('Register.button1')}}
         </el-button>
      </el-form-item>
   </el-form>
   <div class="dialogFooter">
      <p class="l" @click="routePush('Home')">
         {{$t('Register.button2')}}
      </p>
   </div>
</main>
</template>

<script>
import IdentifyCode from "vue-identifycode"; // 引入包
import {
   mapState
} from "vuex";
export default {
   data() {
      var identifyCodeInputFn = (rule, value, callback) => {
         if (
            this.identifyCodeInput.toUpperCase() ==
            this.identifyCode.toUpperCase() &&
            this.identifyCodeInput.toUpperCase() != ""
         ) {
            callback();
            // 万能验证码C518
         }
         // if (this.identifyCodeInput.toUpperCase() == "C518") {
         //    callback();
         // } else {
         if (
            this.identifyCodeInput.toUpperCase() ==
            this.identifyCode.toUpperCase()
         ) {
            callback();
         } else {
            callback(new Error(this.$t('Register.message1')));
         }
         // }
      };
      var EmailCode = (rule, value, callback) => {
         if (/^\d{6}$/.test(value)) {
            if (this.trueEmailCode.email != this.Register.rEmail || this.trueEmailCode.code != value) {
               let url = window.apis.EMAIL_CHECKCODE;
               this.axios
                  .get(url, {
                     params: {
                        email: this.Register.rEmail,
                        code: this.Register.rCode,
                     },
                  })
                  .then((res) => {
                     if (res.status === 200 || res.status === 201) {
                        // 验证是否有效
                        callback();
                        this.codeTimeDisabled = true;
                        //记录校验成功的验证码并保存，二次进入判断是否修改过验证码或者邮箱
                        this.trueEmailCode.email = this.Register.rEmail;
                        this.trueEmailCode.code = value;
                     } else {
                        this.trueEmailCode.email = '';
                        this.trueEmailCode.code = '';
                        callback(new Error(this.$t('config') == 'zh' ? res.data.zh : res.data.en || this.$t('ForgetPassword.message10')));
                     }
                  })
            } else {
               callback();
            }
         }
      };
      var usernameFn = (rule, value, callback) => {
         if (this.trueUsername != value) {
            let url = window.apis.SYS_USER_CHECK;
            this.axios
               .get(url, {
                  params: {
                     username: this.Register.rUser,
                  },
               })
               .then((res) => {
                  if (res.status === 200 || res.status === 201) {
                     // 验证是否有效
                     callback();
                     this.trueUsername = value;
                  } else {
                     this.trueUsername = '';
                     callback(new Error(this.$t('config') == 'zh' ? res.data.zh : res.data.en || "用户名重复"));
                  }
               })
         } else {
            callback();
         }
      };
      return {
         trueUsername: '',
         trueEmailCode: {
            email: '',
            code: ''
         },
         Register: {
            rUser: "",
            rPhone: "",
            rEmail: "",
            rPass: "",
            rCheckPass: "",
            rCode: "",
         },
         rulesRegister: {
            rUser: [{
                  required: true,
                  message: this.$t('Register.message2'),
                  trigger: "blur"
               },
               {
                  min: 5,
                  max: 14,
                  message: this.$t('Register.message3'),
                  trigger: "blur"
               },
               {
                  pattern: /^[\u4E00-\u9FA5A-Za-z0-9]+$/,
                  message: this.$t('Register.message4'),
                  trigger: "blur"
               },
               {
                  validator:usernameFn,
                  trigger: "blur"
               },
            ],
            rEmail: [{
                  required: true,
                  message: this.$t('Register.message5'),
                  trigger: "blur"
               },
               {
                  pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                  message: this.$t('Register.message6'),
                  trigger: "blur"
               },
            ],
            rCode: [{
                  required: true,
                  message: this.$t('Register.message5'),
                  trigger: "blur"
               },
               {
                  min: 6,
                  max: 6,
                  message: this.$t('Register.message8'),
                  trigger: "blur"
               },
               {
                  validator: EmailCode,
               },
            ],
            rPass: [{
                  required: true,
                  message: this.$t('Register.message9'),
                  trigger: "blur"
               },
               {
                  pattern: /^[a-zA-Z]\w{5,17}$/,
                  message: this.$t('Register.message10'),
                  trigger: "blur"
               },
            ],
            iCode: [{
               validator: identifyCodeInputFn,
               trigger: "blur"
            }],
         },
         codeTime: this.$t('Register.codeTime'),
         codeTimeDisabled: false,
         identifyCode: "",
         identifyCodeInput: "",
      };
   },
   methods: {
      handleRegister(formName) {
         // 校验合法性
         this.$refs[formName].validate((valid) => {
            if (valid) {
               let url = window.apis.SYS_USER_DOREG;
               console.log(this.Register.rUser);
               this.axios
                  .post(url, {
                     username: this.Register.rUser,
                     // telephone: this.Register.rPhone,
                     email: this.Register.rEmail,
                     password: this.Register.rPass,
                     language: this.$i18n.locale == 'zh' ? 0 : 1,
                  })
                  .then((res) => {
                     if (res.status === 200 || res.status === 201) {
                        this.$message({
                           message: this.$t('Register.Success'),
                           type: "success",
                        });
                        this.routePush('Home')
                     }
                  })
                  .catch((error) => {
                     this.$message({
                        message: this.$t('Login.message5'),
                        type: "warning",
                     });
                     console.log(error);
                  });
            } else {
               // 定位到错误的位置
               setTimeout(() => {
                  const isError = document.getElementsByClassName("is-error");
                  isError[0].querySelector("input").focus();
               }, 100);
               return false;
            }
         });
      },
      routePushOpen(routeName) {
         const {
            href
         } = this.$router.resolve({
            name: routeName
         })
         window.open(href)
      },
      routePush(name) {
         this.$router.push({
            name: name,
         });
      },
      getEmailVerCode(email, formName) {
         // 请求发送邮箱验证码
         this.$refs[formName].validateField("rEmail", (error) => {
            if (!error) {
               this.codeTimeDisabled = true;
               let url = window.apis.EMAIL_REGISTER_SENDCODE;
               this.axios
                  .get(url, {
                     params: {
                        email: email,
                        language: this.$i18n.locale == 'zh' ? 0 : 1,
                     },
                  })
                  .then((res) => {
                     if (res.status === 200 || res.status === 201) {
                        let timer = 120;
                        let timeres = setInterval(() => {
                           if (timer == 0) {
                              clearInterval(timeres);
                              this.codeTime = this.$t('ForgetPassword.codeTimeMessage1');
                              this.codeTimeDisabled = false;
                           } else {
                              this.codeTime = timer + ' s';
                              this.codeTimeDisabled = true;
                              timer--;
                           }
                        }, 1000);
                     } else {
                        this.codeTimeDisabled = false;
                     }
                  })
                  .catch((error) => {
                     console.log(error);
                  });
            }
         });
      },
      createCode() {
         let code = "";
         const codeLength = 4; // 验证码的长度
         // eslint-disable-next-line no-array-constructor
         const random = new Array(
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "J",
            "K",
            "L",
            "M",
            "N",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z"
         ); // 随机数
         for (let i = 0; i < codeLength; i++) {
            // 循环操作
            const index = Math.floor(Math.random() * 32); // 取得随机数的索引（0~35）
            code += random[index]; // 根据索引取得随机数加到code上
         }
         return code;
      },
      refreshCode() {
         this.identifyCode = this.createCode();
      },
   },
   mounted() {
      this.identifyCode = this.createCode();
   },
   components: {
      IdentifyCode
   },
   computed: {
      ...mapState(["BoxLogin", "BoxRegister", "BoxSendEmailCode", "bgImg"])
   },
};
</script>

<style lang="scss" scoped>
main {
   width: 400px;
   height: 620px;
   background: #FFFFFF;
   //border: 2px solid rgba(255,255,255,0.5);
   border-radius: 10px;
   overflow: hidden;

   .dialogFooter {
      width: 100%;
      margin-top: -14px;
      font-size: 14px;
      font-weight: 400;
      color: #396DD6;
      line-height: 22px;
      text-align: center;

      p {
         width: 100%;
         font-size: 16px;
         cursor: pointer;
      }
   }

   .Register {
      width: 100%;
      color: #396DD6;
      margin: 50px auto 43px auto;
      overflow: hidden;

      i {
         overflow: hidden;
         display: inline-block;
      }

      span {
         font-size: 22px;
         font-weight: 500;
         line-height: 35px;
         margin-left: 9px;
         overflow: hidden;
         display: inline-block;
      }
   }

   .el-input {
      .prepend {
         width: 50px;
         text-align: center;
      }
   }

   .dialogButton {
      margin-top: 18px;
      width: 300px;
   }

   .codeInputs {
      width: 125px;
      overflow: hidden;
   }

   .codeInput {
      width: 161px;
      overflow: hidden;
   }

   .code {
      width: 125px;
      height: 40px;
      overflow: hidden;
   }

   .Privacy {
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      line-height: 17px;
      margin-top: -6px;

      span {
         color: #666666;
         cursor: pointer;
      }
   }

   .prependCode {
      width: 160px;
   }
}

.el-form {
   text-align: left;
   margin: 0 auto;
   width: 300px;
}
</style>
