<template>
<div class="Auto" size="default">
   <!-- <breadcrumb /> -->
   <div style="height:43px;width:100%"></div>
   <div class="main">
      <header>
         <div class="flowChart">
            <div class="flowChartBox">
            <span><img
              class="flowChartIcon"
              src="../../../assets/img/abt.png"
              alt=""
            ></span>
            <h6>
              {{ $t('AutoDL.DL.AutoDL.vh436l') }}
            </h6>
          </div>
          <div
            class="flowChartT"
            :style="computedStyle('2')"
          />
            <div class="flowChartBox">
               <span :style="computedStyle('2')"><img class="flowChartIcon" src="../../../assets/img/dsfwe.png" alt=""></span>
               <h6>
                  <!-- 模型训练  -->
                  {{ $t("Auto.List4") }}
                  <!-- <el-tooltip
                     class="item"
                     effect="light"
                     content=""
                     placement="top">
                     <div slot="content" style="width:300px;line-height: 20px;text-align: justify;">
                        {{ $t("Auto.ListContent4") }}
                     </div>
                     <i class="iconfont iconbangzhu1" />
                  </el-tooltip> -->
               </h6>
            </div>
            <div class="flowChartT" :style="computedStyle('3')" />
            <div class="flowChartBox">
               <span :style="computedStyle('3')"><img class="flowChartIcon" src="../../../assets/img/eqwe.png" alt=""></span>
               <h6>
                  {{ $t("Auto.List5") }}
                  <!-- <el-tooltip
                     class="item"
                     effect="light"
                     placement="top">
                     <div slot="content" style="width:300px;line-height: 20px;text-align: justify;">
                        {{ $t("Auto.ListContent5") }}
                     </div>
                     <i class="iconfont iconbangzhu1" />
                  </el-tooltip> -->
               </h6>
            </div>
            <div class="flowChartT" :style="computedStyle('4')" />
            <div class="flowChartBox">
               <span :style="computedStyle('4')"><img class="flowChartIcon" src="../../../assets/img/yuce.png" alt=""></span>
               <h6>
                  <!-- 模型预测  -->
                  {{ $t("Auto.List7") }}
                  <!-- <el-tooltip
                     class="item"
                     effect="light"
                     content=""
                     placement="top">
                     <div slot="content" style="width:280px;line-height: 20px;text-align: justify;">
                        上传包含训练时输入的特征，调用生成的模型进行预测。若上传的文件中包含目标列，对于分类问题会展示预测结果生成的混淆矩阵，对于回归问题会展示预测的曲线图。
                        {{ $t("Auto.ListContent7") }}
                     </div>
                     <i class="iconfont iconbangzhu1" />
                  </el-tooltip> -->
               </h6>
            </div>
         </div>
      </header>
      <div class="routerTransition">
         <router-view />
      </div>
   </div>
</div>
</template>

<script>
import Breadcrumb from "../../../components/Breadcrumb";
import {
   mapState
} from "vuex";
export default {
   data() {
      return {
         active: 1,
         transitionName: "",
      };
   },
   components: {
      Breadcrumb,
   },
   watch: {
      $route(to, from) {
         if (to.meta.active > from.meta.active) {
            this.transitionName = "slide-left";
         } else {
            this.transitionName = "slide-right";
         }
      },
   },
   mounted() {
      if (!this.dataset_id_DL) {
         this.$message({
            message: this.$t('qing-xuan-ze'),
            type: "warning",
         });
         this.$router.back(-1)
      }
      this.active = JSON.parse(this.$route.matched[2].meta.active);
   },
   updated() {
      this.active = JSON.parse(this.$route.matched[2].meta.active);
   },
   computed: {
      computedStyle() {
         return function (type) {
            return {
               background: type <= this.active ?
                  "rgba(57, 109, 214, 0.5)" : "rgba(204, 204, 204, 1)",
            };
         };
      },
      ...mapState(["task_id_DL", "dataset_id_DL"]),
   },
};
</script>

<style lang="scss" scoped>
.main {
   width: auto;
   min-width: 1165px;
   margin: 0 33px;
}

header {
   width: auto;
   min-width: 900px;
   height: auto;
   // border: 1px solid rgba(218, 223, 231, 0.5);
   margin: 5px auto;
   margin-bottom: 0px;
   position: relative;

   // box-sizing: border-box;
   .flowChart {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      overflow: hidden;

      // margin-top: 15px;
      .flowChartBox {
         display: inline-block;

         // overflow: hidden;
         // margin-bottom: 10px;
         .flowChartIcon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
         }

         span {
            display: inline-block;
            width: 90px;
            height: 90px;
            background: rgba(57, 109, 214, 0.5);
            border-radius: 50%;
            // overflow: hidden;
            position: relative;

            img {
               width: 53px;
            }
         }

         h6 {
            //   width: 100px;
            white-space: nowrap;
            margin-top: 5px;
            font-size: 16px;
            font-weight: 500;
            color: #4568ee;
         }
      }

      .flowChartT {
         display: inline-block;
         width: 20%;
         height: 3px;
         background: rgba(57, 109, 214, 0.5);
         margin: 0 10px 40px 10px;
         overflow: hidden;
      }
   }
}
</style>
