<template>
<div class="Report">
   <div class="articleBoxBig">
      <h3 class="RTitle">
         <!-- 1 特征分析报告 -->
         1 {{ this.$t("Report.RTitle") }}
         <el-button
            class="Download r"
            type="primary"
            plain
            @click="DownloadPDF"
         >
            <i class="el-icon-download" />
            <!-- 下载PDF -->
            {{ this.$t("Report.download") }}
         </el-button>
      </h3>
      <span class="articleTitle">
         <!-- 1.1 数据集概要 -->
         1.1 {{ $t("Report.articleTitle1") }}
      </span>
      <div class="Slider" v-if="task_type != 3">
         <div class="ItemBox">
            <div class="elItem">
               <h4>
                  <!-- 数据集名称： -->
                  {{ $t("Report.elItem1") }}
               </h4>
               <p>{{ dataset.name }}</p>
            </div>
            <div class="elItem">
               <h4>
                  <!-- 任务名称： -->
                  {{ $t("Report.elItem2") }}
               </h4>
               <p>{{ task_name }}</p>
            </div>
            <div class="elItem">
               <h4>
                  <!-- 任务类型： -->
                  {{ $t("Report.elItem3") }}
               </h4>
               <p>{{ dataset.task_type_cn }}</p>
            </div>
            <div class="elItem">
               <h4>
                  <!-- 样本行数： -->
                  {{ $t("Report.elItem4") }}
               </h4>
               <p>{{ dataset.rows }}</p>
            </div>
            <div class="elItem">
               <h4>
                  <!-- 样本列数： -->
                  {{ $t("Report.elItem5") }}
               </h4>
               <p>{{ dataset.columns }}</p>
            </div>
            <div class="elItem">
               <h4>
                  <!-- 文件大小： -->
                  {{ $t("Report.elItem6") }}
               </h4>
               <p>{{ dataset.size | byteChange }}</p>
            </div>
            <div class="elItem see">
               <h4>
                  <!-- 数据描述： -->
                  {{ $t("Report.elItem7") }}
               </h4>
               <p>{{ dataset.desc }}</p>
            </div>
         </div>
      </div>
      <div class="Slider" v-else>
         <div class="ItemBox">
            <div class="elItem">
               <h4>
                  {{$t('parameter.elItem9')}}
               </h4>
               <p>{{ analysisReport.args_time_series[0] }}</p>
            </div>
            <div class="elItem">
               <h4>
                  {{$t('parameter.elItem10')}}
               </h4>
               <p>{{ analysisReport.args_time_series[1] }}</p>
            </div>
            <div class="elItem">
               <h4>
                  <!-- 任务类型： -->
                  {{$t('parameter.elItem4')}}
               </h4>
               <p>{{ analysisReport.task }}</p>
            </div>
            <div class="elItem">
               <h4>
                  <!-- 特征选择参数： -->
                  {{$t('parameter.elItem11')}}
               </h4>
               <p>{{ analysisReport.processed_feature_count }}</p>
            </div>
            <div class="elItem">
               <h4>
                  <!-- 选择的特征名称： -->
                  {{$t('parameter.elItem12')}}
               </h4>
               <p>{{ analysisReport.processed_features }}</p>
            </div>
         </div>
      </div>
   </div>
   <div class="articleBoxBig">
      <span class="articleTitle">
         <!-- 1.2 特征分析结果 -->
         1.2 {{ $t("Report.articleTitle2") }}
      </span>
      <div class="Slider">
         <div class="ItemBox">
            <div class="elItem">
               <!-- 原始特征值数量： -->
               <h4>{{ $t("Report.elItem2_1") }}</h4>
               <p>{{ analysisReport.original_feature_count }}</p>
            </div>
            <div class="elItem">
               <!-- 原始特征值名称： -->
               <h4>{{ $t("Report.elItem2_2") }}</h4>
               <p>{{ analysisReport.original_features }}</p>
            </div>
            <div class="elItem">
               <!-- 目标列： -->
               <h4>{{ $t("Report.elItem2_3") }}</h4>
               <p>{{ analysisReport.target_column }}</p>
            </div>
            <div class="elItem">
               <!-- 任务类型： -->
               <h4>{{ $t("Report.elItem2_4") }}</h4>
               <p>{{ analysisReport.task }}</p>
            </div>
            <div class="elItem">
               <!-- 特征选择参数： -->
               <h4>{{ $t("Report.elItem2_5") }}</h4>
               <p>{{ analysisReport.params }}</p>
            </div>
            <div class="elItem">
               <!-- 选择的特征个数： -->
               <h4>{{ $t("Report.elItem2_6") }}</h4>
               <p>{{ analysisReport.processed_feature_count }}</p>
            </div>
            <div class="elItem">
               <!-- 选择的特征名称： -->
               <h4>{{ $t("Report.elItem2_7") }}</h4>
               <p>{{ analysisReport.processed_features }}</p>
            </div>
            <div class="elItem">
               <!-- 缺失值处理方式： -->
               <h4>{{ $t("Report.elItem2_8") }}</h4>
               <p>{{ analysisReport.missing_handle_mode }}</p>
            </div>
            <div class="elItem">
               <!-- 特征分析耗时： -->
               <h4>{{ $t("Report.elItem2_9") }}</h4>
               <p>
                  {{ (analysisReport.end_time - analysisReport.start_time) | timeTrans }}
               </p>
            </div>
         </div>
      </div>
   </div>
   <div
      class="articleBox l"
      v-for="v in srcList"
      :key="v.value"
   >
      <div class="Slider">
         <!-- <img v-bind:src="`${downLoadUrl}${v}`" alt="" /> -->
         <el-image
            style="width: 100%; height: 100%"
            fit="contain"
            :src="v"
            :preview-src-list="srcList"
         />
      </div>
   </div>

   <div class="articleBoxBig">
      <h3 class="RTitle">2 {{ $t("Report.articleTitle4") }}</h3>
      <span class="articleTitle">2.1 {{ $t("Report.articleTitle3") }}</span>
      <div class="Slider">
         <div class="ItemBox">
            <div class="elItem">
               <h4>{{ $t("Report.elItem3_1") }}</h4>
               <p>{{ task.scoring }}</p>
            </div>
            <div class="elItem">
               <h4>{{ $t("Report.elItem3_2") }}</h4>
               <p>{{ task.g }}</p>
            </div>
            <div class="elItem">
               <h4>{{ $t("Report.elItem3_3") }}</h4>
               <p>{{ task.p }}</p>
            </div>
            <div class="elItem">
               <h4>{{ $t("Report.elItem3_4") }}</h4>
               <p>{{ task.njobs }}</p>
            </div>
            <div class="elItem">
               <h4>Early Stop：</h4>
               <p>{{ task.es }}</p>
            </div>
            <div class="elItem">
               <h4>{{ $t("Report.elItem3_6") }}</h4>
               <p>{{ trainingTime | timeTrans }}</p>
            </div>
         </div>
      </div>
   </div>
   <div class="tableBox">
      <span class="articleTitle">2.2 {{ $t("Report.articleTitle5") }}</span>
      <el-table
         :data="tableDataProcess"
         border
         size="mini"
         style="width: 100%"
      >
         <el-table-column
            v-for="(item, index) in tableData"
            :key="index"
            :width="tableData[index] == $t('Report.detail') ? 500 : ''"
            :label="`${tableData[index]}`"
            :prop="`${tableData[index]}`"
         />
      </el-table>
   </div>
   <section>
      <el-tooltip
         class="item"
         effect="light"
         :content="$t('pretreatment.buttonTop')"
         placement="top-start"
      >
         <el-button
            class="Step"
            type="primary"
            icon="el-icon-arrow-left"
            circle
            @click="lastStep"
         />
      </el-tooltip>
      <el-tooltip
         class="item"
         effect="light"
         :content="$t('pretreatment.buttonNext')"
         placement="top-start"
      >
         <el-button
            class="Step"
            type="primary"
            icon="el-icon-arrow-right"
            circle
            @click="nextStep"
         />
      </el-tooltip>
   </section>
   <div class="backTop" @click="backTop">
      <el-tooltip
         class="item"
         effect="light"
         :content="$t('MdmHome.tooltip')"
         placement="top-start"
      >
         <el-button
            type="primary"
            icon="el-icon-arrow-up"
            circle
            v-show="flag_scroll"
            class="top"
         />
      </el-tooltip>
   </div>
</div>
</template>

<script>
import {
   mapState
} from "vuex";

export default {
   data() {
      return {
         flag_scroll: false,
         scroll: "",

         tableDataProcess: [],
         tableData: [],
         tableDataTitle: [],
         imgList: [],
         downLoadUrl: "",
         srcList: [],
         analysisReport: [],
         dataset: [],
         task: {},
         trainingTime: 0,
         pdfUrl: "",
         eng_pdfUrl: "",
         i18n: "",
      };
   },
   methods: {
      //滑动超过200时显示按钮
      handleScroll(e) {
         let scrollTop = e.target.scrollTop;
         if (scrollTop > 200) {
            this.flag_scroll = true;
         } else {
            this.flag_scroll = false;
         }
      },
      //返回顶部事件
      backTop() {
         document.getElementById("router-view").scrollIntoView({
            behavior: "smooth",
            block: "start"
         });
      },

      // eslint-disable-next-line no-unused-vars
      objectSpanMethod({
         row,
         column,
         rowIndex,
         columnIndex
      }) {
         console.log(row, column, rowIndex, columnIndex);
         if (columnIndex === 0) {
            if (rowIndex % 2 === 0) {
               return {
                  rowspan: 2,
                  colspan: 1,
               };
            } else {
               return {
                  rowspan: 0,
                  colspan: 0,
               };
            }
         }
      },
      nextStep() {
         this.$router.push({
            name: "Output",
         });
      },
      lastStep() {
         this.$router.push({
            name: "Training",
         });
      },
      getFeatureReport() {
         let SYS_TPOT_REPORT = window.apis.SYS_TPOT_REPORT;
         let SYS_DATASET = window.apis.SYS_DATASET;
         let SYS_MODELS = window.apis.SYS_MODELS;
         let SYS_FEATURE_REPORT = window.apis.SYS_FEATURE_REPORT;
         this.downLoadUrl = window.apis.SERVER + window.apis.SYS_DOWNLOAD;
         this.axios
            .get(SYS_TPOT_REPORT + this.task_id + "/")
            .then((res) => {
               if (res.status === 200 || res.status === 201) {
                  this.trainingTime = res.data.end_time - res.data.start_time; // 模型训练时间
                  this.tableDataProcess = res.data.other_pipeline;
                  this.$store.commit("DownloadSRC", res.data.model_result);
                  this.pdfUrl = res.data.report_pdf; // pdf下载url
                  this.eng_pdfUrl = res.data.report_eng_pdf; //英文状态pdf下载url
                  this.tableData = Object.keys(res.data.other_pipeline[0]); //获取key值
                  console.log("111", this.tableData);
               } else {
                  this.$message({
                     message: this.$t('Report.message1'),
                     type: "warning",
                  });
               }
            })
            .catch((error) => {
               console.log(error);
            });
         this.axios.get(SYS_DATASET + this.dataset_id + "/").then((res) => {
            this.dataset = res.data;
            switch (res.data.task_type) {
               case 0:
                  this.dataset.task_type_cn = this.$t('MdmHome.optionsLabel2');
                  break;
               case 1:
                  this.dataset.task_type_cn = this.$t('MdmHome.optionsLabel3');
                  break;
               case 2:
                  this.dataset.task_type_cn = this.$t('MdmHome.optionsLabel4');
                  break;
               case 3:
                  this.dataset.task_type_cn = this.$t('MdmHome.optionsLabel5');
                  break;
            }
         });
         this.axios.get(SYS_FEATURE_REPORT + this.task_id + "/").then((res) => {
            this.analysisReport = res.data;
            // 循环添加token
            for (let key in this.analysisReport.img) {
               this.imgList.push(this.analysisReport.img[key] + "?token=" + localStorage.token.split(" ")[1]);
            }
            this.srcList = this.imgList.map((item) => this.downLoadUrl + item);
         });
         this.axios.get(SYS_MODELS + this.task_id + "/").then((res) => {
            this.task = JSON.parse(res.data.train_input);
         });
      },
      DownloadPDF() {
         this.i18n = this.$i18n.locale;
         if (this.i18n == "zh") {
            if (this.pdfUrl) {
               window.open(this.downLoadUrl + this.pdfUrl + "?token=" + localStorage.token.split(" ")[1], "_self");
            } else {
               this.$message({
                  message: "文件不存在",
                  type: "warning",
               });
            }
         } else {
            if (this.eng_pdfUrl) {
               window.open(this.downLoadUrl + this.eng_pdfUrl + "?token=" + localStorage.token.split(" ")[1], "_self");
            } else {
               this.$message({
                  message: "The file is not exist",
                  type: "warning",
               });
            }
         }
      },
   },
   dataDispose() {},
   mounted() {
      this.getFeatureReport();
      window.addEventListener("scroll", this.handleScroll, true);
   },
   beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
   },
   computed: {
      ...mapState(["task_id", "task_name", "dataset_id", "task_type", "target_column", "desc"]),
   },
};
</script>

<style lang="scss" scoped>
.RTitle {
   width: 100%;
   font-size: 18px;
   font-weight: bold;
   color: #333333;
   overflow: hidden;
   display: block;
}

.Report {
   text-align: left;
}

.Slider {
   position: relative;
   overflow: hidden;

   .ItemBox {
      width: 100%;
      border: 1px solid rgba(218, 223, 231, 0.5);
      overflow: hidden;

      .see {
         height: auto;
         word-break: break-all;
         word-wrap: break-word;
      }

      .elItem {
         width: 100%;
         margin: 0;
         border-bottom: 1px solid rgba(218, 223, 231, 0.5);
         transition: all 200ms;

         h4 {
            width: 200px;
            text-align: left;
            display: inline-block;
            margin-left: 16px;
            line-height: 36px;
            font-size: 14px;
            font-weight: bold;
         }

         p {
            display: inline-block;
            margin: 0 50px;
            line-height: 36px;
            font-weight: 500;
            font-size: 12px;
         }
      }

      .elItem:hover {
         background: rgba(246, 247, 248, 0.5);
      }

      .elItem:last-of-type {
         border: 0px;
      }
   }
}

.articleBox {
   width: 50%;
   display: inline-block;
   margin-top: 30px;
   overflow: hidden;

   .Slider {
      width: 100%;
      height: auto;
      border: 1px solid rgba(218, 223, 231, 0.5);
      // img {
      //   display: inline-block;
      //   width: 100%;
      // }
   }
}

.articleBoxBig {
   width: 100%;
   display: inline-block;
   margin-top: 30px;
}

.articleTitle {
   display: block;
   width: 100%;
   height: 30px;
   margin-top: 30px;
   font-size: 16px;
   font-weight: bold;
   color: #4e4f54;
}

.tableBox {
   width: auto;
   position: relative;
   margin: 30px 0;
   // h5 {
   //   width: 100%;
   //   height: 50px;
   //   font-size: 16px;
   //   font-weight: bold;
   //   color: #4e4f54;
   //   text-align: left;
   // }
}

section {
   position: fixed;
   right: 100px;
   bottom: 50px;
   z-index: 100;

   .Step {
      margin: 0 15px;
      box-shadow: 0px 0px 7px #333333;
   }
}

.scrollTop {
   width: 100%;
   height: 100%;
   overflow-y: scroll;
}

.backTop {
   position: fixed;
   bottom: 120px;
   z-index: 10;
   right: 40px;
   background: white;

   .top {
      width: 41px;
      height: 41px;
   }
}
</style>
