// import 'babel-polyfill'; 
import '@babel/polyfill';
import promise from 'es6-promise'
promise.polyfill()
import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../src/theme/index.css'
import axios from './api/http'
import VueAxios from 'vue-axios'
import VueWechatTitle from 'vue-wechat-title'
import * as echarts from 'echarts'
import moment from 'moment-timezone'
import VueClipboard from 'vue-clipboard2'
import GlobalVal from './api/global_val'
import Privilage from './api/Privilage'
import Tooltip from './components/Tooltip.vue'
import { i18n } from './lang'  // 路径要视代码目录结构，看lang文件夹和main.js文件的层次

import './assets/style/rewrite.scss'
import '../src/icons/autoML/iconfont.css' //引入iconfont

// element-ui组件国际化
Vue.use(ElementUI, {
   i18n: (key, value) => i18n.t(key, value)
})



Vue.use(VueClipboard)

Vue.filter('fmtdate', (v) => {
   return moment(v).format('YYYY-MM-DD HH:mm:ss')
})

Vue.filter('ellipsis_s', (v) => {
   if (!v) return ''
   if (v.length > 32) {
      return v.slice(0, 32) + '...'
   }
   return v
})

Vue.filter('ellipsis_l', (v) => {
   if (!v) return ''
   if (v.length > 128) {
      return v.slice(0, 128) + '...'
   }
   return v
})

Vue.filter('byteChange', (v) => {
   if (!v) return ''
   var num = 1024.00; //byte
   if (v < num)
      return v + " B";
   if (v < Math.pow(num, 2))
      return (v / num).toFixed(2) + " KiB"; //kb
   if (v < Math.pow(num, 3))
      return (v / Math.pow(num, 2)).toFixed(2) + " MiB"; //M
   if (v < Math.pow(num, 4))
      return (v / Math.pow(num, 3)).toFixed(2) + " GiB"; //G
   return (v / Math.pow(num, 4)).toFixed(2) + " TiB"; //T
})

Vue.filter('timeTrans', (msTime) => {
   if (!msTime) return  ` 0 ${i18n.t('tian')} 0 ${i18n.t('shi')} 0 ${i18n.t('fen')} 0 ${i18n.t('miao')}`

   let time = msTime;
   let day = Math.floor(time / 60 / 60 / 24);
   let hour = Math.floor(time / 60 / 60);
   hour = hour.toString().padStart(2, "0");
   let minute = Math.floor(time / 60) % 60;
   minute = minute.toString().padStart(2, "0");
   let second = Math.floor(time) % 60;
   second = second.toString().padStart(2, "0");

   return ` ${day} ${i18n.t('tian')} ${hour} ${i18n.t('shi')} ${minute} ${i18n.t('fen')} ${second} ${i18n.t('miao')}`
})

Vue.prototype.building = function () {
   this.$message('正在建设中');
}

Vue.prototype.$echarts = echarts

Vue.prototype.$GlobalVal = GlobalVal
Vue.prototype.$Privilage = Privilage



Vue.use(VueWechatTitle)

// Vue.use(ElementUI)
Vue.use(VueAxios, axios)
Vue.config.productionTip = false
Vue.component('tooltip', Tooltip)
new Vue({
   data() {
      return {
         Privilage: Privilage,
         GlobalVal: GlobalVal
      };
   },
   router,
   i18n,
   store,
   render: h => h(App)
}).$mount('#app')

// 点击事件节流优化 1s内只能操作一次
const on = Vue.prototype.$on
Vue.prototype.$on = function (event, func) {
   let previous = 0
   let newFunc = func
   if (event === 'click') {
      newFunc = function () {
         const now = new Date().getTime()
         if (previous + 1000 <= now) {
            func.apply(this, arguments)
            previous = now
         }
      }
   }
   on.call(this, event, newFunc)
}