<template>
  <div class="Mail">
    <div class="AccountTitle l">
      <ul>
        <li>{{$t('PersonalCenter.Mail.Li1')}}</li>
        <li>{{$t('PersonalCenter.Mail.Li2')}}</li>
        <li>{{$t('PersonalCenter.Mail.Li3')}}</li>
      </ul>
    </div>
    <div class="AccountInput l">
      <el-form
        ref="form"
        :rules="rulesMail"
        :model="resetMail"
        status-icon
      >
        <el-form-item>
          <el-input
            v-model="user.email"
            :disabled="true"
          />
        </el-form-item>
        <el-form-item prop="newEmail">
          <el-input
            v-model="resetMail.newEmail"
            :placeholder="$t('PersonalCenter.Mail.newEmail')"
          />
        </el-form-item>
        <el-form-item prop="verCode">
          <el-input
            v-model="resetMail.verCode"
            :placeholder="$t('PersonalCenter.Mail.Code')"
          >
            <el-button
              class="prependCoder"
              slot="append"
              @click="getEmailVerCode('form')"
              :disabled="codeTimeDisabled"
            >
              <p class="prependCodeSlot">
                {{ codeTime }}
              </p>
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            class="l elbutton"
            @click="bindEmail('form')"
            type="primary"
          >
            {{$t('PersonalCenter.Mail.button')}}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    var EmailCode = (rule, value, callback) => {
      if(/^\d{6}$/.test(value)){
        // 请求发送邮箱验证码
        this.axios.get(window.apis.EMAIL_CHECKCODE, {
          params: {
            email: this.resetMail.newEmail,
            code: this.resetMail.verCode
          }
        })
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                this.$message({
                  message: this.$t('config')=='zh'?res.data.zh:res.data.en,
                  type: 'success',
                });
                this.codeTimeDisabled = true;
                callback()
              } else {
                callback(new Error(this.$t('config')=='zh'?res.data.zh:res.data.en));
              }
            })
      }
    };
    var EmailTrue = (rule, value, callback) => {
      if(this.user.email == value){
          callback(new Error(this.$t('PersonalCenter.Mail.Message5')));
        }else{
          callback();
        }
    };
    return{
      rulesMail: {
        newEmail: [
          { required: true, message: this.$t('PersonalCenter.Mail.Message1'), trigger: "blur" },
          {
            pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
            message: this.$t('PersonalCenter.Mail.Message2'),
          },
          { validator: EmailTrue, trigger: "blur" }
        ],
        verCode: [
          { required: true, message: this.$t('PersonalCenter.Mail.Message3'), trigger: "blur" },
          { min: 6, max: 6, message: this.$t('PersonalCenter.Mail.Message4'), trigger: "blur" },
          { validator: EmailCode, trigger: "blur" }
        ],
      },
      user: {},
      resetMail: {},
      codeTime: this.$t('PersonalCenter.Mail.codeTime1'),
      codeTimeDisabled: false,
    }
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      let url = window.apis.SYS_USER_INFO;
      this.axios.get(url)
      .then((res) => {
        if (res.status === 200) {
          this.user = res.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    },
    getEmailVerCode(formName) {
      // 请求发送邮箱验证码
      this.$refs[formName].validateField('newEmail', (error) => {
      if(!error){
      this.codeTimeDisabled = true;
      let url = window.apis.EMAIL_REGISTER_SENDCODE;
      console.log("url:"+url)
      this.axios.get(url, {
        params: {
          email: this.resetMail.newEmail,
          language: this.$i18n.locale=='zh'?0:1,
        }
      })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          let timer = 120;
          let timeres = setInterval(() => {
            if(timer == 0){
              clearInterval(timeres);
              this.codeTime = this.$t('PersonalCenter.Mail.codeTime1');
              this.codeTimeDisabled = false;
            }else{
              this.codeTime = timer + " s";
              this.codeTimeDisabled = true;
              timer--;
            }
          },1000)
        } else {
          this.$message({
            message: this.$t('PersonalCenter.Mail.message7'),
            type: 'warning',
          });
          this.codeTimeDisabled = false;
        }
      })
      .catch((error) => {
        console.log(error);
      });}})
    },
    bindEmail(formName) {
      // 校验合法性
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url = window.apis.SYS_USER;
          this.axios.put(url + this.user.id + "/", {
            email: this.resetMail.newEmail
          })
          .then((res) => {
            if (res.status === 200) {
              this.$message({
                message: this.$t('PersonalCenter.Mail.message6'),
                type: "success",
              });
              this.user = res.data;
              this.resetMail.newEmail = '';
              this.resetMail.verCode = '';
            }
          })
          .catch((error) => {
            console.log(error);
          });
        } else {
          // 定位到错误的位置
          setTimeout(()=>{
            const isError= document.getElementsByClassName('is-error')
            isError[0].querySelector('input').focus()
          },100)
          return false;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item {
  margin: 0;
}
.el-input.is-disabled .el-input__inner {
  background:#D5DCEC;
}
  .Mail {
    overflow: hidden;
    text-align: left;
    padding: 20px 0 30px 91px;
    .AccountTitle {
      //   width: 120px;
      li {
        height: 40px;
        display: block;
        font-size: 15px;
        line-height: 40px;
        font-weight: 500;
        color: #333333;
        list-style: none;
        text-align: right;
        margin-right: 20px;
        margin-top: 17px;
      }
    }
    .AccountInput {
      width: 280px;
      height: 100%;
      .el-input {
        margin-top: 17px;
      }
      .elbutton {
        margin: 0;
        margin-top: 17px;
      }
    }
  }
</style>